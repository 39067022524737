import { ReactComponent as DotsIcon } from "@assets/icons/dots-horizontal.svg";
import { ReactComponent as EditIcon } from "@assets/icons/edit-01.svg";
import Popover from "@components/Popover";
import { TrashIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import { useState } from "react";
import ProtectedComponent from "src/components/ProtectedComponent";
import { PERMISSIONS_TYPE } from "src/constants/permissionsConstant";
import { PermissionTypes } from "src/types";
import { AvailableCustomSources } from "../../create-policy/types";
import { analyticsInstance } from "src/config/event-analytics";
import { CustomAPIActions } from "src/constants/EventAnalytics";

type Props = {
  row: AvailableCustomSources;
  deleteRow: (row: AvailableCustomSources) => void;
  updateRow: (row: AvailableCustomSources) => void;
};

const SourceTableRowOptions = ({
  row,
  deleteRow,
  updateRow
}: Props) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const deleteSourceApi = () => {
    deleteRow(row);
    setIsPopoverOpen(false);
    analyticsInstance.triggerAnalytics(CustomAPIActions.DELETE,
    {
      name:row.name
    });
  }

  const updateSourceApi = () => {
    updateRow(row);
    setIsPopoverOpen(false);
    
  }

  return (
    <Popover>
      {({ open }) => (
        <>
          <Popover.Button
            className={clsx(
              "w-7 h-7 duration-150 p-1.5 flex rounded-md hover:bg-neutral-50",
              open ? "bg-neutral-50" : ""
            )}
          >
            <DotsIcon
              className="w-4 h-4 stroke-neutral-600 [&>path]:stroke-neutral-600"
              onClick={() => setIsPopoverOpen(true)}
            />
          </Popover.Button>
          {isPopoverOpen && (
            <Popover.Panel className="text-xs" static>
              <ProtectedComponent
                type={
                  PERMISSIONS_TYPE.dataSourcesCustom as PermissionTypes
                }
                action="edit"
              >
                <div
                  className="p-2 flex gap-2 cursor-pointer items-center text-neutral-black font-b2-medium bg-white  hover:bg-neutral-25"
                  onClick={updateSourceApi}
                >
                  <EditIcon className="w-4 h-4 stroke-neutral-500" />
                  Update
                </div>
              </ProtectedComponent>
              <ProtectedComponent
                type={
                  PERMISSIONS_TYPE.dataSourcesCustom as PermissionTypes
                }
                action="delete"
              >
                <div
                  className="p-2 flex gap-2 border-t cursor-pointer items-center font-b2-medium bg-white  hover:bg-neutral-25 !text-error-600"
                  onClick={deleteSourceApi}
                >
                  <TrashIcon className="z-10 h-4 w-4 cursor-pointer [&>path]:stroke-error-600" />
                  Delete
                </div>
              </ProtectedComponent>
            </Popover.Panel>
          )}
        </>
      )}
    </Popover>
  );
};

export default SourceTableRowOptions;
