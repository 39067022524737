import { ReactComponent as PlusIcon } from "@assets/icons/workflow/plus-add-condition.svg";
import Button from "@components/Button";
import QueryWrapper from "@components/QueryWrapper";
import Shimmer from "@components/Shimmer";
import {
  getErrors,
  getWorkflowOutcomeConfig,
  useUpdateWorkflowOutcomeConfig,
} from "@screens/workflow/queries";
import { getOutcomeConfig } from "@screens/workflow/studio/components/Outcome/queries";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getNetworkErrorText, notify } from "@utils/utils";
import { clsx } from "clsx";
import { useFieldArray, useForm } from "react-hook-form";
import { useWorkflowContext } from "../../WorkflowContext";
import { analyticsInstance } from "src/config/event-analytics";
import { PolicyStudioActions } from "src/constants/EventAnalytics";
import OutcomeFieldRow from "./OutcomeFieldRow";

const MAX_OUTCOME_COUNT = 40;

const Outcome = () => {
  const { workflow, isWorkflowEditable } = useWorkflowContext();

  const globalConfig = useQuery(getWorkflowOutcomeConfig(workflow?.id!));
  const queryClient = useQueryClient();

  const form = useForm<{
    variables: Array<{
      type: "text" | "number" | "boolean";
      name: string;
      isMandatory: boolean;
    }>;
  }>({
    values: {
      variables: globalConfig.data?.data.data ?? [
        {
          name: "",
          type: "text",
          isMandatory: false,
        },
      ],
    },
    defaultValues: {
      variables: globalConfig.data?.data.data ?? [
        {
          name: "",
          type: "text",
          isMandatory: false,
        },
      ],
    },
  });

  const variablesFieldArray = useFieldArray({
    name: "variables",
    control: form.control,
    rules: {
      maxLength: {
        value: MAX_OUTCOME_COUNT,
        message: "Max " + MAX_OUTCOME_COUNT + " outcomes",
      },
    },
  });

  const updateConfig = useUpdateWorkflowOutcomeConfig();

  const onSave = form.handleSubmit((data) => {
    updateConfig.mutate(
      {
        workflowId: workflow?.id!,
        variables: data.variables,
      },
      {
        onSuccess: () => {
          
          const totalCount = {
            text: 0,
            number: 0,
            boolean: 0,
          };
          
          data.variables.forEach(({ type }) => {
            if (totalCount.hasOwnProperty(type)) {
              totalCount[type as keyof typeof totalCount] += 1;
            }
          });
          analyticsInstance.triggerAnalytics(
            PolicyStudioActions.SIDEBAR_INPUT_PARAMETERS_DOWNLOAD_SAVE,
            {
              bucket_name: workflow?.policyName ?? "",
              version: workflow?.policyVersion ?? "",
              ...totalCount,
            }
          );

          queryClient.invalidateQueries(
            getOutcomeConfig({
              workflowId: workflow?.id!,
            })
          );
          queryClient.invalidateQueries(
            getWorkflowOutcomeConfig(workflow?.id!)
          );
          queryClient.invalidateQueries(getErrors(workflow?.id!));
          notify({
            title: "Updated",
            text: "Updated workflow config",
            type: "success",
          });
        },
        onError: (err) =>
          notify({ title: "Failed", text: getNetworkErrorText(err) }),
      }
    );
  });

  const addOutcomes = () => {
      if (MAX_OUTCOME_COUNT < variablesFieldArray.fields.length ) return null;

      analyticsInstance.triggerAnalytics(
        PolicyStudioActions.SIDEBAR_OUTPUT_ADD,
        {
          bucket_name: workflow?.policyName ?? "",
          version: workflow?.policyVersion ?? "",
        }
      );

      variablesFieldArray.append({
        name: "",
        type: "text",
        isMandatory: false,
      });
  }

  const deleteOutComeParam = (index:number) => {
    analyticsInstance.triggerAnalytics(
      PolicyStudioActions.SIDEBAR_OUTPUT_DELETE,
      {
        bucket_name: workflow?.policyName ?? "",
        version: workflow?.policyVersion ?? "",
      }
    );
    variablesFieldArray.remove(index)
  }

  return (
    <div className="relative flex flex-col h-full pt-3">
      <QueryWrapper
        query={globalConfig}
        loader={
          <>
            <Shimmer w="100%" h="32px" />
            <Shimmer w="100%" h="32px" />
            <Shimmer w="100%" h="32px" />
          </>
        }
      >
        {() => {
          return (
            <>
              <div
                className="grid gap-2 items-start pb-4 px-4 overflow-y-auto"
                style={{ gridTemplateColumns: "7fr 3fr 16px" }}
              >
                <span className="font-b2 text-neutral-black">
                  Variable Name
                </span>
                <span className="font-b2 text-neutral-black">Type</span>{" "}
                <span />
                {variablesFieldArray.fields.map((field, index) => (
                    <OutcomeFieldRow
                        deleteOutcomeParam={deleteOutComeParam}
                        form={form}
                        field={field}
                        index={index}
                        isWorkflowEditable={isWorkflowEditable}
                        isMandatory={Boolean(globalConfig.data?.data.data?.[index]?.isMandatory)}
                      />))}
              </div>
              {form.formState.errors.variables?.root?.message && (
                <div className="text-error-500 px-4 font-b2">
                  {form.formState.errors.variables?.root?.message}
                </div>
              )}
              {isWorkflowEditable && (
                <div
                  className={clsx(
                    "font-b2-medium px-4 cursor-pointer mt-1 text-neutral-black group/add-expr hover:text-primary-900 w-max flex items-center gap-1 mb-1",
                    variablesFieldArray.fields.length >= MAX_OUTCOME_COUNT &&
                      "opacity-80 !cursor-not-allowed"
                  )}
                  onClick={addOutcomes}
                >
                  <PlusIcon className="w-4 h-4 group-hover/add-expr:[&>path]:stroke-primary-900 [&>path]:stroke-neutral-black" />
                  Add
                </div>
              )}
            </>
          );
        }}
      </QueryWrapper>
      <div className="sticky bottom-0 mt-auto w-full flex justify-end py-2 pr-2 bg-neutral-0 border-t border-neutral-100">
        <Button disabled={!isWorkflowEditable} onClick={onSave}>
          Save
        </Button>
      </div>
    </div>
  );
};

export default Outcome;
