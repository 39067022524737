import { NO_CHANGE_LOG } from "src/constants/imageConstants";

export const NoLogGeneratedState = () => {
  return (
    <div className="mx-auto flex flex-col mt-36 items-center">
      <img className="w-[195px] h-[138px]" src={NO_CHANGE_LOG} alt="log_icon" />
      <span className="font-b1-medium mb-3 text-neutral-black">
        Changelogs are unavailable
      </span>
      <span className="font-b2-medium text-neutral-500">
        Please ensure you have at least one active version to view the
        changelogs.
      </span>
    </div>
  );
};
