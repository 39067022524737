import { useReducer } from "react";
import { useParams } from "react-router-dom";
import { useSaveSettings } from "@screens/workflow/queries";
import { Settings } from "@screens/workflow/types";
import Button from "@components/Button";
import Toggle from "@components/Toggle";
import { useWorkflowContext } from "../../WorkflowContext";
import { analyticsInstance } from "src/config/event-analytics";
import { PolicyStudioActions } from "src/constants/EventAnalytics";

const reducer = (state: Settings, payload: Partial<Settings>) => {
  return { ...state, ...payload };
};

export default function WorkflowSettings({
  initialSettings,
}: {
  initialSettings: Settings;
}) {
  const [settings, dispatch] = useReducer(reducer, initialSettings);
  const { workflowId } = useParams();

  const { isWorkflowEditable, workflow } = useWorkflowContext();

  const saveSettings = useSaveSettings();

  const handleNullableInput = (value:boolean) => {
    dispatch({ isNullableInputsAllowed: value })
    analyticsInstance.triggerAnalytics(
      PolicyStudioActions.SIDEBAR_SETTINGS_ENABLE_NULLABLE_INPUTS,
      {
        bucket_name: workflow?.policyName ?? "",
        version: workflow?.policyVersion ?? "",
      }
    );
  }

  const handleErrorHandleInput = (value:boolean) => {
    dispatch({ continueEvalWithDataSourceErr: value });
    analyticsInstance.triggerAnalytics(
      PolicyStudioActions.SIDEBAR_SETTINGS_ENABLE_SOURCE_ERROR_HANDLING,
      {
        bucket_name: workflow?.policyName ?? "",
        version: workflow?.policyVersion ?? "",
      }
    );
  }

  return (
    <div className="pt-4 flex flex-col h-full gap-2">
      <div className="px-4 flex justify-between font-b1 w-full">
        <span>Enable nullable inputs</span>
        <Toggle
          disable={!isWorkflowEditable}
          value={!!settings.isNullableInputsAllowed}
          onChange={handleNullableInput}
        />
      </div>
      {!!settings.isNullableInputsAllowed && (
        <div className="px-2 py-1 w-[calc(100%-32px)] mx-4 font-b2 bg-warning-100 border rounded-md border-warning-400">
          <span className="font-semibold">Warning:</span> Please take care to
          check for `nil` values before using them when this setting is enabled
        </div>
      )}
      <div className="px-4 flex justify-between font-b1 w-full">
        <span>Enable data source error handling</span>
        <Toggle
          disable={!isWorkflowEditable}
          value={!!settings.continueEvalWithDataSourceErr}
          onChange={handleErrorHandleInput}
        />
      </div>
      {!!settings.continueEvalWithDataSourceErr && (
        <div className="px-2 py-1 w-[calc(100%-32px)] mx-4 font-b2 bg-warning-100 border rounded-md border-warning-400">
          <span className="font-semibold">Warning:</span> Make sure that data
          source results are handled when this setting is enabled.
        </div>
      )}
      <div className="px-4 pt-2 pb-0 border-t mt-auto border-neutral-100 w-full flex justify-end">
        <Button
          disabled={!isWorkflowEditable}
          onClick={() => {
            saveSettings.mutate({
              workflowId: workflowId!,
              settings: settings,
            });
          }}
        >
          Save
        </Button>
      </div>
    </div>
  );
}
