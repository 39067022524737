import classNames from "clsx";
import { ReactComponent as CheckIcon } from "@assets/icons/check.svg";
import { StepsRowProps } from "./ExploreOverviewCard.types";


const ExploreOverviewStepRow = ({ el, id, curStep }: StepsRowProps) => {
    const isComplete = id < curStep;
    const isSelected = curStep === id;
    return (
      <div
        className={classNames(
          "flex border h-9 w-80 rounded-md px-4 py-2.5 items-center mb-2",
          isComplete
            ? "bg-neutral-25 cursor-auto"
            : isSelected
            ? "bg-primary-50 border-primary-50"
            : "hover:bg-primary-50"
        )}
      >
        <div
          className={classNames(
            "border rounded-full w-5 h-5 justify-center items-center self-center flex mr-2",
            isComplete
              ? "border-neutral-200"
              : isSelected
              ? "border-primary-300"
              : ""
          )}
        >
          <span
            className={classNames(
              "font-b2-medium",
              isComplete
                ? "text-neutral-400"
                : isSelected
                ? "text-primary-900"
                : ""
            )}
          >
            {id}
          </span>
        </div>
        <p
          className={classNames(
            "font-b2-medium",
            isComplete
              ? "text-neutral-400   "
              : isSelected
              ? "text-primary-900"
              : ""
          )}
        >
          {el.text}
        </p>
        {isComplete ? (
          <CheckIcon className="ml-2 w-4 h-4 [&>path]:stroke-neutral-400" />
        ) : (
          ""
        )}
      </div>
    );
  };

export default ExploreOverviewStepRow;
