import { ChangeEvent, Fragment, KeyboardEvent, memo, useCallback } from "react";
import { ReactComponent as TrashIcon } from "@assets/icons/workflow/trash-02.svg";
import MatrixItem from "@screens/workflow/studio/components/DecisionTable/TableCell";

interface ModelSetRowProps {
  rowIndex: number;
  output: string;
  column: string[];
  getDeleteRow: (rowIndex: number) => void;
  keyPressHandler: (e: KeyboardEvent<HTMLInputElement>) => void;
  handleCellEditing?: (cellIndex: [number, number]) => void;
  currentlyEditing: [number, number] | null;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const ModelSetTableRow = ({
  rowIndex,
  column,
  output,
  getDeleteRow,
  keyPressHandler,
  handleCellEditing,
  currentlyEditing,
  handleChange
}: ModelSetRowProps) => {

  const handleDelete = useCallback(() => getDeleteRow(rowIndex), [rowIndex, getDeleteRow]);

  const sharedMatrixItemProps = {
    setIsEditing: handleCellEditing,
    keyPressHandler,
    currentlyEditing,
    handleChange,
  };

  return (
    <Fragment>
      <div
        tabIndex={0}
        className="first:rounded-tl-md py-1 pr-1 pl-4 font-normal border-r border-b border-neutral-100 h-10 flex items-center w-full sticky left-0 bg-white"
      >
        {rowIndex + 1}
        <TrashIcon
          onClick={handleDelete}
          className="[&:hover>path]:stroke-error-500 cursor-pointer absolute right-2 top-1/2 -translate-y-1/2"
        />
      </div>
      {column.map((cellValue, colIndex) => (
        <MatrixItem
          key={`${colIndex}_${rowIndex}`}
          rowIndex={rowIndex}
          colIndex={colIndex}
          value={cellValue}
          {...sharedMatrixItemProps}
        />
      ))}
      <div className="sticky right-0 z-10 bg-white border-l -ml-[1px]">
        <MatrixItem
          key={`${column.length}_${rowIndex}`}
          rowIndex={rowIndex}
          colIndex={column.length}
          value={output}
          {...sharedMatrixItemProps}
        />
      </div>
    </Fragment>
  );
};

export default memo(ModelSetTableRow);
