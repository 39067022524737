import { ReactComponent as PolicyIcon } from "@assets/icons/dataflow-01.svg";
import { ReactComponent as AlertSquareIcon } from "@assets/notificationIcons/alert-square.svg";
import { ReactComponent as ListIcon } from "@assets/notificationIcons/list.svg";
import { ReactComponent as ReportsIcon } from "@assets/notificationIcons/reports.svg";
import { ReactComponent as DownloadIcon } from "@assets/icons/download-cloud-01.svg";
import { ArrowUpRightIcon } from "@heroicons/react/24/solid";
import { tabOptions } from "@components/CustomTabs";
import { ToolbarActions } from "src/constants/EventAnalytics";

type CtaMapperProps = {
    [key: string]: {
      text: string;
      icon: JSX.Element;
    };
  };
  
export const CTA_MAPPER: CtaMapperProps = {
    wf_status: {
      text: "View",
      icon: <ArrowUpRightIcon className="ml-1 stroke-primary-900 w-3 h-3" />,
    },
    report: {
      text: "Download",
      icon: <DownloadIcon className="ml-1 w-4 h-4 [&>path]:stroke-primary-900" />,
    },
    simulation: {
      text: "Download",
      icon: <DownloadIcon className="ml-1 w-4 h-4 [&>path]:stroke-primary-900" />,
    },
    monitor: {
      text: "View",
      icon: <ArrowUpRightIcon className="ml-1 stroke-primary-900 w-3 h-3" />,
    },
};
  
export  const TABS = tabOptions(["All", "Policy", "Reports", "Alerts"] as const);

export const EVENT_NOTIFICATION_MAP:Record<string, ToolbarActions> = {
  "All": ToolbarActions.NOTIFICATIONS_ALL_FILTER,
  "Policy": ToolbarActions.NOTIFICATIONS_POLICY_FILTER, 
  "Reports": ToolbarActions.NOTIFICATIONS_REPORTS_FILTER, 
  "Alerts": ToolbarActions.NOTIFICATIONS_ALERTS_FILTER
}

export  const TABS_MAPPER = {
    all: {
      status: ["wf_status", "report", "monitor", "simulation"],
    },
    policy: {
      status: ["wf_status"],
    },
    reports: {
      status: ["report", "simulation"],
    },
    alerts: {
      status: ["monitor"],
    },
  };
  
export const TAB_ICONS = {
    all: ListIcon,
    policy: PolicyIcon,
    reports: ReportsIcon,
    alerts: AlertSquareIcon,
};
  
export const TIME_RANGE = {
    today: "Today",
    yesterday: "Yesterday",
    last7days: "Last 7 days",
    last30days: "Last 30 days",
};
  
