import { useGetAllMonitors } from "@screens/monitor/queries";
import classNames from "clsx";
import { Link, useLocation } from "react-router-dom";
import { SideBarOption, SideBarTopOptions } from "src/config";
import { analyticsInstance } from "src/config/event-analytics";
import { PERMISSIONS_TYPE } from "src/constants/permissionsConstant";
import usePermissions from "src/context/PermissionContext";
import { PermissionTypes } from "src/types";

type NavLinkProps = {
    item: SideBarOption;
    isExpanded?: boolean;
    className?: string;
    iconClassName?: string;
};



const getIsCurrent = (
    href: (typeof SideBarTopOptions)[number]["href"],
    path: string
  ) => {
    switch (href) {
      case "/":
        return path === "/";
      case "/policies":
        return path === "/policies" || path.startsWith("/details");
      case "/endpoints":
        return path.startsWith("/endpoints");
      case "/datasources":
        return path.startsWith("/datasources");
      case "/analytics":
        return path.startsWith("/analytics");
      case "/reports":
        return path.startsWith("/reports") || path.startsWith("/outcome");
      case "/monitors":
        return path.startsWith("/monitors");
      case "/settings":
        return path.startsWith("/settings");
    }
  };

function NavLink({ item, isExpanded, className }: NavLinkProps) {
    const location = useLocation();
    const current = getIsCurrent(item.href, location.pathname);
    const { getMultiplePagePermissions } = usePermissions();
    const permissions = getMultiplePagePermissions(
      [
        PERMISSIONS_TYPE.monitorsCustom,
        PERMISSIONS_TYPE.monitorLogs,
        PERMISSIONS_TYPE.monitorsEndpoint,
      ] as PermissionTypes[],
      "view"
    );
    const getAlertMonitor = useGetAllMonitors({
      permissions: !!permissions,
      state: "error",
    });
    const getWarningMonitor = useGetAllMonitors({
      permissions: !!permissions,
      state: "warning",
    });

    const handleClick = () => {
        analyticsInstance.triggerAnalytics(item.eventType);
    }
  
    return (
      <Link
        key={item.name}
        to={item.href}
        onClick={handleClick}
        className={classNames(
          current ? "bg-primary-50 text-primary-900" : "text-neutral-500",
          "group mx-2 flex items-center px-1.5 h-8 font-b2-medium group duration-100 hover:text-primary-900 rounded-md",
          className
        )}
      >
        <div className="relative">
          <item.icon
            className={classNames(
              current
                ? "stroke-primary-900 [&>path]:stroke-primary-900"
                : "stroke-neutral-500",
              "h-5 w-5 flex-shrink-0 stroke-2 mr-3 group-hover:stroke-primary-900 duration-100 group-hover:[&>path]:stroke-primary-900"
            )}
            aria-hidden="true"
          />
          {item.name === "Monitors" &&
            typeof getAlertMonitor?.data?.totalCount === "number" &&
            typeof getWarningMonitor?.data?.totalCount === "number" && (
              <>
                {getAlertMonitor?.data?.totalCount > 0 && (
                  <span className="absolute rounded w-2 h-2 bg-red-500 top-[-2px] right-2.5"></span>
                )}
                {getAlertMonitor?.data?.totalCount === 0 &&
                  getWarningMonitor?.data?.totalCount > 0 && (
                    <span className="absolute rounded w-2 h-2 bg-yellow-500 top-[-2px] right-2.5"></span>
                  )}
              </>
            )}
        </div>
        <span className="whitespace-nowrap">{isExpanded && item.name}</span>
      </Link>
    );
}

export default NavLink;