import Dropdown from "@components/DropDown";
import { InputParamDataEnum } from "./InputParameters.types";
import { memo, useCallback, useMemo } from "react";

function InputTypeSelection({
  inputTypeSelection,
  index,
  value,
  disabled
}: {
  inputTypeSelection: (value: InputParamDataEnum, index: number) => void;
  value: string;
  index: number;
  disabled: boolean;
}) {

  const handleSelection = useCallback(
    (val: InputParamDataEnum) => inputTypeSelection(val, index),
    [inputTypeSelection, index]
  );

  const options = useMemo(
    () => Object.keys(InputParamDataEnum) as Array<keyof typeof InputParamDataEnum>,
    []
  );

  return (
    <Dropdown
      disabled={disabled}
      onChange={handleSelection}
      className="h-[27px] mr-1"
    >
      <Dropdown.Button className="min-w-full text-neutral-black capitalize">
        {value || <span className="text-neutral-300">Select</span>}
      </Dropdown.Button>
      <Dropdown.Options>
        {options.map((o) => (
          <Dropdown.Option key={InputParamDataEnum[o]} value={InputParamDataEnum[o]} className="capitalize">
            {o}
          </Dropdown.Option>
        ))}
      </Dropdown.Options>
    </Dropdown>
  );
}

export default memo(InputTypeSelection);
