import { parse } from "csv-parse/browser/esm/sync";
import { useRef, useState } from "react";
import { analyticsInstance } from "src/config/event-analytics";
import { PolicyStudioActions } from "src/constants/EventAnalytics";
import { notify, readFile } from "@utils/utils";
import { useWorkflowContext } from "../../WorkflowContext";
import { InputParamOperation, WorkflowCustomInputType } from "../components/InputParameters/InputParameters.types";

const useInputParamFileChange = (
  appendInputParamList:(list:Array<WorkflowCustomInputType>) => void
) => {
  const { workflow } = useWorkflowContext();
  const [file, setFile] = useState<File>();
  const fileUploadRef = useRef<{ clearFile: () => void }>(null);

  const onFileChange = async (file?: File) => {
    if (!file) return;
    setFile(file);
    const fileType = file.name.split(".").at(-1)?.toLowerCase();
    if (!fileType) return;

    const fileContents = await readFile(file);

    analyticsInstance.triggerAnalytics(
      PolicyStudioActions.SIDEBAR_INPUT_PARAMETERS_UPLOAD,
      {
        bucket_name: workflow?.policyName ?? "",
        version: workflow?.policyVersion ?? "",
      }
    );
    switch (fileType) {
      case "json":
        try {
          if (Array.isArray(JSON.parse(fileContents))) {
            appendInputParamList(JSON.parse(fileContents));
            notify({
              title: "Success",
              text: "File uploaded successfully",
              type: "success",
            });
          } else {
            notify({ title: "Invalid file", text: "Could not parse JSON" });
          }
        } catch {
          notify({ title: "Invalid file", text: "Could not parse JSON" });
        }
        break;
      case "csv":
        try {
          const isNullable = !!workflow?.settings.isNullableInputsAllowed;
          let data:WorkflowCustomInputType[] = [];
          const parsedData = parse(fileContents);
          const isFourColumns = parsedData[0]?.length > 3;

          parsedData.slice(1).forEach((row:any, index:number) => {
            const commonFields = {
              id: `dummy_${index}`,
              name: row[0],
              dataType: row[1],
              operation:InputParamOperation.ADD
            };

            if (isNullable) {
              data.push({
                ...commonFields,
                isNullable: row[2] === "true",
                defaultInput: isFourColumns ? row[3] : row[2],
              });
            } else {
              data.push({
                ...commonFields,
                isNullable: row[2] === "true",
                defaultInput: isFourColumns ? row[3] : row[2],
              });
            }
          });

          for (let index = 0; index < data.length; index++) {
            const row = data[index];
            if (!row || !row.name || !row.dataType) {
              notify({
                title: "Invalid file",
                text: `Could not parse row: ${index + 1}`,
              });
              break;
            }
            if (!["text", "number", "boolean", "object"].includes(row.dataType)) {
              notify({
                title: "Invalid file",
                text: `Invalid type in row: ${index + 1}`,
              });
              break;
            }
          }
          appendInputParamList(data as WorkflowCustomInputType[]);
          notify({
            title: "Success",
            text: "File uploaded successfully",
            type: "success",
          });
        } catch {
          notify({ title: "Invalid file", text: "Could not parse CSV" });
        }
        break;
      default:
        notify({ title: "Invalid file", text: "Upload CSV or JSON file" });
    }
    setFile(undefined);
    fileUploadRef.current?.clearFile();
  };

  return { onFileChange, file, fileUploadRef };
};

export default useInputParamFileChange;
