import { ChangeEvent, KeyboardEvent, memo, useCallback } from "react";
import { classNames } from "src/utils/utils";

function MatrixItem({
  value,
  rowIndex,
  colIndex,
  setIsEditing,
  keyPressHandler,
  currentlyEditing,
  handleChange,
}: {
  value: string;
  rowIndex: number;
  colIndex: number;
  setIsEditing?: (cellIndex: [number, number]) => void;
  keyPressHandler: (key: KeyboardEvent<HTMLInputElement>) => void;
  currentlyEditing: [number, number] | null;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
}) {
  const handleEditing = useCallback(() => {
    setIsEditing?.([rowIndex, colIndex]);
  }, [setIsEditing, rowIndex, colIndex]);

  const isEditing = currentlyEditing?.[0] === rowIndex && currentlyEditing?.[1] === colIndex;

  const baseClass = "py-1 h-10 w-full border-b border-neutral-100";

  return !isEditing ? (
    <div
      tabIndex={0}
      className={classNames(`${baseClass} pr-1 pl-4 flex items-center truncate border-r`)}
      onClick={handleEditing}
      onFocus={handleEditing}
    >
      <span className="group max-w-full">
        {value && (
          <span className="group-hover:block hidden absolute top-full left-0 p-1 rounded-md bg-white shadow">
            {value}
          </span>
        )}
        {value || <>&nbsp;</>}
      </span>
    </div>
  ) : (
    <input
      tabIndex={0}
      className={classNames(`${baseClass} px-1 bg-primary-25 border-l-0 border-t-0 focus:ring-0`)}
      autoFocus
      value={value}
      onKeyUp={keyPressHandler}
      onChange={handleChange}
    />
  );
}

export default memo(MatrixItem);
