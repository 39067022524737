import EmptyState from "@components/EmptyState";
import Loader from "@components/Loader";
import Table from "@components/Table";
import Tooltip from "@components/Tooltip";
import { DATA_SOURCE_PAGE_LIMIT } from "@config";
import { useQueryClient } from "@tanstack/react-query";
import clsx from "clsx";
import { useState } from "react";
import { AvailableCustomSources } from "../../create-policy/types";
import ConfirmModal from "../../monitor/components/ConfirmModalEndpoint";
import { datasourceListQuery, useDeleteDS } from "../queries";
import SourceTableRowOptions from "./SourceTableRowOptions";

type Props = {
  customTableData: AvailableCustomSources[];
  isLoading: boolean;
  onPageChange: setStateType<number>;
  page: number;
  totalCount: number;
  setIsEditing: setStateType<number | false>;
};

const DataSourceTable = ({
  customTableData,
  setIsEditing,
  isLoading,
  onPageChange,
  page,
  totalCount,
}: Props) => {
  const deleteDsRow = useDeleteDS();
  const qc = useQueryClient();
  const [isOpenDeleteModal, setOpenDeleteModal] = useState(false);
  const [dataSrc, setDataSrc] = useState({ name: "", id: 0 });
  const handleDeleteDs = () => {
    deleteDsRow.mutate(
      { dataSourceID: dataSrc.id },
      {
        onSuccess: () => {
          qc.invalidateQueries(datasourceListQuery());
        },
      }
    );
  };

  const deleteSourceApi = (row:AvailableCustomSources) => {
    setDataSrc({ name: row.name, id: row.id });
    setOpenDeleteModal(true);
  }

  const updateSourceApi= (row:AvailableCustomSources) => {
    setIsEditing(row.id);
  }

  return (
    <div className="max-w-full w-full relative z-0 rounded px-8 pb-8 overflow-auto">
      <ConfirmModal
        isOpen={isOpenDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        title={`Delete "${dataSrc.name}"  `}
        action={handleDeleteDs}
        destructive
      >
        Deleting this data source will automatically delete attached monitor.
        Are you sure you want to delete ?
      </ConfirmModal>
      {isLoading && customTableData.length === 0 ? (
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="mx-auto flex h-96 max-w-4xl items-center justify-center">
            <Loader size="small" type="block" />
          </div>
        </div>
      ) : customTableData.length > 0 ? (
        <>
          <Table
            data={customTableData}
            className="sm:overflow-x-visible w-full"
            emptyText={<p>No source found</p>}
            isLoading={false}
            headers={["Source Name", "Endpoint URL", "Type", "Alias", "", ""]}
            page={page}
            setPage={(page) => {
              onPageChange(page);
            }}
            pageLimit={DATA_SOURCE_PAGE_LIMIT}
            totalCount={totalCount || 0}
          >
            {(row, defaultRowClassNames) => {
              return (
                <tr
                  key={row.id}
                  className={clsx(defaultRowClassNames, "!cursor-default")}
                >
                  <td
                    className="max-w-[225px] truncate !font-medium pr-1"
                    style={{ overflow: "hidden" }}
                  >
                    {row.name}
                  </td>
                  <td className="truncate !overflow-hidden max-w-[360px] w-[360px] pr-16">
                    {row.url}
                  </td>
                  <td className="min-w-[90px]">
                    <Table.TdWrapper>{row.method}</Table.TdWrapper>
                  </td>
                  <td className="min-w-[90px]">{row.alias}</td>
                  <td>
                    {!row.istested && (
                      <Tooltip
                        content={
                          <div className="h-6 w-6 cursor-pointer bg-warning-100 rounded-full flex justify-center items-center">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="13"
                              height="13"
                              viewBox="0 0 13 13"
                              fill="none"
                            >
                              <g clipPath="url(#clip0_740_4375)">
                                <path
                                  d="M6.49989 4.42881V6.42881M6.49989 8.42881H6.50489M5.64489 1.85881L1.40989 8.92881C1.32257 9.08002 1.27637 9.25146 1.27588 9.42607C1.27539 9.60068 1.32063 9.77238 1.4071 9.92408C1.49357 10.0758 1.61825 10.2022 1.76874 10.2907C1.91923 10.3793 2.09029 10.4269 2.26489 10.4288H10.7349C10.9095 10.4269 11.0805 10.3793 11.231 10.2907C11.3815 10.2022 11.5062 10.0758 11.5927 9.92408C11.6791 9.77238 11.7244 9.60068 11.7239 9.42607C11.7234 9.25146 11.6772 9.08002 11.5899 8.92881L7.35489 1.85881C7.26575 1.71187 7.14025 1.59037 6.99049 1.50606C6.84072 1.42174 6.67176 1.37744 6.49989 1.37744C6.32802 1.37744 6.15905 1.42174 6.00929 1.50606C5.85953 1.59037 5.73402 1.71187 5.64489 1.85881Z"
                                  stroke="#DC6803"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_740_4375">
                                  <rect
                                    width="12"
                                    height="12"
                                    fill="white"
                                    transform="translate(0.5 0.428711)"
                                  />
                                </clipPath>
                              </defs>
                            </svg>
                          </div>
                        }
                        tooltipContent={
                          <span className="text-xs">Source not tested</span>
                        }
                      />
                    )}
                  </td>
                  <td>
                    <SourceTableRowOptions
                        row={row}
                        deleteRow={deleteSourceApi}
                        updateRow={updateSourceApi}
                    />
                  </td>
                </tr>
              );
            }}
          </Table>
        </>
      ) : (
        <EmptyState>No Source found</EmptyState>
      )}
    </div>
  );
};

export default DataSourceTable;
