import React, { ComponentProps } from "react";
import { classNames } from "src/utils/utils";
import ErrorText from "./ErrorText";
import Label from "./Label";

type Props = {
  label?: React.ReactNode;
  isHiddenLabel?: boolean;
  inputClassName?: string;
  error?: string;
  className?: string;
  prefix?: React.ReactNode; // New prop for the prefix
};

const Input = React.forwardRef<
  HTMLInputElement,
  ComponentProps<"input"> & Props
>(
  (
    {
      label,
      isHiddenLabel = false,
      inputClassName,
      className,
      error,
      prefix,
      ...props
    },
    ref
  ) => {
    return (
      <div className={classNames("relative", className)}>
        {label && (
          <Label
            className={classNames("mb-1", isHiddenLabel ? "sr-only" : "block")}
          >
            {label}
          </Label>
        )}

        <div className="flex">
          {prefix && (
            <div className="border-neutral-100 bg-neutral-0 text-neutral-600 font-b2 border-l border-t border-b  h-7 px-2 py-1 rounded-l-md flex items-center">
              {prefix}
            </div>
          )}

          <input
            className={classNames(
              "block w-full h-7 bg-white border-neutral-100 focus:border-blue-500 focus:ring-0 font-b2 disabled:cursor-not-allowed placeholder-neutral-500",
              inputClassName,
              {
                "border border-error-100 bg-white focus:border-error-700":
                  error,
                "bg-primary-50 bg-opacity-50": props.value && !error,
              },
              prefix ? "rounded-r-md" : "rounded-md"
            )}
            ref={ref}
            type="text"
            {...props}
          />
        </div>

        {error && <ErrorText className="mt-1">{error ? error : ""}</ErrorText>}
      </div>
    );
  }
);

export default Input;
