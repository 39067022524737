
import Button from "@components/Button";
import Shimmer from "@components/Shimmer";
import { useWorkflowContext } from "@screens/workflow/WorkflowContext";
import {
  getCurrentSourcesQuery,
  getSourceListQuery,
  useAddSource,
  useDeleteSource,
  useUpdateSourceItem,
} from "@screens/workflow/studio/components/Source/queries";
import draftIcon from "@assets/icons/edit-05.svg";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getNetworkErrorText, notify } from "@utils/utils";
import { Fragment } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Dropdown from "src/components/DropDown";
import { CurrentSourceItem, SourceItem as SourceItemType } from "./types";
import { Tooltip } from "@finbox-in/finblocks";
import { analyticsInstance } from "src/config/event-analytics";
import { PolicyStudioActions } from "src/constants/EventAnalytics";

interface SourceItemProps {
  item: CurrentSourceItem;
  index: number;
  nodeId: string;
  totalNodeCount:number;
}

const SourceItem = ({ item, nodeId, totalNodeCount }: SourceItemProps) => {
  const { isWorkflowEditable } = useWorkflowContext();
  const { workflow } = useWorkflowContext();
  const workflowId = useParams().workflowId;

  const deleteSourceMutation = useDeleteSource();
  const addSourceMutation = useAddSource();
  const updateSourceMutation = useUpdateSourceItem();
  const queryClient = useQueryClient();

  const sourceListQuery = useQuery(getSourceListQuery(workflowId!));
  const navigate = useNavigate();

  if (sourceListQuery.isPending) return <Shimmer className="w-full h-6" />;

  const options =
    sourceListQuery.data?.data.data.reduce((prev, current) => {
      if (current.type in prev) {
        prev[current.type].push(current);
      } else {
        prev[current.type] = [current];
      }
      return prev;
    }, {} as Record<string, SourceItemType[]>) ?? {};

  const openConfigure = (id: number, action: "add" | "edit") => {
    navigate(`/workflow/${workflowId}/${nodeId}/datasource/${action}/${id}`);
  };

  const handleSourceAddUpdate = (e: CurrentSourceItem) => {
    if (e.type === "customSource") {
      if (item.data_source_id === -1)
        openConfigure(e.data_source_id, "add");
      else
        navigate(
          `/workflow/${workflowId}/${nodeId}/datasource/update/${item.data_source_id}/${e.data_source_id}`
        );
    } else {
      if (item.data_source_id === -1){

        analyticsInstance.triggerAnalytics(
          PolicyStudioActions.SOURCE_BLOCK,
          {
            count:totalNodeCount, 
            source_names:e.name, 
            policy_name:workflow?.policyName, 
            version:workflow?.policyVersion
          }
        );
        addSourceMutation.mutate({
          workflowId: workflowId!,
          nodeId,
          dataSourceId: e.data_source_id,
          dataSourceType: e.type,
          variables: [],
        });
      }
      else
        updateSourceMutation.mutate({
          workflowId: workflowId!,
          datasourceId: e.data_source_id,
          nodeId,
          variables: [],
          dataSourceType: e.type,
          oldDatasourceId: item.data_source_id,
        });
    }
  }

  return (
    <div className="relative flex items-center gap-2">
      {/* <ProtectedComponent
        type={PERMISSIONS_TYPE.policy as PermissionTypes}
        action="view datasource list"
      > */}
      <Dropdown
        className="w-full"
        value={item}
        disabled={!isWorkflowEditable}
        onChange={handleSourceAddUpdate}
      >
        <Dropdown.Button
          className="w-full h-7 text-neutral-black"
          disabled={!isWorkflowEditable}
        >
          {item?.name || "Select a source"}
        </Dropdown.Button>
        <Dropdown.Options className="h-auto">
          {Object.keys(options).map((sourceType) => {
            return (
              <Fragment key={sourceType}>
                <Dropdown.Option
                  value={undefined}
                  className="hover:bg-white"
                  disabled
                  key={sourceType}
                >
                  {
                    {
                      finboxSource: "FinBox Sources",
                      customSource: "Custom Sources",
                      lookup: "Lookups",
                    }[sourceType]
                  }
                </Dropdown.Option>
                {options[sourceType].map((option) => {
                  return (
                    <Dropdown.Option key={option.name} value={option}>
                      {option.name}
                    </Dropdown.Option>
                  );
                })}
              </Fragment>
            );
          })}
        </Dropdown.Options>
      </Dropdown>
      <div className="flex item-center gap-1">
        { item.type === "customSource" && (
          <Tooltip placement="bottom" toolTipContent={"Configure Source"}>
            <Button
              className="p-0 hover:bg-primary-50 hover:stroke-neutral-600"
              variant="link-gray" 
              onClick={(e) => {
                e.stopPropagation();
                openConfigure(item.data_source_id, "edit");
              }}
            >
              <img  className="w-6 h-6 p-1" alt="Configure Icon" src={draftIcon} />
            </Button>
          </Tooltip>
        )}
        {isWorkflowEditable && (
          <Tooltip placement="bottom" toolTipContent={"Delete Source"}>
          <Button
            className="p-0 hover:bg-primary-50 hover:stroke-neutral-600"
            variant="link-gray" 
            onClick={() => {
              if (item.data_source_id === -1) {
                return queryClient.invalidateQueries(
                  getCurrentSourcesQuery(workflowId!, nodeId)
                );
              }
              deleteSourceMutation.mutate(
                {
                  workflowId: workflowId!,
                  dataSourceId: item.data_source_id,
                  dataSourceType: item.type,
                  nodeId,
                },
                {
                  onSuccess: () =>
                    queryClient.invalidateQueries(
                      getCurrentSourcesQuery(workflowId!, nodeId)
                    ),
                  onError: (err) =>
                    notify({ title: "Failed", text: getNetworkErrorText(err) }),
                }
              );
            }}
          >
            <img  className="w-6 h-6 p-1" alt="Delete Icon" src={"https://finbox-cdn.s3.ap-south-1.amazonaws.com/sentinel/common/trash_icon_neutral.svg"} />
          </Button>
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default SourceItem;
