  import { ReactElement } from "react";
  import { classNames } from "src/utils/utils";
  
const NotificationBaseCard = ({
    children,
    isRead,
  }: {
    children: ReactElement;
    isRead: boolean;
  }) => {
    return (
      <div className="group relative flex items-start p-4 hover:bg-neutral-0 border-b">
        <div
          className={classNames(
            "rounded w-2 h-2  mr-1 mt-2.5",
            !isRead && "bg-red-500"
          )}
        ></div>
        {children}
      </div>
    );
};

export  default NotificationBaseCard;