import { analyticsInstance } from "src/config/event-analytics";
import { PolicyStudioActions } from "src/constants/EventAnalytics";
import { generateAndDownloadFile } from "@utils/utils";
import { useWorkflowContext } from "../../WorkflowContext";

const useDownloadInputSample = () => {
  const { workflow } = useWorkflowContext();

  const downloadSample = (type: "csv" | "json") => {
    switch (type) {
      case "csv":
        analyticsInstance.triggerAnalytics(
          PolicyStudioActions.SIDEBAR_INPUT_PARAMETERS_DOWNLOAD_SAMPLECSV,
          {
            bucket_name: workflow?.policyName ?? "",
            version: workflow?.policyVersion ?? "",
          }
        );
        if (workflow?.settings.isNullableInputsAllowed)
          generateAndDownloadFile(
            `name,dataType,nullable,defaultInput
  name1,"text",true,"string"
  name2,"number",true,"1"
  name3,"boolean",true,"false"`,
            "sample_input.csv"
          );
        else
          generateAndDownloadFile(
            `name,dataType,defaultInput
  name1,"text","string"
  name2,"number","1"
  name3,"boolean","false"`,
            "sample_input.csv"
          );
        break;
      case "json":
        analyticsInstance.triggerAnalytics(
          PolicyStudioActions.SIDEBAR_INPUT_PARAMETERS_DOWNLOAD_SAMPLEJSON,
          {
            bucket_name: workflow?.policyName ?? "",
            version: workflow?.policyVersion ?? "",
          }
        );
        if (workflow?.settings.isNullableInputsAllowed)
          generateAndDownloadFile(
            JSON.stringify([
              {
                name: "name1",
                dataType: "text",
                isNullable: true,
                defaultInput: "string",
              },
              {
                name: "name2",
                dataType: "number",
                isNullable: true,
                defaultInput: "1",
              },
              {
                name: "name3",
                dataType: "boolean",
                isNullable: true,
                defaultInput: "true",
              },
            ]),
            "sample_input.json"
          );
        else
          generateAndDownloadFile(
            JSON.stringify([
              {
                name: "name1",
                dataType: "text",
                defaultInput: "string",
              },
              {
                name: "name2",
                dataType: "number",
                defaultInput: "1",
              },
              {
                name: "name3",
                dataType: "boolean",
                defaultInput: "true",
              },
            ]),
            "sample_input.json"
          );
        break;
    }
  };

  return { downloadSample };
};

export default useDownloadInputSample;
