import { DATAFLOW_TRANSITION } from "src/constants/imageConstants";
import { ChangeLogTransition } from "../../types";
import { getStatusIndicator } from "../../utils";

export const DataSourceTransitions = ({
  prevTransitions,
  currTransitions,
}: {
  prevTransitions: ChangeLogTransition[] | null;
  currTransitions: ChangeLogTransition[] | null;
}) => {
  if (prevTransitions?.length === 0 && currTransitions?.length === 0) {
    return null;
  }

  return (
    <div>
      <div className="flex bg-neutral-25 border-y border-neutral-200 px-4 h-8 py-2 font-b2-medium text-neutral-black gap-2">
        <img
          src={DATAFLOW_TRANSITION}
          className="w-4 h-4"
          alt="transition-icon"
        />
        Transition
      </div>

      {(currTransitions || prevTransitions) &&
        Array.from({
          length: Math.max(
            currTransitions?.length || 0,
            prevTransitions?.length || 0
          ),
        }).map((_, idx) => {
          const currTransition = currTransitions ? currTransitions[idx] : null;
          const prevTransition = prevTransitions ? prevTransitions[idx] : null;

          return (
            <div className="flex" key={idx}>
              <div className="border-r w-[50%] flex flex-col px-4 py-3 gap-2 overflow-none">
                <div className="flex gap-2 py-1">
                  {prevTransition ? (
                    <div className="flex flex-wrap gap-1.5">
                      <span className="flex gap-2 font-b2-medium">
                        {getStatusIndicator(prevTransition.state)}
                        <span className="text-neutral-500">
                          is connected to
                        </span>
                      </span>
                      <span className="flex font-b2-medium">
                        {prevTransition.nodeType}: {prevTransition.name}
                      </span>
                    </div>
                  ) : currTransition?.state === "add" ? (
                    <span className="text-neutral-500 font-b2 font-normal ml-1.5">
                      Transition was added
                    </span>
                  ) : (
                    <span className="m-auto text-neutral-500 pl-2">
                      Transition not present
                    </span>
                  )}
                </div>
              </div>

              <div className="flex flex-col w-[50%] px-4 py-3 gap-2 left-0">
                <div className="flex gap-2 py-1">
                  {currTransition ? (
                    <div className="flex flex-wrap gap-1.5">
                      <span className="flex gap-2 font-b2-medium">
                        {getStatusIndicator(currTransition.state)}
                        <span className="text-neutral-500">
                          is connected to
                        </span>
                      </span>
                      <span className="flex font-b2-medium">
                        {currTransition.nodeType}: {currTransition.name}
                      </span>
                    </div>
                  ) : prevTransition?.state === "delete" ? (
                    <span className="text-neutral-500 font-b2 font-normal ml-1.5">
                      Transition was deleted
                    </span>
                  ) : (
                    <span className="m-auto text-neutral-500 pl-2">
                      Transition not present
                    </span>
                  )}
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};
