export const btn_gray_Class =
  "inline-flex items-center justify-center rounded-md border border-transparent bg-gray-200 px-4 py-2 text-sm font-medium text-gray-800 shadow-sm hover:bg-gray-300 focus:outline-none sm:w-auto mr-2 xs:w-full";


export const PREDICTOR_COLOR: Record<string, string> = {
  bank: "text-rose-500",
  bureau: "text-warning-500",
  device: "text-blue-500",
  gst: "text-orange-500",
  platform: "text-green-500",
  application: "text-green-500",
  func: "text-fuchsia-500",
  model: "text-purple-500",
  input: "text-pink-500",
};