import Badge from "src/components/Badge";
import Dropdown from "src/components/DropDown";
import Loader from "src/components/Loader";
import { classNames } from "src/utils/utils";
import { WfChangelogVersionList } from "../../../types";

export const HeaderWithVersion = ({
  currWfName,
  versionList,
  setFromVersion,
  versionListLoading,
  versionListSuccess,
  fromWfId,
}: {
  currWfName: string;
  versionList: WfChangelogVersionList[];
  setFromVersion: React.Dispatch<React.SetStateAction<string | null>>;
  versionListLoading: boolean;
  versionListSuccess: boolean;
  fromWfId: string;
}) => {
  return (
    <div className="flex flex-wrap items-center gap-1.5 mb-3">
      <span className="text-neutral-500 font-b2">Changes in</span>
      <span className="text-black font-b2-medium">{currWfName}</span>
      <span className="text-neutral-500 font-b2">compared to</span>
      <span className="text-black font-b2-medium">
        <Dropdown
          value={versionList?.find((obj) => obj.id === fromWfId)!.name}
          onChange={(e: string) => {
            setFromVersion(e);
          }}
        >
          <Dropdown.Button
            className={classNames(
              "bg-neutral-0 w-full mt-1 !focus:ring-0",
              !!versionList?.find((obj) => obj.id === fromWfId)!.name &&
                "font-b2-medium text-neutral-black"
            )}
          >
            <span className="max-w-[300px] truncate">
              {versionList?.find((obj) => obj.id === fromWfId)!.name ??
                "Choose a version"}
            </span>
          </Dropdown.Button>
          <Dropdown.Options className="ml-10 h-auto max-h-40 !w-[440px] ">
            {versionListLoading && <Loader size="xs" />}
            {versionListSuccess && versionList?.length > 0 ? (
              versionList?.map((r) => (
                <Dropdown.Option key={r.name} value={r.id}>
                  <div className="flex justify-between items-center w-full">
                    <span>{r.name}</span>
                    {r.parent && <Badge variant="primary">source</Badge>}
                  </div>
                </Dropdown.Option>
              ))
            ) : (
              <Dropdown.Option key="empty" disabled value="empty">
                No options
              </Dropdown.Option>
            )}
          </Dropdown.Options>
        </Dropdown>
      </span>
    </div>
  );
};
