import { CHECK_SQUARE_ICON } from "src/constants/imageConstants";
import { InputOutputDataNode } from "../../types";
import { getStatusIndicator, highlightDifferences } from "../../utils";

export const InputOutputCard = ({
  prevData,
  currData,
  cardType,
}: {
  prevData: InputOutputDataNode;
  currData: InputOutputDataNode;
  cardType: "input" | "output";
}) => {
  return (
    <>
      <div className="bg-neutral-0 border-y border-neutral-100 px-4 h-8 py-2 font-b2-medium text-neutral-black">
        <span className="flex items-center gap-2">Variable</span>
      </div>
      <div className="flex">
        <div className="w-[50%] border-r px-4 py-3 gap-2">
          <div className="flex flex-col gap-2">
            {prevData.data?.map((it, idx) => {
              if (!it) {
                return (
                  <div className="flex flex-col gap-1 w-[90%] h-9" key={idx}>
                    <span className="flex text-neutral-500">
                      Variable not present
                    </span>
                  </div>
                );
              }
              const { prevHighlighted } = highlightDifferences(
                it?.defaultInput || "",
                currData.data[idx]?.defaultInput || ""
              );
              return (
                <div className="flex flex-col gap-1 w-[90%]" key={idx}>
                  <span className="flex flex-wrap font-b2-medium items-center gap-1.5 justify-between">
                    <span className="flex flex-wrap break-all">{it?.name}</span>
                  </span>
                  <span className="flex flex-wrap font-b2 text-neutral-black">
                    {
                      highlightDifferences(
                        it?.dataType,
                        currData.data[idx]?.dataType!
                      ).prevHighlighted
                    }
                    {cardType === "input" && (
                      <span className="text-primary-900">
                        : {prevHighlighted}
                      </span>
                    )}
                  </span>
                </div>
              );
            })}
          </div>
        </div>

        <div className="w-[50%] px-4 py-3 gap-2">
          <div className="flex flex-col gap-2">
            {currData.data?.map((it, idx) => {
              if (!it) {
                return (
                  <div className="flex flex-col gap-1" key={idx}>
                    <span className="flex flex-wrap font-b2-medium items-center gap-1.5 justify-between">
                      <span className="flex gap-2">
                        {getStatusIndicator("delete")}
                        <span className="flex flex-wrap break-all">
                          {prevData.data[idx]?.name}
                        </span>
                      </span>
                      {cardType === "input" &&
                        prevData.data[idx]?.isNullable && (
                          <span className="text-neutral-500 flex items-center gap-1 ml-auto">
                            <img
                              src={CHECK_SQUARE_ICON}
                              alt="Check square icon"
                            />
                            Nullable
                          </span>
                        )}
                    </span>
                    <span className="flex flex-wrap font-b2 text-neutral-black ml-3">
                      {prevData.data[idx]?.dataType}
                      {cardType === "input" &&
                        prevData.data[idx]?.defaultInput && (
                          <span className="text-primary-900">
                            : {prevData.data[idx]?.defaultInput}
                          </span>
                        )}
                    </span>
                  </div>
                );
              }

              const highlightedDataType = highlightDifferences(
                prevData.data[idx]?.dataType || "",
                it?.dataType || ""
              );
              const highlightedDefaultInput = highlightDifferences(
                prevData.data[idx]?.defaultInput || "",
                it?.defaultInput || ""
              );

              return (
                <div className="flex flex-col gap-1" key={idx}>
                  <span className="flex flex-wrap font-b2-medium items-center gap-1.5 justify-between">
                    <span className="flex gap-2">
                      {getStatusIndicator(it.state)}
                      <span className="flex flex-wrap break-all">
                        {it?.name}
                      </span>
                    </span>
                    {cardType === "input" && it?.isNullable && (
                      <span className="text-neutral-500 flex items-center gap-1 ml-auto">
                        <img src={CHECK_SQUARE_ICON} alt="Check square icon" />
                        Nullable
                      </span>
                    )}
                  </span>
                  <span className="flex flex-wrap font-b2 text-neutral-black ml-3">
                    {highlightedDataType.currHighlighted}
                    {cardType === "input" && it?.defaultInput && (
                      <span className="text-primary-900">
                        : {highlightedDefaultInput.currHighlighted}
                      </span>
                    )}
                  </span>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};
