import React from "react";
import Tooltip from "src/components/CustomTooltip";
import { classNames, truncateString } from "src/utils/utils";
import { DecisionTableCellProps } from "./DecisionTableChanglog.types";

const getCellClass = (state?: string) => {
  switch (state) {
    case "add":
      return "!border-success-500 bg-success-25";
    case "delete":
      return "!border-error-600 bg-error-0";
    case "edit":
      return "!border-neutral-300 bg-neutral-10";
    default:
      return "!border-neutral-300 bg-white";
  }
};

const DecisionTableChangelogCells: React.FC<DecisionTableCellProps> = ({
  cell,
  ref,
  cellClassName,
  tooltipPosition,
}) => (
  <div
    tabIndex={0}
    ref={ref}
    className={classNames(
      "first:rounded-tl-md py-1 pr-1 pl-4 font-normal border-r border-b h-10 flex items-center w-full",
      getCellClass(cell?.state),
      cellClassName
    )}
  >
    {cell.state === "edit" ? (
      <Tooltip
        text={
          <span className="group  flex flex-col">
            <span className="top-full left-0 p-1">Current Value</span>
            <span className="top-full left-0 p-1 text-neutral-600">
              {cell?.currData}
            </span>
            <span className="top-full left-0 p-1">Previous Value</span>
            <span className="top-full left-0 p-1 text-neutral-600">
              {cell?.prevData}
            </span>
          </span>
        }
        position={tooltipPosition}
        rootClass=" w-full"
      >
        <span className="group w-full max-w-full">
          <span className="top-full w-full left-0 p-1">
            {truncateString(cell?.value, 90)}
          </span>
        </span>
      </Tooltip>
    ) : (
      <span className="group w-full max-w-full">
        <span className="top-full w-full left-0 p-1">{cell?.value}</span>
      </span>
    )}
  </div>
);

export default React.memo(DecisionTableChangelogCells);
