import React, { Fragment, useState } from "react";
import {
  getNotReadCounts,
  getNotificationsList,
  useMarkAllRead,
} from "src/apis";
import Label from "src/components/Label";
import Popover from "src/components/Popover";
import QueryWrapper from "src/components/QueryWrapper";
import Toggle from "src/components/Toggle";
import Tooltip from "src/components/Tooltip";
import { classNames } from "src/utils/utils";
import bellIcon from "@assets/notificationIcons/bell-01.svg";
import { ReactComponent as CheckIcon } from "@assets/notificationIcons/check-circle-broken.svg";
import TabRoot from "@components/CustomTabs";
import { useQuery } from "@tanstack/react-query";
import { analyticsInstance } from "src/config/event-analytics";
import { ToolbarActions } from "src/constants/EventAnalytics";
import { EVENT_NOTIFICATION_MAP, TAB_ICONS, TABS, TABS_MAPPER, TIME_RANGE } from "./NotificationCenter.constants";
import ReportsCard from "./ReportsCard";
import PolicyCard from "./PolicyCard";
import SimulationCard from "./SimulationCard";
import AlertsCard from "./AlertsCard";
import { classifyNotifications, sumValues } from "./NotificationCenter.utils";
import { NoNotification, NotificationFooter, QueryLoader } from "./NotificationCenter.helperComponent";

const NotificationCentre = () => {
  const [unread, setUnread] = useState(false);
  const [notificationStatus, setNotificationStatus] = useState("all");

  const notificationsList = useQuery(
    getNotificationsList({
      type: TABS_MAPPER[notificationStatus as keyof typeof TABS_MAPPER].status,
      page: 0,
      limit: 10,
      showUnread: unread,
    })
  );
  const { data: counts } = useQuery(getNotReadCounts());
  const notReadCounts = counts?.data.data!;

  const markAllRead = useMarkAllRead();

  const handleMarkAllRead = () => {
    markAllRead.mutate();
    analyticsInstance.triggerAnalytics(ToolbarActions.NOTIFICATIONS_MARK_AS_READ);
  };


  const handleNotificationClick = () => {
    analyticsInstance.triggerAnalytics(ToolbarActions.NOTIFICATIONS,{count:notificationsList.data?.data.data.count});
  }

  const showUnread = () => {
    setUnread(!unread);
    if(!unread){
      analyticsInstance.triggerAnalytics(ToolbarActions.NOTIFICATIONS_ONLY_SHOW_UNREAD);
    }
  }

  const handleTabTrigger  = (tab:string, onClick: () => void) => {
    setNotificationStatus(tab.toLowerCase());
    onClick();
    analyticsInstance.triggerAnalytics(EVENT_NOTIFICATION_MAP[tab]);
  };

  return (
    <Popover className="relative" placement="bottom-end">
      {({ open }) => (
        <>
          <Popover.Button onClick={handleNotificationClick}>
            <div
              className={classNames(
                "border border-neutral-200 rounded-md p-1.5 hover:bg-neutral-50 mr-4",
                open && "bg-neutral-50"
              )}
            >
              <img alt="bell" src={bellIcon} className="w-4 h-4" />
              <span
                className={classNames(
                  `absolute rounded-full w-3 h-3 top-[4px] text-white text-[8px] ${
                    sumValues("all",notReadCounts) > 0 ? "bg-red-500" : "bg-none"
                  }`
                )}
              >
                {sumValues("all", notReadCounts) > 0 && sumValues("all", notReadCounts)}
              </span>
            </div>
          </Popover.Button>
          <Popover.Panel className="-translate-x-3 text-xs -mt-1 !w-[460px] max-h-[70%] overflow-scroll shadow-1">
            <div className="text-neutral-600 flex flex-col cursor-pointer ">
              <div className="flex z-40 sticky top-0 items-center justify-between px-4 h-10 border-b bg-neutral-0 cursor-default">
                <p className="text-neutral-black font-b1-medium">
                  Notifications
                </p>
                <div className="text-neutral-black font-b2 flex items-center">
                  <span className="mr-2">Only show unread</span>
                  <Toggle
                    disable={notificationsList.isPending}
                    onChange={showUnread}
                    value={unread}
                  />
                  <div
                    className="w-6 h-6 ml-4 flex justify-center items-center rounded-md hover:bg-neutral-25 cursor-pointer"
                    onClick={handleMarkAllRead}
                  >
                    <Tooltip
                      bottom
                      left
                      tooltipClassName="-right-[18px]"
                      content={
                        <CheckIcon
                          className="w-4 h-4"
                          onClick={handleMarkAllRead}
                        />
                      }
                      tooltipContent={
                        <p className="font-b2 text-neutral-black">
                          Mark all as read
                        </p>
                      }
                    />
                  </div>
                </div>
              </div>
              <TabRoot defaultTab={TABS[0]}>
                <div className="flex gap-2 px-2 bg-neutral-0 border-b border-neutral-100 z-10 sticky top-[40px]">
                  {TABS.map((tab) => (
                    <TabRoot.Trigger tabName={tab} key={tab}>
                      {({ onClick, isSelected }) => {
                        return (
                          <div
                            className={classNames(
                              "flex font-b1-medium gap-2 px-2 h-9 py-3 mt-[1px] items-center cursor-pointer text-neutral-600 border-b-[3px] border-transparent group",
                              isSelected ? " !border-primary-900" : ""
                            )}
                            onClick={() => handleTabTrigger(tab,onClick)}
                          >
                            <div
                              className={classNames(
                                isSelected
                                  ? "stroke-primary-900 [&>g&>path]:stroke-primary-900 [&>path]:stroke-primary-900 fill-primary-900"
                                  : "stroke-neutral-500 [&>path]:stroke-neutral-500 group-hover:stroke-primary-900 group-hover:[&>path]:stroke-primary-900"
                              )}
                            >
                              {React.createElement(
                                TAB_ICONS[
                                  tab.toLowerCase() as keyof typeof TAB_ICONS
                                ],
                                {
                                  className: classNames(
                                    "w-4 h-4",
                                    isSelected
                                      ? "stroke-primary-900 [&>g&>path]:stroke-primary-900 [&>path]:stroke-primary-900 "
                                      : "stroke-neutral-500 [&>path]:stroke-neutral-600 group-hover:stroke-primary-900 group-hover:[&>path]:stroke-primary-900"
                                  ),
                                }
                              )}
                            </div>
                            <span
                              className={classNames(
                                isSelected
                                  ? "text-primary-900 !border-primary-900"
                                  : "group-hover:text-primary-900"
                              )}
                            >
                              {tab}
                            </span>
                            {sumValues(tab.toLowerCase(), notReadCounts) > 0 ? (
                              <span
                                className={classNames(
                                  "rounded-md items-center justify-center flex w-6 h-6 text-xs font-semibold",
                                  isSelected
                                    ? "bg-primary-50  text-primary-600"
                                    : "bg-neutral-25  text-neutral-600 group-hover:text-primary-900 group-hover:bg-primary-50"
                                )}
                              >
                                {sumValues(tab.toLowerCase(), notReadCounts)}
                              </span>
                            ) : (
                              <></>
                            )}
                          </div>
                        );
                      }}
                    </TabRoot.Trigger>
                  ))}
                </div>
                <TabRoot.Panel tabName={TABS[0]}>
                  <QueryWrapper
                    query={notificationsList}
                    loader={<QueryLoader />}
                  >
                    {(data) => {
                      if (
                        data.data.data.list &&
                        data.data.data.list.length > 0
                      ) {
                        return (
                          <>
                            {Object.entries(
                              classifyNotifications(data?.data.data.list)
                            ).map(([timeRange, notifications]) => (
                              <Fragment
                                key={timeRange as keyof typeof TIME_RANGE}
                              >
                                {notifications.length > 0 && (
                                  <Label className="px-4 py-2 sticky top-[78px] z-40 bg-white">
                                    {TIME_RANGE[
                                      timeRange as keyof typeof TIME_RANGE
                                    ].toUpperCase()}
                                  </Label>
                                )}
                                {notifications.map((item) => (
                                  <Fragment key={item.id}>
                                    {item.type === "wf_status" && (
                                      <PolicyCard item={item} />
                                    )}
                                    {item.type === "report" && (
                                      <ReportsCard item={item} />
                                    )}
                                    {item.type === "simulation" && (
                                      <SimulationCard item={item} />
                                    )}
                                    {item.type === "monitor" && (
                                      <AlertsCard item={item} />
                                    )}
                                  </Fragment>
                                ))}
                              </Fragment>
                            ))}
                            {data.data.data.list.length > 7 && (
                              <NotificationFooter />
                            )}
                          </>
                        );
                      } else return <NoNotification />;
                    }}
                  </QueryWrapper>
                </TabRoot.Panel>
                <TabRoot.Panel tabName={TABS[1]}>
                  <QueryWrapper
                    query={notificationsList}
                    loader={<QueryLoader />}
                  >
                    {(data) => {
                      if (data.data.data.list && data.data.data.list.length > 0)
                        return (
                          <>
                            {Object.entries(
                              classifyNotifications(data?.data.data.list)
                            ).map(([timeRange, notifications]) => (
                              <Fragment
                                key={timeRange as keyof typeof TIME_RANGE}
                              >
                                {notifications.length > 0 && (
                                  <Label className="px-4 py-2 sticky top-[78px] z-40 bg-white">
                                    {TIME_RANGE[
                                      timeRange as keyof typeof TIME_RANGE
                                    ].toUpperCase()}
                                  </Label>
                                )}
                                {notifications.map((item) => (
                                  <Fragment key={item.id}>
                                    {item.type === "wf_status" && (
                                      <PolicyCard item={item} />
                                    )}
                                  </Fragment>
                                ))}
                              </Fragment>
                            ))}
                            {data.data.data.list.length > 7 && (
                              <NotificationFooter />
                            )}
                          </>
                        );
                      else return <NoNotification />;
                    }}
                  </QueryWrapper>
                </TabRoot.Panel>
                <TabRoot.Panel tabName={TABS[2]}>
                  <QueryWrapper
                    query={notificationsList}
                    loader={<QueryLoader />}
                  >
                    {(data) => {
                      if (data.data.data.list && data.data.data.list.length > 0)
                        return (
                          <>
                            {Object.entries(
                              classifyNotifications(data?.data.data.list)
                            ).map(([timeRange, notifications]) => (
                              <Fragment
                                key={timeRange as keyof typeof TIME_RANGE}
                              >
                                {notifications.length > 0 && (
                                  <Label className="px-4 py-2 sticky top-[78px] z-40 bg-white">
                                    {TIME_RANGE[
                                      timeRange as keyof typeof TIME_RANGE
                                    ].toUpperCase()}
                                  </Label>
                                )}
                                {notifications.map((item) => (
                                  <Fragment key={item.id}>
                                    {item.type === "report" && (
                                      <ReportsCard item={item} />
                                    )}
                                    {item.type === "simulation" && (
                                      <SimulationCard item={item} />
                                    )}
                                  </Fragment>
                                ))}
                              </Fragment>
                            ))}
                            {data.data.data.list.length > 7 && (
                              <NotificationFooter />
                            )}
                          </>
                        );
                      else return <NoNotification />;
                    }}
                  </QueryWrapper>
                </TabRoot.Panel>
                <TabRoot.Panel tabName={TABS[3]}>
                  <QueryWrapper
                    query={notificationsList}
                    loader={<QueryLoader />}
                  >
                    {(data) => {
                      if (data.data.data.list && data.data.data.list.length > 0)
                        return (
                          <>
                            {Object.entries(
                              classifyNotifications(data?.data.data.list)
                            ).map(([timeRange, notifications]) => (
                              <Fragment
                                key={timeRange as keyof typeof TIME_RANGE}
                              >
                                {notifications.length > 0 && (
                                  <Label className="px-4 py-2 sticky top-[78px] z-40 bg-white">
                                    {TIME_RANGE[
                                      timeRange as keyof typeof TIME_RANGE
                                    ].toUpperCase()}
                                  </Label>
                                )}
                                {notifications.map((item) => (
                                  <Fragment key={item.id}>
                                    {item.type === "monitor" && (
                                      <AlertsCard item={item} />
                                    )}
                                  </Fragment>
                                ))}
                              </Fragment>
                            ))}
                            {data.data.data.list.length > 7 && (
                              <NotificationFooter />
                            )}
                          </>
                        );
                      else return <NoNotification />;
                    }}
                  </QueryWrapper>
                </TabRoot.Panel>
              </TabRoot>
            </div>
          </Popover.Panel>
        </>
      )}
    </Popover>
  );
};

export default NotificationCentre;
