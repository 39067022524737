import React, { Fragment } from "react";
import DecisionTableChangelogCells from "./DecisionTableChangelogCells";
import { DecisionTableRowProps } from "./DecisionTableChanglog.types";

const DecisionTableRow: React.FC<DecisionTableRowProps> = ({
  rowIndex,
  columns,
  output,
}) => (
  <Fragment>
    <div
      tabIndex={0}
      className="first:rounded-tl-md py-1 pr-1 pl-4 font-normal border-r border-b border-neutral-100 h-10 flex items-center w-full sticky left-0 bg-white outer-shadow-right"
    >
      {rowIndex + 1}
    </div>
    {columns.map((column, colIndex) => (
      <DecisionTableChangelogCells
        key={`${rowIndex}_${colIndex}`}
        cell={column}
      />
    ))}
    <div className="sticky right-0 z-10 bg-white border-l -ml-[1px] outer-shadow-left">
      <DecisionTableChangelogCells cell={output} />
    </div>
  </Fragment>
);

export default React.memo(DecisionTableRow);
