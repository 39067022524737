import { Fragment } from "react";
import { Transition } from "@headlessui/react";
import { ModelScreen } from "../types";
import CurlModel from "./CurlModel";

type AddApiModelProps = {
  modelScreen: ModelScreen;
  close:() => void;
  isEditing: false | number;
};

const AddApiModel = ({
  modelScreen,
  close,
  isEditing,
}: AddApiModelProps) => {
  return (
    <>
      <Transition show={modelScreen !== ""}>
        <Transition.Child
          enter="transform transition ease-in-out duration-300 sm:duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transform transition ease-in-out duration-200 sm:duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          as={Fragment}
        >
          <div className="transition-opacity h-screen w-screen backdrop-brightness-100 bg-[#eaeaea] bg-opacity-80 fixed left-0 bottom-0 right-0 top-0 box-border rounded-md shadow-lg ring-1 ring-gray-100 z-[103]"></div>
        </Transition.Child>
        <Transition.Child
          enter="transform transition ease-in-out duration-300 sm:duration-500"
          enterFrom="translate-x-full opacity-90"
          enterTo="translate-x-0 opacity-100"
          leave="transform transition ease-in-out duration-200 sm:duration-500"
          leaveFrom="translate-x-0 "
          leaveTo="translate-x-full "
          as={Fragment}
        >
          <div className="h-full overflow-auto w-[640px] absolute right-0 top-0 z-[104]">
            <CurlModel
              isUpdating={isEditing}
              close={close}
            />
          </div>
        </Transition.Child>
      </Transition>
    </>
  );
};

export default AddApiModel;
