import classNames from "clsx";
import { memo } from "react";
import { Handle, NodeProps, Position, useReactFlow } from "reactflow";
import alertCircle from "@assets/icons/alert-circle.svg";
import { ReactComponent as DuplicateIcon } from "@assets/icons/copy-07.svg";
import TestButton from "@screens/workflow/components/TestButton";
import { BE_MODEL_NODE_TYPE, MODEL_NODE_TYPE } from "@screens/workflow/config";
import { getErrors, getWorkflowKeywordsQuery } from "@screens/workflow/queries";
import BottomPanel from "@screens/workflow/studio/components/BottomPanel";
import ErrorList from "@screens/workflow/studio/components/ErrorList";
import InputList from "@screens/workflow/studio/components/InputList";
import { getModelExpressionQuery } from "@screens/workflow/studio/components/Model/queries";
import useDuplicateNode from "@screens/workflow/studio/hooks/useDuplicateNode";
import useGetIcon from "@screens/workflow/studio/hooks/useGetIcon";
import useImportNodeWithInput from "@screens/workflow/studio/hooks/useImportNodeWithInput";
import useIsHiddenAfterTest from "@screens/workflow/studio/hooks/useIsHiddenAfterTest";
import useKeywordsFromWorkflowKeywords from "@screens/workflow/studio/hooks/useKeywordsFromWorkflowKeywords";
import { ModelId } from "@screens/workflow/types";
import { ArrowsUpDownIcon } from "@heroicons/react/16/solid";
import { TrashIcon } from "@heroicons/react/24/outline";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { generateAndDownloadFile } from "@utils/utils";
import { useWorkflowContext } from "../../../WorkflowContext";
import HandleWithPlus from "../../components/HandleWithPlus";
import ModelExpressions from "../../components/Model/ModelExpressions";
import NodeMenu from "../../components/NodeMenu";
import { NodeName } from "../../components/NodeName";
import useBottomPanelState, { CLOSE } from "../../hooks/useBottomPanelState";
import useIsExpand, {
  useGetHandleStyles,
  useGetHandleStylesForInputDot,
  useGetNodeFontStyles,
  useGetNodeIconStyles,
  useGetNodeStyles,
} from "../../hooks/useIsExpand";
import { getNewNodeName, getUpdateModelNode } from "../../utils";
import { analyticsInstance } from "src/config/event-analytics";
import { PolicyStudioActions } from "src/constants/EventAnalytics";

const ModelNode = ({
  id,
  selected,
  data,
}: NodeProps<{
  label: string;
  isFromFlow?: boolean;
  desc: string;
  hidden?: boolean;
}>) => {
  const {
    isWorkflowEditable,
    setShowDeleteConfirmation,
    workflow,
    updateWorkflow,
    errors,
  } = useWorkflowContext();
  const { getNodes } = useReactFlow();

  const modelExpressionQuery = useQuery(
    getModelExpressionQuery(workflow?.id ?? "", id)
  );

  const [bottomPanelStatus, setBottomPanelStatus] = useBottomPanelState();

  const fontStyles = useGetNodeFontStyles();
  const iconStyles = useGetNodeIconStyles();
  const handleStyles = useGetHandleStyles();
  const isExpanded = useIsExpand();
  const nodeStyles = useGetNodeStyles();
  const queryClient = useQueryClient();
  const handleStylesInputDot = useGetHandleStylesForInputDot();

  const { getInputProps, importNode } = useImportNodeWithInput(
    id,
    MODEL_NODE_TYPE
  );
  const { duplicateNode } = useDuplicateNode(workflow?.id);

  const Icon = useGetIcon(MODEL_NODE_TYPE);

  const hidden = useIsHiddenAfterTest(id, data?.hidden);

  const updateModelNodeName = (name: string) => {
    const e = getUpdateModelNode(name, id, data.desc);
    updateWorkflow(e).then(() => {
      queryClient.invalidateQueries(getWorkflowKeywordsQuery());
      queryClient.invalidateQueries(getErrors(workflow?.id));
    });
  };

  const keywordsQuery = useKeywordsFromWorkflowKeywords(workflow?.id, id);

  const exportNode = () => {
    analyticsInstance.triggerAnalytics(
      PolicyStudioActions.BLOCK_EXPORT,
      {
        name: data.label, 
        type: MODEL_NODE_TYPE,
      }
    );
    generateAndDownloadFile(
      JSON.stringify({
        type: BE_MODEL_NODE_TYPE,
        feType: MODEL_NODE_TYPE,
        expressions: expressions.map((i, index) => ({
          name: i.name,
          condition: i.body,
          seqNo: index,
        })),
      }),
      data.label + ".json"
    );
  };

  const expressions = modelExpressionQuery.data ?? [];

  const nodeErrors: [string, string][] = expressions
    .filter((item) => errors[id as ModelId]?.errors?.[item.id])
    .map((item) => [item.name, errors[id as ModelId].errors[item.id]]);

  return (
    <>
      <div className="flex relative cursor-grab active:cursor-grabbing group">
        <div
          style={nodeStyles}
          className={classNames(
            "overflow-visible w-[360px] bg-white rounded-[10px] hover:border-primary-200 border p-[7px]",
            {
              "opacity-25": hidden,
              "border-primary-200": selected && !hidden,
              "border-neutral-100": !selected,
              "shadow-lg": data?.isFromFlow && !hidden,
              "shadow-studio-node": !(data?.isFromFlow && !hidden),
              "pb-8": !isWorkflowEditable && isExpanded,
              "pb-2": !isWorkflowEditable && !isExpanded,
              "pb-6": isWorkflowEditable && isExpanded,
              "!border-error-500": !isExpanded && nodeErrors.length,
            }
          )}
        >
          <Handle
            type="target"
            position={Position.Left}
            className="w-2 h-2 top-0 mt-[20px] opacity-50 hover:opacity-100 border-2 rounded-full bg-neutral-0  border-neutral-300 !-left-4 duration-150 overflow-visible"
            isConnectable={isWorkflowEditable}
            style={handleStylesInputDot}
          >
            <div className="bg-transparent h-24 w-12 -translate-x-8 -translate-y-1/2" />
          </Handle>
          {!isExpanded && (
            <Handle
              type="source"
              position={Position.Right}
              className="!-right-3 top-1/2"
              isConnectable={false}
              style={handleStyles}
            />
          )}
          <div className="w-full text-neutral-black font-medium flex items-center justify-between">
            <div className="flex gap-1.5 items-center">
              <Icon style={iconStyles} />
              <span className="w-64 truncate" style={fontStyles}>
                <NodeName
                  onChange={(name) => updateModelNodeName(name)}
                  defaultName={data.label || "Model_Node"}
                />
              </span>
            </div>
            <TestButton nodeId={id} />
            <NodeMenu
              isExport={true}
              handleExportNode={exportNode}
              items={[
                {
                  key: "import",
                  label: (
                    <span className="text-neutral-black flex gap-2 items-center">
                      <ArrowsUpDownIcon className="text-neutral-black h-3 w-3 -mt-0.5" />
                      Import
                    </span>
                  ),
                  onClick: () => importNode(),
                },
                {
                  key: "duplicate",
                  label: (
                    <span className="text-neutral-black flex gap-2 items-center">
                      <DuplicateIcon className="text-neutral-black h-3 w-3 -mt-0.5" />
                      Duplicate
                    </span>
                  ),
                  onClick: () =>
                    duplicateNode({
                      type: BE_MODEL_NODE_TYPE,
                      name: getNewNodeName(MODEL_NODE_TYPE, getNodes()),
                      expressions: expressions.map((item, index) => ({
                        name: item.name,
                        condition: item.body,
                        seqNo: index,
                      })),
                    }),
                },
                {
                  key: "delete",
                  label: (
                    <span className="text-error-500 flex gap-2 items-center">
                      <TrashIcon className="[&>path]:stroke-error-500 h-3 w-3 -mt-0.5" />
                      Delete
                    </span>
                  ),
                  onClick: () =>
                    setShowDeleteConfirmation({ id: id, label: data.label }),
                },

                // {
                //   key: "export",
                //   label: (
                //     <span className="text-neutral-black flex gap-2 items-center">
                //       <ArrowDownTrayIcon className="text-neutral-black h-3 w-3 -mt-0.5" />
                //       Export
                //     </span>
                //   ),
                //   onClick: exportNode,
                // },
              ]}
              isFromFlow={!!data.isFromFlow}
            />
          </div>
          {isExpanded && (
            <ModelExpressions
              expressions={expressions}
              workflowId={workflow?.id!}
              nodeId={id}
              isLoading={modelExpressionQuery.isPending}
              isFetching={modelExpressionQuery.isFetching}
              nodeName={data.label}
            />
          )}
          {isExpanded && (
            <div className="absolute bottom-[1px] left-[1px] w-[358px] border-t bg-neutral-0 rounded-b-[10px] font-b2-medium border-neutral-100 px-2 py-1.5 flex gap-6 items-center">
              <span
                className="cursor-pointer text-neutral-500 hover:text-neutral-black"
                onClick={() => setBottomPanelStatus("Inputs")}
              >
                Inputs
              </span>
              {nodeErrors.length > 0 && (
                <span
                  className="flex items-center ml-auto gap-1.5 cursor-pointer text-error-500 font-b2-medium"
                  onClick={() => setBottomPanelStatus("Errors")}
                >
                  <img src={alertCircle} alt="!" />
                  {nodeErrors.length}{" "}
                  {nodeErrors.length === 1 ? " Error" : " Errors"}
                </span>
              )}
            </div>
          )}
        </div>
        {isExpanded && (
          <HandleWithPlus
            nodeId={id}
            label=""
            showAdd={false}
            position={Position.Right}
            id="model"
            className="!absolute top-0 -right-3"
            type="source"
            isConnectable={isWorkflowEditable}
            handleStyles={handleStyles}
          />
        )}
      </div>
      <input {...getInputProps()} />
      <BottomPanel selected={selected} show={bottomPanelStatus === "Inputs"}>
        <InputList
          close={() => setBottomPanelStatus(CLOSE)}
          keywords={keywordsQuery}
          query={""}
        />
      </BottomPanel>
      <BottomPanel selected={selected} show={bottomPanelStatus === "Errors"}>
        <ErrorList
          errors={nodeErrors}
          close={() => setBottomPanelStatus(CLOSE)}
        />
      </BottomPanel>
    </>
  );
};

export default memo(ModelNode);
