import {
    formatDistanceToNow,
    parseISO,
  } from "date-fns";
  import { useNavigate } from "react-router-dom";
  import {
    useMarkNotificationRead,
  } from "src/apis";
  import { useDownloadReport } from "src/screens/reports/pages/generated-reports/queries";
  import {
    ReportNotification,
  } from "src/types";
  import { getInitial } from "src/utils/utils";
  import { ArrowUpRightIcon } from "@heroicons/react/24/solid";
  import { CTA_MAPPER } from "./NotificationCenter.constants";
import NotificationBaseCard from "./NotificationBaseCard";
  
const ReportsCard = ({ item }: { item: ReportNotification }) => {
    const downloadReport = useDownloadReport();
    const markNotificationRead = useMarkNotificationRead();
    const navigate = useNavigate();
  
    const handleCtaAction = () => {
      downloadReport.mutate(item.metadata.id);
      markNotificationRead.mutate({ notificationId: item.id });
    };
    const handleRetryAction = () => {
      navigate("/reports/outcomes");
      markNotificationRead.mutate({ notificationId: item.id });
    };
    return (
      <>
        <NotificationBaseCard isRead={item.isRead}>
          <>
            <div className="w-7 h-7 flex justify-center items-center rounded-full bg-neutral-900 text-neutral-0 font-medium text-[11px]">
              {getInitial(item.metadata.updatedBy)}
            </div>
            <div className="flex flex-col ml-4 w-[90%]">
              <p className="mb-3 font-b1 text-neutral-600">
                <span className="text-neutral-black font-b1-medium">
                  {item.metadata?.type} Report
                </span>{" "}
                {item.metadata.status.toLowerCase() === "delivered" ? (
                  <>
                    is shared to{" "}
                    <span className="text-neutral-black font-b1-medium">
                      {item.metadata?.email}
                    </span>
                  </>
                ) : (
                  <>has failed</>
                )}
              </p>
              <div className="flex justify-between">
                <p className="font-[12px] text-neutral-600">
                  {formatDistanceToNow(parseISO(item.updatedAt))} ago
                </p>
                {item.metadata.status.toLowerCase() === "delivered" ? (
                  <button
                    className="group-hover:visible invisible flex text-primary-900 font-b2-medium items-center"
                    onClick={() => handleCtaAction()}
                  >
                    {CTA_MAPPER[item.type]?.text}
                    {CTA_MAPPER[item.type]?.icon}
                  </button>
                ) : (
                  <button
                    className="group-hover:visible invisible flex text-primary-900 font-b2-medium items-center"
                    onClick={() => handleRetryAction()}
                  >
                    View
                    <ArrowUpRightIcon className="ml-1 stroke-primary-900 w-3 h-3" />{" "}
                  </button>
                )}
              </div>
            </div>
          </>
        </NotificationBaseCard>
      </>
    );
  };
  

  export default ReportsCard;