import { useRef, useState } from "react";
import Modal from "src/components/Dialogue";
import { ReactComponent as UploadIcon } from "@assets/icons/UploadIcon.svg";
import { useSimulate } from "@screens/workflow/components/Simulation/queries";
import Button from "@components/Button";
import Link from "@components/Link";
import ErrorText from "@components/RunSimulationsModal/ErrorText";
import { useSimulationV2 } from "@components/RunSimulationsModal/useSimulation";
import { TrashIcon } from "@heroicons/react/24/outline";
import Footer from "src/components/RunSimulationsModal/Footer";
import { SimulationModalProps } from "../SimulationModal.types";
import { useGetTemplate, useGetWorkflowTemplate } from "./CustomSimulation.queries";
import { analyticsInstance } from "src/config/event-analytics";
import { PolicyStudioActions } from "src/constants/EventAnalytics";
import { useWorkflowContext } from "src/screens/workflow/WorkflowContext";


export default function CustomCSV({ closeModal, policyID, ...props }: SimulationModalProps) {
  const [file, setFile] = useState<File>();
  const [error, setError] = useState("");
  const { workflow } = useWorkflowContext();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [hasRunSimulation, setHasRunSimulation] = useState(false);

  const { isPending, mutate } = useSimulationV2();
  const runWfSimulation = useSimulate();

  const wfSampleFile = useGetWorkflowTemplate(
    (props.type === "workflow" && props.workflowId) ? props.workflowId : "",
    props.type === "workflow"
  );

  const sampleFile = useGetTemplate(
    { policy_id: policyID },
    props.type !== "workflow"
  );

  const validate = () => {
    if (!file) setError("File is required");
    return file;
  };
  const submitSimulation = () => {
    if (!validate()) return;
    if (props.type === "workflow" && props?.workflowId) {
      runWfSimulation.mutate({
        type: "custom",
        file: file!,
        workflowId: props.workflowId,
      });
    } else {
      const formData = new FormData();
      formData.append("policy_id", policyID);
      if (file) {
        formData.append("file", file);
      }

      mutate(
        { formData },
        {
          onSuccess: () => {
            setFile(undefined);
            setError("");
            if (fileInputRef.current) fileInputRef.current.value = "";
            setHasRunSimulation(true);
          },
        }
      );
    }

    analyticsInstance.triggerAnalytics(
      PolicyStudioActions.SIDEBAR_SIMULATION_CUSTOM_RUN_SIMULATION,
      {
        bucket_name: workflow?.policyName ?? "",
        version: workflow?.policyVersion ?? "",
      }
    );
  };

    const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
      const file = e.target?.files?.[0];
      if (!file) return;
      const ext = file.name.split(".").pop()!;
      if (!["csv"].includes(ext)) {
        setError("Only CSV files are allowed");
      }
      setError("");
      setFile(file);

      analyticsInstance.triggerAnalytics(
      PolicyStudioActions.SIDEBAR_SIMULATION_CUSTOM_UPLOAD,
      {
        bucket_name: workflow?.policyName ?? "",
        version: workflow?.policyVersion ?? "",
      }
      );
    }

    const handleCutomDataTemplete = () => {

      analyticsInstance.triggerAnalytics(
        PolicyStudioActions.SIDEBAR_SIMULATION_CUSTOM_DATA_TEMPLATE_DOWNLOAD,
        {
          bucket_name: workflow?.policyName ?? "",
          version: workflow?.policyVersion ?? "",
        }
      );
    }
    

  return (
    <>
      {hasRunSimulation && (
        <>
          <div className="font-b2 mt-4">
            Your simulation report will be generated in a few minutes. The
            report will be available to download in the{" "}
            <span className="font-b2-medium">
              <Link to="/reports/simulations">Simulation Reports Page</Link>
            </span>
          </div>
          <Modal.Footer className="flex w-full justify-end gap-2">
            <Button
              disabled={isPending}
              className="!w-max"
              onClick={closeModal}
            >
              Okay
            </Button>
          </Modal.Footer>
        </>
      )}
      {!hasRunSimulation && (
        <>
          <div className="relative w-full">
            <div className="group relative flex flex-col items-center gap-1 border-dashed rounded-md border p-5 text-xs text-gray-500 my-4">
              {file ? (
                <span className="flex items-center gap-1">
                  {file.name}
                  <TrashIcon
                    className="z-10 h-4 w-4 cursor-pointer text-red-600"
                    onClick={() => setFile(undefined)}
                  />
                </span>
              ) : (
                <>
                  <span className="">
                    <UploadIcon className="w-5 h-5" />
                  </span>
                  <span className="flex items-center gap-2">
                    <Button className="px-0" variant="link" onClick={() => {}}>
                      Click to upload
                    </Button>{" "}
                    or drag and drop
                  </span>
                  <span>CSV (max. 5MB)</span>
                </>
              )}
              <input
                ref={fileInputRef}
                onChange={handleFileUpload}
                type="file"
                className="absolute top-0 left-0 right-0 bottom-0 opacity-0"
              />
            </div>
            <ErrorText>{error}</ErrorText>
          </div>

          <span className="font-b2 flex items-start">
            <span className="flex-col flex">
              You can download our template containing all the data you would
              require to run the simulation
              <a
                onClick={handleCutomDataTemplete}
                href={
                  props.type === "workflow"
                    ? wfSampleFile.data
                    : sampleFile.data
                }
                className="text-primary-800 hover:underline mt-2"
                target="_blank"
                rel="noreferrer"
              >
                Custom Data Template
              </a>
            </span>
          </span>

          {props.type === "workflow" &&
            !runWfSimulation.isPending &&
            runWfSimulation.isSuccess && (
              <div className="font-b2 mt-4 text-neutral-black">
                You can download the simulation report from{" "}
                <Link to="/reports/simulations">Reports &gt; Simulations</Link>
              </div>
            )}
          {props.type !== "workflow" && (
            <Footer isLoading={isPending} submit={submitSimulation} />
          )}
          {props.type === "workflow" && (
            <div className="fixed w-[680px] -translate-x-4 bottom-0 justify-end flex items-center border-t border-indigo-50 bg-neutral-0 px-4 py-2.5">
              <Button disabled={isPending} onClick={submitSimulation}>
                Run Simulation
              </Button>
            </div>
          )}
        </>
      )}
    </>
  );
}
