import { useState } from "react";
import { useParams } from "react-router-dom";
import Dropdown from "@components/DropDown";
import Application from "@components/RunSimulationsModal/Application";
import CustomCSV from "@components/RunSimulationsModal/Custom";
import Historical from "@components/RunSimulationsModal/Historical";
import { SimulationModalProps } from "src/components/RunSimulationsModal/SimulationModal.types";
import { analyticsInstance } from "src/config/event-analytics";
import { PolicyStudioActions } from "src/constants/EventAnalytics";
import { useWorkflowContext } from "../../WorkflowContext";

const DATA_TYPES = {
  Historical:"Historical",
  Application:"Application",
  Custom:"Custom"
}

const SimulationForm = () => {
  const { workflow } = useWorkflowContext();
  const [selectedDataType, setSelectedDataType] = useState("Historical");
  const { workflowId } = useParams();


  const selectSimulationType = (val: string) => {
    setSelectedDataType(val);

    analyticsInstance.triggerAnalytics(
      PolicyStudioActions.SIDEBAR_SIMULATION_TYPE,
      {
        bucket_name: workflow?.policyName ?? "",
        version: workflow?.policyVersion ?? "",
        simulationType:val,
      }
    );
  }

  const renderWorkFlowChild = () => {
    const commonProps:SimulationModalProps = {
      policyID: "test",
      workflowId: workflowId!,
      type: "workflow",
      closeModal: () => {},
    };
  
    switch (selectedDataType) {
      case DATA_TYPES.Application:
        return <Application {...commonProps} />;
      case DATA_TYPES.Custom:
        return <CustomCSV {...commonProps} />;
      default:
        return <Historical {...commonProps} />;
    }
  };

  return (
    <div className="pt-4 relative">
      <div className="px-4">
        <label className="font-b2-medium text-neutral-500 block leading-7">
          Select Simulation Type
        </label>
        <div>
          <Dropdown
            value={selectedDataType}
            className="w-full"
            onChange={selectSimulationType}
          >
            <Dropdown.Button className="relative w-full rounded-md border py-2 px-4 text-left text-neutral-black">
              {selectedDataType}
            </Dropdown.Button>
            <Dropdown.Options className="absolute left-0 top-10 z-20 box-border w-full rounded-md border bg-white shadow-lg ring-1 ring-gray-100">
              {Object.values(DATA_TYPES).map((dataType) => (
                <Dropdown.Option key={dataType} value={dataType} showTick>
                  <div>{dataType}</div>
                </Dropdown.Option>
              ))}
            </Dropdown.Options>
          </Dropdown>
        </div>
      </div>
      <div className="px-4 flex max-h-[100%]  min-w-full  grow flex-col rounded-md bg-white">
        {renderWorkFlowChild()}
      </div>
    </div>
  );
};

export default SimulationForm;
