import { useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import Button from "src/components/Button";
import Modal from "src/components/Dialogue";
import Input from "src/components/Input";
import {
  allRoleListQuery,
  memberRoleListQuery,
  useCreateNewRole,
  useDuplicateRole,
} from "./queries";
import { analyticsInstance } from "src/config/event-analytics";
import { SettingsActions } from "src/constants/EventAnalytics";

type Props = {
  show: boolean;
  setShow: setStateType<boolean>;
  isDuplicate?: boolean;
  oldRoleName?: string;
};

export function AddNewRole({ show, setShow, isDuplicate, oldRoleName }: Props) {
  const navigate = useNavigate();
  const createNewRole = useCreateNewRole();
  const duplicateRole = useDuplicateRole();
  const qc = useQueryClient();

  const {
    formState: { errors },
    register,
    handleSubmit,
  } = useForm({
    defaultValues: {
      roleName: "",
    },
  });

  const submit = handleSubmit(({ roleName }) => {
    if (isDuplicate && oldRoleName) {
      duplicateRole.mutate(
        {
          oldRoleName: oldRoleName,
          roleName: roleName,
        },
        {
          onSuccess: () => {
            setShow(false);
            navigate("/settings/roles/update", {
              state: { roleName: roleName, roleType: "custom" },
            });
            qc.invalidateQueries(allRoleListQuery());
          },
        }
      );
      return;
    }
    
    analyticsInstance.triggerAnalytics(
      SettingsActions.ROLES_CREATE_NEW_ROLE,
      {role_name:roleName}
    );

    createNewRole.mutate(
      {
        roleName: roleName,
      },
      {
        onSuccess: () => {
          setShow(false);
          navigate("/settings/roles/update", {
            state: { roleName: roleName, roleType: "custom" },
          });
          qc.invalidateQueries(allRoleListQuery());
          qc.invalidateQueries(memberRoleListQuery());
        },
      }
    );
  });

  return (
    <Modal open={show} onClose={() => setShow(false)}>
      <Modal.Panel size="sm" onClose={() => setShow(false)}>
        <Modal.Header>
          {isDuplicate ? `Duplicate Role - ${oldRoleName}` : "Create New Role"}
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={submit}>
            <Input
              label="Role Name"
              placeholder="Enter role name"
              autoComplete="off"
              error={errors.roleName?.message}
              {...register("roleName", {
                required: { value: true, message: "Role name is required" },
                pattern: {
                  value: /^[a-zA-Z0-9_]+$/,
                  message:
                    "Role name must contain only letters, numbers, and underscores",
                },
              })}
            />
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={submit}
            disabled={createNewRole.isPending || duplicateRole.isPending}
          >
            {isDuplicate ? "Duplicate" : "Create"}
          </Button>
        </Modal.Footer>
      </Modal.Panel>
    </Modal>
  );
}
