import { getExpressionTypeIcon, highlightDifferences } from "../../utils";

export const ModelSetExpression = ({
  name,
  prevExpression,
  currExpression,
  isUpdated,
  prevName,
  state,
}: {
  name: string | undefined;
  prevExpression: string | undefined | null;
  currExpression: string | undefined | null;
  state: string;
  isUpdated?: boolean;
  prevName?: string;
}) => {
  const { prevHighlighted, currHighlighted } = highlightDifferences(
    prevExpression || "",
    currExpression || ""
  );

  return (
    <div>
      {!!isUpdated && state === "edit" ? (
        <div className="bg-neutral-0 border-y border-neutral-100 px-4 h-[100%] py-2 font-b2-medium text-neutral-black">
          <span className="flex flex-wrap items-center gap-2">
            {getExpressionTypeIcon(state)}
            {prevName} <span className="text-neutral-500">is changed to</span>{" "}
            {name}
          </span>
        </div>
      ) : (
        <div className="bg-neutral-0 border-y border-neutral-100 px-4 h-8 py-2 font-b2-medium text-neutral-black">
          <span className="flex items-center gap-2">
            {getExpressionTypeIcon(state)}
            {name}
          </span>
        </div>
      )}
      <div className="flex">
        <div className="border-r w-[50%] flex flex-col px-4 py-3 gap-2 overflow-none">
          {prevExpression ? (
            <>
              <span className="text-neutral-black text-xs font-medium">
                Expression
              </span>
              <div className="text-neutral-black font-code text-sm flex flex-wrap break-all">
                {prevHighlighted}
              </div>
            </>
          ) : (
            state !== "edit" && (
              <span className="text-neutral-500 m-auto">
                Modelset not present
              </span>
            )
          )}
        </div>
        <div className="flex flex-col w-[50%] px-4 py-3 gap-2 left-0">
          {currExpression ? (
            <>
              <span className="text-neutral-black text-xs font-medium">
                Expression
              </span>
              <div className="text-neutral-black font-code text-sm flex flex-wrap break-all">
                {currHighlighted}
              </div>
            </>
          ) : (
            state !== "edit" && (
              <span className="text-neutral-500 m-auto">
                Modelset is deleted
              </span>
            )
          )}
        </div>
      </div>
    </div>
  );
};
