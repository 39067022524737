import { clsx } from "clsx";
import * as monacoEditor from "monaco-editor";
import { useEffect, useRef } from "react";
import { useWorkflowContext } from "@screens/workflow/WorkflowContext";
import Editor, { EditorProps } from "@monaco-editor/react";

const BASE_SIZE = 150;

interface ExprEditorPops {
  setValue: (e: string, shouldRefetchWf?: boolean) => void;
  value: string;
  disableRegister?: boolean;
  autoFocus?: boolean;
  error?: {
    startCol: number;
    endCol: number;
    type: monacoEditor.MarkerSeverity;
  };
  monacoOptions?: monacoEditor.editor.IStandaloneEditorConstructionOptions;
  containerClassName?: string;
}

function calcContainerHeightFromEditorHeight(editorHeight: number) {
  const finalHeight = Math.min(editorHeight, BASE_SIZE);
  const extra = (finalHeight < BASE_SIZE) ? 20 : 30;
  return finalHeight + extra;
}

export default function CustomEditor({
  value,
  setValue,
  monacoOptions,
  containerClassName,
  autoFocus = true,
}: ExprEditorPops) {
  const { isWorkflowEditable } = useWorkflowContext();
  const editorRef = useRef<monacoEditor.editor.IStandaloneCodeEditor>();
  const containerRef = useRef<HTMLDivElement>(null);
  const isEditing = useRef(false);

  const handleEditorDidMount: EditorProps["onMount"] = (editor, monaco) => {
    editorRef.current = editor;
    // if (autoFocus) editor.focus();

    editor.setPosition({
      column: value.length + 1,
      lineNumber: 1,
    });
    editor.onDidBlurEditorText(() => {
      if (isEditing.current) {
        isEditing.current = false;
        setValue(editorRef.current?.getModel()?.getValue() || "");
      }
    });
    editor.onDidBlurEditorWidget(() => {
      if (isEditing.current) {
        isEditing.current = false;
        setValue(editorRef.current?.getModel()?.getValue() || "");
      }
    });
    editor.onDidContentSizeChange(() => {
      const height = editorRef.current?.getContentHeight() ?? 16;
      if (containerRef.current)
        containerRef.current.style.height = `${calcContainerHeightFromEditorHeight(
          height
        )}px`;
      editorRef.current?.layout({
        width: editorRef.current?.getDomNode()?.clientWidth ?? 430,
        height: Math.min(height, BASE_SIZE)+10,
      });
    });
    editor.onDidFocusEditorWidget(() => (isEditing.current = true));
  };

  useEffect(() => {
    if (window.ResizeObserver && containerRef.current) {
      const resizeObserver = new ResizeObserver((entries) => {
        editorRef.current?.layout({
          width: editorRef.current?.getDomNode()?.clientWidth ?? 430,
          height: editorRef.current?.getContentHeight() ?? 16,
        });
      });
      resizeObserver.observe(containerRef.current);
      return () => resizeObserver.disconnect();
    }
  }, []);

  useEffect(() => {
    return () => {
      if (editorRef.current?.dispose) editorRef.current.dispose();
    };
  }, []);

  return (
    <div
      ref={containerRef}
      className={clsx(
        "w-full relative rounded-md px-1.5 p-1 bg-white border border-neutral-100 nodrag pb-0 nodrag max-h-[160px] h-[28px]",
        containerClassName
      )}
    >
      <Editor
        defaultLanguage="sent-lang"
        className={clsx("nodrag")}
        value={value}
        onMount={handleEditorDidMount}
        options={{
          wordWrap: "on",
          lineDecorationsWidth: 0,
          overviewRulerLanes: 0,
          overviewRulerBorder: false,
          scrollbar: {
            horizontal: "auto",
            vertical: "hidden",
            horizontalScrollbarSize: 3,
            horizontalSliderSize: 3,
          },
          minimap: { enabled: false },
          quickSuggestions: true,
          wordBasedSuggestions: true,
          renderLineHighlight: "none",
          readOnly: !isWorkflowEditable,
          renderValidationDecorations: "on",
          tabCompletion: "on",
          scrollBeyondLastLine: false,
          suggest: {
            showFunctions: true,
            preview: true,
            selectionMode: "always",
            showInlineDetails: true,
            shareSuggestSelections: true,
          },
          peekWidgetDefaultFocus: "editor",
          ...monacoOptions,
        }}
      />
    </div>
  );
}
