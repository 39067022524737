import { ReactComponent as FiChevronDown } from "@assets/icons/chevron-down.svg";
import { ReactComponent as FiChevronRight } from "@assets/icons/chevron-right.svg";
import closeIcon from "@assets/icons/chevron-selector-vertical-close.svg";
import openIcon from "@assets/icons/chevron-selector-vertical-open.svg";
import jsonPlaceholder from "@assets/icons/jsonPlaceHolder.png";
import React, { FC, useEffect, useState } from "react";
import { classNames } from "src/utils/utils";
import { JsonNodeProps, JsonViewerProps } from "./JsonInput.types";

const JsonNode: FC<JsonNodeProps> = ({
  node,
  isNullableEnabled,
  readOnly,
  childNode = true,
  expandAll = false,
}) => {
  const hasChildren = node.children && node.children.length > 0;
  const [isCollapsed, setIsCollapsed] = useState<boolean>(Boolean(hasChildren));

  useEffect(() => {
    // if(expandAll){
    setIsCollapsed(!expandAll);
    // }
  }, [expandAll]);

  return (
    <div className={classNames(childNode ? "pl-4 flex flex-col gap-1" : "")}>
      <div className="flex items-center space-x-2 mt-1">
        <div className="flex items-start space-x-2 font-b2-medium">
          {hasChildren ? (
            <button
              className="text-blue-500 hover:underline pt-0.5"
              onClick={() => setIsCollapsed(!isCollapsed)}
            >
              {isCollapsed ? (
                <FiChevronRight className="[&>path]:stroke-neutral-black" />
              ) : (
                <FiChevronDown />
              )}
            </button>
          ) : (
            <span className="w-[18px]"></span>
          )}

          {hasChildren ? (
            <span className="text-neutral-500 font-b1">{`{${node?.children?.length}}`}</span>
          ) : (
            <span className="w-3"></span>
          )}
          {isNullableEnabled && childNode && (
            <input
              id={`${node.id}_nullable`}
              type="checkbox"
              checked={Boolean(node.isNullable)}
              disabled={readOnly}
            />
          )}
          {/* {!hasChildren && getIcon(node.dataType)} */}
          <span className="font-b1-medium word-split max-w-[240px]">
            {node.name}
          </span>
          <span className="text-neutral-500 capitalize font-b2">
            {node.dataType}
          </span>
          {node?.isArray && (
            <span className="text-neutral-500 font-b2 bg-neutral-25 rounded-md px-2">
              Array
            </span>
          )}
        </div>
      </div>
      {/* {
        (!hasChildren && !readOnly) && 
            <Input
                defaultValue={node.defaultInput}
                id={`${node.id}_defaultInput`}
                placeholder="Enter value"
                className="max-w-[300px] h-[27px] mr-1 mt-3"
            />
      } */}
      {!isCollapsed && hasChildren && (
        <div className="mt-1 space-y-2">
          {node.children?.map((child, index) => (
            <JsonNode
              key={index}
              node={child}
              isNullableEnabled={isNullableEnabled}
              readOnly={readOnly}
              expandAll={expandAll}
            />
          ))}
        </div>
      )}
    </div>
  );
};

const InputJsonTreeRender: FC<JsonViewerProps> = ({
  data,
  isNullableEnabled,
  readOnly,
}) => {
  const [expandAll, setExpandedAll] = useState<boolean>(false);

  const handleExpandClick = () => {
    setExpandedAll(!expandAll);
  };

  return (
    <div className="h-full max p-4 gap-3 flex flex-col border-l">
      <div className="flex justify-between items-center h-[28px]">
        <div className="text-sm text-neutral-black font-medium">Tree</div>
        {data ? (
          <img
            src={expandAll ? closeIcon : openIcon}
            alt=""
            className="hover:bg-neutral-25 cursor-pointer rounded-md p-1 w-6 h-6 "
            onClick={handleExpandClick}
          />
        ) : null}
      </div>
      <div className="h-full pb-4 max-h-[500px] w-full flex overflow-hidden">
        {!data?.schema ? (
          <div
            className="h-3/4 w-full flex justify-center items-center"
            style={{
              pointerEvents: "none", // Make the image unclickable
            }}
          >
            <div className="flex flex-col justify-center items-center max-w-[290px] gap-4">
              <img
                src={jsonPlaceholder} // Replace with your image URL
                alt="placeholder"
              />
              <span className="font-b2 text-neutral-500 text-center">
                Paste your JSON on the left and click 'Transform' to see data
                structure here.
              </span>
            </div>
          </div>
        ) : (
          <div className="h-full max-h-[600px] w-full flex overflow-scroll show-scrollbar">
            <JsonNode
              expandAll={expandAll}
              node={data}
              isNullableEnabled={isNullableEnabled}
              readOnly={readOnly}
              childNode={false}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(InputJsonTreeRender);
