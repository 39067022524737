import { ChangeLogState, EndNode, EndNodeData } from "../../types";
import { getStatusIndicator } from "../../utils";

export const EndNodeLogCard = ({
  prevData,
  currData,
  nodeState,
}: {
  prevData: EndNode | null;
  currData: EndNode | null;
  nodeState: ChangeLogState;
}) => {
  const renderDecisionNode = (data: EndNodeData, type: string) => {
    if (!data?.decisionNode)
      return <span className="text-neutral-500">No data available</span>;

    return (
      <div className="flex flex-col gap-2 py-1">
        {Object.entries(data.decisionNode).map(([key, value]) => (
          <div key={key} className="flex gap-1 flex-col">
            <span className="flex flex-wrap font-b2-medium gap-2">
              {type === "curr" && getStatusIndicator(nodeState)} {key}
            </span>
            <span className="flex flex-wrap w-[100%] font-b2 text-neutral-500 gap-1.5 items-center">
              {value.type}:
              <span className="flex break-all text-neutral-black text-sm font-code">
                {" "}
                {value.value}
              </span>
            </span>
          </div>
        ))}
      </div>
    );
  };

  const renderWorkflowState = (data: EndNodeData) => {
    if (!data?.workflowState?.outcomeLogic?.workflows?.length) {
      return (
        <div className="m-auto text-neutral-black">
          Last workflow/policy run
        </div>
      );
    }

    return (
      <div className="flex flex-col gap-1.5">
        <div className="flex flex-wrap gap-1">
          {data.workflowState.outcomeLogic.workflows.map((workflow, index) => (
            <span
              key={index}
              className="flex flex-wrap font-b2-medium break-all"
            >
              {typeof workflow === "string"
                ? workflow
                : JSON.stringify(workflow)}
            </span>
          ))}
        </div>
        <div className="flex flex-wrap font-b2 text-neutral-500 gap-1">
          Variable:
          <span className="text-primary-900">
            {data.workflowState.outcomeLogic.predictor}
          </span>
        </div>
        <div className="flex flex-wrap font-b2 text-neutral-500 gap-1">
          Operator:
          <span className="text-primary-900">
            {data.workflowState.outcomeLogic.function}
          </span>
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-col">
      <div className="bg-neutral-0 border-y border-neutral-100 px-4 h-8 py-2 font-b2-medium text-neutral-black">
        <span className="flex items-center gap-2">
          {currData?.data?.from === "decisionNode"
            ? "Custom Script"
            : "Workflows"}
        </span>
      </div>

      <div className="flex">
        <div className="w-[50%] border-r px-4 py-3">
          {prevData ? (
            prevData.data?.from === "decisionNode" ? (
              renderDecisionNode(prevData.data, "prev")
            ) : (
              renderWorkflowState(prevData.data!)
            )
          ) : (
            <div className="flex flex-col m-auto px-4 py-3 gap-2 left-0">
              <span className="flex font-b2 text-neutral-500 m-auto">
                Endnode not present
              </span>
            </div>
          )}
        </div>

        <div className="w-[50%] px-4 py-3">
          {currData ? (
            currData.data?.from === "decisionNode" ? (
              renderDecisionNode(currData.data, "curr")
            ) : (
              renderWorkflowState(currData.data!)
            )
          ) : (
            <div className="flex flex-col m-auto px-4 py-3 gap-2 left-0">
              <span className="flex font-b2 text-neutral-500 m-auto">
                Endnode is deleted
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
