import Search from "@components/Search";
import { Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useQuery } from "@tanstack/react-query";
import {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useReactFlow } from "reactflow";
import Loader from "src/components/Loader";
import {
  HORIZONTAL_ARROW_COLLAPSE,
  HORIZONTAL_ARROW_EXPAND,
} from "src/constants/imageConstants";
import useOnClickOutside from "src/hooks/useOnClickOutside";
import {
  getChangeLog,
  getChangeLogWfVersionList,
} from "src/screens/workflow/queries";
import { classNames, truncateString } from "src/utils/utils";
import { ChangeLogLeftPanelList, ChangelogNode } from "../../types";
import { getChangeLogIcon, getLogTypeIcon, groupByTitle } from "../../utils";
import { ChangedNodes } from "../ChangedNodes";
import { HeaderWithVersion } from "./subComponents/HeaderWithVersion";
import { NoChangesState } from "./subComponents/NoChangesState";
import { NoLogGeneratedState } from "./subComponents/NoLogGeneratedState";

export const WfChangeLogSidePanel = ({
  setIsChangeLog,
  bucketId,
  fromWfId,
  toWfId,
  setFromVersion,
}: {
  setIsChangeLog: React.Dispatch<React.SetStateAction<boolean>>;
  bucketId: string;
  fromWfId: string;
  toWfId: string;
  setFromVersion: React.Dispatch<React.SetStateAction<string | null>>;
}) => {
  const {
    data: changeLogData,
    isPending,
    isSuccess,
  } = useQuery(getChangeLog(bucketId, fromWfId, toWfId));
  const [searchQuery, setSearchQuery] = useState("");
  const [isHideNodes, setIsHideNodes] = useState(false);
  const [selectedNodeId, setSelectedNodeId] = useState<string | null>(null);
  const [changeLogCount, setChangeLogCount] = useState({
    editCount: 0,
    addCount: 0,
    deleteCount: 0,
  });
  const {
    data: versionList,
    isLoading: versionListLoading,
    isSuccess: versionListSuccess,
  } = useQuery(getChangeLogWfVersionList(bucketId, toWfId));
  const ref = useRef<HTMLDivElement>(null);
  const { setCenter, getNodes } = useReactFlow();
  const nodes = useMemo(() => getNodes(), [getNodes]);

  useOnClickOutside(ref, () => setIsChangeLog(false));

  const handleNodePosition = useCallback(
    (item: { id: string }) => {
      setSelectedNodeId(item.id);
      const node = nodes.find((node) => node.id === item.id);
      if (node) {
        const { x, y } = node.position;
        const nodeHeight = node.height || 0;
        setCenter(x + 235, y + nodeHeight / 2, {
          duration: 500,
          zoom: 1,
        });
      }
    },
    [nodes, setCenter]
  );

  useEffect(() => {
    if (isSuccess && changeLogData) {
      const nodes = changeLogData.data.changelogNodes;
      const editCount = nodes.filter(
        (node: ChangelogNode) => node.state === "edit"
      ).length;
      const addCount = nodes.filter(
        (node: ChangelogNode) => node.state === "add"
      ).length;
      const deleteCount = nodes.filter(
        (node: ChangelogNode) => node.state === "delete"
      ).length;
      setChangeLogCount({ editCount, addCount, deleteCount });
    }
  }, [changeLogData, isSuccess]);

  const filteredList: ChangeLogLeftPanelList[] = useMemo(() => {
    return groupByTitle(changeLogData?.data?.changelogNodes || [])
      .map((section) => ({
        ...section,
        items: section.items.filter((item) =>
          item?.nodeName.toLowerCase().includes(searchQuery.toLowerCase())
        ),
      }))
      .filter((section) => section.items.length > 0);
  }, [changeLogData, searchQuery]);

  return (
    <Transition
      enter="hide-scrollbar transform transition-all ease-in-out duration-300 sm:duration-500"
      enterFrom="translate-x-full opacity-0"
      enterTo="translate-x-0 opacity-100"
      leave="hide-scrollbar transform transition-all ease-in-out duration-200 sm:duration-500"
      leaveFrom="translate-x-0 opacity-100"
      leaveTo="translate-x-full opacity-0"
      show
      appear
      as="div"
      className="absolute right-0 top-10 h-[calc(100vh-44px)] bg-white shadow-lg"
    >
      <div className="flex justify-between p-3 font-b1-medium border-l">
        Version Change Logs
        <div className="flex gap-4 items-center">
          {isSuccess &&
            !isPending &&
            changeLogData?.data?.changelogNodes.length > 0 && (
              <div
                className="flex gap-1 cursor-pointer items-center"
                onClick={() => setIsHideNodes(!isHideNodes)}
              >
                <img
                  src={
                    isHideNodes
                      ? HORIZONTAL_ARROW_COLLAPSE
                      : HORIZONTAL_ARROW_EXPAND
                  }
                  alt=""
                  className="w-4 h-4"
                />
                <span>
                  {isHideNodes ? "Collapse changes" : "Expand changes"}
                </span>
              </div>
            )}
          <XMarkIcon
            className="h-4 w-4 cursor-pointer"
            onClick={() => {
              setIsChangeLog(false);
              setFromVersion(null);
            }}
          />
        </div>
      </div>

      <div className="h-[95%] bg-white flex border-t border-l">
        {isPending ? (
          <div
            className={classNames(
              isHideNodes ? "w-[calc(100vw-40px)]" : "w-[976px]"
            )}
          >
            <div className="mx-auto flex flex-col mt-36 items-center">
              <Loader />
            </div>
          </div>
        ) : isSuccess ? (
          changeLogData ? (
            changeLogData?.data.changelogNodes.length > 0 ? (
              <div
                className={classNames(
                  "flex",
                  isHideNodes ? "w-[calc(100vw-40px)]" : "w-[976px]"
                )}
              >
                <div className="min-w-[275px] overflow-y-auto border-r h-full p-3">
                  <Search
                    className="min-w-[250px] mb-3 !mr-0"
                    onSearchChange={(e) => setSearchQuery(e.target.value)}
                    placeholder="Search by Parameters, Node"
                  />
                  {filteredList.map((section, sectionIndex) => (
                    <Fragment key={sectionIndex}>
                      <h3 className="text-xs font-normal text-neutral-500 mb-2">
                        {section.title}
                      </h3>
                      {section.items.map((item) => (
                        <div
                          key={item.id}
                          onClick={() => handleNodePosition(item)}
                          className={classNames(
                            "items-center text-neutral-black flex gap-2 font-b2-medium py-1.5 mb-1.5 cursor-pointer hover:bg-neutral-25 px-2 rounded-md justify-between",
                            selectedNodeId === item.id && "bg-neutral-100"
                          )}
                        >
                          <span className="flex gap-2">
                            {getChangeLogIcon(
                              item.type,
                              item.nodeName.toLowerCase()
                            )}
                            <span className="truncate">
                              {truncateString(item.nodeName, 25)}
                            </span>
                          </span>
                          {getLogTypeIcon(item.action)}
                        </div>
                      ))}
                    </Fragment>
                  ))}
                </div>
                <div className="w-[calc(100vw-40px)] px-6 pt-3 relative items-center">
                  <HeaderWithVersion
                    currWfName={changeLogData?.data?.currWfName}
                    versionList={versionList || []}
                    setFromVersion={setFromVersion}
                    versionListLoading={versionListLoading}
                    versionListSuccess={versionListSuccess}
                    fromWfId={fromWfId}
                  />
                  <div className="flex gap-4">
                    <div className="flex items-center gap-1.5">
                      {getLogTypeIcon("edit")}
                      <span className="font-b2-medium">
                        {changeLogCount.editCount}{" "}
                        {changeLogCount.editCount > 1
                          ? "Nodes updated"
                          : "Node updated"}
                      </span>
                    </div>
                    <div className="flex items-center gap-1.5">
                      {getLogTypeIcon("add")}
                      <span className="font-b2-medium">
                        {changeLogCount.addCount}{" "}
                        {changeLogCount.addCount > 1
                          ? "Nodes added"
                          : "Node added"}
                      </span>
                    </div>
                    <div className="flex items-center gap-1.5">
                      {getLogTypeIcon("delete")}
                      <span className="font-b2-medium">
                        {changeLogCount.deleteCount}{" "}
                        {changeLogCount.deleteCount > 1
                          ? "Nodes deleted"
                          : "Node deleted"}
                      </span>
                    </div>
                  </div>
                  <ChangedNodes
                    changeLogData={changeLogData?.data}
                    selectedNodeId={selectedNodeId}
                  />
                </div>
              </div>
            ) : (
              <div className={classNames("flex flex-col w-[701px] px-6 py-3")}>
                <HeaderWithVersion
                  currWfName={changeLogData?.data?.currWfName}
                  versionList={versionList || []}
                  setFromVersion={setFromVersion}
                  versionListLoading={versionListLoading}
                  versionListSuccess={versionListSuccess}
                  fromWfId={fromWfId}
                />
                <NoChangesState />
              </div>
            )
          ) : (
            <NoLogGeneratedState />
          )
        ) : (
          <NoLogGeneratedState />
        )}
      </div>
    </Transition>
  );
};
