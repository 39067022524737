import { default as Axios } from "axios";

const _axios = Axios.create({
  baseURL: `${import.meta.env.REACT_APP_API_ENDPOINT}/sentinel/dashboard/`,
  paramsSerializer: {
    indexes: null,
  },
  withCredentials:true
  // header.token is set in AppProvider.tsx
});

const v2 = Axios.create({
  baseURL: `${import.meta.env.REACT_APP_API_V2_ENDPOINT}/sentinel/dashboard/`,
  paramsSerializer: {
    indexes: null,
  },
  withCredentials:true
  // header.token is set in AppProvider.tsx
});

let axios = Object.assign(_axios, { v2 });
export default axios;
