import { RuleSetRules } from "../../types";
import { getExpressionTypeIcon, highlightDifferences } from "../../utils";

export const RuleSetRow = ({
  name,
  prevRule,
  currRule,
  prevName,
  state,
}: {
  name: string | null | undefined;
  prevRule: RuleSetRules | null | undefined;
  currRule: RuleSetRules | null | undefined;
  prevName?: string;
  state: string;
}) => {
  const {
    prevHighlighted: prevApproveHighlighted,
    currHighlighted: currApproveHighlighted,
  } = highlightDifferences(prevRule?.approve || "", currRule?.approve || "");
  const {
    prevHighlighted: prevCantDecideHighlighted,
    currHighlighted: currCantDecideHighlighted,
  } = highlightDifferences(
    prevRule?.["cantDecide"] || "",
    currRule?.["cantDecide"] || ""
  );

  return (
    <div>
      {state === "edit" && prevName !== name ? (
        <div className="bg-neutral-0 border-y border-neutral-100 px-4 h-[100%] py-2 font-b2-medium text-neutral-black">
          <span className="flex flex-wrap items-center gap-2">
            {getExpressionTypeIcon(state)}
            {prevName} <span className="text-neutral-500">is changed to</span>{" "}
            {name}
          </span>
        </div>
      ) : (
        <div className="bg-neutral-0 border-y border-neutral-100 px-4 h-8 py-2 font-b2-medium text-neutral-black">
          <span className="flex items-center gap-2">
            {getExpressionTypeIcon(state!)}
            {name}
          </span>
        </div>
      )}
      {(prevRule?.approve ||
        currRule?.approve ||
        prevRule?.cantDecide ||
        currRule?.cantDecide) && (
        <div className="flex">
          <div className="border-r w-[50%] flex flex-col px-4 py-3 gap-2 overflow-none">
            {prevRule ? (
              <div className="flex flex-col gap-2">
                {prevRule.approve && (
                  <div className="flex flex-col">
                    <span className="text-neutral-black text-xs font-medium">
                      Approve
                    </span>
                    <span className="text-neutral-black font-code text-sm flex flex-wrap break-all mt-2">
                      {prevApproveHighlighted}
                    </span>
                  </div>
                )}
                {prevRule.cantDecide && (
                  <div className="flex flex-col">
                    <span className="text-neutral-black text-xs font-medium">
                      Can't Decide:
                    </span>
                    <span className="text-neutral-black font-code text-sm flex flex-wrap break-all mt-2">
                      {prevCantDecideHighlighted}
                    </span>
                  </div>
                )}
              </div>
            ) : (
              <span className="m-auto text-neutral-500 pl-2">
                Rule not present
              </span>
            )}
          </div>
          <div className="flex flex-col w-[50%] px-4 py-3 gap-2 left-0">
            {currRule ? (
              <div className="flex flex-col gap-2">
                {currRule.approve && (
                  <div className="flex flex-col">
                    <span className="text-neutral-black text-xs font-medium">
                      Approve
                    </span>
                    <span className="text-neutral-black font-code text-sm flex flex-wrap break-all mt-2">
                      {currApproveHighlighted}
                    </span>
                  </div>
                )}
                {currRule.cantDecide && (
                  <div className="flex flex-col">
                    <span className="text-neutral-black text-xs font-medium">
                      Can't Decide:
                    </span>
                    <span className="text-neutral-black font-code text-sm flex flex-wrap break-all mt-2">
                      {currCantDecideHighlighted}
                    </span>
                  </div>
                )}
              </div>
            ) : (
              <span className="text-neutral-500 m-auto"> Rule is deleted</span>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
