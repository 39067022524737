import { useState } from "react";
import { notify } from "src/utils/utils";
import EmptyState from "@components/EmptyState";
import Loader from "@components/Loader";
import Table from "@components/Table";
import { DATA_SOURCE_PAGE_LIMIT } from "@config";
import { useQueryClient } from "@tanstack/react-query";
import ConfirmModal from "../../monitor/components/ConfirmModalEndpoint";
import { lookupTableListDsQuery, useLookupDeactivateFile } from "../queries";
import { LookupData, LookupTableSources } from "../types";
import LookUpTableRow from "./LookUpTableRow";

type Props = {
  customTableData: LookupTableSources;
  isLoading: boolean;
  onPageChange: setStateType<number>;
  page: number;
  totalCount: number;
  handleOpenModel: (arg: LookupData) => void;
};

const LookupTable = ({
  customTableData,
  isLoading,
  onPageChange,
  page,
  totalCount,
  handleOpenModel,
}: Props) => {
  const deleteLookup = useLookupDeactivateFile();
  const qc = useQueryClient();
  const [isOpenDeleteModal, setOpenDeleteModal] = useState(false);
  const [dataSrc, setDataSrc] = useState({ name: "", id: 0 });
  const handleDeleteLookup = () => {
    deleteLookup.mutate(
      { lookupId: dataSrc.id },
      {
        onSuccess: () => {
          qc.invalidateQueries(lookupTableListDsQuery());
          notify({ title: "Success", text: "Deleted lookup", type: "success" });
        },
      }
    );
  };

  const updateLookupRow = (row:LookupData) => {
    if (!isOpenDeleteModal) {
        handleOpenModel(row);
    }
  }

    const deleteLookupRow = (row:LookupData) => {
            setDataSrc({ name: row.name, id: row.id });
            setOpenDeleteModal(true);
    }

  return (
    <div className="max-w-full w-full relative z-0 rounded px-8 pb-8 overflow-auto">
      <ConfirmModal
        isOpen={isOpenDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        title={`Delete "${dataSrc.name}"`}
        action={handleDeleteLookup}
        destructive
      >
        Are you sure you want to delete ?
      </ConfirmModal>
      {isLoading && customTableData.length === 0 ? (
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="mx-auto flex h-96 max-w-4xl items-center justify-center">
            <Loader size="small" type="block" />
          </div>
        </div>
      ) : customTableData.length > 0 ? (
        <>
          <Table
            data={customTableData}
            className="sm:overflow-x-visible w-full"
            emptyText={<p>No lookups found</p>}
            isLoading={isLoading}
            headers={[
              "Source Name",
              "Alias",
              "Confidential",
              "File Status",
              "",
            ]}
            page={page}
            setPage={(page) => {
              onPageChange(page);
            }}
            pageLimit={DATA_SOURCE_PAGE_LIMIT}
            totalCount={totalCount || 0}
          >
            {(row, defaultRowClassNames) => {
              return (
                    <LookUpTableRow
                        row={row}
                        defaultRowClassNames={defaultRowClassNames}
                        deleteLookupRow={deleteLookupRow}
                        updateLookupRow={updateLookupRow}
                    />
              );
            }}
          </Table>
        </>
      ) : (
        <EmptyState>No Lookup found</EmptyState>
      )}
    </div>
  );
};

export default LookupTable;
