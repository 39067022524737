import React, { useEffect, useRef, useState, useCallback, memo } from "react";
import { createPortal } from "react-dom";
import { classNames } from "src/utils/utils";
import { TooltipProps } from "./ToolTip.types";

const Tooltip: React.FC<TooltipProps> = ({
  text,
  position = "top",
  className = "",
  rootClass = "",
  style = {},
  children,
  childRef,
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const tooltipRef = useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = useState(false);

  const updatePosition = useCallback(() => {
    if (!tooltipRef.current) return;
    const target = childRef?.current || wrapperRef.current;
    if (!target) return;

    const targetRect = target.getBoundingClientRect();
    const tooltipRect = tooltipRef.current.getBoundingClientRect();

    const gap = 8;
    let top = 0;
    let left = 0;

    switch (position) {
      case "top":
        top = targetRect.top - tooltipRect.height - gap;
        left = targetRect.left + (targetRect.width - tooltipRect.width) / 2;
        break;
      case "bottom":
        top = targetRect.bottom + gap;
        left = targetRect.left + (targetRect.width - tooltipRect.width) / 2;
        break;
      case "left":
        top = targetRect.top + (targetRect.height - tooltipRect.height) / 2;
        left = targetRect.left - tooltipRect.width - gap;
        break;
      case "right":
        top = targetRect.top + (targetRect.height - tooltipRect.height) / 2;
        left = targetRect.right + gap;
        break;
    }

    tooltipRef.current.style.top = `${top}px`;
    tooltipRef.current.style.left = `${left}px`;
  }, [position, childRef]);

  useEffect(() => {
    if (!isVisible) return;

    const update = () => requestAnimationFrame(updatePosition);
    update();

    window.addEventListener("scroll", update, true);
    window.addEventListener("resize", update);

    return () => {
      window.removeEventListener("scroll", update, true);
      window.removeEventListener("resize", update);
    };
  }, [isVisible, updatePosition]);

  useEffect(() => {
    if (!childRef?.current) return;

    const target = childRef.current;
    const show = () => setIsVisible(true);
    const hide = () => setIsVisible(false);

    target.addEventListener("mouseenter", show);
    target.addEventListener("mouseleave", hide);

    return () => {
      target.removeEventListener("mouseenter", show);
      target.removeEventListener("mouseleave", hide);
    };
  }, [childRef]);

  const tooltipContent = (
    <div
      ref={tooltipRef}
      style={{
        position: "fixed",
        pointerEvents: "none",
        ...style,
      }}
      className={`whitespace-nowrap bg-white p-2 rounded-md shadow-lg text-sm z-[1000] ${className}`}
    >
      {text}
    </div>
  );

  if (childRef) {
    return isVisible ? createPortal(tooltipContent, document.body) : null;
  }

  return (
    <div
      ref={wrapperRef}
      onMouseEnter={() => setIsVisible(true)}
      onMouseLeave={() => setIsVisible(false)}
      className={classNames("inline-block", rootClass)}
    >
      {children}
      {isVisible && createPortal(tooltipContent, document.body)}
    </div>
  );
};

export default memo(Tooltip);
