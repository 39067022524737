import { LOCALSTORAGE_KEYS } from "@config";
import { AppDispatchContext } from "@context/AppProvider";
import { useMutation } from "@tanstack/react-query";
import { FinBoxResponse } from "@types";
import { getNetworkErrorText, notify } from "@utils/utils";
import axios from "@axios";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { UserDetailsResources } from "../settings/pages/SettingsAdmin/types";
import { analyticsInstance } from "src/config/event-analytics";

const useLogin = () => {
  const { setAppState } = useContext(AppDispatchContext);
  const navigate = useNavigate();

  return useMutation({
    mutationFn: ({
      email,
      password,
    }: {
      email: string;
      password: string;
      redirectTo?: string;
    }) =>
      axios.post<
        FinBoxResponse<{
          logo_url: string;
          email: string;
          name: string;
          org_name: string;
          token: string;
        }>
      >(`${import.meta.env.REACT_APP_API_ENDPOINT}/sentinel/dashboard/login`, {
        email,
        password,
      }),
    onError: (err: Error) => {
      notify({
        title: "Login Failed",
        text: getNetworkErrorText(err),
      });
    },
    onSuccess: (response, inputData) => {
      const { data } = response.data;
      analyticsInstance.setUserInfo({email:data.email, org_name:data.org_name});
      window.localStorage.setItem(LOCALSTORAGE_KEYS.TOKEN, data.token);
      window.localStorage.setItem(LOCALSTORAGE_KEYS.NAME, data.name);
      window.localStorage.setItem(LOCALSTORAGE_KEYS.LOGO_URL, data.logo_url);
      window.localStorage.setItem(LOCALSTORAGE_KEYS.USER, inputData.email);
      window.localStorage.setItem(
        LOCALSTORAGE_KEYS.ORG_NAME,
        data.org_name || ""
      );
      setAppState((prevState) => ({
        ...prevState,
        token: data.token,
        isAuthenticated: true,
        name: data.name,
        orgName: data.org_name,
        logoURL: data.logo_url,
      }));
      navigate(inputData.redirectTo || "/");
    },
  });
};

export const useLoginWithToken = (
  token: string,
  options: { redirect: string }
) => {
  const { setAppState } = useContext(AppDispatchContext);
  const navigate = useNavigate();

  return useMutation({
    mutationFn: () =>
      axios.post<FinBoxResponse<UserDetailsResources>>(
        `${
          import.meta.env.REACT_APP_API_ENDPOINT
        }/sentinel/dashboard/login/token`,
        {},
        {
          headers: {
            token,
          },
        }
      ),
    onError: (err: Error) => {
      notify({
        title: "Login Failed",
        text: getNetworkErrorText(err),
      });
      setTimeout(() => {
        navigate("/");
      }, 1000);
    },
    onSuccess: (response) => {
      const { data } = response.data;
      analyticsInstance.setUserInfo({email:data.email, org_name:data.org_name});
      window.localStorage.setItem(LOCALSTORAGE_KEYS.TOKEN, token);
      window.localStorage.setItem(LOCALSTORAGE_KEYS.NAME, data.name);
      window.localStorage.setItem(LOCALSTORAGE_KEYS.LOGO_URL, data.logo_url);
      window.localStorage.setItem(
        LOCALSTORAGE_KEYS.ORG_NAME,
        data.org_name || ""
      );
      setAppState((prevState) => ({
        ...prevState,
        token: token,
        isAuthenticated: true,
        name: data.name,
        orgName: data.org_name,
        logoURL: data.logo_url,
      }));
      if (!!options.redirect) navigate(options.redirect);
      else navigate("/");
    },
  });
};

export const useLoginWithSSO = () => {
  return useMutation({
    mutationFn: ({ email }: { email: string }) =>
      axios.post(
        `${
          import.meta.env.REACT_APP_API_ENDPOINT
        }/sentinel/dashboard/login-sso`,
        { email }
      ),
    onError: (err: Error) => {
      notify({
        title: "Login Failed",
        text: getNetworkErrorText(err),
      });
    },
    onSuccess: (response) => {
      const { data } = response.data;
      window.location = data.redirectURL;
    },
  });
};

export default useLogin;
