import {
    startOfDay,
    subDays,
  } from "date-fns";
  import {
    NotificationList,
    NotificationType,
  } from "src/types";

  
export const classifyNotifications = (notifications: NotificationList[]) => {
    const today = startOfDay(new Date());
    const yesterday = startOfDay(subDays(today, 1));
    const last7days = startOfDay(subDays(today, 7));
    const last30days = startOfDay(subDays(today, 30));

    const classifiedNotifications = {
      today: [] as NotificationList[],
      yesterday: [] as NotificationList[],
      last7days: [] as NotificationList[],
      last30days: [] as NotificationList[],
    };

    for (const notification of notifications) {
      const updatedAt = startOfDay(new Date(notification.updatedAt));
      if (updatedAt >= today) {
        classifiedNotifications.today.push(notification);
      } else if (updatedAt >= yesterday) {
        classifiedNotifications.yesterday.push(notification);
      } else if (updatedAt >= last7days) {
        classifiedNotifications.last7days.push(notification);
      } else if (updatedAt >= last30days) {
        classifiedNotifications.last30days.push(notification);
      } else {
        break;
      }
    }

    return classifiedNotifications;
};


export const sumValues = (key: string,notReadCounts:Record<NotificationType, number>) => {
  if (!notReadCounts) return 0;
  switch (key) {
    case "alerts":
      return notReadCounts["monitor"] || 0;
    case "reports":
      return (
        (notReadCounts["report"] || 0) + (notReadCounts["simulation"] || 0)
      );
    case "all":
      return (
        Object.values(notReadCounts).reduce((acc, curr) => acc + curr, 0) || 0
      );
    case "policy":
      return notReadCounts["wf_status"] || 0;
    default:
      return 0;
  }
};