import classNames from "clsx";
import React, { ReactNode } from "react";
import { getInnerSidebarWidth } from "@utils/utils";

type Props = {
  title: ReactNode;
  content: ReactNode;
};

export function InnerSidebarItem({
  selected,
  content,
  onClick,
}: {
  selected: boolean;
  content: ReactNode;
  onClick?: (e: React.MouseEvent<HTMLDivElement> ) => void;
}) {
  return (
    <div
      className={classNames(
        "mt-1 rounded-md px-2 py-2 font-b2-medium text-neutral-black cursor-pointer flex items-center group",
        selected
          ? "bg-primary-50 font-semibold text-primary-900"
          : "hover:bg-primary-50"
      )}
      onClick={onClick}
    >
      {content}
    </div>
  );
}

export default function InnerSidebar({ title, content }: Props) {
  const width = getInnerSidebarWidth();
  return (
    <div
      style={{ minWidth: width, maxWidth: width }}
      className="sticky pt-12 top-0 left-0 z-[99] h-screen border-r flex flex-col border-r-neutral-100 bg-white overflow-auto hide-scrollbar pb-4"
    >
      <div className="fixed top-0 flex gap-2 items-center w-full h-12 px-6 py-3.5 font-b1 font-semibold text-neutral-black bg-white border-b z-10">
        {title}
      </div>
      {content}
    </div>
  );
}
