import clsx from "clsx";
import { useRef, useState } from "react";
import Loader from "src/components/Loader";
import { getWorkflowKeywordsQuery } from "src/screens/workflow/queries";
import { ReactComponent as PlusIcon } from "@assets/icons/workflow/plus-add-condition.svg";
import { useWorkflowContext } from "@screens/workflow/WorkflowContext";
import { RuleSetCondition } from "@screens/workflow/studio/components/RuleSet/types";
import Shimmer from "@components/Shimmer";
import {
  DndContext,
  DragEndEvent,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  restrictToParentElement,
  restrictToVerticalAxis,
} from "@dnd-kit/modifiers";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { useQueryClient } from "@tanstack/react-query";
import RuleSetItem from "./RuleSetItem";
import { useAddRuleSetCondition, useUpdateRuleSetCondition } from "./queries";
import { getUniqueRuleSetName } from "./utils";
import { analyticsInstance } from "src/config/event-analytics";
import { PolicyStudioActions } from "src/constants/EventAnalytics";

interface RuleSetExpressionProps {
  workflowId: string;
  nodeId: string;
  rules: RuleSetCondition[];
  isLoading: boolean;
}

const RuleSetConditions = ({
  workflowId,
  nodeId,
  rules,
  isLoading,
}: RuleSetExpressionProps) => {
  const modelMutation = useAddRuleSetCondition();
  const [currentEditingRule, setCurrentEditingRule] = useState<string | null>(
    null
  );
  const { isWorkflowEditable, workflow } = useWorkflowContext();

  const itemsRef = useRef<Map<string, HTMLDivElement> | null>(null);

  const updateRuleSetCondition = useUpdateRuleSetCondition();
  const sensors = useSensors(useSensor(PointerSensor));
  const queryClient = useQueryClient();

  const onAddNewNode = () => {
    if (modelMutation.isPending) return;

    analyticsInstance.triggerAnalytics(
      PolicyStudioActions.RULESET,
      {
        count:(rules ?? []).length + 1, 
        policy_name:workflow?.policyName, 
        version:workflow?.policyVersion
      }
    );
    setCurrentEditingRule(null);
    const length = rules.length;
    const currentRuleNames = rules.map((e) => e.description);
    modelMutation.mutate({
      description: getUniqueRuleSetName(currentRuleNames, length),
      approve: "true",
      workflowId,
      nodeId,
    });
  };

  function getRefMap() {
    if (!itemsRef.current) {
      itemsRef.current = new Map();
    }
    return itemsRef.current;
  }

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    const items = rules.map((c) => c.rule_id) ?? [];
    if (items.length === 0) return;

    if (over && active.id !== over.id) {
      const oldIndex = items.indexOf(active.id as string);
      const newIndex = items.indexOf(over.id as string);
      
      const item = rules[oldIndex];

      updateRuleSetCondition.mutate(
        {
          workflowId,
          nodeId,
          conditionId: item.rule_id,
          description: item.description,
          approve: item.approve,
          cant_decide: item.cant_decide,
          seq: rules[newIndex].seq,
        },
        {
          onSuccess: () =>
            queryClient.invalidateQueries(getWorkflowKeywordsQuery()),
        }
      );
    }
  };

  return (
    <div
      className="flex flex-col gap-2 mt-2 mb-2"
      id="exp-parent"
      tabIndex={0}
      onBlur={(e) => {
        if (!itemsRef.current) {
          return;
        }
        const isInside = Array.from(itemsRef.current.values()).some(
          (el) =>
            el.contains(e.relatedTarget) || e.relatedTarget?.id === "exp-parent"
        );
        if (!isInside) {
          setCurrentEditingRule(null);
        }
      }}
    >
      {(isLoading || updateRuleSetCondition.isPending) && (
        <div className="absolute flex items-center z-10 justify-center inset-0 bg-white bg-opacity-50">
          <Loader size="xs" />
        </div>
      )}
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
        modifiers={[restrictToVerticalAxis, restrictToParentElement]}
      >
        <SortableContext
          items={rules.map((m) => m.rule_id) || []}
          strategy={verticalListSortingStrategy}
        >
          {isLoading && (
            <>
              <Shimmer w="100%" h="60px" />
              <Shimmer w="100%" h="60px" />
            </>
          )}
          {rules.map((exp, index) => (
            <RuleSetItem
              index={index}
              showDelete={rules.length > 1 && isWorkflowEditable}
              ref={(r) => {
                const map = getRefMap();
                if (r) {
                  map.set(exp.rule_id, r);
                } else {
                  map.delete(exp.rule_id);
                }
              }}
              key={exp.rule_id}
              condition={exp}
              workflowId={workflowId}
              nodeId={nodeId}
              isEdit={exp.rule_id === currentEditingRule}
              setIsEdit={() => {
                setCurrentEditingRule(exp.rule_id);
              }}
              clear={() => setCurrentEditingRule(null)}
            />
          ))}
        </SortableContext>
      </DndContext>

      {isWorkflowEditable &&
        (isLoading ? (
          <Shimmer className="w-[85px] h-[1.5em]" />
        ) : (
          <div
            className={clsx(
              "font-b2-medium cursor-pointer mt-1 text-neutral-black group/add-expr hover:text-primary-900 w-max flex items-center gap-1",
              modelMutation.isPending && "!cursor-not-allowed opacity-50"
            )}
            onClick={onAddNewNode}
          >
            <PlusIcon className="w-4 h-4 group-hover/add-expr:[&>path]:stroke-primary-900 [&>path]:stroke-neutral-black" />
            Add Rule
          </div>
        ))}
    </div>
  );
};

export default RuleSetConditions;
