import classNames from "clsx";
import { useEffect, useRef } from "react";
import { Handle, Position, useReactFlow } from "reactflow";
import useOnClickOutside from "@hooks/useOnClickOutside";
import { XMarkIcon } from "@heroicons/react/20/solid";
import { useWorkflowContext } from "../../../WorkflowContext";
import { ADD_BLOCK_CONFIG } from "../../../config";
import useAddNode from "../../hooks/useAddNode";
import { PolicyStudioActions } from "src/constants/EventAnalytics";
import { analyticsInstance } from "src/config/event-analytics";

const NewNode = () => {
  const { workflow } = useWorkflowContext();
  
  const ref = useRef<HTMLDivElement | null>(null);
  const { onAddNode } = useAddNode();
  const { getEdges, screenToFlowPosition } = useReactFlow();
  const { setNewNode } = useWorkflowContext();
  useOnClickOutside(ref, () => {
    setNewNode(undefined);
  });

  const onAddNewNode = (nodeName: string) => {
    const p = ref.current?.getBoundingClientRect();
    if (p) {
      const y = p.y + p.height / 2 - 80;
      const x = p.x + 10;
      const newNodeEdge = getEdges()?.find((e) => e.id === "new-node-edge");
      if (newNodeEdge) {
        analyticsInstance.triggerAnalytics(
          PolicyStudioActions.ADD_BLOCK,
          {
            bucket_name: workflow?.policyName ?? '',
            version:workflow?.policyVersion ?? '',
            type: nodeName.split(":")?.[1] ?? ''
          }
        );
        onAddNode(
          nodeName,
          screenToFlowPosition({ x, y }),
          newNodeEdge.source,
          newNodeEdge.sourceHandle!
        );
      }
    }
    setNewNode(undefined);
  };

  useEffect(() => {
    setNewNode((n) => (n ? { ...n, selected: true } : undefined));
  }, [setNewNode]);

  return (
    <div
      ref={ref}
      className="bg-white z-50 shadow-sm border border-neutral-100 rounded-md w-36 cursor-default overflow-hidden"
      onBlur={() => setNewNode(undefined)}
    >
      <div className="flex items-center justify-between bg-neutral-0 border-b border-neutral-50 text-neutral-500 font-b2-medium px-2 py-1.5">
        Add block
        <XMarkIcon
          onClick={() => setNewNode(undefined)}
          className="h-4 w-4 cursor-pointer"
        />
      </div>

      <Handle
        type="target"
        position={Position.Left}
        className="w-2 h-2 rounded-full bg-white border-neutral-500 !-left-4 duration-150 overflow-visible"
      />
      <div className="pb-1.5 divide-y text-neutral-black font-b2-medium flex flex-col">
        {ADD_BLOCK_CONFIG.map((e) => (
          <div key={e.name} className="space-y-1.5 px-1.5 py-1.5">
            {e.items.map((item) => (
              <div
                key={item.name}
                className={classNames(
                  "flex items-center gap-2 cursor-pointer hover:bg-neutral-25 px-1.5 py-1 rounded-md"
                )}
                title={item.name}
                onClick={() => {
                  onAddNewNode(`${e.name}:${item.name}`);
                }}
              >
                <item.icon
                  className="h-4 w-4"
                  style={{
                    stroke: item.bgColor,
                  }}
                />
                {item.name}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default NewNode;
