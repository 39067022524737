import { Fragment } from "react";
import { BranchNodeExpression, ChangeLogState } from "../../types";
import {
  getChangeLogIcon,
  getExpressionTypeIcon,
  getStatusIndicator,
  highlightDifferences,
} from "../../utils";

export const BranchNodeRow = ({
  name,
  prevNode,
  currNode,
  state,
}: {
  name: string | undefined;
  currNode: BranchNodeExpression | null;
  prevNode: BranchNodeExpression | null;
  state: ChangeLogState;
}) => {
  const prevExpression = prevNode?.expression || "";
  const currExpression = currNode?.expression || "";

  const { prevHighlighted, currHighlighted } = highlightDifferences(
    prevExpression,
    currExpression
  );

  return (
    <div>
      {state === "edit" && prevNode?.name !== name ? (
        <div className="bg-neutral-0 border-y border-neutral-100 px-4 h-[100%] py-2 font-b2-medium text-neutral-black">
          <span className="flex flex-wrap items-center gap-2">
            {getExpressionTypeIcon(state)}
            {prevNode?.name}{" "}
            <span className="text-neutral-500">is changed to</span> {name}
          </span>
        </div>
      ) : (
        <div className="flex gap-2 bg-neutral-0 border-y border-neutral-100 px-4 h-8 py-2 font-b2-medium text-neutral-black">
          {getExpressionTypeIcon(state)}
          <span className="flex items-center gap-2">{name}</span>
        </div>
      )}
      {(prevNode || currNode) && (
        <div className="flex">
          <div className="border-r w-[50%] flex flex-col px-4 py-3 gap-2 overflow-none">
            {prevNode ? (
              <>
                {prevNode.expression && (
                  <>
                    <span className="text-neutral-black text-xs font-medium">
                      Expression
                    </span>
                    <div className="text-neutral-black font-code text-sm flex flex-wrap break-all">
                      {prevHighlighted.map((element, idx) => (
                        <Fragment key={idx}>{element}</Fragment>
                      ))}
                    </div>
                  </>
                )}
                {prevNode.transition && (
                  <div className="flex flex-wrap gap-1.5">
                    <span className="flex gap-2 ">
                      {getStatusIndicator(state)}
                      is connected to
                    </span>
                    <span className="flex font-b2-medium gap-1">
                      {getChangeLogIcon(prevNode.transition.nodeType)}
                      {prevNode.transition.name}
                    </span>
                  </div>
                )}
              </>
            ) : (
              <span className="text-neutral-500 m-auto">
                Branch not present
              </span>
            )}
          </div>
          <div className="flex flex-col w-[50%] px-4 py-3 gap-2 left-0">
            {currNode ? (
              <>
                {currNode.expression && (
                  <>
                    <span className="text-neutral-black text-xs font-medium">
                      Expression
                    </span>
                    <div className="text-neutral-black font-code text-sm flex flex-wrap break-all">
                      {currHighlighted.map((element, idx) => (
                        <Fragment key={idx}>{element}</Fragment>
                      ))}
                    </div>
                  </>
                )}
                {currNode.transition && (
                  <div className="flex flex-wrap gap-1.5">
                    <span className="flex gap-2 ">
                      {getStatusIndicator(state)}
                      is connected to
                    </span>
                    <span className="flex font-b2-medium gap-1">
                      {getChangeLogIcon(currNode.transition.nodeType)}
                      {currNode.transition.name}
                    </span>
                  </div>
                )}
              </>
            ) : (
              <span className="text-neutral-500 m-auto">Branch is deleted</span>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
