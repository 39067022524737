import { PolicyType } from "@screens/endpoints/types";
import { useQuery } from "@tanstack/react-query";
import axios from "src/axios";
import { PolicyBucketList } from "src/components/Searchbar/filters/types";
import { DATE_FORMAT } from "src/config";
import { FinBoxResponse } from "src/types";
import { getGQEndTime, getGQStartTime } from "src/utils/utils";

export type EntityList = {
  id: string;
  type: PolicyType;
  version: string;
  simulationCount: number;
  createdBy: string;
  createdAt: string;
  updatedAt: string;
  status: "active" | "draft" | "archive" | "inreview";
};

const DEFAULT_ENTITIES = ["compound", "normal", "workflow"];
const DEFAULT_STATUS = ["active", "draft", "archive", "inreview"];

export const usePolicyWorkflowList = ({
  program,
  entity = DEFAULT_ENTITIES,
  policy,
  enabled = true,
  status = DEFAULT_STATUS,
  createdBy,
  startDate,
  endDate,
}: {
  entity?: string[];
  policy?: string;
  program?: string;
  enabled?: boolean;
  status?: string[];
  createdBy?: string;
  startDate?: string;
  endDate?: string;
}) => {
  return useQuery({
    queryKey: [
      "policyWorkflowCompoundlist",
      program,
      entity,
      status,
      policy,
      createdBy,
      startDate,
      endDate,
    ],
    queryFn: async () =>
      axios.get<FinBoxResponse<EntityList[]>>("simulation/policyWorkflow", {
        params: {
          program: program,
          type: entity,
          name: policy,
          status: status,
        },
      }),
    select: (data) => data.data.data,
    enabled,
  });
};

export const usePolicyWorkflowBucketOutcomesList = ({
  status,
}: {
  status: string;
}) => {
  return useQuery({
    queryKey: ["usePolicyWorkflowBucketOutcomesList"],
    queryFn: async () =>
      axios.get<
        FinBoxResponse<{
          totalCount: number;
          policyBuckets: PolicyBucketList[];
        }>
      >("outcomes/policyWorkflowBucketList", {
        params: {
          status: status,
        },
      }),
    select: (data) => data.data.data,
  });
};

export const usePolicyWorkflowOutcomesList = ({
  program,
  entity = DEFAULT_ENTITIES,
  policy,
  status = DEFAULT_STATUS,
  policyBucketIds,
}: {
  entity?: string[];
  policy?: string;
  program?: string;
  status?: string[];
  policyBucketIds?: string[];
}) => {
  return useQuery({
    queryKey: [
      "usePolicyWorkflowOutcomesList",
      program,
      entity,
      status,
      policy,
      policyBucketIds,
    ],
    queryFn: async () =>
      axios.get<FinBoxResponse<EntityList[]>>(
        "outcomes/policyWorkflowVersionList",
        {
          params: {
            program: program,
            type: entity,
            name: policy,
            status: status,
            policybucket: policyBucketIds,
          },
        }
      ),
    select: (data) => data.data.data,
  });
};

export const useEndpointPolicyWorkflowList = ({
  program,
  entity = DEFAULT_ENTITIES,
  policy,
  enabled = true,
  status = DEFAULT_STATUS,
  createdBy,
  startDate,
  endDate,
}: {
  entity?: string[];
  policy?: string;
  program?: string;
  enabled?: boolean;
  status?: string[];
  createdBy?: string;
  startDate?: string;
  endDate?: string;
}) => {
  return useQuery({
    queryKey: [
      "policyWorkflowCompoundlist",
      program,
      entity,
      status,
      policy,
      createdBy,
      startDate,
      endDate,
    ],
    queryFn: async () =>
      axios.get<FinBoxResponse<EntityList[]>>("endpoint/policyWorkflow", {
        params: {
          program: program,
          type: entity,
          name: policy,
          status: status,
          createdBy: createdBy,
          startDate: getGQStartTime(startDate!, DATE_FORMAT),
          endDate: getGQEndTime(endDate!, DATE_FORMAT),
        },
      }),
    select: (data) => data.data.data,
    enabled,
  });
};

export const useAnalyticsPolicyWorkflowList = ({
  program,
  entity = DEFAULT_ENTITIES,
  policy,
  enabled = true,
  status = DEFAULT_STATUS,
  createdBy,
  startDate,
  endDate,
}: {
  entity?: string[];
  policy?: string;
  program?: string;
  enabled?: boolean;
  status?: string[];
  createdBy?: string;
  startDate?: string;
  endDate?: string;
}) => {
  return useQuery({
    queryKey: [
      "policyWorkflowCompoundlist",
      program,
      entity,
      status,
      policy,
      createdBy,
      startDate,
      endDate,
    ],
    queryFn: async () =>
      axios.get<FinBoxResponse<EntityList[]>>(
        "analytics/workflow/policyWorkflow",
        {
          params: {
            program: program,
            type: entity,
            name: policy,
            status: status,
          },
        }
      ),
    select: (data) => data.data.data,
    enabled,
  });
};
