// import { Hero } from '@doc/components/Hero'
import classnames from "clsx";
import { useCallback, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Logo, Logomark } from "@doc/components/Logo";
import { MobileNavigation } from "@doc/components/MobileNavigation";
import { Navigation } from "@doc/components/Navigation";
import { Prose } from "@doc/components/Prose";
import { ThemeSelector } from "@doc/components/ThemeSelector";
import { Hero } from "./Hero";
import { HelpActions } from "src/constants/EventAnalytics";

export const navigation = [
  {
    title: "Introduction",
    links: [
      {
        title: "Getting started with Sentinel",
        href: "/docs",
        md: import("../md/getting-started.md"),
        event:HelpActions.GETTING_STARTED
      },
      {
        title: "Authentication Guide",
        href: "/docs/authentication",
        md: import("../md/authentication.md"),
        event:HelpActions.AUTHENTICATION_GUIDE
      },
    ],
  },
  {
    title: "Core Concepts",
    links: [
      {
        title: "Programme",
        href: "/docs/core-concepts/programme",
        md: import("../md/core-concepts/programme.md"),
        event:HelpActions.PROGRAM
      },
      {
        title: "Journeys",
        href: "/docs/core-concepts/journeys",
        md: import("../md/core-concepts/journeys.md"),
        event:HelpActions.JOURNEY
      },
      {
        title: "Endpoints",
        href: "/docs/core-concepts/endpoints",
        md: import("../md/core-concepts/endpoints.md"),
        event:HelpActions.ENDPOINTS
      },
      {
        title: "Simulation",
        href: "/docs/core-concepts/simulation",
        md: import("../md/core-concepts/simulation.md"),
        event:HelpActions.SIMULATIONS
      },
    ],
  },
  {
    title: "Deployments",
    links: [
      {
        title: "Full Rollout",
        href: "/docs/deployments/full-rollout",
        md: import("../md/deployments/full-rollout.md"),
        event:HelpActions.FULL_ROLLOUT
      },
      {
        title: "Champion/Challenger",
        href: "/docs/deployments/champion-challenger",
        md: import("../md/deployments/champion-challenger.md"),
        event:HelpActions.CC
      },
      {
        title: "Canary",
        href: "/docs/deployments/canary",
        md: import("../md/deployments/canary.md"),
        event:HelpActions.CANARY
      },
    ],
  },
  {
    title: "Alerts & Monitoring",
    links: [
      {
        title: "Alerts",
        href: "/docs/alerts/alerts-monitoring",
        md: import("../md/alerts/alerts-monitoring.md"),
        event:HelpActions.ALERTS
      },
      {
        title: "Analytics",
        href: "/docs/alerts/analytics",
        md: import("../md/alerts/analytics.md"),
        event:HelpActions.ANALYTICS
      },
    ],
  },
  {
    title: "Risk Connector",
    links: [
      {
        title: "Risk Connector",
        href: "/docs/risk-connector",
        md: import("../md/risk-connector.md"),
        event:HelpActions.RISK_CONNECTORS
      },
    ],
  },
  {
    title: "API Reference",
    links: [
      {
        title: "Getting started",
        href: "/docs/apis-ref/getting-started",
        md: import("../md/api-ref/getting-started.md"),
        event:HelpActions.GETTING_STARTED_API
      },
      {
        title: "Authentication",
        href: "/docs/apis-ref/authentication",
        md: import("../md/api-ref/authentication.md"),
        event:HelpActions.AUTHENTICATION_API
      },
      {
        title: "Errors",
        href: "/docs/apis-ref/errors",
        md: import("../md/api-ref/errors.md"),
        event:HelpActions.ERRORS_API
      },
      {
        title: "Language Definition",
        href: "/docs/apis-ref/language-definition",
        md: import("@doc/md/api-ref/language-definition.md"),
        event:HelpActions.LANGUAGE_DEF
      },
      {
        title: "Api Specification",
        href: "/docs/apis-ref/api-spec",
        md: import("../md/api-ref/api-spec.md"),
        event:HelpActions.API_SPECS
      },
    ],
  },
  {
    title: "Explore Sentinel",
    links: [
      {
        title: "Invite Members",
        href: "/docs/video-ref/invite-members",
        md: import("../md/video-ref/invite-members.md"),
        event:HelpActions.VIDEOS_INVITE
      },
      {
        title: "Create your first policy",
        href: "/docs/video-ref/first-policy",
        md: import("../md/video-ref/first-policy.md"),
        event:HelpActions.VIDEOS_CREATE_POLICY
      },
      {
        title: "Publish a Policy",
        href: "/docs/video-ref/publish-policy",
        md: import("../md/video-ref/publish-policy.md"),
        event:HelpActions.VIDEOS_PUBLISH_POLICY
      },
      {
        title: "View Outcomes & Details",
        href: "/docs/video-ref/outcomes",
        md: import("../md/video-ref/outcomes.md"),
        event:HelpActions.VIDEOS_OUTCOMES_AND_DETAILS
      },
      {
        title: "Create Analytics Template",
        href: "/docs/video-ref/create-analytics",
        md: import("../md/video-ref/create-analytics.md"),
        event:HelpActions.VIDEOS_CREATE_ANALYTICS_TEMPLATES
      },
      {
        title: "Create a Monitor",
        href: "/docs/video-ref/create-monitor",
        md: import("../md/video-ref/create-monitor.md"),
        event:HelpActions.VIDEOS_CREATE_MONITORS
      },
    ],
  },
];

function Header({ navigation }) {
  let [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    function onScroll() {
      setIsScrolled(window.scrollY > 0);
    }

    onScroll();
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  return (
    <header
      className={classnames(
        "sticky top-0 z-50 flex flex-wrap items-center justify-between bg-white px-4 py-5 shadow-md shadow-slate-900/5 transition duration-500 dark:shadow-none sm:px-6 lg:px-8",
        isScrolled
          ? "dark:bg-slate-900/95 dark:backdrop-blur dark:[@supports(backdrop-filter:blur(0))]:bg-slate-900/75"
          : "dark:bg-transparent"
      )}
    >
      <div className="mr-6 flex lg:hidden">
        <MobileNavigation navigation={navigation} />
      </div>
      <div className="relative flex flex-grow  items-center">
        <a href="/" aria-label="Home page">
          <Logomark className="h-9 lg:hidden" />
          <Logo className="hidden h-9 w-auto fill-slate-700 dark:fill-primary-300 lg:block" />
        </a>
      </div>
      <div className="relative flex basis-0 justify-end gap-6 sm:gap-8 md:flex-grow pr-8">
        <ThemeSelector className="relative z-10" />
      </div>
    </header>
  );
}

function useTableOfContents(tableOfContents) {
  let [currentSection, setCurrentSection] = useState(tableOfContents[0]?.id);

  let getHeadings = useCallback((tableOfContents) => {
    return tableOfContents
      .flatMap((node) => [node.id, ...node.children.map((child) => child.id)])
      .map((id) => {
        let el = document.getElementById(id);
        if (!el) return {};

        let style = window.getComputedStyle(el);
        let scrollMt = parseFloat(style.scrollMarginTop);

        let top = window.scrollY + el.getBoundingClientRect().top - scrollMt;
        return { id, top };
      });
  }, []);

  useEffect(() => {
    if (tableOfContents.length === 0) return;
    let headings = getHeadings(tableOfContents);

    function onScroll() {
      let top = window.scrollY;
      let current = headings[0]?.id;
      if (current) {
        for (let heading of headings) {
          if (top >= heading.top) {
            current = heading.id;
          } else {
            break;
          }
        }
        setCurrentSection(current);
      }
    }

    window.addEventListener("scroll", onScroll, { passive: true });
    onScroll();
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, [getHeadings, tableOfContents]);

  return currentSection;
}

const Layout = ({ children, title, tableOfContents }) => {
  let location = useLocation();
  let isHomePage = location.pathname === "/docs";
  let allLinks = navigation.flatMap((section) => section.links);
  let linkIndex = allLinks.findIndex((link) => link.href === location.pathname);
  let previousPage = allLinks[linkIndex - 1];
  let nextPage = allLinks[linkIndex + 1];
  let section = navigation.find((section) =>
    section.links.find((link) => link.href === location.pathname)
  );
  let currentSection = useTableOfContents(tableOfContents);

  function isActive(section) {
    if (section.id === currentSection) {
      return true;
    }
    if (!section.children) {
      return false;
    }
    return section.children.findIndex(isActive) > -1;
  }

  return (
    <>
      <Header navigation={navigation} />

      {isHomePage && <Hero />}

      <div className="relative mx-auto flex max-w-8xl justify-center sm:px-2 lg:px-8 xl:px-12">
        <div className="hidden lg:relative lg:block lg:flex-none">
          <div className="absolute inset-y-0 right-0 w-[50vw] bg-slate-50 dark:hidden" />
          <div className="absolute bottom-0 right-0 top-16 hidden h-12 w-px bg-gradient-to-t from-slate-800 dark:block" />
          <div className="absolute bottom-0 right-0 top-28 hidden w-px bg-slate-800 dark:block" />
          <div className="sticky top-[4.5rem] -ml-0.5 h-[calc(100vh-4.5rem)] w-64 overflow-y-auto overflow-x-hidden py-16 pl-0.5 pr-8 xl:w-72 xl:pr-16">
            <Navigation navigation={navigation} />
          </div>
        </div>
        <div className="min-w-0 max-w-2xl flex-auto px-4 py-16 lg:max-w-none lg:pl-8 lg:pr-0 xl:px-16">
          <article>
            {(title || section) && (
              <header className="mb-9 space-y-1">
                {section && (
                  <p className="font-display text-sm font-medium text-primary-700">
                    {section.title}
                  </p>
                )}
                {title && (
                  <h1 className="font-display text-3xl tracking-tight text-slate-900 dark:text-white">
                    {title}
                  </h1>
                )}
              </header>
            )}
            <Prose>{children}</Prose>
          </article>
          <dl className="mt-12 flex border-t border-slate-200 pt-6 dark:border-slate-800">
            {previousPage && (
              <div>
                <dt className="font-display text-sm font-medium text-slate-900 dark:text-white">
                  Previous
                </dt>
                <dd className="mt-1">
                  <Link
                    to={previousPage.href}
                    className="text-base font-semibold text-slate-500 hover:text-slate-600 dark:text-slate-400 dark:hover:text-slate-300"
                  >
                    <span aria-hidden="true">&larr;</span> {previousPage.title}
                  </Link>
                </dd>
              </div>
            )}
            {nextPage && (
              <div className="ml-auto text-right">
                <dt className="font-display text-sm font-medium text-slate-900 dark:text-white">
                  Next
                </dt>
                <dd className="mt-1">
                  <Link
                    to={nextPage.href}
                    className="text-base font-semibold text-slate-500 hover:text-slate-600 dark:text-slate-400 dark:hover:text-slate-300"
                  >
                    {nextPage.title} <span aria-hidden="true">&rarr;</span>
                  </Link>
                </dd>
              </div>
            )}
          </dl>
        </div>
        <div className="hidden xl:sticky xl:top-[4.5rem] xl:-mr-6 xl:block xl:h-[calc(100vh-4.5rem)] xl:flex-none xl:overflow-y-auto xl:py-16 xl:pr-6">
          <nav aria-labelledby="on-this-page-title" className="w-56">
            {tableOfContents.length > 0 && (
              <>
                <h2
                  id="on-this-page-title"
                  className="font-display text-sm font-medium text-slate-900 dark:text-white"
                >
                  On this page
                </h2>
                <ol className="mt-4 space-y-3 text-sm">
                  {tableOfContents.map((section) => (
                    <li key={section.id}>
                      <h3>
                        <a
                          href={`#${section.id}`}
                          className={classnames(
                            isActive(section)
                              ? "text-primary-700"
                              : "font-normal text-slate-500 hover:text-slate-700 dark:text-slate-400 dark:hover:text-slate-300"
                          )}
                        >
                          {section.title}
                        </a>
                      </h3>
                      {section.children.length > 0 && (
                        <ol className="mt-2 space-y-3 pl-5 text-slate-500 dark:text-slate-400">
                          {section.children.map((subSection) => (
                            <li key={subSection.id}>
                              <a
                                href={`#${subSection.id}`}
                                className={
                                  isActive(subSection)
                                    ? "text-primary-700"
                                    : "hover:text-slate-600 dark:hover:text-slate-300"
                                }
                              >
                                {subSection.title}
                              </a>
                            </li>
                          ))}
                        </ol>
                      )}
                    </li>
                  ))}
                </ol>
              </>
            )}
          </nav>
        </div>
      </div>
    </>
  );
};

export default Layout;
