import { clsx } from "clsx";
import { AxiosError } from "axios";
import hljs from "highlight.js/lib/core";
import json from "highlight.js/lib/languages/json";
import "highlight.js/styles/stackoverflow-light.css";
import { ChangeEvent, useState } from "react";
import { useForm } from "react-hook-form";
import Button from "src/components/Button";
import Input from "src/components/Input";
import Tooltip from "src/components/Tooltip";
import { getNetworkErrorText, notify } from "src/utils/utils";
import PencilIcon from "@assets/icons/editPencilHollow.svg";
import infoIcon from "@assets/icons/info-1.svg";
import successIcon from "@assets/icons/workflow/pass.svg";
import failIcon from "@assets/icons/workflow/reject.svg";
import { ReactComponent as CloseIcon } from "@assets/icons/x-close.svg";
import Label from "@components/Label";
import Shimmer from "@components/Shimmer";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { isJsonFormat } from "../component";
import {
  datasourceListQuery,
  getDatasourceDetailsQuery,
  useGetVar,
  useSaveDsConnCurl,
  useTestDsConnCurl,
  useUpdateDatasource,
} from "../queries";
import { analyticsInstance } from "src/config/event-analytics";
import { CustomAPIActions } from "src/constants/EventAnalytics";

hljs.registerLanguage("json", json);

function CurlModel({
  close,
  isUpdating,
}: {
  close: () => void;
  isUpdating: false | number;
}) {
  const [outputVal, setOutputVal] = useState("{}");
  const [toggleEdit, setToggleEdit] = useState(false);
  const [istested, setIsTested] = useState(false);
  const [outputError, setOutputError] = useState("");

  const testCurl = useTestDsConnCurl();

  const saveCurl = useSaveDsConnCurl();
  const updateDatasource = useUpdateDatasource();

  const [openOutput, setOpenOutput] = useState(false);
  const queryClient = useQueryClient();

  const dsDetails = useQuery(getDatasourceDetailsQuery(isUpdating));

  const form = useForm<{
    name: string;
    identifier: string;
    retryCount: number;
    timeout: number;
    curl: string;
    variables: Record<string, string>;
  }>({
    shouldUnregister: true,
    defaultValues: {
      retryCount: 0,
      timeout: 3,
    },
    values: dsDetails.data?.data.data.details
      ? {
          name: dsDetails.data.data.data.details.name,
          identifier: dsDetails.data.data.data.details.alias,
          timeout: dsDetails.data.data.data.details.timeout / 1000,
          retryCount: dsDetails.data.data.data.details.retries,
          curl: dsDetails.data.data.data.details.curl,
          variables: Object.keys(
            dsDetails.data.data.data.details.variables
          ).reduce<Record<string, string>>((prev, curr) => {
            if (!prev[curr]) prev[curr] = "";
            return prev;
          }, dsDetails.data.data.data.details.variables as Record<string, string>),
        }
      : undefined,
  });

  const curlText = form.watch("curl", dsDetails.data?.data.data.details.curl);
  const getVarCurl = useGetVar(curlText);

  const handleApiSave = form.handleSubmit((data) => {
    if (!isJsonFormat(outputVal)) return setOutputError("Invalid JSON");

    if (isUpdating){

      analyticsInstance.triggerAnalytics(CustomAPIActions.UPDATE_SOURCE_SAVE,
      {
        name:dsDetails.data?.data.data.details.name, 
        run_test_done:istested
      });

      updateDatasource.mutate(
        {
          id: isUpdating,
          name: dsDetails.data?.data.data.details.name!,
          curl: dsDetails.data?.data.data.details.curl!,
          istested: istested,
          variables: data.variables,
          output: JSON.parse(outputVal),
          alias: dsDetails.data?.data.data.details.alias!,
          timeout: data.timeout * 1000,
          retries: data.retryCount,
        },
        {
          onSuccess() {
            notify({
              title: "Success",
              text: "Data Source updated successfully",
              type: "success",
            });
            queryClient.invalidateQueries(datasourceListQuery());
            close();
          },
          onError(err) {
            notify({
              text: "Error occured while updating",
              title: getNetworkErrorText(err),
              type: "error",
            });
          },
        }
      );
    } else {
      analyticsInstance.triggerAnalytics(CustomAPIActions.ADD_SOURCE_SAVE,
      {
        name:dsDetails.data?.data.data.details.name, 
        run_test_done:istested
      });
      saveCurl.mutate(
        {
          name: data.name,
          curl: data.curl,
          istested: istested,
          variables: data.variables,
          output: JSON.parse(outputVal),
          alias: data.identifier,
          timeout: data.timeout * 1000,
          retries: data.retryCount,
        },
        {
          onSuccess() {
            notify({
              title: "Success",
              text: "Data Source added successfully",
              type: "success",
            });
            queryClient.invalidateQueries(datasourceListQuery());
            close();
          },
          onError(err) {
            notify({
              text: "Error occured while adding",
              title: getNetworkErrorText(err),
              type: "error",
            });
          },
        }
      );
    }
  });

  const handleTest = form.handleSubmit((data) => {
    const { curl, variables, timeout, retryCount } = data;

    testCurl.mutate(
      isUpdating
        ? {
            curl: dsDetails.data?.data.data.details.curl!,
            variables: variables || {},
            timeout: timeout * 1000,
            retries: retryCount,
          }
        : {
            curl: curl,
            variables: variables || {},
            timeout: timeout * 1000,
            retries: retryCount,
          },
      {
        onSuccess(data) {
          setOutputVal(JSON.stringify(data.data.data.responseBody, null, 2));
          setOpenOutput(true);
          if (
            data.data.data.statusCode >= 200 &&
            data.data.data.statusCode <= 299
          ) {
            setIsTested(true);
          } else {
            setIsTested(false);
          }
          analyticsInstance.triggerAnalytics(isUpdating?CustomAPIActions.UPDATE_SOURCE_RUN_TEST:CustomAPIActions.ADD_SOURCE_RUN_TEST,{status_code:data.data.data.statusCode});
        },
        onError(err) {
          notify({
            text: "Error occured while testing",
            title: getNetworkErrorText(err),
            type: "error",
          });
          setIsTested(false);
          if(err instanceof AxiosError){
            analyticsInstance.triggerAnalytics(isUpdating?CustomAPIActions.UPDATE_SOURCE_RUN_TEST:CustomAPIActions.ADD_SOURCE_RUN_TEST,{status_code: err?.response?.status});
          }
        },
      }
    );
  });

  const curlChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setIsTested(false);

    if (!e.target.value) {
      notify({
        text: "Missing input curl",
        title: "Validation",
        type: "error",
      });
      return;
    }
    // getVarCurl.mutate(e.target.value, {
    //   onError() {
    //     notify({
    //       title: "something went wrong",
    //       text: "unable to get variable ",
    //       type: "error",
    //     });
    //   },
    // });
  };

  const closeModel = () => {
      analyticsInstance.triggerAnalytics(isUpdating?CustomAPIActions.UPDATE_SOURCE_CLOSE:CustomAPIActions.ADD_SOURCE_CLOSE);
      close();
  }


  const cancelModel = () => {
      analyticsInstance.triggerAnalytics(isUpdating?CustomAPIActions.UPDATE_SOURCE_CANCEL:CustomAPIActions.ADD_SOURCE_CANCEL);
      close();
  }

  return (
    <>
      <div className="h-screen overflow-auto hide-scrollbar bg-white relative flex-col">
        <div className="flex sticky top-0 bg-white z-10 items-center justify-between h-12 px-6 border-b border-neutral-100">
          <div className="font-b1-medium text-neutral-black">
            Import API Source
          </div>
          <CloseIcon
            className="text-neutral-500 hover:text-neutral-black cursor-pointer"
            onClick={closeModel}
          />
        </div>
        <div className="flex flex-col gap-4 px-6 py-6 items-center border-b border-neutral-100 bg-white">
          <div className="justify-between flex items-center w-full">
            <Label className="font-b2-medium text-neutral-black">Name</Label>
            <Input
              disabled={!!isUpdating}
              className="w-[464px] [&>input]:font-b1 ml-auto"
              placeholder="Enter name"
              error={form.formState.errors.name?.message}
              {...form.register("name", {
                value: dsDetails.data?.data.data.details.name,
                disabled: !!isUpdating,
                maxLength: {
                  value: 30,
                  message: "Max 30 characters",
                },
              })}
            />
          </div>
          <div className="justify-between flex items-center w-full">
            <Label className="font-b2-medium text-neutral-black">
              <div className="flex gap-2">
                <span>Identifier</span>
                <Tooltip
                  right
                  content={<img src={infoIcon} alt="info icon" />}
                  tooltipContent={
                    <p>
                      The alias will be used when you add <br /> variables from
                      this source in any policy.
                    </p>
                  }
                />
              </div>
            </Label>
            <Input
              className="w-[464px] [&>input]:font-b1"
              placeholder="Enter the identifier"
              disabled={!!isUpdating}
              error={form.formState.errors.identifier?.message}
              {...form.register("identifier", {
                disabled: !!isUpdating,
                maxLength: {
                  value: 10,
                  message: "Max 10 characters",
                },
                pattern: {
                  value: /^[a-z_]+$/,
                  message: "Only lowercase letters and underscore is allowed",
                },
                required: {
                  value: true,
                  message: "*required",
                },
              })}
            />
          </div>
          <div className="justify-between flex items-center w-full">
            <Label className="font-b2-medium text-neutral-black">
              Number of Retries
            </Label>
            <Input
              className="w-[464px] [&>input]:font-b1 ml-auto"
              placeholder="Enter number of retries"
              error={form.formState.errors.retryCount?.message}
              type="number"
              {...form.register("retryCount", {
                max: {
                  value: 8,
                  message: "Max retry count allowed is 8",
                },
                validate: {
                  nonNegative: (value) =>
                    value >= 0 || "Retry count should not be negative",
                },
                valueAsNumber: true,
              })}
            />
          </div>
          <div className="justify-between flex items-center w-full">
            <Label className="font-b2-medium text-neutral-black">
              Timeout (in seconds)
            </Label>
            <Input
              className="w-[464px] [&>input]:font-b1 ml-auto"
              placeholder="Enter timeout in seconds"
              error={form.formState.errors.timeout?.message}
              type="number"
              {...form.register("timeout", {
                max: {
                  value: 60,
                  message: "Max timeout is 60 seconds",
                },
                validate: {
                  nonNegative: (value) =>
                    value >= 0 || "Timeout should not be negative",
                },
                valueAsNumber: true,
              })}
            />
          </div>
        </div>

        <div className="mt-6 px-6 border-b border-neutral-100 pb-6">
          <div className="flex">
            <h2 className="font-b1-medium text-neutral-black">
              Input curl command
            </h2>
          </div>
          <div className="relative mt-4">
            <textarea
              disabled={!!isUpdating}
              className="block disabled:cursor-not-allowed font-b1 w-full rounded-md h-[160px] bg-[#fafafa] overflow-auto border-indigo-50 shadow-sm focus:border-indigo-300 focus:outline-none focus:ring-0 sm:text-xs resize-none"
              {...form.register("curl", {
                required: { value: true, message: "Curl command is required" },
                disabled: !!isUpdating,
                onChange: curlChange,
              })}
            />
          </div>
          <h4 className="font-b2 text-neutral-500 mt-2">
            Please make sure variables are written inside
            &#123;&#123;&#125;&#125;. For example
            &#123;&#123;variable_name&#125;&#125;
          </h4>
          <span className="text-error-900 font-b2-medium">
            {form.formState.errors.curl?.message}{" "}
          </span>
        </div>

        {(getVarCurl.data?.data.data.variables ?? []).length > 0 && (
          <>
            <div className="px-6 pt-6">
              <div className="flex">
                <h1 className="font-b1-medium text-neutral-black mr-2">
                  Input Variables Values
                </h1>
                <Tooltip
                  content={<img src={infoIcon} alt="info icon" />}
                  tooltipContent={
                    <>
                      Enter sample values to fetch <br /> the response.
                    </>
                  }
                />
              </div>
              <div>
                <div>
                  {(getVarCurl.data?.data.data.variables ?? []).map(
                    (variable) => (
                      <div
                        key={variable}
                        className="flex items-center justify-between mt-4"
                      >
                        <span className="basis-1/2 truncate max-w-[50%] grow-0 shrink-0 text-neutral-black font-b2-medium mr-6">
                          {variable}
                        </span>
                        <Input
                          placeholder="Enter Value"
                          className="[&input]:text-neutral-500 [&>input]:font-b1 w-full"
                          {...form.register(`variables.${variable}`)}
                        />
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          </>
        )}
        <Button
          className="mt-6 ml-6"
          variant="outline"
          disabled={
            testCurl.isPending ||
            (!!form.getValues("curl") &&
              form.getValues("curl").trim().length === 0)
          }
          onClick={handleTest}
        >
          Run Test
        </Button>
        <div className="h-[1px] bg-neutral-25 my-6 "></div>

        {openOutput && (
          <div className="px-6 mb-6">
            <div className="flex justify-between mt-6">
              <div>
                <h2 className="font-b1-medium text-indigo-900 flex gap-2">
                  Output
                  <Tooltip
                    content={<img src={infoIcon} alt="info icon" />}
                    right
                    tooltipContent={
                      <p>
                        Editing the output will disconnect <br />
                        the source from the platform
                      </p>
                    }
                  />
                </h2>
                <div className="font-b2 text-neutral-500 pt-1">
                  Verify the API connection and data structure to ensure
                  seamless integration.
                </div>
              </div>
              <div className="flex text-neutral-black gap-2 font-b1-medium min-w-[100px]">
                <span>Result:</span>
                {testCurl.isPending && <Shimmer className="w-20 h-[2em]" />}
                {testCurl.data?.data &&
                  (testCurl.data?.data.data.statusCode / 100 === 2 ? (
                    <div className="flex gap-2 items-start">
                      <img src={successIcon} alt="success" className="" />
                      <span>Success</span>
                    </div>
                  ) : (
                    <div className="flex gap-2 items-start">
                      <img src={failIcon} alt="fail" className="" />
                      <span>Fail</span>
                    </div>
                  ))}
              </div>
            </div>

            <div className="mt-4 relative">
              <Button
                className={clsx(
                  "absolute top-4 right-4 !p-2",
                  testCurl.isPending && "hidden"
                )}
                variant="outline"
                onClick={() => setToggleEdit(!toggleEdit)}
              >
                <img src={PencilIcon} alt="edit img" className="w-5 h-5" />
              </Button>
              {testCurl.isPending && <Shimmer className="w-full h-96" />}
              {toggleEdit ? (
                <textarea
                  value={outputVal}
                  onChange={(e) => {
                    setOutputVal(e.target.value);
                    setIsTested(false);
                  }}
                  className={clsx(
                    "block font-b1 w-full rounded-md h-[340px] bg-[#fafafa] overflow-auto border-indigo-50 shadow-sm focus:border-indigo-300 focus:outline-none focus:ring-0 font-b1 resize-none p-4",
                    testCurl.isPending && "hidden"
                  )}
                />
              ) : (
                <pre
                  dangerouslySetInnerHTML={{
                    __html: hljs.highlight(
                      JSON.stringify(JSON.parse(outputVal) || {}, null, 2),
                      {
                        language: "json",
                      }
                    ).value,
                  }}
                  className={clsx(
                    "w-full rounded-md h-[340px] bg-[#fafafa] overflow-auto border-indigo-50 font-b1 p-4",
                    testCurl.isPending && "hidden"
                  )}
                ></pre>
              )}
              <div className="text-error-900 font-b2-medium">{outputError}</div>
            </div>
          </div>
        )}
      </div>
      <div className="sticky bottom-0 mt-auto h-12 flex gap-2 items-center justify-end w-full px-6 bg-neutral-0 border-t border-neutral-100">
        <Button onClick={cancelModel} variant="outline">
          Cancel
        </Button>
        <Button
          disabled={updateDatasource.isPending || saveCurl.isPending}
          onClick={handleApiSave}
        >
          {!!isUpdating ? "Update" : "Save"}
        </Button>
      </div>
    </>
  );
}

export default CurlModel;
