import {
    format,
    formatDistanceToNow,
    parseISO,
  } from "date-fns";
  import { useNavigate } from "react-router-dom";
  import {
    useMarkNotificationRead,
  } from "src/apis";
  import {
    MonitorNotification,
  } from "src/types";
  import { classNames } from "src/utils/utils";
  import { ReactComponent as AlertCircle } from "@assets/notificationIcons/alert-circle.svg";
  import { ReactComponent as AlertTriangle } from "@assets/notificationIcons/alert-triangle.svg";
  import { CTA_MAPPER } from "./NotificationCenter.constants";
import NotificationBaseCard from "./NotificationBaseCard";
  
const AlertsCard = ({ item }: { item: MonitorNotification }) => {
    const navigate = useNavigate();
    const markNotificationRead = useMarkNotificationRead();
  
    const handleCtaAction = () => {
      if (item.metadata?.sourceName === "ENDPOINT_MONITOR") {
        navigate("/monitors/endpoint");
      } else {
        navigate("/monitors/custom");
      }
      markNotificationRead.mutate({ notificationId: item.id });
    };
    const d = new Date(item.updatedAt);
    return (
      <>
        <NotificationBaseCard isRead={item.isRead}>
          <>
            <div
              className={classNames(
                "w-7 h-7 flex justify-center items-center rounded-full",
                item.metadata.status === "error" ? "bg-error-50" : "bg-warning-50"
              )}
            >
              {item.metadata.status === "error" ? (
                <AlertCircle className="w-4 h-4" />
              ) : (
                <AlertTriangle className="w-4 h-4" />
              )}
            </div>
            <div className="flex flex-col ml-4 w-[90%] gap-3">
              <p className="text-neutral-black font-b1-medium">
                Triggered {item.metadata?.status} on "{item.metadata?.monitorName}
                "
              </p>
              <div>
                <span className="rounded-full px-2 py-1 font-b2-medium text-neutral-700 bg-neutral-100 mr-2">
                  {item.metadata?.sourceName === "ENDPOINT_MONITOR"
                    ? "Endpoint"
                    : "Custom API"}
                </span>
                <span className="font-b1 text-neutral-600">
                  {format(d, "dd MMM, yyyy - h:mm aaaaa'm")}
                </span>
              </div>
              <div className="font-b2 text-neutral-600">
                {item.metadata?.description}
              </div>
              <div className="flex justify-between">
                <p className="font-[12px] text-neutral-600">
                  {formatDistanceToNow(parseISO(item.updatedAt))} ago
                </p>
                <button
                  className="group-hover:visible invisible flex text-primary-900 font-b2-medium items-center"
                  onClick={() => handleCtaAction()}
                >
                  {CTA_MAPPER[item.type]?.text}
                  {CTA_MAPPER[item.type]?.icon}
                </button>
              </div>
            </div>
          </>
        </NotificationBaseCard>
      </>
    );
  };
  
export default AlertsCard;