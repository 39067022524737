import React from "react";
import { ReactComponent as NavigatorIcon } from "@assets/icons/dotpoints-01.svg";
import { ReactComponent as FlagIcon } from "@assets/icons/flag-01.svg";
import { ReactComponent as AddIcon } from "@assets/icons/plus-circle.svg";
import settingsIcon from "@assets/icons/settings-02.svg";
import { ReactComponent as branchIcon } from "@assets/icons/shuffle-01.svg";
import { ReactComponent as SettingsIcon } from "@assets/icons/tool-01.svg";
import { ReactComponent as VariableIcon } from "@assets/icons/variable.svg";
import { ReactComponent as approvedIcon } from "@assets/icons/workflow/check-circle.svg";
import { ReactComponent as sourceIcon } from "@assets/icons/workflow/database-01.svg";
import { ReactComponent as cantDecideIcon } from "@assets/icons/workflow/help-circle.svg";
import { ReactComponent as DebuggerIcon } from "@assets/icons/workflow/play-circle.svg";
import { ReactComponent as policyIcon } from "@assets/icons/workflow/policy.svg";
import { ReactComponent as ruleIcon } from "@assets/icons/workflow/rule.svg";
import { ReactComponent as tableIcon } from "@assets/icons/workflow/table.svg";
import { ReactComponent as customIcon } from "@assets/icons/workflow/tool-01.svg";
import { ReactComponent as rejectedIcon } from "@assets/icons/workflow/x-circle.svg";

export const COLORS = {
  bureau: "#444CE7",
  bank: "#E31B54",
  device: "#7C3AED",
  application: "#0075FF",
  gst: "#039855",
  custom: "#343F59",
};

export const OPERATORS = ["<", ">", "==", "!=", "<=", ">="] as string[];
export const OUTPUT_OPTIONS = ["pass", "fail", "custom"];

export const BE_START_NODE_TYPE = "start";
export const BE_BRANCH_NODE_TYPE = "branch";
export const BE_POLICY_NODE_TYPE = "policy";
export const BE_MODEL_NODE_TYPE = "modelExpression";
export const BE_RULE_SET_NODE_TYPE = "ruleSet";
export const BE_SOURCE_NODE_TYPE = "dataSource";
export const BE_WORKFLOW_NODE_TYPE = "workflow";
export const BE_RULE_GROUP_NODE_TYPE = "ruleGroup";
export const BE_DECISION_TABLE_NODE_TYPE = "modelDecisionTable";
export const BE_END_NODE_TYPE = "end";
export const BE_SWITCH_NODE_TYPE = "switch";
export const BE_MODEL_SET_NODE_TYPE = "modelSet";

// React flow node types
export const START_NODE_TYPE = "startNode";
export const BRANCH_NODE_TYPE = "branchNode";
export const POLICY_NODE_TYPE = "policyNode";
export const MODEL_NODE_TYPE = "modelExpressionNode";
export const RULE_SET_NODE_TYPE = "ruleSet";
export const WORKFLOW_NODE_TYPE = "workflowNode";
export const RULE_GROUP_NODE_TYPE = "ruleNode";
export const DECISION_TABLE_NODE_TYPE = "matrixNode";
export const END_NODE_TYPE = "endNode";
export const SWITCH_NODE_TYPE = "switchNode";
export const SOURCE_NODE_TYPE = "sourceNode";
export const MODEL_SET_NODE_TYPE = "modelSet";

export const NODE_IMPORT_INPUT_ID = "importNodeFileInput";


export const ADD_BLOCK_CONFIG = [
  {
    name: "Blocks",
    items: [
      {
        name: "Rule",
        type: RULE_SET_NODE_TYPE,
        icon: ruleIcon,
        bgColor: "#A77AD4",
      },
      {
        name: "Policy",
        type: POLICY_NODE_TYPE,
        icon: policyIcon,
        bgColor: "#4EACB0",
      },
      {
        name: "Workflow",
        type: WORKFLOW_NODE_TYPE,
        icon: policyIcon,
        bgColor: "#4EACB0",
      },
      {
        name: "Source",
        type: SOURCE_NODE_TYPE,
        icon: sourceIcon,
        bgColor: "#E17CA6",
      },
      {
        name: "Branch",
        type: BRANCH_NODE_TYPE,
        icon: branchIcon,
        bgColor: "#7A8ED4",
      },
    ] as const,
  },
  {
    name: "Models",
    items: [
      // {
      //   name: "Expression",
      //   type: MODEL_NODE_TYPE,
      //   icon: expressionIcon,
      //   bgColor: "#F58C10",
      // },
      // {
      //   name: "Decision Table",
      //   type: DECISION_TABLE_NODE_TYPE,
      //   icon: tableIcon,
      //   bgColor: "#777878",
      // },
      {
        name: "Model Set",
        type: MODEL_SET_NODE_TYPE,
        icon: tableIcon,
        bgColor: "#777878",
      },
    ] as const,
  },
  {
    name: "Outcomes",
    items: [
      {
        name: "Approved",
        type: END_NODE_TYPE,
        icon: approvedIcon,
        bgColor: "#12B76A",
      },
      {
        name: "Rejected",
        type: END_NODE_TYPE,
        icon: rejectedIcon,
        bgColor: "#F04438",
      },
      {
        name: "Can't Decide",
        type: END_NODE_TYPE,
        icon: cantDecideIcon,
        bgColor: "#F79009",
      },
      {
        name: "Custom",
        type: END_NODE_TYPE,
        icon: customIcon,
        bgColor: "#1982FF",
      },
    ] as const,
  },
] as const;

export const SIDEBAR_CONFIG = [
  {
    name: "Add Block",
    icon: <AddIcon className="w-[1.125rem] h-[1.125rem]" />,
  },
  {
    name: "Navigator",
    icon: <NavigatorIcon className="w-[1.125rem] h-[1.125rem]" />,
  },
  {
    name: "Configure Input Parameters",
    icon: <VariableIcon className="w-[1.125rem] h-[1.125rem]" />,
  },
  {
    name: "Configure Outcomes",
    icon: <SettingsIcon className="w-[1.125rem] h-[1.125rem]" />,
  },
  {
    name: "Test Workflow",
    icon: <DebuggerIcon className="w-[1.125rem] h-[1.125rem]" />,
  },
  {
    name: "Simulate",
    icon: <FlagIcon className="w-[1.125rem] h-[1.125rem]" />,
  },
  {
    name: "Settings",
    icon: (
      <img alt="" className="w-[1.125rem] h-[1.125rem]" src={settingsIcon} />
    ),
  },
] as const;
