import { useEffect, useRef, useMemo } from "react";
import Modal from "src/components/Dialogue";
import Loader from "src/components/Loader";
import { useWorkflowContext } from "src/screens/workflow/WorkflowContext";
import closeIcon from "@assets/icons/x-close.svg";
import { getLogTypeIcon } from "../../utils";
import { useDecisionTableChangeLog } from "./DecisionTableChangeLog.queries";
import DecisionTableChangelogCells from "./DecisionTableChangelogCells";
import { CellData, DTChangeLogData } from "./DecisionTableChanglog.types";
import DecisionTableRow from "./DecisionTableRow";

export default function DecisionTableChangelog({
  open,
  handleClose,
  prevDTData,
  currentDTData,
  name,
}: {
  open: boolean;
  name: string;
  handleClose: () => void;
  prevDTData: DTChangeLogData;
  currentDTData: DTChangeLogData;
}) {
  const { workflow } = useWorkflowContext();
  const getDecisionTableChangeLogData = useDecisionTableChangeLog(
    workflow?.policyBucketId ?? ""
  );
  const containerRef = useRef<HTMLDivElement>(null);
  const lastColumnRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (prevDTData?.decisionTableID && currentDTData.decisionTableID) {
      getDecisionTableChangeLogData.mutate({
        currWorkflow: currentDTData,
        prevWorkflow: prevDTData,
      });
    }
  }, []);

  const {
    headers = [],
    rows = [],
    default: defaultChange,
  } = getDecisionTableChangeLogData.data?.data?.data ?? {};

  const [editCount, addRowCount, deleteRowCount, addColCount, deleteColCount] =
    useMemo(() => {
      let edit = 0,
        addRow = 0,
        delRow = 0,
        addCol = 0,
        delCol = 0;

      const countState = (columns: CellData[]) => {
        columns.forEach((column) => {
          if (column.state === "edit") edit++;
        });
      };

      countState(headers);
      headers.forEach((head) => {
        if (head.state === "add") addCol++;
        if (head.state === "delete") delCol++;
      });
      rows.forEach((row) => {
        countState([...row.columns, row.output]);
        if (row.columns[0].state === "add") addRow++;
        if (row.columns[0].state === "delete") delRow++;
      });

      return [edit, addRow, delRow, addCol, delCol];
    }, [headers, rows]);

  return (
    <Modal open={open} onClose={handleClose}>
      <Modal.Panel size="lg" className="w-full h-full z-[10]">
        {getDecisionTableChangeLogData.isPending ? (
          <div className="h-screen w-screen flex items-center justify-center">
            <Loader size="large" />
          </div>
        ) : (
          <Modal.Body className="mt-3 mb-3 !h-full !max-h-full">
            <div className="flex justify-between items-center">
              <span
                className="max-w-44 w-max truncate cursor-pointer font-medium text-2xl hover:bg-neutral-25 px-1 py-0.5 rounded-md"
                aria-label="Modal ID"
              >
                {name}
              </span>
              <img
                alt="Close"
                src={closeIcon}
                onClick={handleClose}
                className="h-4 w-4 p-0 ml-auto cursor-pointer z-10 text-neutral-500"
                aria-label="Close modal"
              />
            </div>
            <div className="flex gap-4 mb-6 mt-1">
              {[
                {
                  count: editCount,
                  type: "edit",
                  label: `${editCount} ${
                    editCount > 1 ? "Cells updated" : "Cell updated"
                  }`,
                },
                {
                  count: addRowCount,
                  type: "add",
                  label: `${addRowCount} ${
                    addRowCount > 1 ? "Rows added" : "Row added"
                  }`,
                },
                {
                  count: deleteRowCount,
                  type: "delete",
                  label: `${deleteRowCount} ${
                    deleteRowCount > 1 ? "Rows deleted" : "Row deleted"
                  }`,
                },
                {
                  count: addColCount,
                  type: "add",
                  label: `${addColCount} ${
                    addColCount > 1 ? "Cols added" : "Col added"
                  }`,
                },
                {
                  count: deleteColCount,
                  type: "delete",
                  label: `${deleteColCount} ${
                    deleteColCount > 1 ? "Cols deleted" : "Col deleted"
                  }`,
                },
              ].map(({ count, type, label }) => (
                <>
                  {count > 0 && (
                    <div className="flex items-center gap-1.5" key={type}>
                      {getLogTypeIcon(type)}
                      <span className="font-b2-medium text-neutral-800">
                        {label}
                      </span>
                    </div>
                  )}
                </>
              ))}
            </div>
            {defaultChange?.state && (
              <div className="flex gap-0.5 items-center mb-5">
                <span className="text-neutral-black text-xs font-medium">
                  Default Value
                </span>
                <span className="text-neutral-600 text-xs">
                  is changed from
                </span>
                <span className="text-neutral-600 bg-neutral-50 text-xs px-1 rounded-sm">
                  {defaultChange?.prevData}
                </span>
                <span className="text-neutral-800 text-xs">to</span>
                <span className="text-neutral-black bg-neutral-100 text-xs px-1 rounded-sm">
                  {defaultChange?.currData}
                </span>
              </div>
            )}
            <div
              ref={containerRef}
              className="border-t overflow-auto border-l font-b1 border-neutral-100 rounded-md grid divide-neutral-100 min-h-[1ch] max-h-[calc(100vh-150px)]"
              style={{
                gridTemplateColumns: `75px repeat(${
                  headers.length + 1
                }, minmax(250px, 1fr))`,
              }}
            >
              <div className="bg-neutral-0 sticky z-[100] top-0 left-0 overflow-visible w-full font-medium first:rounded-tl-md py-1 px-4 border-r border-b border-neutral-100 h-full flex items-center">
                Sl. No.
              </div>
              {headers.map((cell, index) => (
                <DecisionTableChangelogCells
                  ref={index === headers.length - 1 ? lastColumnRef : null}
                  key={index}
                  cell={cell}
                  tooltipPosition={"bottom"}
                  cellClassName={"sticky z-[50] top-0 left-0 shadow-md"}
                />
              ))}
              <div className="sticky top-0 right-0 z-[50] py-1 pl-4 pr-1 -ml-[1px] border-l border-r border-neutral-100 font-medium border-b bg-neutral-0 rounded-tr-md flex items-center">
                Output
              </div>
              {rows.map((row, rowIndex) => (
                <DecisionTableRow
                  key={rowIndex}
                  rowIndex={rowIndex}
                  columns={row.columns}
                  output={row.output}
                />
              ))}
            </div>
          </Modal.Body>
        )}
      </Modal.Panel>
    </Modal>
  );
}
