import { useParams } from "react-router-dom";
import { Node } from "reactflow";
import Tooltip from "src/components/Tooltip";
import { titleCase } from "src/utils/utils";
import ruleSetIcon from "@assets/icons/rule-set.svg";
import {
  getRuleSetConditionsQuery,
  useTestRuleSet,
} from "@screens/workflow/studio/components/RuleSet/queries";
import { getIcon } from "@screens/workflow/studio/utils";
import { useQuery } from "@tanstack/react-query";
import { FinBoxResponse } from "@types";
import RightPanel from "./RightPanel";

type RuleSetNodeProps = {
  data: Node<{
    label: string;
  }>;
};

const RuleSetNode = ({ data }: RuleSetNodeProps) => {
  const { workflowId } = useParams();

  const ruleSetConditionsQuery = useQuery(
    getRuleSetConditionsQuery(workflowId || "", data.id)
  );

  const rules = ruleSetConditionsQuery.data?.data.data.rules ?? [];
  const testRuleSet = useTestRuleSet();

  const ruleIdNameMap =
    ruleSetConditionsQuery.isSuccess && rules
      ? rules.reduce((prev, curr) => {
          prev[curr.rule_id] = curr.description;
          return prev;
        }, {} as Record<string, string>)
      : {};

  const test = (variables: Record<string, Record<string, any>>) => {
    if (!ruleSetConditionsQuery.isSuccess) return;
    testRuleSet.mutate({
      ruleset: rules,
      variables,
    });
  };

  const expressions:string[] = [];

  (ruleSetConditionsQuery.data?.data.data?.rules ?? []).forEach(item => {
    if (item.cant_decide){
      expressions.push(item.approve);
      expressions.push(item.cant_decide);
    } else {
      expressions.push(item.approve);
    };
  })

  return (
    <RightPanel
      nodeId={data.id}
      label={data?.data.label}
      expressions={expressions}
      onTest={test}
      finalDecisionResult={
        testRuleSet.isSuccess ? (
          <div className="flex items-center gap-2 px-4 py-3 font-b2-medium border-b justify-between">
            <span>Final Decision: </span>

            <span className="inline-flex gap-2">
              {getIcon(testRuleSet.data?.data.data.decision)}{" "}
              {testRuleSet.data?.data.data.decision}
            </span>
          </div>
        ) : (
          <></>
        )
      }
      testResult={
        testRuleSet.isSuccess ? (
          <div
            style={{
              gridTemplateColumns: "1fr 100px",
            }}
            className="flex flex-col text-neutral-500 font-b2"
          >
            <div className="flex text-neutral-black font-b2-medium mb-1">
              <img alt="" src={ruleSetIcon} className="w-4 h-4 mr-2" />
              {data?.data.label || "Ruleset_Name"}
            </div>
            <div className="flex flex-col">
              {Object.keys(testRuleSet.data?.data.data.rules ?? {}).map(
                (key) => {
                  return (
                    <div className="font-b2-medium flex gap-2 mt-4 justify-between">
                      <Tooltip
                        tooltipContent={
                          <div className="text-wrap">{ruleIdNameMap[key]}</div>
                        }
                        content={
                          <div className="truncate max-w-[340px]">
                            {ruleIdNameMap[key]}
                          </div>
                        }
                        center
                      />
                      <span className="flex gap-1">
                        {getIcon(testRuleSet.data?.data.data.rules[key])}{" "}
                        {titleCase(testRuleSet.data?.data.data.rules[key])}
                      </span>
                    </div>
                  );
                }
              )}
            </div>
          </div>
        ) : testRuleSet.isError ? (
          <div className="text-error-500 w-full font-code grid grid-cols-2 gap-2 font-b2 py-3">
            {(
              testRuleSet.error as unknown as {
                response: { data: FinBoxResponse };
              }
            ).response.data.error
              .split("\n")
              .map((row) => (
                <>
                  {row}
                  <br />
                </>
              ))}
          </div>
        ) : (
          <></>
        )
      }
    />
  );
};
export default RuleSetNode;
