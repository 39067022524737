
import Shimmer from "src/components/Shimmer";

export const QueryLoader = () => {
    return (
      <div className="flex flex-col m-auto p-8 gap-2">
        <Shimmer w="400px" h="20px" />
        <Shimmer w="400px" h="20px" />
        <Shimmer w="400px" h="20px" />
      </div>
    );
  };
  
export const NotificationFooter = () => {
    return (
      <p className="m-auto font-b2-medium text-neutral-500 py-2">
        Older notifications beyond 30 days won't be visible
      </p>
    );
  };
  
export const NoNotification = () => {
    return (
      <div className="m-auto py-12">
        <p className="font-b2-medium text-neutral-black">No notifications</p>
      </div>
    );
  };