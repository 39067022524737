import closeIcon from "@assets/icons/x-close-blue.svg";
import Button from "@components/Button";
import { useQueryClient } from "@tanstack/react-query";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import Loader from "src/components/Loader";
import ProtectedComponent from "src/components/ProtectedComponent";
import { PERMISSIONS_TYPE } from "src/constants/permissionsConstant";
import useBreadcrumbs from "src/context/BreadcrumbsContext";
import usePermissions from "src/context/PermissionContext";
import { PermissionTypes } from "src/types";
import { notify, titleCase } from "src/utils/utils";
import { AdvancedSettings } from "./AdvancedSettings";
import {
  getPermissionQuery,
  useGetPermissionsQuery,
  useUpdatePermissions,
} from "./queries";
import { RoleData } from "./types";
import { analyticsInstance } from "src/config/event-analytics";
import { SettingsActions } from "src/constants/EventAnalytics";

export default function EditRoles() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [advancedSettings, setAdvancedSettings] = useState(false);

  const {
    data: permissionsList,
    isSuccess,
    isPending,
  } = useGetPermissionsQuery(state?.roleName);
  const updatePermissions = useUpdatePermissions();
  const qc = useQueryClient();
  const { getPermissions } = usePermissions();
  const isEditing =
    (getPermissions(
      PERMISSIONS_TYPE.administrationRoles as PermissionTypes,
      "edit"
    ) &&
      state?.roleType === "custom") ||
    "";

  useBreadcrumbs([
    {
      name: "Administration",
      link: "/settings/roles",
    },
    {
      name: "Roles",
      link: "/settings/roles",
    },
    {
      name: state?.roleName,
      link: "#",
    },
  ]);

  const [data, setData] = useState({
    name: "",
    resources: {},
    filters: {},
  } as RoleData);

  useEffect(() => {
    if (isSuccess && permissionsList) {
      setData({
        name: permissionsList.name,
        resources: permissionsList.resources || {},
        filters: permissionsList.filters || {},
      });
    }
  }, [permissionsList, isSuccess]);

  const setPermission = (category: PermissionTypes, resource: string) => {
    setData((prevData) => {
      const currentActions = prevData.resources[category]?.actions || {};
      const newActions = { ...currentActions };
      const currentValue = newActions[resource];
      newActions[resource] = !currentValue;

      if (resource !== "view" && newActions[resource]) {
        newActions.view = true;
      }

      if (resource === "create" && newActions[resource] && newActions.edit) {
        newActions.edit = true;
      } else if (
        resource === "create" &&
        !newActions[resource] &&
        newActions.edit
      ) {
        newActions.edit = false;
      }

      const shouldDisableView = Object.keys(newActions).some(
        (key) => key !== "view" && newActions[key]
      );

      if (!shouldDisableView) {
        // eslint-disable-next-line no-self-assign
        newActions.view = newActions.view;
      }

      const updatedResources = {
        ...prevData.resources,
        [category]: {
          ...prevData.resources[category],
          actions: newActions,
          disabledActions: {
            view: shouldDisableView,
            edit: newActions.create,
          },
        },
      };

      return {
        ...prevData,
        resources: updatedResources,
      };
    });
  };

  const setAllPermissions = (category: PermissionTypes, value: boolean) => {
    setData((prevData) => {
      const updatedActions = Object.fromEntries(
        Object.entries(prevData.resources[category].actions).map(
          ([permission, _]) => [permission, value]
        )
      );

      const updatedResources = {
        ...prevData.resources,
        [category]: {
          ...prevData.resources[category],
          actions: updatedActions,
          disabledActions: {
            view: false,
            edit: false,
          },
        },
      };

      return {
        ...prevData,
        resources: updatedResources,
      };
    });
  };

  const handleSave = () => {
    analyticsInstance.triggerAnalytics(
      SettingsActions.ROLES_CREATE_SAVE,
      {
        name: data.name,
        resources: data.resources,
        filters: data.filters,
      }
    );
    updatePermissions.mutate(
      {
        name: data.name,
        resources: data.resources,
        filters: data.filters,
      },
      {
        onSuccess: () => {
          notify({
            title: "Success",
            text: "Updated permissions",
            type: "success",
          });
          navigate("/settings/roles");
          qc.invalidateQueries(getPermissionQuery(state?.roleName));
        },
      }
    );
  };

  const openAddRoleModel = () =>{
    setAdvancedSettings(true);

    analyticsInstance.triggerAnalytics(
      SettingsActions.ROLES_CREATE_ADD_RULE
    );
  }

  const cancelUpdateRole = () =>{
    navigate("/settings/roles") 

    analyticsInstance.triggerAnalytics(
      SettingsActions.ROLES_CREATE_CANCEL
    );
  }

  return (
    <div className="mt-16">
      {isPending && (
        <div className="m-auto w-max p-20">
          <Loader />
        </div>
      )}
      {isSuccess && (
        <>
          <div className="mt-4 px-8 bg-white">
            <div className="flex flex-col mb-5 gap-1">
              <p className="text-xl font-medium">
                {titleCase(state?.roleName) || ""}
              </p>
              <span className="font-b2 text-neutral-500">
                Manage the access level of this role in your organization{" "}
              </span>
            </div>

            {(Object.keys(data.resources) as PermissionTypes[]).map(
              (category) => (
                <div
                  className="flex flex-col border-b mb-4 pb-4 gap-4"
                  key={category}
                >
                  <div className="flex flex-col gap-2">
                    <span className="font-b1-medium">{category}</span>
                    <span className="font-b2 text-neutral-500">
                      {data.resources[category]?.metadata?.description || ""}
                    </span>
                  </div>
                  <div className="flex flex-col gap-4">
                    <div className="flex font-b1-medium gap-3">
                      {Object.entries(data.resources[category].actions).map(
                        ([permission, value]) => {
                          const isDisabled =
                            data.resources[category].disabledActions?.[
                              permission
                            ] ||
                            (permission === "view" &&
                              data.resources[category].disabledActions?.view) ||
                            (permission === "edit" &&
                              data.resources[category].disabledActions?.edit);

                          return (
                            <div
                              className={classNames(
                                "px-2 py-1 border rounded-md border-neutral-100 cursor-pointer w-max items-center",
                                value && "border-primary-900 bg-primary-50",
                                isDisabled && "cursor-not-allowed opacity-60",
                                state?.roleType === "default" &&
                                  "cursor-not-allowed opacity-85"
                              )}
                              onClick={() => {
                                if (
                                  isEditing &&
                                  !isDisabled &&
                                  state?.roleType === "custom"
                                )
                                  setPermission(category, permission);
                              }}
                              key={permission}
                            >
                              <div className="flex gap-1.5 justify-between items-center">
                                <label
                                  className={classNames(
                                    "cursor-pointer font-b1",
                                    value && "text-primary-900 font-b1-medium",
                                    isDisabled && "cursor-not-allowed"
                                  )}
                                  htmlFor={permission}
                                >
                                  {titleCase(permission)}
                                </label>
                                {value &&
                                  !isDisabled &&
                                  state?.roleType === "custom" && (
                                    <img
                                      className="w-3 h-3"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        if (isEditing && !isDisabled) {
                                          setPermission(category, permission);
                                        }
                                      }}
                                      src={closeIcon}
                                      alt=""
                                    />
                                  )}
                              </div>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                  {state?.roleType === "custom" && (
                    <ProtectedComponent
                      type={
                        PERMISSIONS_TYPE.administrationRoles as PermissionTypes
                      }
                      action="edit"
                    >
                      <div className="flex items-end gap-4">
                        <button
                          onClick={() => setAllPermissions(category, true)}
                          className="font-b2-medium  text-neutral-black hover:text-primary-900"
                        >
                          Select All
                        </button>
                        <button
                          onClick={() => setAllPermissions(category, false)}
                          className="font-b2-medium text-neutral-500 hover:text-neutral-black"
                        >
                          Clear All
                        </button>
                      </div>
                    </ProtectedComponent>
                  )}
                </div>
              )
            )}
            {Object.keys(data.filters).length > 0 && (
              <div className="mb-4">
                <p className="font-b1-medium">Advanced Settings</p>
                <span className="font-b2 text-neutral-600">
                  This role has access to
                </span>
                <div className="flex flex-wrap gap-2 mt-2">
                  {Object.entries(data.filters).map(([key, value]) => (
                    <div key={key} className="flex font-b1 w-full">
                      <div className="flex">
                        {Array.isArray(value) && value.length > 0 ? (
                          value.map((item) => (
                            <div
                              key={item.id}
                              className="px-2 py-1 border rounded-md border-neutral-100 cursor-pointer w-max items-center mr-2"
                            >
                              {titleCase(key)}: {item.name}
                            </div>
                          ))
                        ) : (
                          <span className="text-neutral-600">
                            No data available
                          </span>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}

            <button
              className="font-b1-medium text-primary-900 mb-8 hover:text-neutral-black"
              onClick={openAddRoleModel}
              disabled={false}
            >
              + Add Rule
            </button>
          </div>

          <div className="sticky h-10 bg-neutral-0 w-full border border-neutral-100 bottom-0 right-0 flex justify-end items-center px-4 gap-2">
            <Button
              variant="outline"
              onClick={cancelUpdateRole}
            >
              Cancel
            </Button>
            <ProtectedComponent
              type={PERMISSIONS_TYPE.administrationRoles as PermissionTypes}
              action="edit"
            >
              <Button
                variant="primary"
                disabled={
                  updatePermissions.isPending || state?.roleType === "default"
                }
                onClick={handleSave}
              >
                Save
              </Button>
            </ProtectedComponent>
          </div>
          {isEditing && (
            <AdvancedSettings
              isEditing={isEditing}
              advancedSettings={advancedSettings}
              setAdvancedSettings={setAdvancedSettings}
              filters={data.filters}
              setData={setData}
            />
          )}
        </>
      )}
    </div>
  );
}
