import classNames from "clsx";
import { CSSProperties } from "react";

export default function ProgressBar({
  width,
  progressPerc,
  className,
  style,
}: {
  width?: string;
  progressPerc: number;
  className?: string;
  style?: CSSProperties;
}) {
  return (
    <div
      className={classNames("bg-neutral-100 rounded-sm h-2", className)}
      style={style ?? { width }}
    >
      <div
        className="bg-primary-900 rounded-sm h-full"
        style={{ width: `${progressPerc}%` }}
      />
    </div>
  );
}
