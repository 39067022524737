import PrivateRoute from "@components/PrivateRoute";
import WorkFlow from "@screens/workflow";
import ModelSetTableEditor from "@screens/workflow/studio/components/ModelSet/ModelSetTableEditor";
import {
  ConfigureModalWhileAdd,
  ConfigureModalWhileUpdate,
} from "@screens/workflow/studio/components/Source/ConfigureModal";
import { Route } from "react-router-dom";
import ProtectedComponent from "src/components/ProtectedComponent";
import { PERMISSIONS_TYPE } from "src/constants/permissionsConstant";
import { PermissionTypes } from "src/types";

const WorkflowRoute = (
  <Route
    path="/workflow/:workflowId"
    element={
      <ProtectedComponent
        type={PERMISSIONS_TYPE.policy as PermissionTypes}
        action="view"
        showNotAllowed
      >
        <PrivateRoute>
          <WorkFlow />
        </PrivateRoute>
      </ProtectedComponent>
    }
  >
    <Route
      path=":nodeId/modelset-table/:itemId"
      element={<ModelSetTableEditor />}
    />
    <Route
      path=":nodeId/datasource/add/:datasourceId"
      element={<ConfigureModalWhileAdd />}
    />
    <Route
      path=":nodeId/datasource/update/:oldDatasourceId/:datasourceId"
      element={<ConfigureModalWhileAdd isUpdate />}
    />
    <Route
      path=":nodeId/datasource/edit/:datasourceId"
      element={<ConfigureModalWhileUpdate />}
    />
  </Route>
);

export default WorkflowRoute;
