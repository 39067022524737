import Button from "@components/Button";
import NewHeader from "@components/NewHeader";
import Searchbar from "@components/Searchbar";
import { DATA_SOURCE_PAGE_LIMIT } from "@config";
import useBreadcrumbs from "@context/BreadcrumbsContext";
import AddApiModel from "@screens/datasources/DataSourceModel/AddApiModel";
import { SourceDecideFunc } from "@screens/datasources/DataSourceModel/SourceDecideModel";
import DataSourceTable from "@screens/datasources/DataSourceTable";
import { ModelScreen } from "@screens/datasources/types";
import { useState } from "react";
import ProtectedComponent from "src/components/ProtectedComponent";
import { PERMISSIONS_TYPE } from "src/constants/permissionsConstant";
import { useSearchParamState } from "src/hooks/useSearchParamState";
import { PermissionTypes } from "src/types";
import { useGetAvailableSourceList } from "../queries";
import { analyticsInstance } from "src/config/event-analytics";
import { CustomAPIActions } from "src/constants/EventAnalytics";

export default function CustomDataSources() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [openSourceModel, setOpenSourceModel] = useState(false);
  const [isEditing, setIsEditing] = useState<false | number>(false);
  const [sourceModel, setSourceModel] = useState(false);
  const [modelScreen, setModelScreen] = useState<ModelScreen>("");

  useBreadcrumbs([
    {
      name: "Custom Data Sources",
      link: "#",
    },
  ]);
  const { setSearchParams, getSearchParam } = useSearchParamState({
    page: "1",
    name: "",
  });

  const name = getSearchParam("name");
  const page = getSearchParam("page");
  const { isSuccess, data, isPending } = useGetAvailableSourceList({
    pageNumber: parseInt(page),
    pageSize: DATA_SOURCE_PAGE_LIMIT,
    sourceName: name,
  });

  const addSource = () => {
    setModelScreen("curl");
    analyticsInstance.triggerAnalytics(CustomAPIActions.ADD_SOURCE);
  }

  const close =()=>{
    setModelScreen("");
    setIsEditing(false);
  }

  return (
    <>
      <NewHeader
        buttons={[
          <ProtectedComponent
            type={PERMISSIONS_TYPE.dataSourcesCustom as PermissionTypes}
            action="create"
          >
            <Button
              key="add"
              size="sm"
              type="button"
              onClick={addSource}
            >
              Add Source
            </Button>
          </ProtectedComponent>,
        ]}
        filters={
          <Searchbar
            onSearch={(e) => {
              setSearchParams("page", "1");
              setSearchParams("name", e);
            }}
            hint="Search API"
            defaultValue={name}
          />
        }
      >
        <NewHeader.Title>Custom API Sources</NewHeader.Title>
      </NewHeader>
      {isSuccess && (
        <DataSourceTable
          customTableData={data?.data?.customSources || []}
          isLoading={isPending}
          setIsEditing={(id) => {
            setIsEditing(id);
            setModelScreen("curl");
          }}
          onPageChange={(page) => {
            setSearchParams("page", page.toString());
          }}
          page={parseInt(page)}
          totalCount={data?.data?.totalCustomSourcesCount || 0}
        />
      )}
      <AddApiModel
        isEditing={isEditing}
        close={close}
        modelScreen={modelScreen}
      />
      <SourceDecideFunc
        sourceModel={sourceModel}
        setSourceModel={setSourceModel}
        modelScreen={modelScreen}
        setModelScreen={setModelScreen}
        setOpenSourceModel={setOpenSourceModel}
      />
    </>
  );
}
