import classNames from "clsx";
import { memo } from "react";
import { Handle, NodeProps, Position, useReactFlow } from "reactflow";
import alertCircle from "@assets/icons/alert-circle.svg";
import { ReactComponent as DuplicateIcon } from "@assets/icons/copy-07.svg";
import { ReactComponent as ApprovedIcon } from "@assets/icons/workflow/check-circle.svg";
import { ReactComponent as CantIcon } from "@assets/icons/workflow/help-circle.svg";
import { ReactComponent as RejectIcon } from "@assets/icons/workflow/x-circle.svg";
import "@screens/create-policy/queries";
import TestButton from "@screens/workflow/components/TestButton";
import {
  BE_RULE_SET_NODE_TYPE,
  RULE_SET_NODE_TYPE,
} from "@screens/workflow/config";
import { getErrors, getWorkflowKeywordsQuery } from "@screens/workflow/queries";
import ErrorList from "@screens/workflow/studio/components/ErrorList";
import InputList from "@screens/workflow/studio/components/InputList";
import NodeDescription from "@screens/workflow/studio/components/NodeDescription";
import RuleSetConditions from "@screens/workflow/studio/components/RuleSet/RuleSetConditions";
import { getRuleSetConditionsQuery } from "@screens/workflow/studio/components/RuleSet/queries";
import useDuplicateNode from "@screens/workflow/studio/hooks/useDuplicateNode";
import useGetIcon from "@screens/workflow/studio/hooks/useGetIcon";
import useImportNodeWithInput from "@screens/workflow/studio/hooks/useImportNodeWithInput";
import useIsHiddenAfterTest from "@screens/workflow/studio/hooks/useIsHiddenAfterTest";
import useKeywordsFromWorkflowKeywords from "@screens/workflow/studio/hooks/useKeywordsFromWorkflowKeywords";
import useNodeSuggestionsProvider from "@screens/workflow/studio/hooks/useNodeSuggestionsProvider";
import { RuleId } from "@screens/workflow/types";
import { ArrowsUpDownIcon } from "@heroicons/react/16/solid";
import { TrashIcon } from "@heroicons/react/24/outline";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { generateAndDownloadFile } from "@utils/utils";
import { useWorkflowContext } from "../../../WorkflowContext";
import BottomPanel from "../../components/BottomPanel";
import HandleWithPlus from "../../components/HandleWithPlus";
import NodeMenu from "../../components/NodeMenu";
import { NodeName } from "../../components/NodeName";
import useBottomPanelState, { CLOSE } from "../../hooks/useBottomPanelState";
import useIsExpand, {
  useGetHandleStyles,
  useGetHandleStylesForInputDot,
  useGetNodeFontStyles,
  useGetNodeIconStyles,
  useGetNodeStyles,
} from "../../hooks/useIsExpand";
import { getNewNodeName, getUpdateRuleSetNode } from "../../utils";
import { analyticsInstance } from "src/config/event-analytics";
import { PolicyStudioActions } from "src/constants/EventAnalytics";

const handleToIcon = (str: string) => {
  switch (str) {
    case "pass":
      return (
        <ApprovedIcon
          className="stroke-neutral-400 fill-neutral-50 group-hover:stroke-success-500 group-hover:fi
ll-success-200 group-hover:[&>path]:stroke-success-100 group-hover:fill-success-100 w-4 h-4 duration-150"
        />
      );
    case "reject":
      return (
        <RejectIcon
          className="stroke-neutral-400 fill-neutral-50 group-hover:stroke-error-500 group-hover:fill-e
rror-200 group-hover:[&>path]:stroke-error-500 group-hover:fill-error-200 w-4 h-4 duration-150"
        />
      );
    case "cant_decide":
      return (
        <CantIcon className="stroke-neutral-400 fill-neutral-5 group-hover:stroke-warning-500 group-hover:fill-warning-100 group-hover:[&>path]:fill-warning-100 w-4 h-4" />
      );
  }
  return "label";
};

const RuleSetNode = ({
  id,
  selected,
  data,
}: NodeProps<{
  handles: string[];
  hidden?: boolean;
  isFromFlow?: boolean;
  label: string;
  desc: string;
}>) => {
  const {
    isWorkflowEditable,
    setShowDeleteConfirmation,
    workflow,
    updateWorkflow,
    errors,
  } = useWorkflowContext();
  const { getNodes } = useReactFlow();

  const [bottomPanelStatus, setBottomPanelStatus] = useBottomPanelState();

  const fontStyles = useGetNodeFontStyles();
  const iconStyles = useGetNodeIconStyles();
  const nodeStyles = useGetNodeStyles();
  const handleStyles = useGetHandleStyles();
  const isExpanded = useIsExpand();
  const queryClient = useQueryClient();

  const Icon = useGetIcon(RULE_SET_NODE_TYPE);

  const { getInputProps, importNode } = useImportNodeWithInput(
    id,
    RULE_SET_NODE_TYPE
  );
  const { duplicateNode } = useDuplicateNode(workflow?.id);

  const keywords = useKeywordsFromWorkflowKeywords(workflow?.id, id, data.label);

  const ruleSetConditionsQuery = useQuery(
    getRuleSetConditionsQuery(workflow?.id || "", id)
  );

  const hidden = useIsHiddenAfterTest(id, data?.hidden);

  const updateRuleSetNodeName = async (name: string) => {
    const e = getUpdateRuleSetNode(name, id, data.desc);
    await updateWorkflow(e).then(() => {
      queryClient.invalidateQueries(getWorkflowKeywordsQuery());
      queryClient.invalidateQueries(getErrors(workflow?.id));
    });
  };

  const provider = useNodeSuggestionsProvider(keywords, id);

  const updateRuleSetDesc = async (desc: string) => {
    const e = getUpdateRuleSetNode(data.label, id, desc);
    await updateWorkflow(e);
  };
  const handleStylesInputDot = useGetHandleStylesForInputDot();

  const exportNode = () => {
    analyticsInstance.triggerAnalytics(
      PolicyStudioActions.BLOCK_EXPORT,
      {
        name: data.label, 
        type: RULE_SET_NODE_TYPE,
      }
    );
    const fileContents = JSON.stringify({
      type: BE_RULE_SET_NODE_TYPE,
      feType: RULE_SET_NODE_TYPE,
      rulesets: ruleSetConditionsQuery.data?.data.data.rules?.map((item) => {
        return {
          name: item.description,
          approveCondition: item.approve,
          cantDecideCondition: item.cant_decide,
          seqNo: item.seq,
        };
      }),
    });
    generateAndDownloadFile(fileContents, `${data.label}.json`);
  };

  const rules = ruleSetConditionsQuery.data?.data.data.rules ?? [];

  const nodeErrors: [string, string][] = [];
  rules.forEach((item) => {
    if (errors[id as RuleId]?.errors?.[item.rule_id]?.approve_error)
      nodeErrors.push([
        item.description,
        errors[id as RuleId].errors[item.rule_id].approve_error,
      ]);
    if (errors[id as RuleId]?.errors?.[item.rule_id]?.cant_decide_error)
      nodeErrors.push([
        item.description + ": Can't Decide",
        errors[id as RuleId].errors[item.rule_id].cant_decide_error,
      ]);
  });

  return (
    <>
      <div
        onFocus={() => {
          provider();
        }}
        tabIndex={-1}
        className="flex relative cursor-grab active:cursor-grabbing group items-center"
      >
        <div
          style={nodeStyles}
          className={classNames(
            "overflow-visible w-[360px] h-max bg-white rounded-[10px] hover:border-primary-200 border p-[7px]",
            {
              "opacity-25": hidden,
              "border-primary-200": selected && !hidden,
              "border-neutral-100": !selected,
              "shadow-lg": data?.isFromFlow && !hidden,
              "shadow-studio-node": !(data?.isFromFlow && !hidden),
              "!border-error-500": !isExpanded && nodeErrors.length,
            }
          )}
        >
          <Handle
            type="target"
            position={Position.Left}
            className="w-2 h-2 top-0 mt-[20px] opacity-50 hover:opacity-100 border-2 rounded-full bg-neutral-0 border-neutral-300 !-left-4 duration-150 overflow-visible"
            isConnectable={isWorkflowEditable}
            style={handleStylesInputDot}
          >
            <div className="bg-transparent h-24 w-12 -translate-x-8 -translate-y-1/2" />
          </Handle>

          {!isExpanded && (
            <Handle
              type="source"
              position={Position.Right}
              className="!-right-3 top-1/2"
              isConnectable={false}
              style={handleStyles}
            />
          )}
          <div className="w-full text-neutral-black font-medium flex items-center justify-between">
            <div className="flex gap-1.5 items-center">
              <Icon style={iconStyles} />
              <span className="w-64 truncate" style={fontStyles}>
                <NodeName
                  onChange={(name) => updateRuleSetNodeName(name)}
                  defaultName={data.label || "RuleSet_Node"}
                />
              </span>
            </div>
            <TestButton nodeId={id} />
            <NodeMenu
              isExport={true}
              handleExportNode={exportNode}
              items={[
                {
                  key: "import",
                  label: (
                    <span className="text-neutral-black flex gap-2 items-center">
                      <ArrowsUpDownIcon className="text-neutral-black h-3 w-3 -mt-0.5" />
                      Import
                    </span>
                  ),
                  onClick: () => importNode(),
                },
                {
                  key: "duplicate",
                  label: (
                    <span className="text-neutral-black flex gap-2 items-center">
                      <DuplicateIcon className="text-neutral-black h-3 w-3 -mt-0.5" />
                      Duplicate
                    </span>
                  ),
                  onClick: () =>
                    duplicateNode({
                      type: BE_RULE_SET_NODE_TYPE,
                      name: getNewNodeName(RULE_SET_NODE_TYPE, getNodes()),
                      rulesets: rules.map((rule, index) => ({
                        name: rule.description,
                        approveCondition: rule.approve,
                        cantDecideCondition: rule.cant_decide,
                        seqNo: index,
                      })),
                    }),
                },
                // {
                //   key: "export",
                //   label: (
                //     <span className="text-neutral-black flex gap-2 items-center">
                //       <ArrowDownTrayIcon className="text-neutral-black h-3 w-3 -mt-0.5" />
                //       Export
                //     </span>
                //   ),
                //   onClick: exportNode,
                // },
                {
                  key: "delete",
                  label: (
                    <span className="text-error-500 flex gap-2 items-center">
                      <TrashIcon className="[&>path]:stroke-error-500 h-3 w-3 -mt-0.5" />
                      Delete
                    </span>
                  ),
                  onClick: () =>
                    setShowDeleteConfirmation({ id: id, label: data.label }),
                },
              ]}
              isFromFlow={!!data.isFromFlow}
            />
          </div>
          {isExpanded && (
            <div className="pb-6 pt-[1px]">
              <NodeDescription
                defaultDesc={data.desc}
                placeholder="Add description"
                onChange={(e) => updateRuleSetDesc(e)}
              />
              <RuleSetConditions
                rules={rules}
                workflowId={workflow?.id!}
                nodeId={id}
                isLoading={ruleSetConditionsQuery.isLoading}
              />
            </div>
          )}
        </div>
        {isExpanded && (
          <div className="absolute bottom-[1px] left-[1px] w-[358px] border-t bg-neutral-0 rounded-b-[10px] font-b2-medium border-neutral-100 px-2 py-1.5 flex gap-6 items-center">
            <span
              className="cursor-pointer text-neutral-500 hover:text-neutral-black"
              onClick={() => setBottomPanelStatus("Inputs")}
            >
              Inputs
            </span>
            {Object.keys(nodeErrors).length > 0 && (
              <span
                className="flex items-center ml-auto gap-1.5 cursor-pointer text-error-500 font-b2-medium"
                onClick={() => setBottomPanelStatus("Errors")}
              >
                <img src={alertCircle} alt="!" />
                {Object.keys(nodeErrors).length}{" "}
                {Object.keys(nodeErrors).length === 1 ? " Error" : " Errors"}
              </span>
            )}
          </div>
        )}
        {isExpanded && (
          <div className="flex flex-col gap-2 absolute top-0 -right-[24px]">
            {data.handles?.map((item) => (
              <HandleWithPlus
                key={item}
                nodeId={id}
                label={handleToIcon(item)}
                showAdd={false}
                position={Position.Right}
                id={item}
                type="source"
                isConnectable={isWorkflowEditable}
                noIcon={true}
              />
            ))}
          </div>
        )}
      </div>
      <input {...getInputProps()} />
      <BottomPanel selected={selected} show={bottomPanelStatus === "Inputs"}>
        <InputList
          close={() => setBottomPanelStatus(CLOSE)}
          keywords={keywords}
          query={""}
        />
      </BottomPanel>
      <BottomPanel selected={selected} show={bottomPanelStatus === "Errors"}>
        <ErrorList
          errors={nodeErrors}
          close={() => setBottomPanelStatus(CLOSE)}
        />
      </BottomPanel>
    </>
  );
};

export default memo(RuleSetNode);
