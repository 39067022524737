import { ReactComponent as ShareIcon } from "@assets/icons/share-04.svg";
import { ReactComponent as TrashIcon } from "@assets/icons/workflow/trash-02.svg";
import Autocomplete from "@components/Autocomplete";
import Button from "@components/Button";
import { Combobox } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/24/outline";
import usePolicyList from "@screens/policy-dashboard/usePolicyList";
import { POLICY_NODE_TYPE } from "@screens/workflow/config";
import {
  getErrors,
  getWorkflowKeywordsQuery,
  getWorkflowPredictors,
} from "@screens/workflow/queries";
import InputList from "@screens/workflow/studio/components/InputList";
import NodeMenu from "@screens/workflow/studio/components/NodeMenu";
import useBottomPanelState, {
  CLOSE,
} from "@screens/workflow/studio/hooks/useBottomPanelState";
import useGetIcon from "@screens/workflow/studio/hooks/useGetIcon";
import useKeywordsFromWorkflowKeywords from "@screens/workflow/studio/hooks/useKeywordsFromWorkflowKeywords";
import { useQueryClient } from "@tanstack/react-query";
import { default as classNames, default as clsx } from "clsx";
import { memo, useState } from "react";
import { Handle, NodeProps, Position } from "reactflow";
import { getKeywordsQuery } from "src/screens/create-policy/queries";
import { useWorkflowContext } from "../../../WorkflowContext";
import HandleWithPlus from "../../components/HandleWithPlus";
import StateConnector from "../../components/StateConnectors";
import useIsExpand, {
  useGetHandleStyles,
  useGetNodeFontStyles,
  useGetNodeIconStyles,
  useGetNodeStyles,
} from "../../hooks/useIsExpand";
import { getUpdatePolicyNode } from "../../utils";
import { analyticsInstance } from "src/config/event-analytics";
import { PolicyStudioActions } from "src/constants/EventAnalytics";

const PolicyNode = ({ data, id, selected }: NodeProps) => {
  const {
    updateWorkflow,
    isWorkflowEditable,
    setShowDeleteConfirmation,
    isShowingTestResult,
    runWorkflowMutation,
  } = useWorkflowContext();
  const [searchQuery, setSearchQuery] = useState("");
  const isExpanded = useIsExpand();
  const [bottomPanelStatus, setBottomPanelStatus] = useBottomPanelState();
  const { workflow } = useWorkflowContext();

  const queryClient = useQueryClient();

  const Icon = useGetIcon(POLICY_NODE_TYPE);

  const policyList = usePolicyList({
    policyStatus: ["active", "inactive", "readytodeploy"],
    page: 1,
    enabled: isWorkflowEditable,
    searchQuery,
  });


  const onUpdatePolicy = async (p: string) => {
    if(!data?.label){
      const policy = policyList.data?.data.data.policies.find(item => item.policyId===p)
      analyticsInstance.triggerAnalytics(
        PolicyStudioActions.POLICY,
        {
          policy_name: workflow?.policyName ?? '',
          version: workflow?.policyVersion ?? '',
          name: policy?.policyVersion ?? ""
        }
      );
    }
    const e = getUpdatePolicyNode(p, id);
    return await updateWorkflow(e).then(() => {
      queryClient.invalidateQueries(getWorkflowKeywordsQuery());
      queryClient.invalidateQueries(getWorkflowPredictors(workflow?.id));
      queryClient.invalidateQueries(getErrors(workflow?.id));
      queryClient.invalidateQueries(getKeywordsQuery());
    });
  };


  const fontStyles = useGetNodeFontStyles();
  const iconStyles = useGetNodeIconStyles();
  const handleStyles = useGetHandleStyles();
  const nodeStyles = useGetNodeStyles();

  const keywordsQuery = useKeywordsFromWorkflowKeywords(workflow?.id, id);

  const hidden =
    data?.hidden ??
    (isShowingTestResult &&
      !runWorkflowMutation.isPending &&
      !runWorkflowMutation.data?.data.data.testEval.data
        .map((i) => i.stateID)
        .includes(id));

  return (
    <>
      <div className="flex cursor-grab active:cursor-grabbing group items-center">
        <div
          style={nodeStyles}
          className={classNames(
            "overflow-visible w-[360px] h-max bg-white rounded-[10px] hover:border-primary-200 border pt-[7px]",
            isExpanded ? "" : "pb-[7px]",
            {
              "opacity-25": hidden,
              "border-primary-200": selected && !hidden,
              "border-neutral-100": !selected,
              "shadow-lg": data?.isFromFlow && !hidden,
              "shadow-studio-node": !(data?.isFromFlow && !hidden),
            }
          )}
        >
          <Handle
            type="target"
            position={Position.Left}
            className="w-2 h-2 top-0  mt-[20px] opacity-50 hover:opacity-100 border-2 rounded-full bg-neutral-0  border-neutral-300 !-left-4 duration-150 overflow-visible"
            isConnectable={isWorkflowEditable}
            style={handleStyles}
          >
            <div className="bg-transparent h-24 w-12 -translate-x-8 -translate-y-1/2" />
          </Handle>
          {!isExpanded && (
            <Handle
              type="source"
              position={Position.Right}
              className="w-2 h-2 opacity-50 hover:opacity-100 bg-white hover:bg-primary-50 group-hover:bg-primary-50 border-neutral-500 hover:h-3 hover:w-3 group-hover:h-3 group-hover:w-3 hover:border-primary-300 group-hover:border-primary-300 duration-150 rounded-full !-right-3 top-1/2"
              isConnectable={false}
              style={handleStyles}
            />
          )}

          <div className="w-full text-neutral-black font-medium flex items-center justify-between px-2">
            <div className="flex gap-2 items-center">
              <Icon style={iconStyles} />
              <span className="w-64 truncate" style={fontStyles}>
                {data.label || "Policy_Name"}
              </span>
            </div>
            <NodeMenu
              isExport={false}
              items={[
                {
                  key: "delete",
                  label: (
                    <span className="text-error-500 flex gap-2 items-center">
                      <TrashIcon className="[&>path]:stroke-error-500 h-3 w-3 -mt-0.5" />
                      Delete
                    </span>
                  ),
                  onClick: () =>
                    setShowDeleteConfirmation({ id: id, label: data.label }),
                },
              ]}
              isFromFlow={data.isFromFlow}
            />
          </div>
          {isExpanded && (
            <div className="relative px-2">
              <span className="font-b2 text-neutral-black mb-1">
                <span>Select Policy</span>
                {data.policyID && (
                  <Button
                    onClick={(e) => {
                      e.stopPropagation();
                      window.open(`/create-policy/${data.policyID}`, "_blank");
                    }}
                    variant="outline"
                    className="font-b2 !font-medium h-6 w-6 !p-0 group absolute z-10 bottom-1 right-8 justify-center"
                  >
                    <ShareIcon className="[&>path]:stroke-neutral-500 group-hover:[&>path]:stroke-neutral-500 w-3 h-3" />
                  </Button>
                )}
              </span>
              {isWorkflowEditable ? (
                <Autocomplete
                  disabled={!isWorkflowEditable}
                  onChange={(e) => onUpdatePolicy(e.policyId)}
                  selected={{
                    policyVersion: data.label,
                    policyId: data.policyID,
                  }}
                  displayKey="policyVersion"
                  onSearchChange={setSearchQuery}
                  disableShadow
                  className="nodrag"
                >
                  {policyList.data?.data.data.policies.map((policy) => (
                    <Combobox.Option
                      key={policy.policyId}
                      value={policy}
                      className={({ active }) =>
                        classNames(
                          "!w-[420px] relative cursor-pointer select-none py-2 px-3 text-neutral-black",
                          active ? "bg-neutral-50" : ""
                        )
                      }
                    >
                      {({ active, selected }) => (
                        <>
                          <span
                            className={classNames(
                              "block truncate",
                              selected && "font-semibold"
                            )}
                          >
                            <span className="text-neutral-500 uppercase mr-1.5">
                              {policy.program}
                            </span>
                            {policy.policyVersion}
                          </span>
                          {selected && (
                            <span
                              className={classNames(
                                "absolute inset-y-0 left-0 flex items-center pl-1.5",
                                active && "bg-neutral-25"
                              )}
                            >
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          )}
                        </>
                      )}
                    </Combobox.Option>
                  ))}
                </Autocomplete>
              ) : (
                <span className="pl-3 h-8 flex items-center rounded-md mt-2 w-full border border-neutral-100 font-b1-medium">
                  {data.label}
                </span>
              )}
            </div>
          )}
          {isExpanded && (
            <div className="border-t mt-3 bg-neutral-0 rounded-b-[10px] font-b2-medium border-neutral-100 px-2 py-1.5 flex gap-6 items-center">
              <span
                onClick={() => setBottomPanelStatus("Inputs")}
                className="cursor-pointer text-neutral-500 hover:text-neutral-black"
              >
                Inputs
              </span>
            </div>
          )}
        </div>

        {data?.policyID && isExpanded && (
          <div
            className={classNames("flex items-center ml-1 mt-3", {
              "opacity-25": hidden,
            })}
          >
            <StateConnector length={3} />
            <div className="flex justify-between gap-3 h-max flex-col">
              <HandleWithPlus
                nodeId={id}
                label="pass"
                showAdd={false}
                position={Position.Right}
                id="pass"
                type="source"
                isConnectable={isWorkflowEditable}
                handleStyles={handleStyles}
                className="!mt-0"
              />
              <HandleWithPlus
                nodeId={id}
                label="cant_decide"
                showAdd={false}
                position={Position.Right}
                id="cant_decide"
                type="source"
                isConnectable={isWorkflowEditable}
                handleStyles={handleStyles}
                className="!mt-0"
              />
              <HandleWithPlus
                nodeId={id}
                label="fail"
                showAdd={false}
                position={Position.Right}
                id="fail"
                type="source"
                isConnectable={isWorkflowEditable}
                handleStyles={handleStyles}
                className="!mt-0"
              />
            </div>
          </div>
        )}
        {/*<div*/}
        {/*  className="absolute -bottom-2 left-1/2"*/}
        {/*  id={"policyNodeEnd-" + data.policyID}*/}
        {/*/>*/}
      </div>
      {isExpanded && bottomPanelStatus === "Inputs" && (
        <div
          className={clsx(
            "px-3 bg-white overflow-y-scroll max-h-[288px] rounded-md mt-1 border border-neutral-100 shadow-studio-node pt-2 overflow-auto",
            selected && "border-primary-200"
          )}
          onClick={(e) => e.stopPropagation()}
        >
          <InputList
            close={() => setBottomPanelStatus(CLOSE)}
            keywords={keywordsQuery}
            query={""}
          />
        </div>
      )}
    </>
  );
};

export default memo(PolicyNode);
