import { Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Dispatch, SetStateAction } from "react";
import { useParams } from "react-router-dom";
import Shimmer from "src/components/Shimmer";
import { WfActivityLog } from "src/components/WfActivityLog";
import { useGetWorkflowAudit } from "../../queries";
import { WorkflowDetails } from "./components/WorkflowDetails";

export const WorkflowInfo = ({
  setIsWorkflowInfo,
}: {
  setIsWorkflowInfo: Dispatch<SetStateAction<boolean>>;
}) => {
  const { workflowId } = useParams();
  const {
    data: workflowInfo,
    isLoading,
    isFetching,
  } = useGetWorkflowAudit(workflowId);

  return (
    <Transition
      enter="hide-scrollbar transform transition-all ease-in-out duration-300 sm:duration-500"
      enterFrom="translate-x-full opacity-0"
      enterTo="translate-x-0 opacity-100"
      leave="hide-scrollbar transform transition-all ease-in-out duration-200 sm:duration-500"
      leaveFrom="translate-x-0 opacity-100"
      leaveTo="translate-x-full opacity-0"
      show
      appear
      as="div"
      className="absolute right-0 top-10 border-l bg-white shadow-lg"
    >
      <div className="w-[500px] h-[calc(100vh-40px)] flex flex-col">
        <p className="flex px-6 py-3 bg-neutral-0 border-b border-neutral-100 font-b1-medium items-center justify-between">
          <span>Workflow Details</span>
          <XMarkIcon
            className="h-4 w-4 cursor-pointer"
            onClick={() => {
              setIsWorkflowInfo(false);
            }}
          />
        </p>
        <div className="flex-1">
          {isLoading || isFetching || !workflowInfo ? (
            <div className="flex flex-col gap-2 p-4">
              <Shimmer w="100%" h="50px" />
              <Shimmer w="100%" h="50px" />
              <Shimmer w="100%" h="50px" />
            </div>
          ) : (
            <WorkflowDetails workflowInfo={workflowInfo?.workflow!} />
          )}
          <div className="border-t mx-6"></div>
          <div className="flex flex-col px-6 py-3 gap-4 overflow-y-auto">
            <p className="font-b1-medium">Activity Log</p>
            <div className="overflow-y-auto h-[calc(100vh-460px)]">
              {isLoading || isFetching || !workflowInfo ? (
                <div className="flex flex-col gap-2">
                  <Shimmer w="100%" h="50px" />
                  <Shimmer w="100%" h="50px" />
                  <Shimmer w="100%" h="50px" />
                </div>
              ) : workflowInfo && workflowInfo?.audit?.length > 0 ? (
                workflowInfo.audit?.map((log, idx) => {
                  return (
                    <WfActivityLog
                      key={log.id}
                      log={log}
                      isLast={idx === workflowInfo.audit.length - 1}
                    />
                  );
                })
              ) : (
                <span className="text-neutral-500 font-b1-medium">
                  No activity log
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </Transition>
  );
};
