import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ProtectedComponent from "src/components/ProtectedComponent";
import { PERMISSIONS_TYPE } from "src/constants/permissionsConstant";
import { PermissionTypes } from "src/types";
import InnerSidebar, { InnerSidebarItem } from "@components/InnerSidebar";
import { analyticsInstance } from "src/config/event-analytics";
import { ReportsActions, SimulationActions } from "src/constants/EventAnalytics";

const SIDEBAR_ITEM_LIST = {
  platformReports: [
    {
      title: "Outcomes",
      href: "/reports/outcomes",
      event:ReportsActions.OUTCOMES_OUTCOMES,
      permissionsTag: PERMISSIONS_TYPE.reportsOutcome,
    },
    {
      title: "Simulations",
      href: "/reports/simulations",
      event:SimulationActions.LIST,
      permissionsTag: PERMISSIONS_TYPE.simulations,
    },
  ],
  generatedReports: [
    {
      title: "All Reports",
      href: "/reports/generated",
      event:ReportsActions.ALL_REPORT,
      permissionsTag: PERMISSIONS_TYPE.reportsAll,
    },
  ],
};

const SIDEBAR_ITEMS = [
  {
    title: "Platform Reports",
    items: SIDEBAR_ITEM_LIST.platformReports,
  },
  {
    title: "Generated Reports",
    items: SIDEBAR_ITEM_LIST.generatedReports,
  },
];


export default function Sidebar() {
  const location = useLocation();
  const navigate = useNavigate();
  
  const handleClick = (e: React.MouseEvent<HTMLElement> ) => {
    const target = e.target as HTMLElement; 
    const sidebarItem = Object.values(SIDEBAR_ITEM_LIST).flat().find(item => item.title===target.innerText);
    if(sidebarItem){
      analyticsInstance.triggerAnalytics(sidebarItem?.event);
      navigate(sidebarItem.href)
    }
    e.stopPropagation();
    e.preventDefault();
  }

  return (
    <InnerSidebar
      title="Reports"
      content={
        <div className="px-6 py-4">
          {SIDEBAR_ITEMS.map((section) => (
            <div key={section.title} onClick={handleClick}>
              <span className="font-b2-medium text-neutral-500 inline-block mb-1">
                {section.title}
              </span>
              {section.items.map((item) => (
                <ProtectedComponent
                  type={item.permissionsTag as PermissionTypes}
                  action="view"
                >
                  <InnerSidebarItem
                    key={item.href}
                    selected={location.pathname === item.href}
                    content={item.title}
                  />
                </ProtectedComponent>
              ))}
            </div>
          ))}
        </div>
      }
    />
  );
}
