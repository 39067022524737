import { DATAFLOW_TRANSITION } from "src/constants/imageConstants";
import { ChangeLogState, ModelSetNode } from "../../types";
import { getChangeLogIcon, getStatusIndicator } from "../../utils";
import { ModelSetExpression } from "./ModelSetExpression";
import { ModelSetTable } from "./ModelSetTable";

export const ModelSetLogCard = ({
  prevData,
  currData,
  nodeState,
  currWFId,
  prevWFId,
}: {
  prevData: ModelSetNode | null;
  currData: ModelSetNode | null;
  nodeState: ChangeLogState;
  currWFId: string;
  prevWFId: string;
}) => {
  return (
    <>
      {nodeState === "add" &&
        currData &&
        currData?.data.expression.length > 0 &&
        currData.data.expression.map((node, idx) => (
          <ModelSetExpression
            key={idx}
            name={node?.name}
            prevExpression={null}
            currExpression={node?.expression}
            state={"add"}
          />
        ))}

      {nodeState === "delete" &&
        prevData &&
        prevData?.data.expression.length > 0 &&
        prevData.data.expression.map((node, idx) => (
          <ModelSetExpression
            key={idx}
            name={node?.name}
            prevExpression={node?.expression}
            currExpression={null}
            state={"delete"}
          />
        ))}

      {nodeState === "edit" &&
        ((prevData && prevData?.data.expression.length > 0) ||
          (currData && currData?.data.expression.length > 0)) &&
        currData?.data.expression.map((node, idx) => {
          const prevLog = prevData?.data.expression[idx];
          const isUpdated = prevLog?.name !== node?.name;

          return (
            <ModelSetExpression
              key={idx}
              name={node?.name ? node.name : prevLog?.name}
              prevExpression={prevLog?.expression}
              currExpression={node?.expression}
              isUpdated={isUpdated}
              prevName={prevLog?.name}
              state={node?.state ? node.state! : prevLog?.state!}
            />
          );
        })}

      {nodeState === "add" &&
        currData &&
        currData?.data.decisionTable.length > 0 &&
        currData.data.decisionTable.map((decision, idx) => (
          <ModelSetTable
            modelId={currData.id}
            key={idx}
            name={decision?.name}
            prevDecision={null}
            currDecision={decision}
            state={"add"}
            currWFId={currWFId}
            prevWFId={prevWFId}
            currNodeId={currData?.id ?? ""}
            prevNodeId={prevData?.id ?? ""}
          />
        ))}

      {nodeState === "delete" &&
        prevData &&
        prevData?.data.decisionTable.length > 0 &&
        prevData.data.decisionTable.map((decision, idx) => (
          <ModelSetTable
            modelId={prevData.id}
            key={idx}
            name={decision?.name}
            prevDecision={decision}
            currDecision={null}
            state={"delete"}
            currWFId={currWFId}
            prevWFId={prevWFId}
            currNodeId={currData?.id ?? ""}
            prevNodeId={prevData?.id ?? ""}
          />
        ))}

      {nodeState === "edit" &&
        ((prevData && prevData?.data.decisionTable.length > 0) ||
          (currData && currData?.data.decisionTable.length > 0)) &&
        currData?.data.decisionTable.map((decision, idx) => {
          const prevDecision = prevData?.data.decisionTable[idx];
          const isUpdated = prevDecision?.name !== decision?.name;

          return (
            <ModelSetTable
              modelId={currData.id}
              key={idx}
              name={decision?.name ? decision?.name : prevDecision?.name}
              prevDecision={prevDecision}
              currDecision={decision}
              isUpdated={isUpdated}
              prevName={prevDecision?.name}
              state={decision?.state ? decision.state! : prevDecision?.state!}
              currWFId={currWFId}
              prevWFId={prevWFId}
              currNodeId={currData?.id ?? ""}
              prevNodeId={prevData?.id ?? ""}
            />
          );
        })}
      {((prevData?.data?.transitions &&
        prevData?.data?.transitions.length > 0) ||
        (currData?.data?.transitions &&
          currData?.data?.transitions.length > 0)) && (
        <div>
          <div className="flex bg-neutral-25 border-y border-neutral-200 px-4 h-8 py-2 font-b2-medium text-neutral-black gap-2">
            <img
              src={DATAFLOW_TRANSITION}
              alt="dataflow_icon"
              className="w-4 h-4"
            />
            Transition
          </div>
          {nodeState === "add" &&
            currData?.data?.transitions &&
            currData?.data?.transitions.length > 0 && (
              <div className="flex">
                <div className="border-r w-[50%] flex flex-col px-4 py-3 gap-2 overflow-none">
                  <span className="flex font-b2 text-neutral-500 m-auto">
                    Transition not present
                  </span>
                </div>
                <div className="flex flex-col w-[50%] px-4 py-3 gap-2 left-0">
                  {currData.data.transitions.map((transition, idx) => (
                    <div className="flex flex-wrap gap-1.5" key={idx}>
                      <span className="flex text-neutral-500 gap-2 font-b2">
                        {getStatusIndicator(transition.state)} is connected to
                      </span>
                      <span className="flex font-b2-medium gap-1">
                        {getChangeLogIcon(transition.nodeType)}
                        {transition.name}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            )}

          {nodeState === "delete" &&
            prevData?.data.transitions &&
            prevData?.data.transitions.length > 0 && (
              <div className="flex">
                <div className="border-r w-[50%] flex flex-col px-4 py-3 gap-2 overflow-none">
                  {prevData.data.transitions.map((transition, idx) => (
                    <div className="flex flex-wrap gap-1.5" key={idx}>
                      <span className="flex text-neutral-500 gap-2 font-b2">
                        {getStatusIndicator(transition.state)} is connected to
                      </span>
                      <span className="flex font-b2-medium gap-1">
                        {getChangeLogIcon(transition.nodeType)}
                        {transition.name}
                      </span>
                    </div>
                  ))}
                </div>
                <div className="flex flex-col w-[50%] px-4 py-3 gap-2 left-0">
                  <span className="flex font-b2 text-neutral-500 m-auto">
                    Modelset is deleted
                  </span>
                </div>
              </div>
            )}

          {nodeState === "edit" &&
            currData?.data?.transitions &&
            currData?.data.transitions.map((transition, idx) => {
              const prevTransition = prevData?.data.transitions![idx];
              return (
                <div className="flex" key={idx}>
                  <div className="border-r w-[50%] flex flex-col px-4 py-3 gap-2 overflow-none">
                    <div className="flex gap-2 py-1">
                      {prevTransition ? (
                        <div className="flex flex-wrap gap-1.5">
                          <span className="flex text-neutral-500 gap-2 font-b2">
                            {getStatusIndicator(prevTransition.state)} is
                            connected to
                          </span>
                          <span className="flex font-b2-medium gap-1">
                            {getChangeLogIcon(prevTransition.nodeType)}
                            {prevTransition.name}
                          </span>
                        </div>
                      ) : (
                        <span className="m-auto text-neutral-500 pl-2">
                          Transition not present
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="flex flex-col w-[50%] px-4 py-3 gap-2 left-0">
                    <div className="flex gap-2 py-1">
                      {transition ? (
                        <div className="flex flex-wrap gap-1.5">
                          <span className="flex text-neutral-500 gap-2 font-b2">
                            {getStatusIndicator(transition.state)} is connected
                            to
                          </span>
                          <span className="flex font-b2-medium gap-1">
                            {getChangeLogIcon(transition.nodeType)}
                            {transition.name}
                          </span>
                        </div>
                      ) : (
                        <span className="m-auto text-neutral-500 pl-2">
                          Transition not present
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      )}
    </>
  );
};
