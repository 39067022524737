import sentinelLogo from "@assets/sentinel-logo.svg";
import { SideBarTopOptions } from "@config";
import { useAppState } from "@context/AppProvider";
import classNames from "clsx";
import { Fragment, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import usePermissions from "src/context/PermissionContext";
import { PermissionTypes } from "src/types";
import NavLink from "./NavLinkSidebar";



const Sidebar = () => {
  const [expanded, setExpanded] = useState(false);
  const timeout = useRef<ReturnType<typeof setTimeout>>();
  const navigate = useNavigate();
  const appState = useAppState();
  const { getSidebarPermissions } = usePermissions();
  return (
    <div
      onMouseEnter={() => {
        timeout.current = setTimeout(() => setExpanded(true), 300);
      }}
      onMouseLeave={() => {
        clearTimeout(timeout.current);
        setExpanded(false);
      }}
      style={{
        boxShadow: expanded
          ? "0px 0px 4px -2px rgba(16, 24, 41, 0.04), 0px 4px 8px -2px rgba(134, 134, 134, 0.10)"
          : "none",
      }}
      className={classNames(
        "fixed top-0 bottom-0 z-[102] bg-white flex min-h-0 w-max flex-col border-r border-neutral-100 shrink-0 grow-0 0px 0px 4px -2px",
        { "w-12": !expanded },
        {
          "w-[216px]": expanded,
        }
      )}
    >
      <div
        className={classNames(
          "flex flex-col bg-transparent pt-3 duration-150 h-full",
          { "w-12": !expanded },
          { "w-[216px]": expanded }
        )}
        style={{
          boxShadow: expanded
            ? "0px 0px 4px -2px rgba(16, 24, 41, 0.04), 0px 4px 8px -2px rgba(134, 134, 134, 0.10)"
            : "none",
        }}
      >
        <div className="flex max-w-xs flex-col">
          <nav className="flex flex-col gap-5" aria-label="Sidebar">
            <div className="flex gap-0 items-center pt-1 pb-1">
              {appState.appState.logoURL ? (
                <img
                  alt="FinBox"
                  src={appState.appState.logoURL}
                  onClick={() => navigate("/")}
                  className="h-5 w-5 ml-[14px] mx-4 cursor-pointer"
                />
              ) : (
                <img
                  alt="FinBox"
                  src={sentinelLogo}
                  onClick={() => navigate("/")}
                  className="h-6 w-6 ml-3 mr-2 cursor-pointer"
                />
              )}
              {expanded && (
                <span className="truncate font-medium text-[14px]">
                  {appState.appState.orgName}
                </span>
              )}
            </div>
            {SideBarTopOptions.filter((item) =>
                getSidebarPermissions(
                  item?.permissionsTag as PermissionTypes | PermissionTypes[]
                )
              ).map((item, index) => {
              return (
                <Fragment key={index}>
                  <NavLink
                    key={item.name}
                    item={item}
                    isExpanded={expanded}
                  />
                  {item.bottomBorder &&<span
                    className={classNames(
                      "h-[1px] last:hidden duration-150 inline-block border-t border-[#d9d9d9] mx-2",
                      expanded ? "w-[200px]" : "w-8"
                    )}
                  />}
                </Fragment>
              );
            })}
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
