import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Modal from "src/components/Dialogue";
import Loader from "src/components/Loader";
import { getConfigureWorkflowKeywordsQuery } from "src/screens/workflow/queries";
import { ReactComponent as FileIcon } from "@assets/icons/file-x-03.svg";
import Button from "@components/Button";
import { useQuery } from "@tanstack/react-query";
import FilterSection from "./components/FilterSection";
import {
  getInputPolicyMappingQuery,
  useCreateInputPolicyMapping,
  useDeleteInputPolicyMapping,
  useUpdateInputPolicyMapping,
} from "./queries";
import { getPredictorsName } from "./utils";
import { notify } from "src/utils/utils";
import { Index_Regex } from "src/constants/appLevel";

type Mapping = {
  childPredictors: string;
  parentPredictors: string;
  parentInputs: string;
  policyInputId: string;
  predictorsType: number;
};

type FormInput = {
  mapping: Mapping[];
};

type ConfigureModalProps = {
  childwfName: string;
  parentWfId: string;
  childWfId: string;
  wfNodeId: string;
  setConfigureModal: Dispatch<SetStateAction<any>>;
  isWorkflowEditable: boolean;
};

const ConfigureWorkflowModal = ({
  childwfName,
  parentWfId,
  wfNodeId,
  childWfId,
  setConfigureModal,
  isWorkflowEditable,
}: ConfigureModalProps) => {
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    getValues,
    formState: { errors },
    clearErrors,
  } = useForm<FormInput>({
    defaultValues: {
      mapping: [
        {
          childPredictors: "",
          parentPredictors: "",
          parentInputs: "",
          policyInputId: "",
          predictorsType: 0,
        },
      ],
    },
  });

  const [deletedPolicyInputIds, setDeletedPolicyInputIds] = useState<string[]>(
    []
  );
  const [isUpdate, setIsUpdate] = useState(false);

  const watchFilters = watch("mapping");

  const {
    data: mappingList,
    isSuccess,
    isPending,
  } = useQuery(getInputPolicyMappingQuery(parentWfId, wfNodeId, childWfId));
  const { data: workflowKeywords } = useQuery(
    getConfigureWorkflowKeywordsQuery(parentWfId)
  );

  const createMapping = useCreateInputPolicyMapping();
  const updateMapping = useUpdateInputPolicyMapping();
  const deleteMapping = useDeleteInputPolicyMapping();

  useEffect(() => {
    if (isSuccess && mappingList && mappingList.length > 0) {
      const mappedValues = mappingList
        .filter((mapping) => mapping.isMapped)
        .map((mapping) => {
          const { source, value, name, policyInputId, type } = mapping;
          return {
            childPredictors: name,
            parentPredictors: source,
            parentInputs: value,
            policyInputId: policyInputId,
            predictorsType: type,
          };
        });

      if (mappedValues.length > 0) {
        setIsUpdate(true);
        setValue("mapping", mappedValues);
      } else {
        setIsUpdate(false);
        setValue("mapping", []);
      }
    }
  }, [mappingList, setValue, isSuccess]);

  const handleDeleteFilterSection = (indexToDelete: number) => {
    const currentFilters = getValues("mapping");
    const updatedFilters = currentFilters.filter(
      (_, index) => index !== indexToDelete
    );
    const deletedPolicyInputId = currentFilters[indexToDelete]?.policyInputId;

    if (deletedPolicyInputId) {
      setDeletedPolicyInputIds((prev) => [...prev, deletedPolicyInputId]);
    }
    setValue("mapping", updatedFilters);
  };

  const handleAddAccess = () => {
    const currentFilters = getValues("mapping");
    const newIndex = currentFilters.length;

    setValue("mapping", [
      ...currentFilters,
      {
        childPredictors: "",
        parentPredictors: "",
        parentInputs: "",
        policyInputId: "",
        predictorsType: 0,
      },
    ]);

    setTimeout(() => {
      const newSection = document.getElementById(`filter-section-${newIndex}`);
      if (newSection) {
        newSection.scrollIntoView({ behavior: "smooth" });
      }
    }, 0);
  };

  const submit = handleSubmit((data) => {
    const { mapping } = data;
    const convertedArray = mapping.map(
      ({ parentPredictors, parentInputs, policyInputId, predictorsType }) => {
        const predName = getPredictorsName(predictorsType);
        const id =
          // @ts-ignore
          workflowKeywords?.predictors?.[predName]?.[parentPredictors]?.id || 0;

        return {
          policyInputId: policyInputId,
          source: parentPredictors,
          value: parentInputs,
          type: predictorsType,
          id,
        };
      }
    );

    const isIndexMissed = convertedArray.some((header) => Index_Regex.test(header.value));
    if(isIndexMissed){
      notify({ title: "Failed", text: 'Please Specify index' });
      return;
    }

    if (deletedPolicyInputIds.length > 0) {
      deleteMapping.mutate({
        workflowId: parentWfId,
        wfNodeId: wfNodeId,
        policyInputId: deletedPolicyInputIds,
      });
    }

    if (convertedArray.length > 0) {
      if (isUpdate) {
        updateMapping.mutate({
          workflowId: parentWfId,
          wfNodeId: wfNodeId,
          childWfId: childWfId,
          mapping: convertedArray,
        });
      } else {
        createMapping.mutate({
          workflowId: parentWfId,
          wfNodeId: wfNodeId,
          childWfId: childWfId,
          mapping: convertedArray,
        });
      }
    }
  });

  const usedChildPredictors = getValues("mapping")
    .map((m) => m.childPredictors)
    .filter((item) => item !== "");

  const filteredChildSourceList =
    mappingList?.filter((item) => !usedChildPredictors.includes(item.name)) ||
    [];

  return (
    <Modal open={true} onClose={() => setConfigureModal(false)}>
      <Modal.Panel size="lg" onClose={() => setConfigureModal(false)}>
        <Modal.Header>Configure Input Parameters</Modal.Header>
        <Modal.Body>
          <form onSubmit={submit} className="flex flex-col gap-3">
            {childWfId && isPending && <Loader className="mx-[45%] mt-6" />}
            {isSuccess && mappingList && mappingList?.length > 0 ? (
              <>
                <p className="font-b2 text-neutral-500">
                  Map your{" "}
                  <span className="font-b2-medium text-neutral-black">
                    {childwfName}
                  </span>{" "}
                  Input variables
                </p>
                {watchFilters.length > 0 &&
                  watchFilters.map((_, arrayIndex) => (
                    <div key={arrayIndex} className="flex flex-col gap-2">
                      {isSuccess && (
                        <FilterSection
                          getValues={getValues}
                          errors={errors}
                          clearErrors={clearErrors}
                          index={arrayIndex}
                          control={control}
                          onDelete={handleDeleteFilterSection}
                          childSourceList={filteredChildSourceList.map(
                            ({ name, policyInputId }) => ({
                              name,
                              policyInputId,
                            })
                          )}
                          setValue={setValue}
                          parentWfId={parentWfId}
                          wfNodeId={wfNodeId}
                          isWorkflowEditable={!isWorkflowEditable}
                        />
                      )}
                    </div>
                  ))}

                <div>
                  <button
                    className="font-b2-medium text-neutral-black hover:text-primary-900 disabled:text-neutral-500"
                    type="button"
                    disabled={
                      createMapping.isPending ||
                      updateMapping.isPending ||
                      deleteMapping.isPending ||
                      filteredChildSourceList.length === 0 ||
                      !isWorkflowEditable
                    }
                    onClick={() => handleAddAccess()}
                  >
                    + Add Input
                  </button>
                </div>
              </>
            ) : (
              !isPending && (
                <div className="flex flex-col m-auto mt-8 items-center justify-center gap-4">
                  <FileIcon className="w-10 h-10" />
                  <span className="font-b2 text-neutral-500">
                    It looks like there are no input parameters to map
                  </span>
                </div>
              )
            )}
          </form>
        </Modal.Body>
        {mappingList && mappingList?.length > 0 && (
          <Modal.Footer className="gap-2">
            <Button variant="outline" onClick={() => setConfigureModal(false)}>
              Cancel
            </Button>
            <Button
              onClick={submit}
              disabled={
                createMapping.isPending ||
                updateMapping.isPending ||
                deleteMapping.isPending ||
                isPending ||
                !(mappingList && mappingList?.length > 0) ||
                !isWorkflowEditable
              }
            >
              Save Changes
            </Button>
          </Modal.Footer>
        )}
      </Modal.Panel>
    </Modal>
  );
};

export default ConfigureWorkflowModal;
