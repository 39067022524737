import finboxLogo from "@assets/finboxLogo.svg";
import chevronDown from "@assets/icons/chevron-down.svg";
import chevronRight from "@assets/icons/chevron-right.svg";
import helpIcon from "@assets/icons/help-circle.svg";
import logoutIcon from "@assets/icons/log-out-01.svg";
import settingsIcon from "@assets/icons/settings-02.svg";
import { useAppState } from "@context/AppProvider";
import { useBreadcrumbsContext } from "@context/BreadcrumbsContext";
import { Menu, Transition } from "@headlessui/react";
import { classNames, getInitial } from "@utils/utils";
import React, { Fragment } from "react";
import posthog from 'posthog-js';
import { useLocation, useNavigate } from "react-router-dom";
import { useLogout } from "src/apis";
import usePermissions from "src/context/PermissionContext";
import Button from "../Button";
import NotificationCentre from "./notificationCentre";
import { APP_URL } from "src/constants/app-url";
import { analyticsInstance } from "src/config/event-analytics";
import { ToolbarActions } from "src/constants/EventAnalytics";

export default function Topbar() {
  const { breadcrumbs } = useBreadcrumbsContext();
  const appState = useAppState();
  const navigate = useNavigate();
  const location = useLocation();

  const name = window.localStorage.getItem("name") || "";
  const logout = useLogout();
  const { userRole } = usePermissions();

  const isFullWidthTopBar =
    ["policies", "details", "decision"].some((item) =>
      location.pathname.includes(item)
    ) ||
    ["", "/"].includes(location.pathname) ||
    location.pathname.match("/endpoints/.*/.*") ||
    location.pathname.match("/simulation/.*") ||
    location.pathname.match("/outcome/.*") ||
    location.pathname.match("/settings/roles/update");

  let widthClassName = "w-[calc(100vw-48px)]";
  if (!isFullWidthTopBar)
    widthClassName =
      window.innerWidth < 1350
        ? "w-[calc(100vw-303px)]"
        : "w-[calc(100vw-319px)]";

  const handleHomeRedirect = (e: React.MouseEvent<HTMLAnchorElement>) => {
    analyticsInstance.triggerAnalytics(ToolbarActions.HOME);
    navigate(APP_URL.home);
  }

  const openHelpDoc = () => {
    analyticsInstance.triggerAnalytics(ToolbarActions.HELP_AND_DOCS);
    window.open(APP_URL.helpDocs, "_blank");
  }

  const openProfile = () => {
    analyticsInstance.triggerAnalytics(ToolbarActions.PROFILE);
  }
  
  const navigateSetting = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    analyticsInstance.triggerAnalytics(ToolbarActions.PROFILE_SETTINGS);
    navigate("/settings")
  }

  const logoutUser = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    analyticsInstance.triggerAnalytics(ToolbarActions.PROFILE_SIGNOUT);
    logout.mutate();
    posthog.reset(true);
  }

  return (
    <nav
      className={classNames(
        "px-8 py-3 h-12 bg-white flex border-b border-l border-neutral-100 fixed top-0 right-0 z-[100] items-center",
        widthClassName
      )}
    >
      <div className="flex gap-2 font-b1">
        <a onClick={handleHomeRedirect} href="/" className="text-neutral-600">
          {appState.appState.name}
        </a>
        {breadcrumbs
          .filter((p) => p.name)
          .map((p) => {
            return (
              <React.Fragment key={p.name}>
                <img alt="" src={chevronRight} className="stroke-neutral-500" />
                <span
                  className="text-neutral-black font-b1-medium cursor-pointer"
                  onClick={() => navigate(p.link)}
                >
                  {p.name}
                </span>
              </React.Fragment>
            );
          })}
      </div>

      <div className="ml-auto" />
      <Button
        variant="outline"
        className="mr-4 px-2.5"
        onClick={openHelpDoc}
      >
        <div
          className={classNames(
            "rounded-md flex items-center",
            location.pathname.startsWith("/docs") && "bg-neutral-50"
          )}
        >
          <img alt="" src={helpIcon} className="w-4 h-4" />
          <p className="ml-2">Help & Docs</p>
        </div>
      </Button>
      <NotificationCentre />
      <Menu as="div" className="relative flex">
        <Menu.Button onClick={openProfile} data-test-id="clientLogo" className="flex items-center">
          <span className="w-7 h-7 mr-2 rounded-full flex items-center justify-center font-b2 bg-neutral-900 text-white">
            {getInitial(name)}
          </span>
          <p className="mr-2 font-b1-medium text-neutral-black">{name}</p>
          <img className='"w-3 h-3' alt="" src={chevronDown} />
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-200"
          enterFrom="transform opacity-0 scale-100"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="rounded-md absolute z-10 flex w-[168px] flex-col bg-white text-neutral-black focus:outline-none top-full right-0 translate-y-1.5 border border-neutral-100 shadow-1">
            <Menu.Item>
              <div className="w-full px-3 py-2 flex gap-2 items-flex-start bg-neutral-0 border-b border-neutral-100 rounded-t-md  font-b2-medium">
                <span className="rounded-full w-1/4 h-8 max-w-10 flex items-center justify-center bg-neutral-800 text-white">
                  {getInitial(name)}
                </span>
                <div className="flex w-3/4 text-left flex-col font-b1-medium">
                  <span className="flex break-all">{name}</span>
                  <span className="text-neutral-500">{userRole}</span>
                </div>
              </div>
            </Menu.Item>

            <Menu.Item>
              <span
                onClick={navigateSetting}
                className="flex gap-2 justify-start px-3 py-2 items-center font-b2-medium cursor-pointer hover:bg-neutral-0"
              >
                <img alt="" src={settingsIcon} />
                Settings
              </span>
            </Menu.Item>

            <Menu.Item>
              <div
                onClick={logoutUser}
                className="flex cursor-pointer items-center justify-start gap-2 font-b2-medium px-3 py-2 hover:bg-neutral-0"
              >
                <img src={logoutIcon} alt="" />
                Sign Out
              </div>
            </Menu.Item>
            <Menu.Item>
              <div className="px-3 py-2.5 flex gap-1 items-center justify-between bg-neutral-0 border-t border-neutral-100 rounded-b-md text-neutral-500 font-b2">
                powered by <img alt="" src={finboxLogo} />
              </div>
            </Menu.Item>
          </Menu.Items>
        </Transition>
      </Menu>
    </nav>
  );
}
