import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import Loader from "src/components/Loader";
import { getIsChangeLogGenerated } from "../../queries";
import { WfChangeLogModal } from "./components/WfChangeLogModal";
import { WfChangeLogSidePanel } from "./components/WfChangeLogSidePanel";

const WfChangeLog = ({
  setIsChangeLog,
  bucketId,
  toWfId,
  toWfName,
}: {
  setIsChangeLog: React.Dispatch<React.SetStateAction<boolean>>;
  bucketId: string;
  toWfId: string;
  toWfName: string;
}) => {
  const [fromVersion, setFromVersion] = useState<string | null>(null);

  const {
    data: logGenerateId,
    isLoading,
    isSuccess,
  } = useQuery(getIsChangeLogGenerated(bucketId, toWfId));

  useEffect(() => {
    if (isSuccess) {
      setFromVersion(logGenerateId || "");
    }
  }, [isSuccess, logGenerateId]);

  if (isLoading || fromVersion === null) {
    return <Loader size="xs" />;
  }

  if (fromVersion.length === 0) {
    return (
      <WfChangeLogModal
        setIsChangeLog={setIsChangeLog}
        bucketId={bucketId}
        toWfId={toWfId}
        toWfName={toWfName}
        setFromVersion={setFromVersion}
      />
    );
  }

  return (
    <WfChangeLogSidePanel
      setIsChangeLog={setIsChangeLog}
      bucketId={bucketId}
      fromWfId={fromVersion}
      toWfId={toWfId}
      setFromVersion={setFromVersion}
    />
  );
};

export default WfChangeLog;
