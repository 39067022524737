import { PAGES } from "@config";
import { XCircleIcon } from "@heroicons/react/20/solid";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import { captureException } from "@sentry/react";
import logger from "@utils/Logger";
import { AxiosError } from "axios";
import { isSameDay, parse } from "date-fns";
import { toast } from "react-hot-toast";
import CustomToast from "../components/CustomToast";
import { request } from "./request";

export const formatNumber = (
  amount: number,
  decimalPlaces?: number
): string => {
  if (!amount && amount !== 0) return "";
  let x = amount.toString();
  let afterPoint = "";
  if (x.indexOf(".") > 0) afterPoint = x.substring(x.indexOf("."), x.length);
  if (decimalPlaces) afterPoint = afterPoint.substring(0, decimalPlaces + 1);
  const k = Math.floor(Number(x));
  x = k.toString();
  let lastThree = x.substring(x.length - 3);
  let otherNumbers = x.substring(0, x.length - 3);
  if (otherNumbers !== "") lastThree = "," + lastThree;
  return (
    otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree + afterPoint
  );
};

export const notify = ({
  title,
  text,
  type = "error",
}: {
  title: string;
  text: string;
  type?: "error" | "success";
}) => {
  toast.custom(
    (t) => {
      return <CustomToast t={t} title={title} text={text} type={type} />;
    },
    {
      position: "top-right",
      duration: 3000,
      icon:
        type === "error" ? (
          <XCircleIcon />
        ) : (
          <CheckCircleIcon
            className="h-6 w-6 text-green-400"
            aria-hidden="true"
          />
        ),
    }
  );
};

export const debounce = <TFn extends (...args: any) => any>(
  func: TFn,
  timeout: number = 500
) => {
  let timer: ReturnType<typeof setTimeout> | null;
  return function (this: any, ...args: Parameters<TFn>) {
    const context = this;
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      return func.apply(context, args);
    }, timeout);
  };
};

export const getInitial = (name: string) => {
  if (!name) return "";
  const splitName = name
    .trim()
    .split(" ")
    .filter((s) => s.length);
  if (splitName.length === 1) {
    return splitName[0].substring(0, 2).toUpperCase();
  }
  if (splitName.length > 1) {
    return [splitName[0], splitName[1]]
      .map((n) => n[0])
      .join("")
      .toUpperCase();
  }
  return "";
};

export function downloadLink(url?: string, fileName?: string) {
  if (!url) return;
  const a = document.createElement("a");
  a.href = url;
  // This does not work for cross origin urls >:(
  a.download = fileName || url.split("/").pop()!;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

export const downloadPolicy = async (policyId?: string) => {
  if (!policyId) return;
  try {
    const { data } = await request<{ url: string }>(
      "downloadPolicy?policy_id=" + policyId,
      {
        method: "get",
        headers: {
          "content-type": "application/json",
          page: PAGES.POLICY_LIST,
        },
      }
    );
    downloadLink(data?.url);
  } catch (error) {
    notify({
      title: "Failed to download",
      text: "Could not update the role",
    });
  }
};

export const shortNumber = (number: number) => {
  const posNum = number < 0 ? -number : number;
  if (!posNum) return "0";
  return new Intl.NumberFormat(getLocale(), { notation: "compact" }).format(
    number
  );
};

export function getLocale() {
  return navigator.languages && navigator.languages.length
    ? navigator.languages[0]
    : navigator.language ?? "en-IN";
}

export const titleCase = (str: string): string => {
  if (!str) return str;
  return str
    .trim()
    .split(" ")
    .map((s) => s[0]?.toUpperCase() + s?.substring(1)?.toLowerCase())
    .join(" ");
};

export const getPercentage = (
  numerator: number,
  denominator: number,
  decimals = 2
): string => {
  if (denominator === 0) {
    return "-";
  }
  const fraction = (numerator / denominator) * 100;
  if (isNaN(fraction)) return "-";
  return fraction.toFixed(decimals) + "%";
};

export const getGQStartTime = (
  date: string | null | Date,
  dateFormat: string = "yyyy-MM-dd"
) => {
  if (!date) return;
  let startDate: Date;
  if (date instanceof Date) startDate = date;
  else startDate = parse(date, dateFormat, new Date());
  return startDate.toISOString();
};

export const getGQEndTime = (
  date: string | null | Date,
  dateFormat: string = "yyyy-MM-dd"
) => {
  if (!date) return;
  let endDate: Date;
  if (date instanceof Date) endDate = date;
  else endDate = parse(date, dateFormat, new Date());
  const currentDate = new Date();
  if (!isSameDay(endDate, new Date())) endDate.setHours(23, 59, 59, 999);
  else
    endDate.setHours(
      currentDate.getHours(),
      currentDate.getMinutes(),
      currentDate.getSeconds(),
      currentDate.getMilliseconds()
    );
  return endDate.toISOString();
};

export const copyToClipboard = (
  text: string,
  cb?: (success: boolean) => void
) => {
  if (!navigator.clipboard) return cb && cb(false);
  try {
    navigator.clipboard.writeText(text);
    cb && cb(true);
  } catch (e) {
    cb && cb(false);
  }
};

export const reportToSentry = (error: unknown) => {
  if (["PROD", "UAT"].includes(import.meta.env.REACT_APP_API_ENV)) {
    captureException(error);
  } else {
    logger.error(error);
  }
};

export const assertNever = (x: never) => {
  throw new Error("This should never happen");
};

export const toSentenceCase = (str: string) => {
  return str.toLowerCase().replace(/(^\s*\w|[.!?]\s*\w)/g, function (c) {
    return c.toUpperCase();
  });
};

export const getNetworkErrorText = (err: unknown): string => {
  if (err instanceof AxiosError)
    return err?.response?.data?.error || err?.message || "Something went wrong";
  else if (err instanceof Error) return err.message;
  return "Something went wrong";
};

export const getInnerSidebarWidth = () => {
  if (window.innerWidth < 1350) return "256px";
  else return "272px";
};

export const normalizeQueryKey = (
  arr: (string | boolean | Record<string, any> | number | undefined | null)[]
): NonNullable<(typeof arr)[number]>[] => {
  return arr.filter((e) => e !== undefined && e !== null) as NonNullable<
    (typeof arr)[number]
  >[];
};

export const generateAndDownloadFile = (
  fileContents: string,
  fileName: string
) => {
  const element = document.createElement("a");
  element.setAttribute(
    "href",
    "data:text/plain;charset=utf-8," + encodeURIComponent(fileContents)
  );
  element.setAttribute("download", fileName);

  element.style.display = "none";
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
};

export function highlightAfterLastSlash(str: string) {
  const parts = str.split("/");
  const lastPart = parts[parts.length - 1];
  const index = str.lastIndexOf(lastPart);

  return (
    <>
      <span className="text-neutral-600">{str.substring(0, index)}</span>
      <span>{lastPart}</span>
    </>
  );
}

type Predicate<T> = (item: T, lowercaseQuery: string) => number;

export function weightedSearch<T>(
  items: T[],
  query: string,
  predicate: Predicate<T>
): T[] {
  if (items.length === 0) return items;

  const lowercaseQuery = query.toLowerCase();

  return items
    .map((item) => {
      return { item, score: predicate(item, lowercaseQuery) };
    })
    .sort((a, b) => b.score - a.score)
    .filter((result) => result.score > 0)
    .map((i) => i.item);
}

export function readFile(file: File) {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      const result = reader.result as string;
      if (!result) return reject(new Error("Empty file"));
      resolve(result);
    });

    reader.readAsText(file);
  });
}

export function generateUUID() {
  var d = new Date().getTime();
  var d2 =
    (typeof performance !== "undefined" &&
      performance.now &&
      performance.now() * 1000) ||
    0;
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = Math.random() * 16;
    if (d > 0) {
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
}

export function truncateString(
  str: string,
  maxLength: number = 100,
  useEllipsis: boolean = true
): string {
  if (!str) {
    return "";
  }

  // If the string is shorter than or equal to maxLength, return it as is
  if (str.length <= maxLength) {
    return str;
  }

  // Calculate the length of the truncated string, accounting for ellipsis if needed
  const truncatedLength = useEllipsis ? maxLength - 3 : maxLength;

  // Ensure the truncated length is not negative
  const finalLength = Math.max(truncatedLength, 0);

  // Truncate the string and add ellipsis if needed
  return str.slice(0, finalLength) + (useEllipsis ? "..." : "");
}

export function classNames(...args: any[]): string {
  return args
    .flatMap((arg) => {
      if (typeof arg === "string" || typeof arg === "number") {
        return arg; // Directly include strings and numbers
      }
      if (Array.isArray(arg)) {
        return classNames(...arg); // Recursively flatten arrays
      }
      if (typeof arg === "object" && arg !== null) {
        // Include keys of objects where the value is truthy
        return Object.keys(arg).filter((key) => arg[key]);
      }
      return []; // Ignore falsy values
    })
    .filter(Boolean) // Filter out falsy values
    .join(" ");
}

// Define categories based on common file types
const categories: Record<string, string[]> = {
  documents: ["pdf", "doc", "docx", "txt", "xls", "xlsx", "ppt", "pptx"],
  spreadsheets: ["xls", "xlsx", "csv"],
  images: ["jpg", "jpeg", "png", "gif", "bmp", "svg", "tiff"],
  videos: ["mp4", "avi", "mov", "mkv", "flv", "wmv"],
  audio: ["mp3", "wav", "ogg", "flac"],
  archives: ["zip", "rar", "7z", "tar", "gz"],
  code: ["html", "css", "js", "ts", "json", "xml", "py", "java"],
  other: [],
};

export function getFileExtension(fileName: string) {
  const parts = fileName.split(".");
  if (parts?.length > 1) {
    return parts?.pop()?.toLowerCase() ?? "";
  } else {
    return "";
  }
}

export function getFileType(fileName: string) {
  const extension = getFileExtension(fileName);

  // Find the category of the file type
  for (const [category, extensions] of Object.entries(categories)) {
    if (extensions.includes(extension)) {
      return category;
    }
  }
  
  return 'other'; // Return 'other' for uncategorized file types
}

export const isEqual =(value: any, other: any): boolean => {
    if (value === other) return true;

    if (typeof value !== typeof other) return false;

    if (value === null || other === null || typeof value !== 'object') {
        return false;
    }

    if (Array.isArray(value) && Array.isArray(other)) {
        if (value.length !== other.length) return false;
        return value.every((item, index) => isEqual(item, other[index]));
    }

    if (typeof value === 'object' && typeof other === 'object') {
        const valueKeys = Object.keys(value);
        const otherKeys = Object.keys(other);

        if (valueKeys.length !== otherKeys.length) return false;

        return valueKeys.every(key => isEqual(value[key], other[key]));
    }

    return false;
}

export const containsSpecialChars = (str:string):boolean => {
  const specialCharsPattern = /^[0-9]|[!@#$%^&*(),.?":{}|<>\s\\-]/;
  return specialCharsPattern.test(str);
}


export enum ReplacedPattern {
  INDEX = '1',
  INDEX2 = '2',
}

const getReplacedStr = (replacePattern:ReplacedPattern, indexCounter:number):string =>{
  switch(replacePattern){
    case ReplacedPattern.INDEX:
      return `[\${${indexCounter}:index}]`;
    case ReplacedPattern.INDEX2:
      return`[index]`;
    default:
      return '';
  }
}

export const  replacePatternWithIndex = (string:string, replaceStr:ReplacedPattern):string => {
  const res = [string].map(str => {
    let indexCounter = 1; // Start the counter from 1
    
    return str.replace(/\[\$\{\d+\:index\}\]|\[index\]|\[\d+\]/g, () => {
      // For each match, we generate a replacement with the current counter
      let replacement = getReplacedStr(replaceStr, indexCounter);
      indexCounter++; // Increment the counter for the next match
      return replacement;
    });
  });
  return res[0];
}

export const isEmptyObject =(obj: object): boolean => {
  return Object.keys(obj).length === 0;
}
