import { FILE_02 } from "src/constants/imageConstants";
import { ChangeLogState } from "../../types";
import { getStatusIndicator } from "../../utils";

export const BranchNodeDescription = ({
  prevDesc,
  currDesc,
  nodeState,
}: {
  prevDesc: string | null | undefined;
  currDesc: string | null | undefined;
  nodeState: ChangeLogState;
}) => {
  return (
    <div>
      <div className="flex gap-2 bg-neutral-0 border-y border-neutral-100 px-4 h-8 py-2 font-b2-medium text-neutral-black items-center">
        <img src={FILE_02} alt="" />
        Description
      </div>
      <div className="flex">
        <div className="border-r w-[50%] flex flex-col px-4 py-3 gap-2 overflow-none">
          <div className="text-neutral-black font-b2 flex flex-wrap break-all">
            {nodeState === "add" ? (
              <span className="text-neutral-500 m-auto">
                Branch not present
              </span>
            ) : (
              prevDesc
            )}
          </div>
        </div>
        <div className="flex flex-col w-[50%] px-4 py-3 gap-2 left-0">
          <div className="text-neutral-black font-b2 flex flex-wrap break-all h-[100%]">
            {nodeState === "delete" ? (
              <span className="text-neutral-500 m-auto">Branch is deleted</span>
            ) : (
              <span className="flex gap-2">
                {getStatusIndicator(nodeState)}
                <span className="w-[95%]">{currDesc}</span>
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
