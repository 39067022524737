import { ArrowRightIcon } from "@heroicons/react/24/outline";
import { PolicyStatus } from "@types";
import { getInitial } from "@utils/utils";
import { format } from "date-fns";
import { ActivityLogList } from "src/screens/policyDetails/types";
import Badge from "../Badge";
import StatusChip from "../StatusChip";
import { LOG_CONFIG } from "./constants";


export const WfActivityLog = ({
  log,
  isLast,
}: {
  log: ActivityLogList[number];
  isLast?: boolean;
}) => {
  const d = new Date(log.createdAt);
  const getLogBody = () => {
    switch (log.eventType) {
      case "version_created":
      case "published":
      case "simulation":
        return (
          <>
            <span className="flex flex-wrap gap-1 items-center font-b1">
              {log.createdByName}{" "}
              {LOG_CONFIG[log.eventType as keyof typeof LOG_CONFIG]}
              {log.eventType === "published" && log.metadata.endpointName}
            </span>
            <div className="flex gap-1.5 items-center">
              <Badge className="bg-neutral-50 text-neutral-700">
                {log.version}
              </Badge>
            </div>
          </>
        );
      case "removed":
        return (
          <>
            <span className="flex flex-wrap gap-1 items-center font-b1">
              {log.createdByName}{" "}
              {LOG_CONFIG[log.eventType as keyof typeof LOG_CONFIG]}
              {log.metadata.endpointName}
            </span>
            <div className="flex gap-1.5 items-center">
              <Badge className="bg-neutral-50 text-neutral-700">
                {log.version}
              </Badge>
            </div>
          </>
        );
      case "policy_created":
        return (
          <>
            <span className="flex flex-wrap gap-1 items-center font-b1">
              {log.createdByName}{" "}
              {LOG_CONFIG[log.eventType as keyof typeof LOG_CONFIG]}
            </span>
            <div className="flex gap-1.5 items-center">
              <Badge className="bg-neutral-50 text-neutral-700">
                {log.version}
              </Badge>
            </div>
          </>
        );
      case "status_update":
        return (
          <>
            <span className="flex flex-wrap gap-1 items-center font-b1">
              {log.createdByName}{" "}
              {LOG_CONFIG[log.eventType as keyof typeof LOG_CONFIG]}
            </span>
            <div className="flex gap-1.5 items-center">
              <StatusChip status={log.metadata?.prevStatus as PolicyStatus} />
              <ArrowRightIcon className="stroke-neutral-500 w-4 h-4" />
              <StatusChip status={log.metadata?.currStatus as PolicyStatus} />
            </div>
          </>
        );
    }
  };

  return (
    <div className="mb-8 flex relative">
      <div className="w-6 h-6 flex justify-center items-center rounded-full bg-neutral-900 text-neutral-0 font-medium text-[11px] mr-3">
        {getInitial(log.createdByName)}
        <div
          className={`border-l absolute h-[110%] top-6 text-neutral-100 ${
            isLast ? "hidden" : ""
          }`}
        ></div>
      </div>
      <div className="flex flex-col gap-3 w-[90%] overflow-hidden">
        {getLogBody()}
        <span className="font-b2-medium text-neutral-500">
          {format(d, "dd MMM, yyyy 'at' hh:mmaaaaa'm")}
        </span>
      </div>
    </div>
  );
};
