import {
  ChangeLogState,
  ChangeLogTransition,
  DataSource,
  DataSourceNode,
} from "../../types";
import { getStatusIndicator, highlightDifferences } from "../../utils";
import { DataSourceTransitions } from "./DataSourceTransitions";

export const DataSourceLogCard = ({
  prevData,
  currData,
  nodeState,
}: {
  prevData: DataSourceNode | null;
  currData: DataSourceNode | null;
  nodeState: ChangeLogState;
}) => {
  const prevTransitions = prevData?.data.transitions || [];
  const currTransitions = currData?.data.transitions || [];

  return (
    (prevData || currData) && (
      <div className="flex flex-col">
        {Object.keys(currData?.data || prevData?.data || {}).map((key) => {
          const prevSources = (prevData?.data?.[key] as DataSource[]) || [];
          const currSources = (currData?.data?.[key] as DataSource[]) || [];

          const maxLength = Math.max(prevSources.length, currSources.length);

          if (key !== "transitions" && maxLength > 0) {
            return (
              <div key={key}>
                <div className="bg-neutral-0 border-y border-neutral-100 px-4 h-8 py-2 font-b2-medium text-neutral-black">
                  <span className="flex items-center gap-2">{key}</span>
                </div>
                <div className="flex">
                  <div className="w-[50%] border-r px-4 py-3">
                    <div className="flex flex-col gap-2 w-[90%]">
                      {[...Array(maxLength)].map((_, idx) => {
                        const source = prevSources[idx];

                        if (!source) {
                          return (
                            <div key={idx} className="flex">
                              <span className="text-neutral-500"></span>
                            </div>
                          );
                        }

                        return (
                          <div
                            key={idx}
                            className="flex flex-wrap font-b2-medium items-center gap-2"
                          >
                            <div className="flex flex-col gap-1">
                              <span className="flex flex-wrap font-b2-medium break-all gap-2">
                                {source.name}
                              </span>
                              <div className="flex flex-col gap-2 font-b2 font-normal">
                                {source.variables && (
                                  <div className="flex flex-col gap-1">
                                    {Object.entries(source.variables).map(
                                      ([varKey, varValue]) => {
                                        const currVarValue =
                                          currSources[idx]?.variables?.[
                                            varKey
                                          ] ?? "";
                                        const { prevHighlighted } =
                                          highlightDifferences(
                                            varValue,
                                            currVarValue
                                          );

                                        return (
                                          <div
                                            key={varKey}
                                            className="flex flex-wrap break-all gap-1 font-code text-sm"
                                          >
                                            <span className="text-neutral-black mr-2">
                                              {varKey}:
                                            </span>
                                            <span className="ml-2">
                                              {prevHighlighted}
                                            </span>
                                          </div>
                                        );
                                      }
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>

                  <div className="w-[50%] px-4 py-3">
                    <div className="flex flex-col gap-2 w-[90%]">
                      {[...Array(maxLength)].map((_, idx) => {
                        const source = currSources[idx];

                        if (!source) {
                          return (
                            <div
                              key={idx}
                              className="flex flex-wrap font-b2-medium items-center gap-2"
                            >
                              <div className="flex flex-col gap-1">
                                <span className="flex flex-wrap font-b2-medium break-all gap-2">
                                  {getStatusIndicator("delete")}
                                  {prevSources[idx]?.name}
                                </span>
                                <div className="flex flex-col gap-2 font-b2 font-normal ml-3">
                                  {prevSources[idx].variables && (
                                    <div className="flex flex-col gap-1">
                                      {Object.entries(
                                        prevSources[idx].variables
                                      ).map(([varKey, varValue]) => {
                                        const prevVarValue =
                                          prevSources[idx]?.variables?.[
                                            varKey
                                          ] ?? "";
                                        const curVarValue =
                                          currSources[idx]?.variables?.[
                                            varKey
                                          ] ?? "";

                                        const { prevHighlighted } =
                                          highlightDifferences(
                                            prevVarValue,
                                            curVarValue
                                          );

                                        return (
                                          <div
                                            key={varKey}
                                            className="flex flex-wrap break-all gap-1 font-code text-sm"
                                          >
                                            <span className="text-neutral-black">
                                              {varKey}:
                                            </span>{" "}
                                            <span className="ml-2">
                                              {prevHighlighted}
                                            </span>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          );
                        }

                        return (
                          <div
                            key={idx}
                            className="flex flex-wrap font-b2-medium items-center gap-2"
                          >
                            <div className="flex flex-col gap-1">
                              <span className="flex flex-wrap font-b2-medium break-all gap-2">
                                {getStatusIndicator(source.state ?? "add")}
                                {source.name}
                              </span>
                              <div className="flex flex-col gap-2 font-b2 font-normal ml-3">
                                {source.variables && (
                                  <div className="flex flex-col gap-1">
                                    {Object.entries(source.variables).map(
                                      ([varKey, varValue]) => {
                                        const prevVarValue =
                                          prevSources[idx]?.variables?.[
                                            varKey
                                          ] ?? "";

                                        const { currHighlighted } =
                                          highlightDifferences(
                                            prevVarValue,
                                            varValue
                                          );
                                        return (
                                          <div
                                            key={varKey}
                                            className="flex flex-wrap break-all gap-1 font-code text-sm"
                                          >
                                            <span className="text-neutral-black mr-2">
                                              {varKey}:
                                            </span>{" "}
                                            <span className="">
                                              {currHighlighted}
                                            </span>
                                          </div>
                                        );
                                      }
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            );
          }
          return null;
        })}
        <DataSourceTransitions
          prevTransitions={prevTransitions as ChangeLogTransition[]}
          currTransitions={currTransitions as ChangeLogTransition[]}
        />
      </div>
    )
  );
};
