import { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import { initSentry } from "@utils/sentry";
import posthog from 'posthog-js';
import { PostHogProvider} from 'posthog-js/react'
import App from "./App";
import "./styles/index.css";

if (["PROD", "UAT"].includes(import.meta.env.REACT_APP_API_ENV)) {
  initSentry();
  posthog.init(
    import.meta.env.REACT_APP_POSTHOG_API_KEY,
    {
      api_host: 'https://us.i.posthog.com',
      person_profiles: 'identified_only',
    }
  );
}

async function enableMocking() {
  if (
    import.meta.env.REACT_APP_API_ENV !== "LOCAL" &&
    !import.meta.env.VITEST
  ) {
    return;
  }

  const { worker } = await import("./mocks/browser");
  if (import.meta.env.VITEST) {
    return worker.start();
  } else {
    // return worker.start();
  }
}

enableMocking().then(() => {
  const root = ReactDOM.createRoot(document.getElementById("root")!);
  root.render(
    <StrictMode>
      <PostHogProvider client={posthog}>
        <App />
      </PostHogProvider>
    </StrictMode>
  );
});
