import { Keywords } from "@screens/create-policy/Models/PredSearch";
import {
  keepPreviousData,
  queryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { ProgramTemplate } from "@types";
import { getNetworkErrorText, normalizeQueryKey, notify } from "@utils/utils";
import axios from "src/axios";
import { FinBoxResponse } from "src/types";
import { Matrix, Model, ModelFunction, PredResponseType } from "./Models/types";
import { InputVariable } from "./Sources/types";
import { AvailableSources, DecisionResponse, Policy } from "./types";

const programTemplatesQuery = () =>
  queryOptions({
    queryKey: ["programTemplate"],
    queryFn: async () => {
      return axios.get<FinBoxResponse<Array<ProgramTemplate>>>(
        "/program/getProgramTemplates"
      );
    },
    select: (data) => data.data,
  });

export const getPolicyQuery = (policyId?: string) =>
  queryOptions({
    queryKey: normalizeQueryKey(["getPolicy", policyId]),
    queryFn: async () => {
      return axios.v2.get<FinBoxResponse<Policy>>(
        `/getPolicy?policyID=${policyId}`
      );
    },
    select: (data) => data.data,
    enabled: !!policyId,
  });

export const lookupFunctionInputs = (
  // permissions?: boolean,
  params?: {
    entityId: string;
    entityType?: string;
  }
) =>
  queryOptions({
    queryKey: ["lookupFunctionsInput", params?.entityType, params?.entityId],
    // enabled: permissions,
    queryFn: async () => {
      return axios.get<FinBoxResponse<Array<string>>>(
        "/policybucket/lookupfunctioninputs",
        { params }
      );
    },
    select: (data) => data.data,
  });

const getModelsQuery = (searchQuery?: string) =>
  queryOptions({
    queryKey: normalizeQueryKey(["getModels", searchQuery]),
    queryFn: async () => {
      return axios.v2.get<
        FinBoxResponse<Array<{ models: Array<Model>; version: string }>>
      >(`/getModels?search=${searchQuery}`);
    },
    select: (data) => data.data,
  });

export const getKeywordsQuery = <T extends "normal" | "workflow" = "normal">(
  args?: T extends "workflow"
    ? {
        id: string;
        ruleGroupID?: string;
        readonly type: "workflow";
        enabled?: boolean;
      }
    : { id: string; readonly type: "normal"; enabled?: boolean }
) =>
  queryOptions({
    queryKey: normalizeQueryKey(["getKeywords", args]),
    queryFn: async () => {
      return axios.v2.post<
        FinBoxResponse<T extends "workflow" ? WorkflowKeywords : Keywords>
      >(`getKeywords`, args);
    },
    select: (data) => data.data,
    enabled:
      (args?.enabled ?? true) &&
      (!args ||
        ("ruleGroupID" in args ? !!args.id && !!args.ruleGroupID : !!args.id)),
  });

export const getCustomInputsQuery = (policyId?: string, policyType?: string) =>
  queryOptions({
    queryKey: normalizeQueryKey(["getInput", policyId, policyType]),
    queryFn: async () => {
      return axios.v2.get<FinBoxResponse<InputVariable[]>>(
        `/inputs?policyType=${policyType}&policyID=${policyId}`
      );
    },
    select: (data) => data.data,
  });

export const useProgramTemplates = () => useQuery(programTemplatesQuery());

export const useCreateBlankPolicy = () =>
  useMutation({
    mutationFn: ({ formData }: { formData: FormData }) =>
      axios.post<FinBoxResponse<{ policyID: string }>>(
        "policybucket/create",
        formData
      ),
  });

export const useCreateImportPolicy = () =>
  useMutation({
    mutationFn: ({ formData }: { formData: FormData }) =>
      axios.post<FinBoxResponse<{ entityID: string; importErrors: string[] }>>(
        "policybucket/import",
        formData
      ),
  });

export const useGetPolicy = (policyID: string | undefined) =>
  useQuery(getPolicyQuery(policyID));

export const useLookupFunctionsInput = (params: {
  entityId: string;
  entityType?: string;
}) => {
  // const { getPermissions } = usePermissions();
  // const permissions = true;
  // getPermissions(
  //   PERMISSIONS_TYPE.policy as PermissionTypes,
  //   "view lookups list"
  // );
  return useQuery(lookupFunctionInputs(params));
};

export const useGetModels = (search: string) =>
  useQuery(getModelsQuery(search));

export const useUpdatePolicy = () =>
  useMutation({
    mutationFn: (policy: Policy) =>
      axios.v2.put<FinBoxResponse<any>>("updatePolicy", policy),
  });

export type WorkflowKeywords = {
  functionsList: Record<string, ModelFunction>;
  predictorsList: Record<string, PredResponseType>;
  customDataSources: Record<string, PredResponseType>;
  modelExprs?: Array<{ id: string; name: string; output: string[] }>;
  modelSets?: Array<{ id: string; name: string; output: string[] }>;
  policies?: Array<{
    id: string;
    name: string;
    output: string[];
  }>;
  workflows?: Array<{
    id: string;
    name: string;
    output: string[];
  }>;
  input?: string[];
  modelDecisionTable?: Array<{ id: string; name: string; output: string[] }>;
  rulesets?: Array<{
    name: "Ruleset_Node_1";
    id: "a37e0c9f-5849-4696-abc1-a146c70af52a";
    output: ["decision"];
  }>;
};

export const useGetKeywords = <T extends "normal" | "workflow" = "normal">(
  args: T extends "workflow"
    ? {
        id: string;
        ruleGroupID?: string;
        readonly type: "workflow";
        enabled?: boolean;
      }
    : { id: string; readonly type: "normal" }
) => {
  return useQuery(getKeywordsQuery(args));
};

export const useEval = () =>
  useMutation({
    mutationKey: ["testBranch"],
    mutationFn: (body: {
      evalString?: string;
      approveExprString?: string;
      cantDecideExprString?: string;
      matrix?: Matrix | null;
      variables?: Record<string, any>;
    }) => axios.v2.post<FinBoxResponse<any>>("eval", body),
  });

export const useDebug = () =>
  useMutation({
    mutationFn: ({
      policyID,
      variables,
    }: {
      policyID: string;
      variables?: Record<string, any>;
    }) =>
      axios.v2.post<FinBoxResponse<DecisionResponse>>("debug", {
        policyID,
        variables,
      }),
  });

export const useDuplicatePolicy = () =>
  useMutation({
    mutationFn: (policyData: {
      policyID: string;
      policyVersion: string;
      programOutcomeTemplateID: string;
      description?: string;
    }) =>
      axios.v2.post<FinBoxResponse<{ policyID: string }>>(
        "createDuplicatePolicy",
        policyData
      ),
  });

export const useSaveCustomInputs = () =>
  useMutation({
    mutationFn: ({
      policyID,
      inputs,
      policyType = "normal",
    }: {
      policyID: string;
      inputs: InputVariable[];
      policyType?: string;
    }) =>
      axios.v2.post<FinBoxResponse<any>>("saveInputs", {
        policyID,
        inputs,
        policyType,
      }),
  });

export const useGetCustomInputs = (
  policyId: string,
  policyType: string = "normal"
) => useQuery(getCustomInputsQuery(policyId, policyType));

export const useApiStoreQuery = () => useQuery(getApiStoreQuery());

export const getApiStoreQuery = () =>
  queryOptions({
    queryKey: ["apiStoreList"],
    queryFn: async () => {
      return axios.get<FinBoxResponse<any>>(`/plugindatasource/list`);
    },
    select: (data) => data.data.data,
    placeholderData: keepPreviousData,
  });

export const useDataStoreQuery = (dataSourceId: number) =>
  useQuery(getDataStoreQuery(dataSourceId));

export const getDataStoreQuery = (dataSourceId: number) =>
  queryOptions({
    queryKey: normalizeQueryKey(["dataStoreList", dataSourceId]),
    queryFn: async () => {
      return axios.get<FinBoxResponse<any>>(
        `/plugindatasource/${dataSourceId}/variables`
      );
    },
    select: (data) => data.data.data,
    placeholderData: keepPreviousData,
  });

export const getMdFileQuery = (path: string) =>
  queryOptions({
    queryKey: normalizeQueryKey([
      "mdFileContent",
      path.substring(path.length - 10, path.length),
    ]),
    queryFn: async () => {
      return fetch(path).then((response) => response.text());
    },
    select: (mdFile) => mdFile,
    placeholderData: keepPreviousData,
  });

export const useSaveDataSource = () => {
  const qc = useQueryClient();
  return useMutation({
    mutationFn: ({
      variableId,
      variables,
    }: {
      variableId: number;
      variables: Record<string, string>;
    }) =>
      axios.put<FinBoxResponse<any>>(`/plugindatasource/${variableId}`, {
        variables: variables,
      }),
    onSuccess: async () => {
      await qc.invalidateQueries(getApiStoreQuery());
      return notify({
        title: "Success",
        text: "Data source updated successfully!",
        type: "success",
      });
    },
    onError: (err) =>
      notify({ title: "Failed to save", text: getNetworkErrorText(err) }),
  });
};

export const datasourcePolicyListQuery = (args?: {
  pageNumber?: number;
  sourceName?: string;
  pageSize?: number;
}) =>
  queryOptions({
    queryKey: normalizeQueryKey(["datasourceList", args]),
    queryFn: async () => {
      const params = args;
      return axios.get<FinBoxResponse<AvailableSources>>(
        `/policybucket/datasource/list`,
        {
          params,
        }
      );
    },
    select: (data) => data.data,
    placeholderData: keepPreviousData,
  });

export const useGetAvailablePolicySourceList = (
  args: {
    pageNumber?: number;
    sourceName?: string;
    pageSize?: number;
  } = {}
) => useQuery(datasourcePolicyListQuery(args));
