import { Monaco, loader, useMonaco } from "@monaco-editor/react";
import {
  getWorkflowDefinitions
} from "@components/Editor/lang-util";
import * as monacoEditor from "monaco-editor";
import { IDisposable, languages } from "monaco-editor";
import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
} from "react";

import IMonarchLanguage = languages.IMonarchLanguage;
import { workflowAutocomplete } from "src/components/Editor/workflow-autocomplete";

loader.config({ monaco: monacoEditor });

type Context = {
  monacoInstance: Monaco;
  addToDisposeStore: (id: string, dispose: IDisposable["dispose"]) => void;
  removeFromDisposeStore: (id: string) => void;
  disposeAllExceptRoot: () => void;
  autocompleteProvider: (autocompleteProviderOptions: {
    nodeId: string;
    sourceList: string[];
    keywords: {
      policies: Record<string, string[]>;
      workflows: Record<string, string[]>;
      predictorsList: Record<string, string[]>;
      input: string[];
      functionsList: Record<
        string,
        { syntax: string; returnType: string; description: string }
      >;
    };
    autoCompleteRecords: Record<string, string[]>;
    lookupFunctionInputs?: string[];
  }) => void;
};

const MonacoContext = createContext<Context | undefined>(undefined);

export const MonacoProvider = ({
  children,
}: PropsWithChildren<{ nodeId?: string }>) => {
  const autocompleteDisposeStore =
    useRef<Map<string, IDisposable["dispose"]>>();

  const getDisposalStore = () => {
    if (!autocompleteDisposeStore.current)
      autocompleteDisposeStore.current = new Map();
    return autocompleteDisposeStore.current as NonNullable<
      typeof autocompleteDisposeStore.current
    >;
  };

  const removeFromDisposeStore = useCallback((id: string) => {
    const map = getDisposalStore();
    if (map.has(id)) map.get(id)!();
    map.delete(id);
  }, []);

  const addToDisposeStore = useCallback(
    (id: string, dispose: IDisposable["dispose"]) => {
      const map = getDisposalStore();
      removeFromDisposeStore(id);
      map.set(id, dispose);
    },
    [removeFromDisposeStore]
  );

  const disposeAllExceptRoot = useCallback(() => {
    const map = getDisposalStore();
    for (let entry of Array.from(map.entries())) {
      const [key, value] = entry;
      if (key === "root") continue;
      value();
    }
  }, []);

  const monacoInstance = useMonaco()!;

  const disableRegister = false;

  const disposeAllExceptRootAndAddToDisposeStore: Context["addToDisposeStore"] =
    useCallback(
      (id, dispose) => {
        disposeAllExceptRoot();
        addToDisposeStore(id, dispose);
      },
      [addToDisposeStore, disposeAllExceptRoot]
    );

  const autocompleteProvider: Context["autocompleteProvider"] = useCallback(
    ({
      nodeId,
      sourceList,
      keywords,
      autoCompleteRecords,
      lookupFunctionInputs,
    }) => {
      monacoInstance.languages.setMonarchTokensProvider(
        "sent-lang",
        getWorkflowDefinitions(sourceList, keywords) as IMonarchLanguage
      );

      const a = monacoInstance.languages.registerCompletionItemProvider(
        "sent-lang",
        {
          triggerCharacters: [".", "["],
          provideCompletionItems: workflowAutocomplete(
            monacoInstance,
            sourceList,
            keywords,
            autoCompleteRecords,
            lookupFunctionInputs
          ),
        }
      );
      const dispose = a.dispose;
      disposeAllExceptRootAndAddToDisposeStore(nodeId, dispose);
    },
    [disposeAllExceptRootAndAddToDisposeStore, monacoInstance]
  );

  useEffect(() => {
    if (disableRegister) {
      return;
    }
    let dispose: (() => void) | null = null;

    if (!monacoInstance) {
      return;
    }

    monacoInstance.languages.register({ id: "sent-lang" });

    return () => {
      if (!disableRegister && dispose) {
        dispose();
        removeFromDisposeStore("root");
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MonacoContext.Provider
      value={{
        monacoInstance,
        addToDisposeStore,
        removeFromDisposeStore,
        disposeAllExceptRoot,
        autocompleteProvider,
      }}
    >
      {children}
    </MonacoContext.Provider>
  );
};

export default function useMonacoContext() {
  const context = useContext(MonacoContext);
  if (!context)
    throw Error("useMonacoContext can only be used in EditBranchProvider");
  return context;
}
