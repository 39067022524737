import { FinBoxResponse } from "src/types";
import axios from "@axios";
import { LoginActions } from "src/constants/EventAnalytics";

export type MetadataType = any;
export type EventType = keyof typeof LoginActions | string;
export interface UserInfoType {
    email:string;
    org_name:string;
}

class EventAnalytics {
  private userInfo: UserInfoType | null;
  private metadata: MetadataType;
  private event_type: EventType;

  constructor(event_type: EventType = "", metadata: MetadataType = {}) {
    this.metadata = metadata;
    this.userInfo = null
    this.event_type = event_type;
  }


  public setUserInfo(userInfo: UserInfoType) {
    this.userInfo = userInfo;
  }

  private async makeCall() {
    try {
      await axios.post<
        FinBoxResponse<{
          responseBody: null | Record<string, any>;
          statusCode: number;
        }>
      >("/events/add", {
        name: this.event_type,
        metadata: {...this.metadata, ...this.userInfo},
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }

  private async makeNoAuthCall() {
    try {
      await axios.post<
        FinBoxResponse<{
          responseBody: null | Record<string, any>;
          statusCode: number;
        }>
      >("/events/nonAuth/add", {
        name: this.event_type,
        metadata: this.metadata,
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }

  public triggerAnalytics(event_type: EventType, metadata?: MetadataType) {
    this.metadata = metadata ?? {};
    this.event_type = event_type;
    this.makeCall();
  }

  public triggerNoAuthAnalytics(event_type: EventType, metadata?: MetadataType) {
    this.metadata = metadata ?? {};
    this.event_type = event_type;
    this.makeNoAuthCall();
  }

}

export const analyticsInstance = new EventAnalytics();