import classNames from "clsx";
import { useState } from "react";
import * as React from "react";
import { Popover } from "@headlessui/react";

type Props = {
  content: React.ReactNode;
  tooltipContent: React.ReactNode;
  center?: boolean;
  left?: boolean;
  right?: boolean;
  bottom?: boolean;
  tooltipClassName?: string;
  className?: string;
  contentClassName?: string;
};

const centerClassNames = "left-1/2 -translate-x-1/2";
const leftClassNames = "right-1/2";
const rightClassNames = "left-1/2";
const bottomClassNames = "top-full translate-y-[5px]";
const topClassNames = "bottom-full translate-y-[4px]";

export default function Tooltip({
  content,
  tooltipContent,
  center,
  left,
  right,
  bottom,
  tooltipClassName,
  contentClassName,
  className,
}: Props) {
  const [show, setShow] = useState(false);
  center = !center && !left && !right;

  return (
    <Popover className={classNames("relative w-fit", className)}>
      <>
        <Popover.Button
          onMouseEnter={() => setShow(true)}
          onMouseLeave={() => setShow(false)}
          tabIndex={-1}
          className={(classNames("outline-none"), contentClassName)}
        >
          {content}
        </Popover.Button>
        {show && (
          <Popover.Panel
            static
            className={classNames(
              "absolute z-50 max-w-fit whitespace-nowrap rounded-lg bg-white p-2 text-xs font-medium text-gray-500 shadow ",
              {
                [centerClassNames]: center,
                [leftClassNames]: left,
                [rightClassNames]: right,
                [bottomClassNames]: bottom,
                [topClassNames]: !bottom,
              },
              tooltipClassName
            )}
          >
            {tooltipContent}
          </Popover.Panel>
        )}
      </>
    </Popover>
  );
}
