import { useEffect, useRef } from "react";
import { Accordion } from "src/components/Accordion";
import Badge from "src/components/Badge";
import { classNames } from "src/utils/utils";
import chevronDown from "@assets/icons/chevron-down.svg";
import {
  BranchNode,
  DataSourceNode,
  EndNode,
  InputOutputDataNode,
  ModelSetNode,
  RuleSetNode,
  WfChangelog,
  WorkflowPolicyNode,
} from "../types";
import { getChangeLogIcon } from "../utils";
import { BranchNodeLogCard } from "./BranchNodeLogCard";
import { DataSourceLogCard } from "./DataSourceLogCard";
import { EndNodeLogCard } from "./EndNodeLogCard";
import { InputOutputCard } from "./InputOutputCard";
import { ModelSetLogCard } from "./ModelSetLogCard";
import { PolicyWfLogCard } from "./PolicyWfLogCard";
import { RuleSetLogCard } from "./RuleSetLogCard";

export const ChangedNodes = ({
  changeLogData,
  selectedNodeId,
}: {
  changeLogData: WfChangelog;
  selectedNodeId: string | null;
}) => {
  const nodeRefs = useRef<Record<string, HTMLDivElement | null>>({});

  useEffect(() => {
    if (selectedNodeId && nodeRefs.current[selectedNodeId]) {
      nodeRefs.current[selectedNodeId]?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [selectedNodeId]);

  return (
    <div className="flex flex-col gap-4 mt-6 overflow-y-auto h-[90%] pb-12">
      {changeLogData.changelogNodes?.map((node) => {
        return (
          <Accordion
            defaultOpen
            buttonClassName="w-[100%] overflow-none"
            content={({ open }) => (
              <div
                className={classNames(
                  "border rounded-md border-neutral-100 !bg-white flex flex-col",
                  open ? "rounded-b-none border-b-0" : "border-b"
                )}
                ref={(el) => (nodeRefs.current[node.id] = el)}
              >
                <div className="font-b1-medium rounded-md text-neutral-black bg-white flex items-center border-b-0 px-4 py-3 justify-between">
                  {node.state === "edit" ? (
                    <span>
                      {node.prevData.name !== node.currData.name ? (
                        <div className="flex flex-wrap items-center gap-2">
                          <span className="flex items-center gap-2">
                            {getChangeLogIcon(node.type, node.prevData.name)}
                            {node.prevData.name}{" "}
                          </span>
                          <span className="text-neutral-500 font-b2">
                            is changed to
                          </span>{" "}
                          {node.currData.name}
                        </div>
                      ) : (
                        <span className="flex items-center gap-2">
                          {getChangeLogIcon(node.type, node.name)}
                          {node.name}
                        </span>
                      )}
                    </span>
                  ) : (
                    <span className="flex items-center gap-2">
                      {getChangeLogIcon(node.type, node.name)}
                      {node.name}
                      <Badge
                        variant={
                          `${node.state}` === "add" ? "success" : "error"
                        }
                      >
                        {`${node.state}` === "add" ? "Added" : "Deleted"}
                      </Badge>
                    </span>
                  )}
                  <img
                    src={chevronDown}
                    className={classNames("duration-100", open && "rotate-180")}
                    aria-hidden="true"
                    alt=""
                  />
                </div>
              </div>
            )}
          >
            <div className="bg-white -mt-4 border rounded-md rounded-t-none border-t-0 border-neutral-100 font-b2 flex flex-col">
              {(() => {
                switch (node.type) {
                  case "modelSet":
                    return (
                      <ModelSetLogCard
                        prevData={node.prevData as ModelSetNode | null}
                        currData={node.currData as ModelSetNode | null}
                        nodeState={node.state}
                        currWFId={changeLogData.currWfID}
                        prevWFId={changeLogData.prevWfID}
                      />
                    );
                  case "ruleSet":
                    return (
                      <RuleSetLogCard
                        prevData={node.prevData as RuleSetNode | null}
                        currData={node.currData as RuleSetNode | null}
                        nodeState={node.state}
                      />
                    );
                  case "branch":
                    return (
                      <BranchNodeLogCard
                        prevData={node.prevData as BranchNode | null}
                        currData={node.currData as BranchNode | null}
                        nodeState={node.state}
                      />
                    );
                  case "input":
                    return (
                      <InputOutputCard
                        prevData={node.prevData as InputOutputDataNode}
                        currData={node.currData as InputOutputDataNode}
                        cardType="input"
                      />
                    );
                  case "output":
                    return (
                      <InputOutputCard
                        prevData={node.prevData as InputOutputDataNode}
                        currData={node.currData as InputOutputDataNode}
                        cardType="output"
                      />
                    );
                  case "policy":
                    return (
                      <PolicyWfLogCard
                        state={node.state}
                        prevData={node.prevData as WorkflowPolicyNode | null}
                        currData={node.currData as WorkflowPolicyNode | null}
                        cardType="policy"
                      />
                    );
                  case "workflow":
                    return (
                      <PolicyWfLogCard
                        state={node.state}
                        prevData={node.prevData as WorkflowPolicyNode}
                        currData={node.currData as WorkflowPolicyNode}
                        cardType="workflow"
                      />
                    );
                  case "end":
                    return (
                      <EndNodeLogCard
                        prevData={node.prevData as EndNode | null}
                        currData={node.currData as EndNode | null}
                        nodeState={node.state}
                      />
                    );
                  case "dataSource":
                    return (
                      <DataSourceLogCard
                        prevData={node.prevData as DataSourceNode | null}
                        currData={node.currData as DataSourceNode | null}
                        nodeState={node.state}
                      />
                    );
                  default:
                    return null;
                }
              })()}
            </div>
          </Accordion>
        );
      })}
    </div>
  );
};
