import { ComponentProps } from "react";
import {
  EXPRESSION_ADD,
  EXPRESSION_DELETE,
  EXPRESSION_EDIT,
  LOG_ADD_ICON,
  LOG_DELETE_ICON,
  LOG_EDIT_ICON,
} from "src/constants/imageConstants";
import { classNames } from "src/utils/utils";
import { ReactComponent as BranchIcon } from "@assets/icons/shuffle-01.svg";
import { ReactComponent as CustomIcon } from "@assets/icons/tool-01.svg";
import { ReactComponent as VariableIcon } from "@assets/icons/variable.svg";
import { ReactComponent as ExpressionIcon } from "@assets/icons/workflow/calculator.svg";
import { ReactComponent as ApprovedIcon } from "@assets/icons/workflow/check-circle.svg";
import { ReactComponent as SourceIcon } from "@assets/icons/workflow/database-01.svg";
import { ReactComponent as CantIcon } from "@assets/icons/workflow/help-circle.svg";
import { ReactComponent as PolicyIcon } from "@assets/icons/workflow/policy.svg";
import { ReactComponent as RuleGroupIcon } from "@assets/icons/workflow/rule.svg";
import { ReactComponent as RejectIcon } from "@assets/icons/workflow/x-circle.svg";
import {
  ChangeLogFilterItem,
  ChangeLogLeftPanelList,
  ChangeLogState,
  ChangelogNode,
} from "./types";

export const getLogTypeIcon = (type: string, props?: ComponentProps<"svg">) => {
  const svgProps = {
    className: classNames("w-4 h-4", props?.className),
  };
  switch (type) {
    case "add":
      return (
        <img src={LOG_ADD_ICON} alt="add_icon" className={svgProps.className} />
      );
    case "delete":
      return (
        <img
          src={LOG_DELETE_ICON}
          alt="delete_icon"
          className={svgProps.className}
        />
      );
    case "edit":
      return (
        <img
          src={LOG_EDIT_ICON}
          alt="edit_icon"
          className={svgProps.className}
        />
      );
  }
  return null;
};

export const getExpressionTypeIcon = (
  type: string,
  props?: ComponentProps<"svg">
) => {
  const svgProps = {
    className: classNames("w-3 h-3", props?.className),
  };
  switch (type) {
    case "add":
      return (
        <img
          src={EXPRESSION_ADD}
          alt="add_icon"
          className={svgProps.className}
        />
      );
    case "delete":
      return (
        <img
          src={EXPRESSION_DELETE}
          alt="delete_icon"
          className={svgProps.className}
        />
      );
    case "edit":
      return (
        <img
          src={EXPRESSION_EDIT}
          alt="edit_icon"
          className={svgProps.className}
        />
      );
  }
  return null;
};

export const getChangeLogIcon = (type?: string | undefined, label?: string) => {
  const svgProps = {
    className: classNames("w-4 h-4"),
  };
  switch (type) {
    case "policy":
      return <PolicyIcon {...svgProps} />;
    case "workflow":
      return <PolicyIcon {...svgProps} />;
    case "branch":
      return <BranchIcon {...svgProps} />;
    case "modelSet":
      return <ExpressionIcon {...svgProps} />;
    case "ruleSet":
      return <RuleGroupIcon {...svgProps} />;
    case "dataSource":
      return <SourceIcon {...svgProps} />;
    case "input":
      return (
        <VariableIcon className={classNames("stroke-neutral-500 w-4 h-4")} />
      );
    case "output":
      return (
        <CustomIcon className={classNames("stroke-neutral-500 w-4 h-4")} />
      );
    case "end":
      switch (label?.toLowerCase()) {
        case "approved":
          return (
            <ApprovedIcon className="stroke-success-500 fill-success-100 [&>path]:stroke-success-500 [&>path]:fill-success-100 w-4 h-4" />
          );
        case "rejected":
          return (
            <RejectIcon className="stroke-error-500 fill-error-200 [&>path]:stroke-error-500 [&>path]:fill-error-200 w-4 h-4" />
          );
        case "cant_decide":
          return (
            <CantIcon className="stroke-warning-500 fill-warning-100 [&>path]:stroke-warning-100 [&>path]:fill-warning-100 w-4 h-4" />
          );
      }
      return <CustomIcon className="stroke-primary-600 w-4 h-4" />;
  }
  return null;
};

export const getStatusIndicator = (state: ChangeLogState) => {
  const getColorClasses = () => {
    switch (state) {
      case "add":
        return "w-1 h-4 bg-success-200 border-success-200";
      case "delete":
        return "w-1 h-4 bg-error-200 border-error-200";
      case "edit":
        return "w-1 h-4 bg-neutral-100 border-neutral-100";
      default:
        return "w-1 bg-gray-300 border-gray-300";
    }
  };
  return <span className={`w-1 rounded-md border ${getColorClasses()}`}></span>;
};

export const groupByTitle = (
  nodes: ChangelogNode[]
): ChangeLogLeftPanelList[] => {
  if (!nodes || !Array.isArray(nodes)) {
    return [];
  }
  const grouped: {
    Parameters: ChangeLogFilterItem[];
    Nodes: ChangeLogFilterItem[];
    Outcomes: ChangeLogFilterItem[];
  } = {
    Parameters: [],
    Nodes: [],
    Outcomes: [],
  };
  nodes.forEach((node) => {
    let title: keyof typeof grouped;
    if (node.type === "input" || node.type === "output") {
      title = "Parameters";
    } else if (node.type === "end") {
      title = "Outcomes";
    } else {
      title = "Nodes";
    }
    grouped[title].push({
      id: node.id,
      type: node.type,
      nodeName: node.name,
      action: node.state,
    });
  });
  return Object.entries(grouped).map(([title, items]) => ({
    title,
    items,
  }));
};

export const splitString = (input: string) => {
  if (!input) return ["", input];

  // const dotIndex = input.search(/[.(\[]/);
  const dotIndex = input.search(/[.([]/);

  if (dotIndex === -1) return [input, ""];

  const firstPart = input.slice(0, dotIndex);
  const restOfString = input.slice(dotIndex);

  return [firstPart, restOfString];
};
export const highlightDifferencesBg = (prevData: string, currData: string) => {
  const result: {
    prevHighlighted: JSX.Element[];
    currHighlighted: JSX.Element[];
  } = { prevHighlighted: [], currHighlighted: [] };

  // If either prevData or currData is empty
  if (!prevData || !currData) {
    result.prevHighlighted.push(<span key="prev">{prevData}</span>);
    result.currHighlighted.push(<span key="curr">{currData}</span>);
    return result;
  }

  // If both prevData and currData differ completely (considered as single words)
  if (
    prevData !== currData &&
    !prevData.includes(" ") &&
    !currData.includes(" ")
  ) {
    result.prevHighlighted.push(
      <span key="prevFullDiff" className="bg-error-200">
        {prevData}
      </span>
    );
    result.currHighlighted.push(
      <span key="currFullDiff" className="bg-success-200">
        {currData}
      </span>
    );
    return result;
  }

  let prevIdx = 0;
  let currIdx = 0;

  while (prevIdx < prevData.length || currIdx < currData.length) {
    if (prevData[prevIdx] === currData[currIdx]) {
      result.prevHighlighted.push(
        <span key={`prev${prevIdx}`}>{prevData[prevIdx]}</span>
      );
      result.currHighlighted.push(
        <span key={`curr${currIdx}`}>{currData[currIdx]}</span>
      );
      prevIdx++;
      currIdx++;
    } else if (prevData.length === currData.length) {
      result.prevHighlighted.push(
        <span key={`prevGray${prevIdx}`} className="bg-neutral-50">
          {prevData[prevIdx]}
        </span>
      );
      result.currHighlighted.push(
        <span key={`currGray${currIdx}`} className="bg-neutral-200">
          {currData[currIdx]}
        </span>
      );
      prevIdx++;
      currIdx++;
    } else {
      let added = "";
      while (
        currIdx < currData.length &&
        (prevIdx >= prevData.length || prevData[prevIdx] !== currData[currIdx])
      ) {
        added += currData[currIdx];
        currIdx++;
      }
      if (added.length > 0) {
        result.currHighlighted.push(
          <span key={`currAdd${currIdx}`} className="bg-success-200">
            {added}
          </span>
        );
        result.prevHighlighted.push(
          <span key={`prevAdd${prevIdx}`} className="bg-success-100">
            {" ".repeat(added.length)}
          </span>
        );
      }

      let deleted = "";
      while (
        prevIdx < prevData.length &&
        (currIdx >= currData.length || prevData[prevIdx] !== currData[currIdx])
      ) {
        deleted += prevData[prevIdx];
        prevIdx++;
      }
      if (deleted.length > 0) {
        result.prevHighlighted.push(
          <span key={`prevDel${prevIdx}`} className="bg-error-200">
            {deleted}
          </span>
        );
        result.currHighlighted.push(
          <span key={`currDel${currIdx}`} className="bg-error-100">
            {" ".repeat(deleted.length)}
          </span>
        );
      }
    }
  }

  return result;
};

export const highlightDifferences = (prevData: string, currData: string) => {
  const { prevHighlighted, currHighlighted } = highlightDifferencesBg(
    prevData,
    currData
  );

  const adjustColor = (highlightedArray: JSX.Element[]) => {
    return highlightedArray.map((item, index) => {
      const text = (item as any).props.children;
      const className = (item as any).props.className;

      const [firstPart, rest] = splitString(text);

      if (firstPart && rest) {
        return (
          <span key={`adjusted${index}`} className={className}>
            <span className="text-primary-900">{firstPart}</span>
            {rest}
          </span>
        );
      }

      return item;
    });
  };

  const adjustedPrev = adjustColor(prevHighlighted);
  const adjustedCurr = adjustColor(currHighlighted);

  return {
    prevHighlighted: adjustedPrev,
    currHighlighted: adjustedCurr,
  };
};
