import { FinBoxResponse } from "src/types";
import axios from "@axios";
import { useMutation } from "@tanstack/react-query";
import { notify } from "@utils/utils";
import { DecisionTableChangeData } from "./DecisionTableChanglog.types";

export const useDecisionTableChangeLog = (policyBucketId: string) => {
  return useMutation({
    mutationFn: async (body: any) => {
      return axios.post<FinBoxResponse<DecisionTableChangeData>>(
        `/policybucket/${policyBucketId}/changelog/generate/decisiontable`,
        body
      );
    },
    onError: (err) => {
      notify({ title: "Failed", text: "CAM Report not available" });
    },
  });
};
