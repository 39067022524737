import "highlight.js/styles/stackoverflow-light.css";
import { Controller, useForm, useWatch } from "react-hook-form";
import Badge from "src/components/Badge";
import Button from "src/components/Button";
import Input from "src/components/Input";
import ProtectedComponent from "src/components/ProtectedComponent";
import Shimmer from "src/components/Shimmer";
import Tooltip from "src/components/Tooltip";
import { PERMISSIONS_TYPE } from "src/constants/permissionsConstant";
import { PermissionTypes } from "src/types";
import { debounce, notify } from "src/utils/utils";
import infoIcon from "@assets/icons/info-1.svg";
import { ReactComponent as CloseIcon } from "@assets/icons/x-close.svg";
import Label from "@components/Label";
import { useQueryClient } from "@tanstack/react-query";
import {
  lookupTableListDsQuery,
  useCreateLookup,
  useLookupDownloadFile,
  useLookupUpload,
  useUpdateLookup,
} from "../queries";
import { LookupData } from "../types";
import { FileUpload } from "./FileUpload";
import { analyticsInstance } from "src/config/event-analytics";
import { LookupActions } from "src/constants/EventAnalytics";

type FormType = {
  sourceName: string;
  alias: string;
  file?: File;
  confidential: boolean;
  defaultValue: string;
};

function LookupModel({
  modelData,
  close,
  openFromModel,
}: {
  modelData?: LookupData | undefined;
  close: () => void;
  openFromModel: boolean;
}) {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors, dirtyFields },
  } = useForm<FormType>({
    defaultValues: {
      sourceName: modelData?.name || "",
      alias: modelData?.alias || "",
      file: undefined,
      confidential: modelData?.confidential || false,
      defaultValue: modelData?.defaultValue || "",
    },
  });

  const lookupUpload = useLookupUpload();
  const createLookup = useCreateLookup();
  const updateLookup = useUpdateLookup();
  const downloadFile = useLookupDownloadFile();
  const qc = useQueryClient();

  const [file, alias] = useWatch({
    control: control,
    name: ["file", "alias"],
  });

  const handleApiSave = (data: FormType) => {
    const formData = new FormData();
    if (!data.file) {
      return;
    }
    if (!openFromModel) {
      analyticsInstance.triggerAnalytics(LookupActions.ADD_SOURCE_SAVE,{confidential:JSON.stringify(data?.confidential)});
      formData.append("name", data.sourceName);
      formData.append("alias", data.alias);
      formData.append("confidential", JSON.stringify(data?.confidential));
      formData.append("defaultValue", data.defaultValue);
      formData.append("file", data.file);
      createLookup.mutate(
        { formData },
        {
          onSuccess: () => {
            qc.invalidateQueries(lookupTableListDsQuery());
            close();
            notify({ title: "Success", text: "Added lookup", type: "success" });
          },
        }
      );
      return;
    }
    if (modelData) {
      analyticsInstance.triggerAnalytics(LookupActions.UPDATE_SOURCE_SAVE,{confidential:JSON.stringify(data?.confidential)});
      formData.append("file", data.file);
      formData.append("lookupId", (modelData?.id).toString());
      formData.append("confidential", JSON.stringify(data?.confidential));
      updateLookup.mutate(
        { formData },
        {
          onSuccess: () => {
            qc.invalidateQueries(lookupTableListDsQuery());
            close();
            notify({
              title: "Success",
              text: "Updated lookup",
              type: "success",
            });
          },
        }
      );
    }
  };

  const fetchUsageString = debounce((alias: string, file?: File) => {
    if (alias?.length < 3 || !file) return;
    const formData = new FormData();
    formData.append("alias", alias);
    formData.append("file", file);
    lookupUpload.mutate({ formData });
  }, 500);

  const handleFileDownload = () => {
    if (modelData?.id) {
      downloadFile.mutate(
        { lookupId: modelData?.activeVersionId },
        {
          onSuccess: (data) => {
            window.open(data.data.data, "_blank");
          },
        }
      );
    }
  };


  const cancelLookUp = () => {
    close();
    analyticsInstance.triggerAnalytics(openFromModel?LookupActions.UPDATE_SOURCE_CANCEL:LookupActions.ADD_SOURCE_CANCEL);
  }

  const closeModel = () => {
    close();
    analyticsInstance.triggerAnalytics(openFromModel?LookupActions.UPDATE_SOURCE_CLOSE:LookupActions.ADD_SOURCE_CLOSE);
  }

  return (
    <>
      <div className="h-screen overflow-auto hide-scrollbar bg-white relative flex-col">
        <div className="flex sticky top-0 bg-white z-10 items-center justify-between h-12 px-6 border-b border-neutral-100">
          <div className="font-b1-medium text-neutral-black">
            Add Lookup Table
          </div>
          <CloseIcon
            className="text-neutral-500 hover:text-neutral-black cursor-pointer"
            onClick={closeModel}
          />
        </div>
        <div className="flex flex-col gap-4 px-6 py-6 items-center border-b border-neutral-100 bg-white">
          <div className="justify-between flex items-center w-full">
            <Label className="font-b2-medium text-neutral-black">Name</Label>
            <Input
              className="w-[464px] [&>input]:font-b1 [&input]:text-neutral-500 ml-auto"
              placeholder="Enter name"
              {...register("sourceName", {
                maxLength: {
                  value: 30,
                  message: "Max length 30 characters",
                },
                required: {
                  value: true,
                  message: "*required",
                },
                disabled: openFromModel,
              })}
              error={errors.sourceName?.message}
            />
          </div>
          <div className="justify-between flex items-center w-full">
            <Label className="font-b2-medium text-neutral-black">
              <div className="flex gap-2">
                <span>Identifier</span>
                <Tooltip
                  right
                  content={<img src={infoIcon} alt="info icon" />}
                  tooltipContent={
                    <p>
                      The alias will be used when you add <br /> variables from
                      this source in any policy.
                    </p>
                  }
                />
              </div>
            </Label>
            <div>
              <Input
                className="w-[464px] [&>input]:font-b1 [&input]:text-gray-300"
                placeholder="Enter description"
                {...register("alias", {
                  onChange: (e) => {
                    fetchUsageString(e.target.value, file);
                  },
                  pattern: {
                    value: /^[a-z_.0-9]*$/,
                    message:
                      "Identifier should only have lowercase letters, numbers, dot, and underscore",
                  },
                  maxLength: {
                    value: 10,
                    message: "Max length 10 characters",
                  },
                  required: {
                    value: true,
                    message: "*required",
                  },
                  disabled: openFromModel,
                })}
                error={errors.alias?.message}
              />
              {alias?.length < 3 && dirtyFields?.alias ? (
                <span
                  className="text-error-600 font-medium text-xs block"
                  role="alert"
                >
                  Min length 3 characters
                </span>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <div className="p-6 border-b border-neutral-100">
          <Label className="font-b1-medium text-neutral-black">
            Upload file
          </Label>
          <Controller
            control={control}
            name="file"
            rules={{
              validate: (uploadFile) => {
                if (!uploadFile) {
                  return "select file";
                }
              },
              required: true,
            }}
            render={({ field }) => {
              const isFileConfidential = modelData?.confidential;
              const handleFileChange = (file: File | undefined) => {
                field.onChange(file);
                if (!file) return;
                if (!openFromModel) {
                  fetchUsageString(alias, file);
                }
              };
              return (
                <FileUpload
                  file={field.value}
                  handleFileChange={handleFileChange}
                  openFromModel={openFromModel}
                  isFileConfidential={isFileConfidential}
                  handleFileDownload={handleFileDownload}
                />
              );
            }}
          />
          {errors.file && (
            <span
              className="text-error-600 font-medium text-xs block"
              role="alert"
            >
              *required
            </span>
          )}
          {file?.size && file?.size > 5242880 && dirtyFields?.file && (
            <span
              className="text-error-600 font-medium text-xs block"
              role="alert"
            >
              *Max file size 5MB
            </span>
          )}

          <div>
            <Label
              className="flex items-center mt-3 mb-3"
              htmlFor="confidential"
            >
              <Input
                {...register("confidential")}
                className="mr-2 cursor-pointer"
                type="checkbox"
                name="confidential"
              />
              Keep file confidential (Once saved, you will no longer have access
              to its contents)
            </Label>
          </div>
        </div>
        <div className="p-6 border-b border-neutral-100">
          <div className="flex mb-4">
            <Label className="font-b1-medium text-neutral-black mr-2 w-[62px]">
              Input Keys
            </Label>
            <div className="flex flex-wrap">
              {lookupUpload.isPending ? (
                <Shimmer h="16px" w="100px" />
              ) : (
                <>
                  {lookupUpload.data ? (
                    <>
                      {lookupUpload.data.data.data.inputKeys.map((item) => {
                        return (
                          <Badge
                            variant="secondary"
                            className="rounded-md mr-2 mb-2"
                          >
                            {item}
                          </Badge>
                        );
                      })}
                    </>
                  ) : modelData?.inputKeys ? (
                    <>
                      {modelData?.inputKeys.map((item) => {
                        return (
                          <Badge
                            variant="secondary"
                            className="rounded-md mr-2 mb-2"
                          >
                            {item}
                          </Badge>
                        );
                      })}
                    </>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </div>
          </div>
          <div className="flex mb-4">
            <Label className="font-b1-medium text-neutral-black mr-2 w-[72px]">
              Output Keys
            </Label>
            <div className="flex flex-wrap">
              {lookupUpload.isPending ? (
                <Shimmer h="16px" w="100px" />
              ) : (
                <>
                  {lookupUpload.data ? (
                    <>
                      {lookupUpload.data.data.data.outputKeys.map((item) => {
                        return (
                          <Badge
                            variant="secondary"
                            className="rounded-md mr-2 mb-2"
                          >
                            {item}
                          </Badge>
                        );
                      })}
                    </>
                  ) : modelData?.outputKeys ? (
                    <>
                      {modelData?.outputKeys.map((item) => {
                        return (
                          <Badge
                            variant="secondary"
                            className="rounded-md mr-2 mb-2"
                          >
                            {item}
                          </Badge>
                        );
                      })}
                    </>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </div>
          </div>
          <div className="justify-between flex items-center w-full">
            <Label className="font-b2-medium text-neutral-black">
              <div className="flex gap-2">
                <span>Default value</span>
                <Tooltip
                  right
                  content={<img src={infoIcon} alt="info icon" />}
                  tooltipContent={
                    <p>
                      Specify a fallback value
                      <br /> when lookup fails
                    </p>
                  }
                />
              </div>
            </Label>
            <Input
              placeholder="Enter value"
              className="w-[464px] [&>input]:font-b1 [&input]:text-gray-300"
              disabled={openFromModel}
              {...register("defaultValue", {
                required: {
                  value: true,
                  message: "*required",
                },
                maxLength: {
                  value: 50,
                  message: "Max length 50 characters",
                },
              })}
              error={errors.defaultValue?.message}
            />
          </div>
        </div>
        <div className="p-6">
          <Label className="font-b2-medium text-neutral-black">
            <div className="flex gap-2">
              <span>Usage Example</span>
              <Tooltip
                right
                content={<img src={infoIcon} alt="info icon" />}
                tooltipContent={
                  <p>
                    Syntax of using lookup table
                    <br /> while creating policy.
                  </p>
                }
              />
            </div>
          </Label>
          {lookupUpload.isPending ? (
            <Shimmer h="16px" w="180px" className="mt-2" />
          ) : lookupUpload.data ? (
            <p className="font-b1-medium font-code">
              {lookupUpload.data.data.data.sampleUsage}
            </p>
          ) : modelData?.sampleUsage ? (
            <p className="font-b1-medium font-code">{modelData.sampleUsage}</p>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="sticky bottom-0 mt-auto h-12 flex gap-2 items-center justify-end w-full px-6 bg-neutral-0 border-t border-neutral-100">
        <Button onClick={cancelLookUp} variant="outline">
          Cancel
        </Button>

        {openFromModel ? (
          <ProtectedComponent
            type={PERMISSIONS_TYPE.dataSourcesLookup as PermissionTypes}
            action="edit"
          >
            <Button
              onClick={handleSubmit(handleApiSave)}
              disabled={
                createLookup.isPending ||
                updateLookup.isPending ||
                (file && file?.size > 5242880)
              }
            >
              Update
            </Button>
          </ProtectedComponent>
        ) : (
          <ProtectedComponent
            type={PERMISSIONS_TYPE.dataSourcesLookup as PermissionTypes}
            action="create"
          >
            <Button
              onClick={handleSubmit(handleApiSave)}
              disabled={
                createLookup.isPending ||
                updateLookup.isPending ||
                (file && file?.size > 5242880)
              }
            >
              Save
            </Button>
          </ProtectedComponent>
        )}
      </div>
    </>
  );
}

export default LookupModel;
