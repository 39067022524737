import { ExploreSentinel } from "./ExploreOverviewCard.types";

export const ExploreSentinelData: ExploreSentinel[] = [
    {
      text: "Invite Members",
      videoLink:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/sentinel/onboarding-vids/FinBox+Sentinel+-+Invite+New+members.mp4",
      infoText:
        "Invite members easily by entering their details in the 'Invite Members' section and get started with seamless collaboration and growth",
      ctaText: "Invite member",
      goTo: "/settings/administration/members",
    },
    {
      text: "Create your first policy",
      videoLink:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/sentinel/onboarding-vids/FinBox+Sentinel+-+Policy+creation.mp4",
      infoText:
        "Create your first policy to configure, add different data sources, add your rules and compute your offer parameters",
      ctaText: "Create Policy",
      goTo: "/policies",
    },
    {
      text: "Publish a Policy",
      videoLink:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/sentinel/onboarding-vids/FinBox+Sentinel+-+Publish+a+policy.mp4",
      infoText: "Take your policy live and see how it performs in real life",
      ctaText: "Publish Policy",
      goTo: "/endpoints",
    },
    {
      text: "View Outcomes &  Outcome Details",
      videoLink:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/sentinel/onboarding-vids/FinBox+Sentinel+-+View+outcomes+and+outcome+details.mp4",
      infoText:
        " Published your policy already? Great!! Now lets see how the incoming applications are performing on the policy Outcomes and outcome details page",
      ctaText: "View Outcomes",
      goTo: "/reports",
    },
    {
      text: "Create Analytics Template",
      videoLink:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/sentinel/onboarding-vids/FinBox+Sentinel+-+create+analytics+dashboard.mp4",
      infoText:
        "Now that users are getting evaluated on Sentinel, lets create a dashboard to analyse the performance of a policy",
      ctaText: "Create Template",
      goTo: "/analytics",
    },
    {
      text: "Create a Monitor",
      videoLink:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/sentinel/onboarding-vids/FinBox+Sentinel+-+Create+monitors.mp4",
      infoText:
        "Lastly, add alerts on a policy to monitor the functioning of the policy and get alerts on a real-time basis to track, mitigate and deploy any anomalies",
      ctaText: "Create Monitor",
      goTo: "/monitors",
    },
  ];
  