import {
    formatDistanceToNow,
    parseISO,
  } from "date-fns";
  import { useNavigate } from "react-router-dom";
  import {
    useMarkNotificationRead,
  } from "src/apis";
  import StatusChip from "src/components/StatusChip";
  import {
    PolicyNotification,
    PolicyStatus,
  } from "src/types";
  import { ReactComponent as PolicyIcon } from "@assets/icons/dataflow-01.svg";
  import { ArrowRightIcon } from "@heroicons/react/20/solid";
  import { CTA_MAPPER } from "./NotificationCenter.constants";
import NotificationBaseCard from "./NotificationBaseCard";

const PolicyCard = ({ item }: { item: PolicyNotification }) => {
    const navigate = useNavigate();
    const markNotificationRead = useMarkNotificationRead();
  
    const handleCtaAction = () => {
      if (item.metadata?.type === "policy") {
        navigate(`/create-policy/${item.metadata.id}`);
        markNotificationRead.mutate({ notificationId: item.id });
      } else {
        navigate(`/workflow/${item.metadata.id}`);
        markNotificationRead.mutate({ notificationId: item.id });
      }
    };
  
    return (
      <>
        <NotificationBaseCard isRead={item.isRead}>
          <>
            <div className="bg-neutral-25 p-1.5 rounded-full w-7 h-7 items-center justify-center">
              <PolicyIcon className="[&>path]:stroke-neutral-600 w-4 h-4" />
            </div>{" "}
            <div className="flex flex-col ml-4 w-[90%]">
              <p className="mb-3 font-b1 text-neutral-600">
                <span className="text-neutral-black font-b1-medium">
                  {item.metadata?.updatedBy}
                </span>{" "}
                updated the status of{" "}
                <span className="text-neutral-black font-b1-medium">
                  {item.metadata?.name}
                </span>
              </p>
              <div className="flex gap-1 mb-2 items-center">
                <StatusChip status={item.metadata?.oldStatus as PolicyStatus} />
                <ArrowRightIcon className="stroke-neutral-600 w-4 h-4" />
                <StatusChip status={item.metadata?.newStatus as PolicyStatus} />
              </div>
              <div className="flex justify-between">
                <p className="font-[12px] text-neutral-600">
                  {formatDistanceToNow(parseISO(item.updatedAt))} ago
                </p>
                <button
                  className="group-hover:visible invisible flex text-primary-900 font-b2-medium items-center"
                  onClick={() => handleCtaAction()}
                >
                  {CTA_MAPPER[item.type]?.text}
                  {CTA_MAPPER[item.type]?.icon}
                </button>
              </div>
            </div>
          </>
        </NotificationBaseCard>
      </>
    );
  };

export default PolicyCard;