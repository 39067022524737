import classNames from "classnames";
import { useState } from "react";
import { useNavigate } from "react-router";
import Button from "src/components/Button";
import Header from "src/components/Header";
import ProtectedComponent from "src/components/ProtectedComponent";
import Shimmer from "src/components/Shimmer";
import { PERMISSIONS_TYPE } from "src/constants/permissionsConstant";
import useBreadcrumbs from "src/context/BreadcrumbsContext";
import usePermissions from "src/context/PermissionContext";
import { PermissionTypes } from "src/types";
import { getInitial, titleCase } from "src/utils/utils";
import { ReactComponent as DuplicateIcon } from "@assets/icons/copy-07.svg";
import { ReactComponent as DotsIcon } from "@assets/icons/dots-horizontal.svg";
import { ReactComponent as EditIcon } from "@assets/icons/edit-01.svg";
import { ReactComponent as EyeIcon } from "@assets/icons/eye.svg";
import { ReactComponent as TrashIcon } from "@assets/icons/workflow/trash-02.svg";
import EmptyState from "@components/EmptyState";
import Menu from "@components/FloatingMenu";
import Table from "@components/Table";
import { Badge, Tooltip } from "@finbox-in/finblocks";
import { AddNewRole } from "./AddNewRole";
import { ChangeAndDelete } from "./ChangeAndDelete";
import { useAllRoleListQuery } from "./queries";
import { RBACRole } from "./types";
import { analyticsInstance } from "src/config/event-analytics";
import { SettingsActions } from "src/constants/EventAnalytics";

export const Roles = () => {
  const [showNameModal, setShowNameModal] = useState(false);
  const [showDuplicate, setShowDuplicate] = useState(false);
  const [updateRole, setUpdateRole] = useState("");
  const [changeAndDelete, setChangeAndDelete] = useState(false);

  const navigate = useNavigate();
  const {
    data: roleList,
    isPending,
    isSuccess,
    isFetching,
  } = useAllRoleListQuery();
  useBreadcrumbs([
    {
      name: "Administration",
      link: "/settings/roles",
    },
    {
      name: "Roles",
      link: "#",
    },
  ]);

  const handleViewRedirection = (role: string, type: string) => {
    navigate("/settings/roles/update", {
      state: {
        roleName: role,
        roleType: type,
      },
    });
  };
  const handleDuplicate = (roleName: string) => {
    setUpdateRole(roleName);
    setShowNameModal(true);
    setShowDuplicate(true);
  };
  const defaultRoles = roleList
    ?.filter((p) => p?.isDefault === true)
    .map((p) => ({
      ...p,
      type: "default" as const,
    }));
  const customRoles =
    roleList
      ?.filter((p) => p?.isDefault === false)
      .map((p) => ({
        ...p,
        type: "custom" as const,
      })) || [];

  const openCreateNewRoleModel = () => {
    setShowNameModal(true);
    setShowDuplicate(false);

    analyticsInstance.triggerAnalytics(
      SettingsActions.ROLES_CREATE
    );
  }

  return (
    <>
      <Header className="w-full border-b-0">
        <div>
          <div className="flex justify-between">
            <div className="flex justify-between w-full items-center">
              <div className="my-5 mx-2 mb-3">
                <Header.PageHeader>Administration</Header.PageHeader>
                <Header.Description>
                  Manage your team members access for your organization
                </Header.Description>
              </div>
              <div className="flex gap-2 mr-2">
                <ProtectedComponent
                  type={PERMISSIONS_TYPE.administrationRoles as PermissionTypes}
                  action="create"
                >
                  <Button
                    variant="primary"
                    className="sm:w-fit"
                    onClick={openCreateNewRoleModel}
                  >
                    <span className="whitespace-nowrap">Create New Role</span>
                  </Button>
                </ProtectedComponent>
              </div>
            </div>
          </div>
        </div>
      </Header>
      <div className="flex mx-8 my-6 flex-col">
        <>
          {isPending && <Shimmer w="100%" h="500px" />}
          {isSuccess &&
            !isPending &&
            customRoles.length + defaultRoles!.length > 0 && (
              <Table
                className="rounded-b-md"
                data={[
                  { type: "header" as const, value: "Default Roles" },
                  ...defaultRoles!,
                  { type: "header" as const, value: "Custom Roles" },
                  ...customRoles,
                ]}
                emptyText={<EmptyState>No roles found</EmptyState>}
                headers={["Name", "Members", ""]}
                isLoading={isFetching}
              >
                {(row, defaultRowClassNames) => {
                  return (
                    <AuditRow
                      row={row}
                      defaultRowClassNames={defaultRowClassNames}
                      onDuplicate={handleDuplicate}
                      onView={handleViewRedirection}
                      setChangeAndDelete={setChangeAndDelete}
                      setUpdateRole={setUpdateRole}
                    />
                  );
                }}
              </Table>
            )}
        </>
      </div>
      {showNameModal && (
        <AddNewRole
          show={showNameModal}
          setShow={setShowNameModal}
          isDuplicate={showDuplicate}
          oldRoleName={updateRole}
        />
      )}
      {changeAndDelete && (
        <ChangeAndDelete
          show={changeAndDelete}
          setShow={setChangeAndDelete}
          roleName={updateRole}
          roleList={roleList!}
          userList={
            roleList?.find((el) => el.role === updateRole)?.members || []
          }
        />
      )}
    </>
  );
};

const TD_WIDTH_CLASSES = ["w-[24%]", "w-[64%]", "w-[12%]"];

const UserNameIcon = ({ name }: { name: string }) => {
  return (
    <span className="flex gap-1 items-center font-b1">
      <span className="w-6 h-6 flex justify-center items-center rounded-full bg-neutral-900 text-neutral-0 font-medium text-[11px]">
        {getInitial(name)}
      </span>
      {name}
    </span>
  );
};
const AuditRow = ({
  row,
  defaultRowClassNames,
  onDuplicate,
  onView,
  setChangeAndDelete,
  setUpdateRole,
}: {
  row:
    | { type: "header"; value: string }
    | { type: "fullWidth"; value: string }
    | (RBACRole & { type: "default" })
    | (RBACRole & { type: "custom" });
  defaultRowClassNames: string;
  onDuplicate: (v: string) => void;
  onView: (v: string, w: string) => void;
  setChangeAndDelete: React.Dispatch<React.SetStateAction<boolean>>;
  setUpdateRole: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const { getPermissions } = usePermissions();
  const permissions = getPermissions(
    PERMISSIONS_TYPE.administrationRoles as PermissionTypes,
    "edit"
  );
  if (row.type === "header") {
    return (
      <tr>
        <td
          className="px-6 bg-neutral-0 py-0 leading-[28px] font-b2-medium"
          colSpan={7}
        >
          {row.value}
        </td>
      </tr>
    );
  }

  if (row.type === "fullWidth")
    return (
      <tr>
        <td
          className="px-6 py-2.5 bg-white leading-[28px] font-b1 text-neutral-500"
          colSpan={7}
        >
          {row.value}
        </td>
      </tr>
    );

  return (
    <tr
      key={row?.role}
      className={classNames(
        defaultRowClassNames,
        "text-sm font-medium text-neutral-black pointer-events-none !cursor-auto"
      )}
    >
      <td className={classNames("!font-medium", TD_WIDTH_CLASSES[0])}>
        {titleCase(row?.role)}
      </td>
      <td className={classNames("pointer-events-auto", TD_WIDTH_CLASSES[1])}>
        <div className="flex gap-4 items-center justify-start pointer-events-auto">
          {row.members.length === 0 && "-"}
          {row.members.slice(0, 4).map((item) => {
            return <UserNameIcon name={item.name} />;
          })}

          <Tooltip
            placement="right-start"
            title=""
            toolTipContent={
              <div className="flex flex-col gap-2">
                {row.members.slice(4, row.members.length).map((item) => {
                  return <UserNameIcon name={item.name} />;
                })}
              </div>
            }
            className="z-20 mr-2"
          >
            {row.members.length > 4 && (
              <Badge variant="secondary">+ {row.members.length - 4}</Badge>
            )}
          </Tooltip>
        </div>
      </td>
      <td className={TD_WIDTH_CLASSES[2]}>
        <div className="flex items-center justify-end">
          {row.type === "default" ? (
            <EyeIcon
              onClick={(e) => {
                e.stopPropagation();
                onView(row.role, row.type);
              }}
              className="pointer-events-auto cursor-pointer flex items-center justify-between rounded-md p-1.5 text-sm text-gray-500 shadow-sm ring-1 ring-gray-300 ml-auto w-7 h-7 duration-150 [&>path]:stroke-neutral-500 hover:bg-neutral-50"
            />
          ) : !!permissions ? (
            <EditIcon
              onClick={(e) => {
                e.stopPropagation();
                onView(row.role, row.type);
              }}
              className="pointer-events-auto cursor-pointer flex items-center justify-between rounded-md p-1.5 text-sm text-gray-500 shadow-sm ring-1 ring-gray-300 ml-auto w-7 h-7 duration-150 [&>path]:stroke-neutral-500 hover:bg-neutral-50"
            />
          ) : (
            <EyeIcon
              onClick={(e) => {
                e.stopPropagation();
                onView(row.role, "default");
              }}
              className="pointer-events-auto cursor-pointer flex items-center justify-between rounded-md p-1.5 text-sm text-gray-500 shadow-sm ring-1 ring-gray-300 ml-auto w-7 h-7 duration-150 [&>path]:stroke-neutral-500 hover:bg-neutral-50"
            />
          )}

          <Menu as="div" className="relative flex">
            <Menu.Button
              onClick={(e: MouseEvent) => e.stopPropagation()}
              className="ml-2 w-7 h-7 duration-150 p-1.5 hover:bg-neutral-50 pointer-events-auto"
            >
              <DotsIcon className="w-4 h-4 stroke-neutral-800 [&>path]:stroke-neutral-800" />
            </Menu.Button>

            <Menu.Items
              onClick={(e: MouseEvent) => e.stopPropagation()}
              className="cursor-pointer left-2 top-8 rounded-md absolute z-10 flex w-[156px] flex-col bg-white text-neutral-black focus:outline-none border border-neutral-100 shadow-1"
            >
              <ProtectedComponent
                type={PERMISSIONS_TYPE.administrationRoles as PermissionTypes}
                action="create"
              >
                <Menu.Item
                  onClick={(e: MouseEvent) => {
                    e.stopPropagation();
                    onDuplicate(row?.role);
                  }}
                  className={classNames(
                    "p-2 flex gap-2 items-center rounded-t-md font-b2-medium !text-neutral-black border-neutral-100 bg-white hover:bg-neutral-25",
                    row.type === "default" && "rounded-b-md"
                  )}
                >
                  <DuplicateIcon className="w-4 h-4 stroke-neutral-500" />
                  Duplicate Role
                </Menu.Item>
              </ProtectedComponent>
              {row.type === "custom" && (
                <ProtectedComponent
                  type={PERMISSIONS_TYPE.administrationRoles as PermissionTypes}
                  action="delete"
                >
                  <Menu.Item
                    onClick={(e: MouseEvent) => {
                      e.stopPropagation();
                      setUpdateRole(row.role);
                      setChangeAndDelete(true);
                    }}
                    className="p-2 flex gap-2 items-center font-b2-medium border-t rounded-b-md bg-white hover:bg-neutral-25 !text-error-600"
                  >
                    <TrashIcon className="z-10 h-4 w-4 cursor-pointer [&>path]:stroke-error-600" />
                    Archive
                  </Menu.Item>
                </ProtectedComponent>
              )}
            </Menu.Items>
          </Menu>
        </div>
      </td>
    </tr>
  );
};
