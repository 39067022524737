import { JsonNodeType } from "./JsonInput.types";

export const findPathIndexById = (data: JsonNodeType, targetId: string, path: number[] = []): number[] | null => {
    if (data.id === targetId) {
        return path;
    }

    if (data.children && Array.isArray(data.children)) {
        for (let i = 0; i < data.children.length; i++) {
        const child = data.children[i];
        const result = findPathIndexById(child, targetId, [...path, i]); // Pass index instead of ID
        if (result) {
            return result;
        }
        }
    }

    return null; // Return null if the node is not found
}

export const setPropertyAtPath = (data: JsonNodeType, pathIndexes: number[], property: string, value: any): JsonNodeType =>  {
    let currentNode: JsonNodeType = data;

    for (let i = 0; i < pathIndexes.length; i++) {
      const index = pathIndexes[i];
      
      // Check if current node has children and the index is valid
      if (!currentNode?.children || currentNode.children.length <= index) {
        return data; // Invalid path or index out of bounds
      }

      // Traverse to the next node
      if (i === pathIndexes.length - 1) {
        // We're at the target node, so set the property
        currentNode.children[index][property as 'defaultInput' | 'isNullable'] = value;
        return data; // Successfully set the property
      }

      currentNode = currentNode.children[index];
    }

    return data; // Return false if the path could not be fully traversed
}
