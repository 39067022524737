import { WORKFLOW_PAGE_LIMIT } from "src/config";
import {
  getGQEndTime,
  getGQStartTime,
  normalizeQueryKey,
} from "src/utils/utils";
import axios from "@axios";
import { DATE_FORMAT } from "@config";
import {
  keepPreviousData,
  queryOptions,
  useQuery,
} from "@tanstack/react-query";
import { FinBoxResponse } from "@types";
import { Workflow } from "../workflow/types";
import { WORKFLOW_STATUS } from "src/constants/appLevel";

export const getWorkflowListQuery = (args?: {
  startDate: string | null;
  endDate: string | null;
  activeTab: string[] | string;
  page: number;
  searchQuery?: string;
  enabled: boolean;
}) => {
  return queryOptions({
    queryKey: normalizeQueryKey(["workflowList", args]),
    queryFn: async () => {
      if (!args) throw Error("Invalid argruments");
      const params: Record<string, any> = {
        page: args.page.toString(),
        limit: WORKFLOW_PAGE_LIMIT,
        name: args.searchQuery,
        startDate: getGQStartTime(args.startDate, DATE_FORMAT),
        endDate: getGQEndTime(args.endDate, DATE_FORMAT),
        status:
          args.activeTab === "all"
            ? WORKFLOW_STATUS
            : args.activeTab,
      };
      return axios.get<
        FinBoxResponse<{ workflows: Workflow[] | null; totalCount: number }>
      >(`/workflow/list`, {
        params,
      });
    },
    select: (data) => data.data?.data,
    placeholderData: keepPreviousData,
    enabled: args?.enabled,
  });
};

export const useGetWorkflowList = (args: {
  startDate: string | null;
  endDate: string | null;
  activeTab: string[] | string;
  page: number;
  searchQuery?: string;
  enabled: boolean;
}) => useQuery(getWorkflowListQuery(args));
