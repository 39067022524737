import NotificationProvider from "@context/NotificationProvider";
import { PermissionProvider } from "@context/PermissionContext";
import { SseProvider } from "@context/SseProvider";
import "@finbox-in/finblocks/dist/style.css";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { PropsWithChildren } from "react";
import "react-datepicker/dist/react-datepicker.css";
import queryClient from "src/queryClient";
import "./App.css";
import { AppProvider } from "./context";

export default function Providers({ children }: PropsWithChildren) {
  return ( 
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <AppProvider>
          <PermissionProvider>
            <NotificationProvider>
              <SseProvider>{children}</SseProvider>
            </NotificationProvider>
          </PermissionProvider>
        </AppProvider>
      </QueryClientProvider>
  );
}
