import { useNavigate } from "react-router";
import Button from "src/components/Button";
import { useExploreSentinelNext } from "../../queries";
import { analyticsInstance } from "src/config/event-analytics";
import { OverviewActions } from "src/constants/EventAnalytics";
import { StepsCardProps } from "./ExploreOverviewCard.types";
import { ExploreSentinelData } from "./ExploreOverviewCard.constants";
import { useRef } from "react";

const ExploreOverviewStepCard = ({ currentStepId }: StepsCardProps) => {
    const videoRef = useRef<HTMLVideoElement>(null);
    const navigate = useNavigate();
    const nextStep = useExploreSentinelNext();
  
    const handleGoTo = () => {
      navigate(ExploreSentinelData[currentStepId].goTo);
      nextStep.mutate({ stepNumber: (currentStepId + 2).toString() });
      analyticsInstance.triggerAnalytics(OverviewActions.ONBOARDING_ACTION,{title:ExploreSentinelData[currentStepId].text, action:"taken"});
    };
    const handleNextStep = () => {
      nextStep.mutate({ stepNumber: (currentStepId + 2).toString() });
      analyticsInstance.triggerAnalytics(OverviewActions.ONBOARDING_ACTION,{title:ExploreSentinelData[currentStepId].text, action:"Skip"});
    };

    const handlePlay = () => {
        analyticsInstance.triggerAnalytics(OverviewActions.ONBOARDING_VIDEO,{title:ExploreSentinelData[currentStepId].text, videoName:ExploreSentinelData[currentStepId].text});
    };
    
  
    return (
      <div className="flex items-center w-full h-[256px] ml-5 p-4 border border-neutral-50 bg-neutral-0 rounded-md">
        <div className="flex items-center w-[394px] justify-center border-2 rounded-md border-neutral-50">
          <video src={ExploreSentinelData[currentStepId]?.videoLink} controls ref={videoRef} onPlay={handlePlay}>
            <source
              src={ExploreSentinelData[currentStepId]?.videoLink}
              type="video/mp4"
            />
          </video>
        </div>
        <div className="ml-8 w-[45%]">
          <p className="font-b2 text-neutral-black mb-4">
            {ExploreSentinelData[currentStepId]?.infoText}
          </p>
          <div className="flex flex-row">
            <Button className="mr-3" onClick={handleGoTo}>
              {ExploreSentinelData[currentStepId]?.ctaText}
            </Button>
            <Button
              disabled={currentStepId === 5}
              variant="outline"
              onClick={handleNextStep}
            >
              Skip
            </Button>
          </div>
        </div>
      </div>
    );
  };

  export default ExploreOverviewStepCard;