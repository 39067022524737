import React, { useRef, useEffect, ChangeEvent, useState } from "react";

interface JSONEditorProps{ 
    setJsonInput: (newContent: string) => void;
    jsonInput: string;
    transformJsonData:() => void; 
    transformDisabled:boolean;
}

const JSONEditor = ({ setJsonInput, jsonInput, transformJsonData, transformDisabled }:JSONEditorProps ) => {
    const editorRef = useRef<HTMLTextAreaElement>(null);
    const lineNumbersRef = useRef<HTMLDivElement>(null);
    const [lineNumbers, setLineNumbers] = useState<string[]>(["1"]);

    const updateLineNumbers = () => {
        const lines = jsonInput.split("\n").length;
        setLineNumbers(Array.from({ length: lines }, (_, i) => (i + 1).toString()));
    };

    const handleInputChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        e.stopPropagation();
        e.preventDefault();
        setJsonInput(e.target.value);
        updateLineNumbers();
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.key === "Tab") {
            e.preventDefault();
            const target = e.target;
            const start = (target as HTMLTextAreaElement).selectionStart;
            const end = (target as HTMLTextAreaElement).selectionEnd;
            const newValue = jsonInput.substring(0, start) + "  " + jsonInput.substring(end);
            setJsonInput(newValue);
            setTimeout(() => {
                (target as HTMLTextAreaElement).selectionStart = (target as HTMLTextAreaElement).selectionEnd = start + 2;
            }, 0);
            updateLineNumbers();
        }
    };

    useEffect(() => {
        updateLineNumbers();
    }, [jsonInput]);

    // Sync scroll between line numbers and editor
    const handleScroll = () => {
        if (lineNumbersRef.current && editorRef.current) {
            lineNumbersRef.current.scrollTop = editorRef.current.scrollTop;
        }
    };

    const handleTransformClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        event.preventDefault();
        transformJsonData();
    };

    return (
        <div className="h-[calc(100%-48px)] max p-4 gap-3 flex flex-col">
            <div className="flex justify-between items-center">
                <div className="text-sm text-neutral-black font-medium">Code</div>
                <button
                    onClick={handleTransformClick}
                    disabled={transformDisabled}
                    className="font-b1-medium text-primary-900 disabled:opacity-50 hover:text-[#1643DF]"
                >
                   Transform
                </button>
            </div>
            <div className="h-full border border-neutral-100 rounded-md  bg-neutral-0 hide-scrollbar">
                <div className="h-full max-h-[493px] w-full p-4 pb-1 flex overflow-hidden hide-scrollbar ">
                    {/* Line Numbers */}
                    <div
                        ref={lineNumbersRef}
                        className="h-full max-w-[60px] pr-1 text-left text-gray-600 font-mono text-sm select-none overflow-y-hidden hide-scrollbar"
                    >
                        {lineNumbers.map((lineNumber) => (
                            <div key={lineNumber} className="leading-5">{lineNumber}.</div>
                        ))}
                    </div>
                    {/* JSON Editor */}
                    <div className="h-full w-full overflow-auto  overflow-y-hidden hide-scrollbar">
                        <textarea
                            ref={editorRef}
                            autoFocus
                            value={jsonInput}
                            onChange={handleInputChange}
                            onKeyDown={handleKeyDown}
                            onScroll={handleScroll}
                            className="w-full h-full font-mono text-sm no-outline border-none resize-none bg-neutral-0 show-scrollbar leading-5"
                            aria-label="JSON input"
                            spellCheck="false"
                            placeholder="// Paste your JSON and click ’Transform’ to see data structure here //"
                            style={{
                                paddingLeft: "0.3rem",
                                whiteSpace: "pre", // Allows horizontal scrolling
                                overflowX: "auto", // Enables horizontal scrolling
                            }}
                        />
                    </div>
                </div>

            </div>
        </div>
    );
};

export default React.memo(JSONEditor);
