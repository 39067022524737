import { createContext, useContext, useEffect } from "react";
import { getNotReadCounts, getNotificationsList } from "src/apis";
import { useAppState } from "@context/AppProvider";
import { useQueryClient } from "@tanstack/react-query";
import logger from "@utils/Logger";

const SseContext =
  createContext<React.MutableRefObject<EventSource | null> | null>(null);

export function SseProvider({ children }: { children: React.ReactNode }) {
  const appState = useAppState();
  const qc = useQueryClient();

  useEffect(() => {
    const controller = new AbortController();
    const connectToSSE = () => {
      fetch(
        import.meta.env.REACT_APP_API_ENDPOINT + "/sentinel/dashboard/sse",
        {
          credentials: "include",
          signal: controller.signal,
          keepalive: true,
        }
      )
        .then((response) => {
          if (response.ok) {
            const stream = response.body;
            if (!stream) return;
            // Get the reader from the stream
            const reader = stream.getReader();
            // Define a function to read each chunk
            const readChunk = () => {
              // Read a chunk from the reader
              reader
                .read()
                .then(({ value, done }) => {
                  if (done) return;
                  // Convert the chunk value to a string
                  const chunkString = new TextDecoder().decode(value);
                  if (chunkString) {
                    qc.invalidateQueries(getNotificationsList());
                    qc.invalidateQueries(getNotReadCounts());
                  }
                  readChunk();
                })
                .catch((error) => {
                  logger.error(error);
                });
            };
            // Start reading the first chunk
            readChunk();
          } else {
            if (response.status === 504) {
              connectToSSE();
            }
          }
        })
        .catch((error) => {
          if (controller.signal.aborted) return;
          logger.error(error);
          setTimeout(connectToSSE, 5000);
        });
    };

    connectToSSE();

    return () => {
      controller.abort();
    };
  }, [appState.appState.token, qc]);

  return <SseContext.Provider value={null}>{children}</SseContext.Provider>;
}

export default function useSse() {
  const context = useContext(SseContext);
  if (!context) throw Error("useSse can only be used inside PermissionContext");
  return context;
}
