import { useRef, useState } from "react";
import { ReactComponent as UploadIcon } from "@assets/icons/UploadIcon.svg";
import { useSimulate } from "@screens/workflow/components/Simulation/queries";
import Button from "@components/Button";
import Dropdown from "@components/DropDown";
import Link from "@components/Link";
import ErrorText from "@components/RunSimulationsModal/ErrorText";
import Footer from "@components/RunSimulationsModal/Footer";
import useSimulation from "@components/RunSimulationsModal/useSimulation";
import { TrashIcon } from "@heroicons/react/24/outline";
import { SimulationModalProps } from "../SimulationModal.types";
import { analyticsInstance } from "src/config/event-analytics";
import { PolicyStudioActions } from "src/constants/EventAnalytics";
import { useWorkflowContext } from "src/screens/workflow/WorkflowContext";

const customValueMap = {
  reference_id: "Reference ID",
  user_id: "User ID",
};

export default function Application({ closeModal, policyID, ...props }: SimulationModalProps) {
  const [customValue, setCustomValue] =
    useState<keyof typeof customValueMap>("reference_id");
  const [file, setFile] = useState<File>();
  const [errors, setErrors] = useState<
    Partial<{
      file: string;
      customValue: string;
    }>
  >();

  const fileInputRef = useRef<HTMLInputElement>(null);
  const { workflow } = useWorkflowContext();

  const runWfSimulation = useSimulate();
  const { isPending, mutate } = useSimulation((data: any) => {
    window.open(data.data.url, "_blank");
    closeModal();
    setFile(undefined);
    setCustomValue("reference_id");
    setErrors({});
    if (fileInputRef.current) fileInputRef.current.value = "";
  });

  const validate = () => {
    const tempErrors: typeof errors = {};

    if (!file) tempErrors.file = "File is required";
    if (!customValue) tempErrors.customValue = "This is required";

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };
  const submitSimulation = () => {
    if (!validate() || !file) return;
    if (props.type === "workflow" && props?.workflowId) {
      runWfSimulation.mutate({
        type: "application",
        file,
        workflowId: props.workflowId,
        valueType: customValue,
      });
    } else {
      const formData = new FormData();
      formData.append("policy_id", policyID);
      formData.append("value_type", customValue);
      formData.append("file", file);

      mutate({ formData, type: "Application" });
    }

    analyticsInstance.triggerAnalytics(
      PolicyStudioActions.SIDEBAR_SIMULATION_APPLICATION_RUN_SIMULATION,
      {
        bucket_name: workflow?.policyName ?? "",
        version: workflow?.policyVersion ?? "",
        parameter_type:customValue,
        simulation_type:"Application",
      }
    );
  };

  return (
    <>
      <div className="mt-4 flex flex-col ">
        <div className="text-neutral-500 font-b2-medium mb-2">
          Select parameter
        </div>
        <Dropdown
          value={
            customValue &&
            customValueMap[customValue as keyof typeof customValueMap]
          }
          className="w-full"
          onChange={(e: typeof customValue) => setCustomValue(e)}
        >
          <Dropdown.Button className="relative w-full rounded-md border py-2 px-4 text-left">
            {customValueMap[customValue as keyof typeof customValueMap]}
          </Dropdown.Button>
          <Dropdown.Options className="absolute left-0 top-10 z-20 box-border w-full rounded-md border bg-white shadow-lg ring-1 ring-gray-100">
            {(["reference_id", "user_id"] as const).map((p) => (
              <Dropdown.Option key={p} value={p} showTick>
                <div>{customValueMap[p]}</div>
              </Dropdown.Option>
            ))}
          </Dropdown.Options>
        </Dropdown>
        <div className="relative w-full">
          {errors?.customValue && <ErrorText>{errors?.customValue}</ErrorText>}

          <div className="group relative flex flex-col mt-4 items-center gap-1 rounded-md border border-dashed border-neutral-100 p-5 text-xs text-gray-500">
            {file ? (
              <span className="flex items-center gap-1">
                {file.name}
                <TrashIcon
                  className="z-10 h-4 w-4 cursor-pointer text-red-600"
                  onClick={() => setFile(undefined)}
                />
              </span>
            ) : (
              <>
                <span className="w-5 h-5">
                  <UploadIcon className="w-5 h-5" />
                </span>
                <span className="flex items-center gap-2">
                  <Button className="px-0" variant="link" onClick={() => {}}>
                    Click to upload
                  </Button>{" "}
                  or drag and drop
                </span>
                <span>CSV (max. 5MB)</span>
              </>
            )}
            <input
              ref={fileInputRef}
              onChange={(e) => {
                const file = e.target?.files?.[0];
                if (!file) return;
                const ext = file.name.split(".").pop()!;
                if (!["csv"].includes(ext)) {
                  setErrors({ file: "Only CSV  files are allowed" });
                  return;
                }
                setErrors({});
                setFile(file);
              }}
              type="file"
              className="absolute top-0 left-0 right-0 bottom-0 opacity-0"
            />
          </div>
          {errors?.file && <ErrorText>{errors?.file}</ErrorText>}
        </div>
        {props.type === "workflow" &&
          !runWfSimulation.isPending &&
          runWfSimulation.isSuccess && (
            <div className="font-b2 mt-4 text-neutral-black">
              You can download the simulation report from{" "}
              <Link to="/reports/simulations">Reports &gt; Simulations</Link>
            </div>
          )}
      </div>
      {props.type !== "workflow" && (
        <Footer isLoading={isPending} submit={submitSimulation} />
      )}
      {props.type === "workflow" && (
        <div className="fixed w-[680px] -translate-x-4 bottom-0 justify-end flex items-center border-t border-indigo-50 bg-neutral-0 px-4 py-2.5">
          <Button disabled={isPending} onClick={submitSimulation}>
            Run Simulation
          </Button>
        </div>
      )}
    </>
  );
}
