import classNames from "classnames";
import { DATAFLOW_TRANSITION } from "src/constants/imageConstants";
import { ChangeLogState, RuleSetNode } from "../../types";
import { getChangeLogIcon, getStatusIndicator } from "../../utils";
import { RuleSetNodeDescription } from "./RuleSetNodeDescription";
import { RuleSetRow } from "./RuleSetRow";

export const RuleSetLogCard = ({
  prevData,
  currData,
  nodeState,
}: {
  prevData: RuleSetNode | null;
  currData: RuleSetNode | null;
  nodeState: ChangeLogState;
}) => {
  return (
    <>
      {(prevData?.nodeDescription || currData?.nodeDescription) && (
        <RuleSetNodeDescription
          nodeState={nodeState}
          prevDesc={prevData?.nodeDescription}
          currDesc={currData?.nodeDescription}
        />
      )}
      {nodeState === "add" &&
        currData &&
        currData?.data.rules.length > 0 &&
        currData.data.rules.map((rule, idx) => (
          <RuleSetRow
            key={idx}
            name={rule?.description}
            prevRule={null}
            currRule={rule}
            state={rule!.state}
          />
        ))}

      {nodeState === "delete" &&
        prevData &&
        prevData?.data.rules.length > 0 &&
        prevData.data.rules.map((rule, idx) => (
          <RuleSetRow
            key={idx}
            name={rule!.description}
            prevRule={rule}
            currRule={null}
            state={rule!.state}
          />
        ))}

      {nodeState === "edit" &&
        ((prevData?.data.rules && prevData?.data.rules.length > 0) ||
          (currData?.data.rules && currData?.data.rules.length > 0)) &&
        currData?.data.rules.map((rule, idx) => {
          let node = rule;
          let prevLog = prevData?.data.rules[idx];
          if (!prevLog && node?.state === "add") {
            return (
              <RuleSetRow
                key={idx}
                name={node.description}
                prevRule={null}
                currRule={node}
                state={node.state}
              />
            );
          } else if (!node && prevLog?.state === "delete") {
            return (
              <RuleSetRow
                key={idx}
                name={prevLog.description}
                prevRule={prevLog}
                currRule={null}
                state={prevLog.state}
              />
            );
          } else {
            return (
              <RuleSetRow
                key={idx}
                name={node?.description}
                prevRule={prevLog}
                currRule={node}
                prevName={prevLog?.description}
                state="edit"
              />
            );
          }
        })}

      {((prevData?.data.transitions && prevData?.data.transitions.length > 0) ||
        (currData?.data.transitions &&
          currData?.data.transitions.length > 0)) && (
        <div>
          <div className="flex bg-neutral-25 border-y border-neutral-200 px-4 h-8 py-2 font-b2-medium text-neutral-black gap-2">
            <img src={DATAFLOW_TRANSITION} alt="dataflow_icon" className="w-4 h-4" />
            Transition
          </div>
          {nodeState === "add" &&
            currData?.data?.transitions &&
            currData?.data?.transitions?.length > 0 && (
              <div className="flex">
                <div className="border-r w-[50%] flex flex-col px-4 py-3 gap-2 overflow-none">
                  <span className="flex font-b2 text-neutral-500 m-auto"></span>
                </div>
                <div className="flex flex-col w-[50%] px-4 py-3 gap-2 left-0">
                  {currData?.data?.transitions.map((transition, idx) => (
                    <div className="flex flex-wrap gap-1.5" key={idx}>
                      <span className="flex gap-2 font-b2-medium">
                        {getStatusIndicator(transition.state)}{" "}
                        {transition.fromCondition}
                      </span>
                      <span className="text-neutral-500">is connected to</span>
                      <span className="flex font-b2-medium gap-1">
                        {getChangeLogIcon(transition.nodeType)}
                        {transition.name}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            )}

          {nodeState === "delete" &&
            prevData?.data?.transitions &&
            prevData?.data.transitions.length > 0 && (
              <div className="flex">
                <div className="border-r w-[50%] flex flex-col px-4 py-3 gap-2 overflow-none">
                  {prevData.data.transitions.map((transition, idx) => (
                    <div className="flex flex-wrap gap-1.5" key={idx}>
                      <span className="flex gap-2 font-b2-medium">
                        {getStatusIndicator(transition.state)}{" "}
                        {transition.fromCondition}
                      </span>
                      <span className="text-neutral-500">is connected to</span>
                      <span className="flex font-b2-medium gap-1">
                        {getChangeLogIcon(transition.nodeType)}
                        {transition.name}
                      </span>
                    </div>
                  ))}
                </div>
                <div className="flex flex-col w-[50%] m-auto gap-2">
                  <span className="flex font-b2 text-neutral-500 m-auto">
                    Rule is deleted
                  </span>
                </div>
              </div>
            )}

          {nodeState === "edit" &&
            currData?.data?.transitions &&
            currData?.data?.transitions.length > 0 &&
            currData?.data?.transitions.map((transition, idx) => {
              const prevTransition = prevData?.data?.transitions![idx];
              const isLast = idx === currData?.data?.transitions?.length! - 1;
              return (
                <div className="flex" key={idx}>
                  <div className="border-r w-[50%] flex flex-col px-4 overflow-none pt-3">
                    <div className="flex gap-2">
                      {prevTransition ? (
                        <div className="flex flex-wrap gap-1.5">
                          <span className="flex  gap-2 font-b2-medium">
                            {prevTransition.fromCondition}
                          </span>
                          <span className="text-neutral-500">
                            is connected to
                          </span>
                          <span className="flex font-b2-medium gap-1">
                            {getChangeLogIcon(prevTransition.nodeType)}
                            {prevTransition.name}
                          </span>
                        </div>
                      ) : (
                        <span
                          className={classNames(
                            "m-auto text-neutral-500",
                            isLast && "mb-3"
                          )}
                        >
                          Transition not present
                        </span>
                      )}
                    </div>
                  </div>
                  <div
                    className={classNames(
                      "flex flex-col w-[50%] px-4 left-0 pt-3",
                      isLast && "pb-3"
                    )}
                  >
                    <div className="flex gap-2">
                      {transition ? (
                        <div className="flex flex-wrap gap-1.5">
                          <span className="flex  gap-2 font-b2-medium">
                            {getStatusIndicator(transition.state)}{" "}
                            {transition.fromCondition}
                          </span>
                          <span className="text-neutral-500">
                            is connected to
                          </span>
                          <span className="flex font-b2-medium gap-1">
                            {getChangeLogIcon(transition.nodeType)}
                            {transition.name}
                          </span>
                        </div>
                      ) : (
                        <span className="m-auto text-neutral-500">
                          Transition not present
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      )}
    </>
  );
};
