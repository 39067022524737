import { NO_LOG_GENERATED } from "src/constants/imageConstants";

export const NoChangesState = () => {
  return (
    <div className="mx-auto flex flex-col mt-36 items-center">
      <img
        className="w-[111px] h-[145px]"
        src={NO_LOG_GENERATED}
        alt="log_icon"
      />
      <span className="font-b1-medium mb-3 text-neutral-black">
        No changes detected
      </span>
      <span className="font-b2-medium text-neutral-500">
        No changes detected. Ensure the version has modifications to view the
        changelogs.
      </span>
    </div>
  );
};
