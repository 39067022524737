import { BranchNode, ChangeLogState } from "../../types";
import { BranchNodeDescription } from "./BranchNodeDescription";
import { BranchNodeRow } from "./BranchNodeRow";

export const BranchNodeLogCard = ({
  prevData,
  currData,
  nodeState,
}: {
  prevData: BranchNode | null;
  currData: BranchNode | null;
  nodeState: ChangeLogState;
}) => {
  return (
    <>
      {(prevData?.nodeDescription || currData?.nodeDescription) && (
        <BranchNodeDescription
          nodeState={nodeState}
          prevDesc={prevData?.nodeDescription}
          currDesc={currData?.nodeDescription}
        />
      )}
      {nodeState === "add" &&
        currData &&
        currData?.data.length > 0 &&
        currData.data.map((node, idx) => (
          <BranchNodeRow
            key={idx}
            name={node?.name}
            prevNode={null}
            currNode={node}
            state="add"
          />
        ))}

      {nodeState === "delete" &&
        prevData &&
        prevData?.data.length > 0 &&
        prevData.data.map((node, idx) => (
          <BranchNodeRow
            key={idx}
            name={node?.name}
            prevNode={node}
            currNode={null}
            state="delete"
          />
        ))}

      {nodeState === "edit" &&
        ((prevData && prevData?.data.length > 0) ||
          (currData && currData?.data.length > 0)) &&
        currData?.data.map((node, idx) => {
          let prevNode = prevData?.data[idx];
          if (!prevNode && node?.state === "add") {
            return (
              <BranchNodeRow
                key={idx}
                name={node.name}
                state="add"
                prevNode={null}
                currNode={node}
              />
            );
          } else if (!node && prevNode?.state === "delete") {
            return (
              <BranchNodeRow
                key={idx}
                name={prevNode.name}
                state="delete"
                prevNode={prevNode}
                currNode={null}
              />
            );
          } else {
            return (
              <BranchNodeRow
                key={idx}
                name={node?.name}
                state="edit"
                prevNode={prevNode!}
                currNode={node}
              />
            );
          }
        })}
    </>
  );
};
