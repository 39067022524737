import {
  ChangeLogState,
  WorkflowPolicyNode,
  WorkflowPolicyTransition,
} from "../../types";
import { getChangeLogIcon, getStatusIndicator } from "../../utils";

export const PolicyWfLogCard = ({
  prevData,
  currData,
  cardType,
  state,
}: {
  prevData: WorkflowPolicyNode | null;
  currData: WorkflowPolicyNode | null;
  cardType: "policy" | "workflow";
  state: ChangeLogState;
}) => {
  const renderTransitions = (
    data: WorkflowPolicyTransition | null | undefined,
    isCurrData: boolean
  ) => {
    return data?.transitions ? (
      <div className="flex flex-col gap-3">
        {data.transitions.map((it, idx) => (
          <div key={idx} className="flex flex-wrap gap-1.5">
            <span className="flex gap-2 font-b2-medium">
              {isCurrData && it?.state && getStatusIndicator(it.state)}
              {it?.fromCondition}
            </span>
            <span className="text-neutral-500">is connected to</span>
            <span className="flex font-b2-medium gap-1">
              {getChangeLogIcon(it?.nodeType)}
              {it?.name}
            </span>
          </div>
        ))}
      </div>
    ) : (
      <span className="text-neutral-500">Transition not present</span>
    );
  };

  const renderMessage = (message: string) => (
    <div className="w-[50%] m-auto flex">
      <span className="text-neutral-500 m-auto">{message}</span>
    </div>
  );

  return (
    <div className="flex border-t">
      <div className="w-[50%] border-r px-4 py-3 gap-2">
        {state === "add"
          ? renderMessage("Workflow not present")
          : state === "delete"
          ? renderTransitions(prevData?.data, false)
          : state === "edit"
          ? renderTransitions(prevData?.data, false)
          : renderMessage("Transition not present")}
      </div>

      <div className="w-[50%] px-4 py-3 gap-2">
        {state === "delete"
          ? renderMessage("Workflow is deleted")
          : (state === "add" || state === "edit") && currData?.data
          ? renderTransitions(currData.data, true)
          : renderMessage("Transition not present")}
      </div>
    </div>
  );
};
