import Shimmer from "src/components/Shimmer";
import { ReactComponent as CloseIcon } from "@assets/icons/x-close.svg";
import { useQuery } from "@tanstack/react-query";
import { exploreSentinelQuery, useExploreSentinelNext } from "../../queries";
import OverviewHeaderCard from "./../OverviewHeaderCard";
import { analyticsInstance } from "src/config/event-analytics";
import { OverviewActions } from "src/constants/EventAnalytics";
import { ExploreOverviewCardProps, ExploreSentinel } from "./ExploreOverviewCard.types";
import { ExploreSentinelData } from "./ExploreOverviewCard.constants";
import ExploreOverviewStepCard from "./ExploreOverviewStepCard";
import ExploreOverviewStepRow from "./ExploreOverviewStepRow";


const ExploreOverviewCard = ({ setModelScreen }: ExploreOverviewCardProps) => {
  const { data: exploreSentinel, isFetching } = useQuery(
    exploreSentinelQuery()
  );
  const nextStep = useExploreSentinelNext();
  const curStep = Number(exploreSentinel?.data.data.stepNumber);

  if (!curStep || curStep > 6) {
    return <></>;
  }

  const handleOnClose = () => {
    setModelScreen(false);
    nextStep.mutate({
      stepNumber: "0",
    });
    analyticsInstance.triggerAnalytics(OverviewActions.CLOSE_EXPLORE_SENTINEL);
  };

  return (
    <OverviewHeaderCard
      className="mt-8"
      title={
        <div className="flex items-center justify-between w-full">
          Explore Sentinel
          <div className="flex gap-4">
            <CloseIcon
              className="text-neutral-500 hover:text-neutral-black cursor-pointer w-4 h-4"
              onClick={() => handleOnClose()}
            />
          </div>
        </div>
      }
      content={
        isFetching ? (
          <div className="p-4 flex">
            <Shimmer w="100%" h="156px" />
          </div>
        ) : (
          <div className="p-4 flex flex-row">
            <div className="flex flex-col">
              <>
                {ExploreSentinelData.map((el: ExploreSentinel, id: number) => {
                  return (
                    <ExploreOverviewStepRow key={id} id={id + 1} el={el} curStep={curStep} />
                  );
                })}
              </>
            </div>
            <ExploreOverviewStepCard currentStepId={curStep - 1} />
          </div>
        )
      }
    />
  );
};



export default ExploreOverviewCard;
