import { useRef, useState } from "react";
import { useWorkflowContext } from "@screens/workflow/WorkflowContext";
import { getWorkflowKeywordsQuery } from "@screens/workflow/queries";
import ModelSetTableItem from "@screens/workflow/studio/components/ModelSet/ModelSetTableItem";
import { useGetNodeIconStyles } from "@screens/workflow/studio/hooks/useIsExpand";
import useKeywordsFromWorkflowKeywords from "@screens/workflow/studio/hooks/useKeywordsFromWorkflowKeywords";
import {
  getSuggestionList,
  getUniqueItemName,
} from "@screens/workflow/studio/utils";
import { getModelSetItemIcon } from "@screens/workflow/utils";
import Dropdown from "@components/DropDown";
import Loader from "@components/Loader";
import Shimmer from "@components/Shimmer";
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  restrictToParentElement,
  restrictToVerticalAxis,
} from "@dnd-kit/modifiers";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { useQueryClient } from "@tanstack/react-query";
import ModelSetExpressionItem from "./ModelSetExpressionItem";
import { useAddModelSetItem, useUpdateModelSetItemOrder } from "./queries";
import { ModelSetItem } from "./types";
import { useLookupFunctionsInput } from "src/screens/create-policy/queries";
import useMonacoContext from "../../MonacoContext";
import { analyticsInstance } from "src/config/event-analytics";
import { PolicyStudioActions } from "src/constants/EventAnalytics";

interface ModelExpressionsProps {
  expressions: ModelSetItem[];
  workflowId: string;
  nodeId: string;
  isLoading: boolean;
  isFetching: boolean;
  nodeName: string;
}

const ModelSetItems = ({
  expressions,
  workflowId,
  nodeId,
  isLoading,
  isFetching,
  nodeName,
}: ModelExpressionsProps) => {
  const addModelMutation = useAddModelSetItem(workflowId, nodeId);
  const [expressionInEdit, _setExpressionInEdit] = useState<string | null>(
    null
  );
  const itemsRef = useRef<Map<string, HTMLDivElement> | null>(null);
  const sensors = useSensors(useSensor(PointerSensor));

  const updateModelOrderMutation = useUpdateModelSetItemOrder();
  const queryClient = useQueryClient();

  const keywordsQuery = useKeywordsFromWorkflowKeywords(workflowId, nodeId, nodeName);

  const { isWorkflowEditable, workflow } = useWorkflowContext();

  const iconStyles = useGetNodeIconStyles();
  const { autocompleteProvider } = useMonacoContext();

  const lookupFunctionInputs = useLookupFunctionsInput({
    entityId: workflowId || "",
    entityType: "workflow",
  });

  const setExpressionInEdit = (newExpressionId: string | null) => {
    if (newExpressionId) {
      let modelsForAutoComplete = [];
      if (expressions) {
        for (const exp of expressions) {
          if (exp.id === newExpressionId) break;
          modelsForAutoComplete.push(exp.name);
        }
      }

      const { sourceList, keywords } = getSuggestionList(keywordsQuery);

      autocompleteProvider({
        nodeId,
        sourceList: [
          ...sourceList.filter((source) => source !== nodeName),
          ...modelsForAutoComplete,
        ],
        keywords,
        autoCompleteRecords: {},
        lookupFunctionInputs: lookupFunctionInputs?.data?.data,
      });
    }
    _setExpressionInEdit(newExpressionId);
  };

  const onAddNewNode = (type: "expression" | "decisionTable") => {
    if (addModelMutation.isPending) return;

    analyticsInstance.triggerAnalytics(
      PolicyStudioActions.MODELSET,
      {
        count:(expressions ?? []).length + 1, 
        type:type,
        policy_name:workflow?.policyName, 
        version:workflow?.policyVersion
      }
    );

    setExpressionInEdit(null);
    const length = expressions.length || 0;
    const modelName = expressions.map((e) => e.name) || [];
    addModelMutation.mutate(
      type === "expression"
        ? {
            expression: {
              name: getUniqueItemName(modelName, length, "model_"),
              body: "1",
              decisionTableRules: {},
              seqNo: length,
              type: "expression",
            },
          }
        : {
            expression: {
              name: getUniqueItemName(modelName, length, "model_"),
              seqNo: length,
              body: "",
              decisionTableRules: {
                default: "",
                rows: [],
                headers: [],
              },
              type: "decisionTable",
            },
          },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(getWorkflowKeywordsQuery());
        },
      }
    );
  };

  function getRefMap() {
    if (!itemsRef.current) {
      itemsRef.current = new Map();
    }
    return itemsRef.current;
  }

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    const items = expressions.map((c) => c.id) ?? [];
    if (items.length === 0) return;

    if (over && active.id !== over.id) {
      const oldIndex = items.indexOf(active.id as string);
      const newIndex = items.indexOf(over.id as string);

      const item = expressions[oldIndex];
      updateModelOrderMutation.mutate(
        {
          nodeId,
          expressionId: item.id,
          workflowId: workflowId,
          expression: {
            name: item.name,
            body: item.body,
            decisionTableRules: item.decisionTableRules,
            seqNo: newIndex,
          },
        },
        {
          onSuccess: () =>
            queryClient.invalidateQueries(getWorkflowKeywordsQuery()),
        }
      );
    }
  };

  return (
    <div
      className="relative flex flex-col gap-2 mt-2 mb-3"
      id="exp-parent"
      tabIndex={0}
      onBlur={(e) => {
        if (!itemsRef.current) {
          return;
        }
        const isInside = Array.from(itemsRef.current.values()).some(
          (el) =>
            el.contains(e.relatedTarget) || e.relatedTarget?.id === "exp-parent"
        );
        if (!isInside) {
          setExpressionInEdit(null);
        }
      }}
    >
      {(isFetching || updateModelOrderMutation.isPending) && (
        <div className="absolute flex items-center z-10 justify-center inset-0 bg-white bg-opacity-50">
          <Loader size="xs" />
        </div>
      )}
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
        modifiers={[restrictToVerticalAxis, restrictToParentElement]}
      >
        <SortableContext
          items={expressions.map((m) => m.id) || []}
          strategy={verticalListSortingStrategy}
        >
          {isLoading && (
            <>
              <Shimmer w="100%" h="60px" />
              <Shimmer w="100%" h="60px" />
            </>
          )}
          {expressions.map((exp) => {
            if (exp.type === "expression")
              return (
                <ModelSetExpressionItem
                  showDelete={expressions.length > 1}
                  ref={(r) => {
                    const map = getRefMap();
                    if (r) {
                      map.set(exp.id, r);
                    } else {
                      map.delete(exp.id);
                    }
                  }}
                  key={exp.id}
                  expression={exp}
                  workflowId={workflowId}
                  nodeId={nodeId}
                  isEdit={exp.id === expressionInEdit}
                  setIsEdit={() => setExpressionInEdit(exp.id)}
                />
              );
            else
              return (
                <ModelSetTableItem
                  showDelete={expressions.length > 1}
                  ref={(r) => {
                    const map = getRefMap();
                    if (r) {
                      map.set(exp.id, r);
                    } else {
                      map.delete(exp.id);
                    }
                  }}
                  key={exp.id}
                  expression={exp}
                  workflowId={workflowId}
                  nodeId={nodeId}
                  isEdit={exp.id === expressionInEdit}
                  setIsEdit={() => setExpressionInEdit(exp.id)}
                />
              );
          })}
        </SortableContext>
      </DndContext>
      {isWorkflowEditable &&
        (isLoading ? (
          <Shimmer className="w-[120px] h-[1.5em]" />
        ) : (
          <Dropdown
            value={""}
            className="h-7"
            onChange={(e: "expression" | "decisionTable") => onAddNewNode(e)}
          >
            <Dropdown.Button className="focus:ring-0 relative bg-white rounded-md py-2 px-4 ml-5 text-left font-b2-medium text-neutral-black !h-7">
              Add Model
            </Dropdown.Button>
            <Dropdown.Options className="absolute z-20 rounded-md bg-white !max-w-[148px] !w-[148px] translate-x-[14px]">
              <Dropdown.Option
                className="flex gap-2 !px-2 font-b2-medium"
                value="expression"
              >
                {getModelSetItemIcon("expression", {
                  style: iconStyles,
                  className:
                    "[&>path]:stroke-neutral-500 [&>path]:fill-transparent",
                })}
                Expression
              </Dropdown.Option>
              <Dropdown.Option
                className="flex gap-2 !px-2 font-b2-medium"
                value="decisionTable"
              >
                {getModelSetItemIcon("decisionTable", {
                  style: iconStyles,
                  className:
                    "[&_path]:!stroke-neutral-500 [&_path]:fill-transparent",
                })}
                Decision Table
              </Dropdown.Option>
            </Dropdown.Options>
          </Dropdown>
        ))}
    </div>
  );
};

export default ModelSetItems;
