import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { analyticsInstance } from "src/config/event-analytics";
import { PolicyStudioActions } from "src/constants/EventAnalytics";
import { useWorkflowContext } from "src/screens/workflow/WorkflowContext";
import { getOperationType } from "src/screens/workflow/utils";
import {
  getErrors,
  getWorkflowKeywordsQuery,
  getCustomInputsQuery,
  useGetCustomInputs,
  useSaveWorkflowCustomInputs,
} from "@screens/workflow/queries";
import Button from "@components/Button";
import QueryWrapper from "@components/QueryWrapper";
import Shimmer from "@components/Shimmer";
import { useQueryClient } from "@tanstack/react-query";
import {
  generateAndDownloadFile,
  getNetworkErrorText,
  isEqual,
  notify,
} from "@utils/utils";
import { InputForm } from "./InputForm";
import { validateArray, validationSchema } from "./InputForm.utils";
import {
  InputError,
  InputParamDataEnum,
  InputParamOperation,
  JSONConfigType,
  SaveInputType,
  WorkflowCustomInputType,
} from "./InputParameters.types";
import InputJsonModal from "./JsonInput";
import { JsonNodeType } from "./JsonInput/JsonInput.types";

const InputParameters = () => {
  const queryClient = useQueryClient();
  const { workflowId } = useParams();
  const inputListQuery = useGetCustomInputs(workflowId || "", "workflow");
  const { isWorkflowEditable, workflow } = useWorkflowContext();
  const [jsonConfig, setJsonConfig] = useState<JSONConfigType | null>(null);
  const [markAllEnable, setMarkAllEnable] = useState<boolean>(false);

  const isNullableEnabled = !!workflow?.settings.isNullableInputsAllowed;

  const saveCustomInputMutation = useSaveWorkflowCustomInputs();

  const [inputParamList, setInputParamList] = useState<
    Array<WorkflowCustomInputType>
  >([]);
  const [errorList, setErrorList] = useState<InputError>([]);

  useEffect(() => {
    if (inputListQuery?.data?.data) {
      setInputParamList(JSON.parse(JSON.stringify(inputListQuery?.data?.data)));
      setErrorList(Array(inputListQuery?.data?.data.length));
    }
  }, [inputListQuery?.data?.data]);

  const deleteRow = (index: number) => {
    setInputParamList((prev) => {
      const newList = [...prev];
      if(newList[index].id.includes('dummy')){
          return newList.filter(item => item.id !== newList[index].id);
      }
      newList[index].operation = InputParamOperation.DELETE;
      return newList;
    });
  };


  const addRow = (e: any) => {
    setInputParamList((prev) =>
      prev.concat({
        id: `dummy_${prev.length}`,
        operation: InputParamOperation.ADD,
        dataType: InputParamDataEnum.Text,
        name: "",
        defaultInput: "",
        isNullable: false,
      })
    );
    setErrorList((prev) => prev.concat(undefined));

    analyticsInstance.triggerAnalytics(
      PolicyStudioActions.SIDEBAR_INPUT_PARAMETERS_ADD_PARAMETER,
      {
        bucket_name: workflow?.policyName ?? "",
        version: workflow?.policyVersion ?? "",
      }
    );

    e.stopPropagation();
  };

  const onSave = () => {
    if (!workflowId) {
      return;
    }

    const errors = validateArray([...inputParamList], validationSchema, ['name'],{isNullableEnabled, checkConfig:true});
    setErrorList(errors);
    if (errors.some((item) => item)) {
      return;
    }

    const totalCount = {
      text: 0,
      number: 0,
      boolean: 0,
    };
    saveCustomInputMutation.mutate(
      {
        inputs: inputParamList.map((item, index) => {
            const { id, ...rest } = item;
            if (rest.dataType === "text") {
              totalCount.text += 1;
            }
            if (rest.dataType === "number") {
              totalCount.number += 1;
            }
            if (rest.dataType === "boolean") {
              totalCount.boolean += 1;
            }
            const obj: SaveInputType = {
              ...rest,
              parentID: "",
              isArray: false,
              schema: null,
              isNullable: isNullableEnabled?rest.isNullable:!!rest.defaultInput,
              defaultInput:
                isNullableEnabled && rest.defaultInput === "" && rest.isNullable
                  ? null
                  : rest.defaultInput,
            };
            if (!id.includes("dummy")) {
              obj.id = id;
              if(!obj.operation && !isEqual(inputListQuery?.data?.data[index] , item)){
                obj.operation = getOperationType(item);
              }
            }
            if (isNullableEnabled) {
              obj.defaultInput =
                rest.defaultInput === "" && rest.isNullable
                  ? null
                  : rest.defaultInput;
              obj.isNullable = rest.isNullable;
            } else {
              obj.isNullable = !!rest.defaultInput;
            }
            return obj;
          }).filter((item) => item.operation),
        policyID: workflowId,
        policyType: "workflow",
      },
      {
        onSuccess: () => {
          analyticsInstance.triggerAnalytics(
            PolicyStudioActions.SIDEBAR_INPUT_PARAMETERS_DOWNLOAD_SAVE,
            {
              bucket_name: workflow?.policyName ?? "",
              version: workflow?.policyVersion ?? "",
              ...totalCount,
            }
          );
          queryClient.invalidateQueries(getCustomInputsQuery());
          queryClient.invalidateQueries(getWorkflowKeywordsQuery());
          queryClient.invalidateQueries(getErrors(workflowId));
          notify({
            title: "Saved",
            text: "Workflow inputs updated",
            type: "success",
          });
        },
        onError: (err) => {
          notify({
            title: "Error",
            text: getNetworkErrorText(err),
          });
        },
      }
    );
  };

  const exportInputs = () => {
    if (!inputListQuery.isSuccess) return;
    if (workflow?.settings.isNullableInputsAllowed)
      generateAndDownloadFile(
        `name,dataType,nullable,defaultInput
${inputListQuery.data.data
  .map(
    (item) =>
      `${item.name},${item.dataType},${item.isNullable},${item.defaultInput ?? ''}`
  )
  .join("\n")}`,
        `${workflow?.name}_inputs.csv`
      );
    else
      generateAndDownloadFile(
        `name,dataType,defaultInput
${inputListQuery.data.data
  .map((item) => `${item.name},${item.dataType},${item.defaultInput}`)
  .join("\n")}`,
        `${workflow?.name}_inputs.csv`
      );

    analyticsInstance.triggerAnalytics(
      PolicyStudioActions.SIDEBAR_INPUT_PARAMETERS_EXPORT,
      {
        bucket_name: workflow?.policyName ?? "",
        version: workflow?.policyVersion ?? "",
      }
    );
  };

  const handleMarkAllNullable = () => {
    if (inputParamList?.length > 0) {
      setMarkAllEnable(!markAllEnable);
      setInputParamList((prev) => {
        const newParamList = [...prev];
        return newParamList.map((item) => {
          if (item.operation !== InputParamOperation.DELETE) {
            item.isNullable = !markAllEnable;
          }
          return item;
        });
      });

      analyticsInstance.triggerAnalytics(
        PolicyStudioActions.SIDEBAR_INPUT_PARAMETERS_MARK_ALL_AS_NULLABLE,
        {
          bucket_name: workflow?.policyName ?? "",
          version: workflow?.policyVersion ?? "",
        }
      );
    }
  };

  const inputTypeSelection = (value: InputParamDataEnum, index: number) => {

    const newParamList = [...inputParamList];
    newParamList[index] = {
      ...newParamList[index],
      dataType: value,
    };

    setInputParamList(newParamList);

    const errors = validateArray([...newParamList], validationSchema, ['name'],{isNullableEnabled});
    setErrorList(errors);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();

    const [i, valueProperty] = e.target.id.split("_");

    const index = Number(i);

    let newValue: Partial<WorkflowCustomInputType> = {};

    switch (valueProperty) {
      case "name":
        newValue = { name: e.target.value.trim() };
        break;
      case "defaultInput":
        newValue = { defaultInput: e.target.value.trim() };
        break;
      case "nullable":
        newValue = { isNullable: Boolean(e.target.checked) };
        break;
    }

    const newParamList = [...inputParamList];
    newParamList[index] = {
      ...newParamList[index],
      ...newValue,
    };

    setInputParamList(newParamList);

    const errors = validateArray([...newParamList], validationSchema, ['name'],{isNullableEnabled});
    setErrorList(errors);

  };

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    if ((e.target as HTMLElement).id === "addInput") {

      const errors = validateArray([...inputParamList], validationSchema, ['name'],{isNullableEnabled, checkConfig:true});
      setErrorList(errors);
      if (errors.some((item) => item)) {
        return;
      }
      addRow(e);
    } else {
      const [index, valueProperty] = (e.target as HTMLElement).id.split("_");
      if (valueProperty === "delete") {
        deleteRow(Number(index));
      } else if (valueProperty === "openJson") {

        const errors = validateArray([...inputParamList], validationSchema, ['name'],{isNullableEnabled});
        setErrorList(errors);
        if (errors[Number(index)]) {
          return;
        }
        setJsonConfig({
          id: inputParamList[Number(index)].id,
          index: Number(index),
        });
      }
    }
  };

  const mapInputId = (id: string, jsonData: JsonNodeType | null) => {
    if (jsonConfig?.id) {
      setInputParamList((prev) => {
        const newParamList = [...prev];
        newParamList[jsonConfig.index] = {
          ...newParamList[jsonConfig.index],
          id,
          schema: jsonData,
        };
        return newParamList;
      });
      setErrorList((prev) => {
        const newErrorList = [...prev];
        delete newErrorList?.[jsonConfig.index]?.defaultInput;
        if (!Object.keys(newErrorList?.[jsonConfig.index] ?? {}).length) {
          newErrorList[jsonConfig.index] = undefined;
        }
        return newErrorList;
      });

      queryClient.invalidateQueries(getWorkflowKeywordsQuery());
      queryClient.invalidateQueries(getErrors(workflowId));
    }
    setJsonConfig(null);
  };

  const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    e.stopPropagation();
    const errors = validateArray([...inputParamList], validationSchema, ['name'],{isNullableEnabled});
    setErrorList(errors);
  };

  const appendInputParamList = (list: Array<WorkflowCustomInputType>) => {
    const newList = [...inputParamList].concat(list);
    setInputParamList(newList);
    const errors = validateArray([...newList], validationSchema, ['name'],{isNullableEnabled});
    setErrorList(errors);
  };

  return (
    <>
      <QueryWrapper
        query={inputListQuery}
        loader={<Shimmer className="w-[calc(100%-32px)] mx-4 h-[150px] mt-4" />}
      >
        {() => (
          <div
            className="flex flex-col h-full pb-10 overflow-auto"
            onChange={handleChange}
            onClick={handleClick}
            onBlur={onBlur}
          >
            <InputForm
              inputParamList={inputParamList}
              errorList={errorList}
              isNullableEnabled={isNullableEnabled}
              isWorkflowEditable={isWorkflowEditable}
              handleMarkAllNullable={handleMarkAllNullable}
              appendInputParamList={appendInputParamList}
              inputTypeSelection={inputTypeSelection}
            />
          </div>
        )}
      </QueryWrapper>
      <div className="absolute mt-auto bottom-0 w-[479px] flex justify-end py-2 pr-2 bg-neutral-0 border-t border-neutral-100 gap-2">
        <Button onClick={() => exportInputs()} variant="outline">
          Export
        </Button>
        <Button
          onClick={onSave}
          disabled={isEqual(inputListQuery?.data?.data, inputParamList)}
        >
          Save
        </Button>
      </div>
      {jsonConfig && (
        <InputJsonModal
          open={Boolean(jsonConfig)}
          handleClose={() => setJsonConfig(null)}
          mapInputId={mapInputId}
          inputInfo={inputParamList[jsonConfig?.index]}
          isNullableEnabled={isNullableEnabled}
          readOnly={!isWorkflowEditable}
        />
      )}
    </>
  );
};

export default InputParameters;
