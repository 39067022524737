import { ReactComponent as DeleteIcon } from "@assets/icons/workflow/trash-02.svg";
import Dropdown from "@components/DropDown";
import Input from "@components/Input";
import { clsx } from "clsx";
import { Fragment } from "react";
import { Controller, UseFormReturn } from "react-hook-form";


const OutcomeFieldRow = ({
    field, 
    index,
    form,
    isWorkflowEditable,
    deleteOutcomeParam,
    isMandatory
}:{
    field:{
        type: "text" | "number" | "boolean";
        name: string;
        isMandatory: boolean;
        id: string;
    },
    index:number,
    isWorkflowEditable:boolean,
    isMandatory:boolean,
    deleteOutcomeParam:(index:number) => void,
    form:UseFormReturn<{
        variables: Array<{
          type: "text" | "number" | "boolean";
          name: string;
          isMandatory: boolean;
        }>;
      }>
}) => {

  const handleDelete = () => {
    deleteOutcomeParam(index);
  }

  return (
    <Fragment key={field.id}>
      <Input
        placeholder="Enter the Name"
        error={
          form.formState.errors.variables?.[index]?.name?.message
        }
        disabled={!isWorkflowEditable || isMandatory}
        {...form.register(`variables.${index}.name`, {
          maxLength: { value: 50, message: "Max 50 characters" },
          required: {
            value: true,
            message: "Name is required",
          },
          pattern: {
            value: /[a-z_A-Z]/,
            message:
              "Invalid variable name, cannot start with numbers or contain special characters (except '_')",
          },
        })}
      />
      <Controller
        control={form.control}
        name={`variables.${index}.type`}
        defaultValue="text"
        rules={{
          required: {
            value: true,
            message: "Type is required",
          },
        }}
        render={({ field, fieldState }) => {
          return (
            <Dropdown
              disabled={!isWorkflowEditable}
              onChange={field.onChange}
              className={clsx(
                "h-[27px]",
                (isMandatory || !isWorkflowEditable) &&
                  "col-span-2"
              )}
            >
              <Dropdown.Button
                className={clsx(
                  "min-w-full text-neutral-black",
                  fieldState.error?.message && "!border-error-500"
                )}
              >
                {field.value ? (
                  field.value
                ) : (
                  <span className="text-neutral-300">Select</span>
                )}
              </Dropdown.Button>
              <Dropdown.Options>
                <Dropdown.Option value="text">
                  Text
                </Dropdown.Option>
                <Dropdown.Option value="number">
                  Number
                </Dropdown.Option>
                <Dropdown.Option value="boolean">
                  Boolean
                </Dropdown.Option>
              </Dropdown.Options>
            </Dropdown>
          );
        }}
      />
      {(isWorkflowEditable && !isMandatory) && (
          <DeleteIcon
            className="cursor-pointer h-[27px] [&:hover>path]:stroke-error-500 w-4 "
            onClick={handleDelete}
          />
        )}
    </Fragment>
  );
};

export default OutcomeFieldRow;
