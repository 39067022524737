import { format, parseISO } from "date-fns";
import StatusChip from "src/components/StatusChip";
import { analyticsInstance } from "src/config/event-analytics";
import { PolicyStudioActions } from "src/constants/EventAnalytics";
import { DOWNLOAD_ICON } from "src/constants/imageConstants";
import { Workflow } from "src/screens/workflow/types";
import { getFileType, getInitial, truncateString } from "src/utils/utils";

export const WorkflowDetails = ({
  workflowInfo,
}: {
  workflowInfo: Workflow;
}) => {

  const handleDownload = () => {
    analyticsInstance.triggerAnalytics(
      PolicyStudioActions.DOWNLOAD_ATTACH_DOC,
      {
        policy_name: workflowInfo?.policyName, 
        version: workflowInfo?.policyVersion, 
        doc_name:workflowInfo.optionalDocFileName,
        doc_type:getFileType(workflowInfo?.optionalDocFileName ?? "")
      }
    );
  };

  return (
    <div className="flex flex-col px-6 py-4 gap-4 h-fit">
      <div className="flex items-center gap-10">
        <label className="font-b2 text-neutral-500 w-20">Name</label>
        <p className="font-b1 text-neutral-black">
          {workflowInfo.policyBucketName}
        </p>
      </div>
      <div className="flex items-center gap-10">
        <label className="font-b2 text-neutral-500 w-20">Version</label>
        <p className="font-b1 text-neutral-black">({workflowInfo.version})</p>
      </div>
      <div className="flex items-center gap-10">
        <label className="font-b2 text-neutral-500 w-20">Program</label>
        <p className="font-b1 text-neutral-black">{workflowInfo.program}</p>
      </div>
      <div className="flex items-center gap-10">
        <label className="font-b2 text-neutral-500 w-20">Status</label>
        <StatusChip status={workflowInfo.status!} />
      </div>
      <div className="flex items-center gap-10">
        <label className="font-b2 text-neutral-500 w-20">Created by</label>

        <span className="flex gap-2 items-center font-b1">
          <span className="w-6 h-6 flex justify-center items-center rounded-full bg-neutral-900 text-neutral-0 font-medium text-[11px]">
            {getInitial(workflowInfo.createdBy!)}
          </span>
          {truncateString(workflowInfo.createdBy!, 25)}
        </span>
      </div>
      <div className="flex items-center gap-10">
        <label className="font-b2 text-neutral-500 w-20">Created On</label>
        <p className="font-b1 text-neutral-black">
          {format(
            parseISO(workflowInfo.createdAt!),
            String.raw`dd MMM, yyyy 'at' hh:mmaaaaa'm`
          )}
        </p>
      </div>
      <div className="flex items-center gap-10">
        <label className="font-b2 text-neutral-500 w-20">Attachments</label>
        {workflowInfo.optionalDocURL ? (
          <a
            className="flex gap-2 items-center"
            href={workflowInfo.optionalDocURL}
            download={workflowInfo.optionalDocFileName}
            onClick={handleDownload}
          >
            <p className="font-b1 text-neutral-black">
              {workflowInfo.optionalDocFileName ?? ""}
            </p>
            <img src={DOWNLOAD_ICON} alt="download_icon" />
          </a>
        ) : (
          <p className="font-b1-medium text-neutral-400">No files attached</p>
        )}
      </div>
      <div className="flex items-center gap-10">
        <label className="font-b2 text-neutral-500 w-20">Description</label>
        <p className="font-b1 text-neutral-black">{workflowInfo.description}</p>
      </div>
    </div>
  );
};
