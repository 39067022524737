import {
  differenceInDays,
  endOfDay,
  format,
  parse,
  startOfDay,
  subDays,
} from "date-fns";
import { useState } from "react";
import { PERMISSIONS_TYPE } from "src/constants/permissionsConstant";
import handIcon from "@assets/icons/image-296.svg";
import Dropdown from "@components/DropDown";
import ProtectedComponent from "@components/ProtectedComponent";
import DateFilter from "@components/Searchbar/filters/DateFilter";
import Shimmer from "@components/Shimmer";
import { DATE_FORMAT } from "@config";
import { useAppState } from "@context/AppProvider";
import useBreadcrumbs from "@context/BreadcrumbsContext";
import { PermissionTypes, ProgramTemplate } from "@types";
import { OverviewChart, StatCards } from "./components";
import DeploymentHistoryCard from "./components/DeploymentHistoryCard";
import ExploreOverviewCard from "./components/explore-overview-card";
import TriggeredMonitorsCard from "./components/TriggeredMonitorsCard";
import { useProgramOverviewTemplates } from "./queries";
import { analyticsInstance } from "src/config/event-analytics";
import { OverviewActions } from "src/constants/EventAnalytics";

type DateRange = {
  startDate: string;
  endDate: string;
};

const Overview = () => {
  const [selectedDateFilter, setSelectedDateFilter] = useState<DateRange>({
    startDate: format(subDays(new Date(), 7), DATE_FORMAT),
    endDate: format(new Date(), DATE_FORMAT),
  });

  const [program, setProgram] = useState<
    Pick<ProgramTemplate, "program" | "label" | "id">
  >({ program: "all", label: "All Programs", id: "" });

  const [modelScreen, setModelScreen] = useState(true);

  const { data: programList, isPending: isProgramListPending } =
    useProgramOverviewTemplates();

  const { appState } = useAppState();

  useBreadcrumbs([
    {
      name: "Overview",
      link: "#",
    },
  ]);

  const handleProgrammeSet = (e: typeof program) => {
    setProgram(e);
    analyticsInstance.triggerAnalytics(OverviewActions.PROGRAM, {program_name: e.label});
  }

  const handleDateFilterSelection = (e: DateRange) => {
    setSelectedDateFilter(e);
    analyticsInstance.triggerAnalytics(OverviewActions.TIME_PERIOD,{date_range: e});
  }

  const isGroupedByHour =
    !!selectedDateFilter.startDate &&
    !!selectedDateFilter.endDate &&
    differenceInDays(
      parse(selectedDateFilter.endDate, DATE_FORMAT, new Date()),
      parse(selectedDateFilter.startDate, DATE_FORMAT, new Date())
    ) <= 7;

  return (
    <div className="flex max-h-screen w-full flex-col bg-white h-full pt-12 overflow-auto hide-scrollbar">
      <h1 className="flex items-center font-medium text-[24px] text-neutral-black mx-auto pt-10 pb-4">
        Welcome back, {appState.name}{" "}
        <img
          alt=""
          src={handIcon}
          className="ml-2 w-7 h-7 animate-hi-wave"
          style={{ animationDelay: "500ms" }}
        />
      </h1>

      <div className="mx-auto text-neutral-500 gap-2 flex whitespace-nowrap items-center font-b2 mb-2">
        Showing data from
        <Dropdown
          value={program}
          className="h-7"
          onChange={handleProgrammeSet}
        >
          <Dropdown.Button className="focus:ring-0 relative bg-white rounded-md py-2 px-4 text-left font-b2-medium text-neutral-black !h-7">
            {program.label}
          </Dropdown.Button>
          <Dropdown.Options className="absolute z-20 rounded-md bg-white !max-w-[148px] !w-[148px] translate-x-[14px]">
            <Dropdown.Option
              key={"all"}
              value={{ program: "all", label: "All Programs", id: "" }}
            >
              All Programs
            </Dropdown.Option>
            {programList?.data.map((p) => (
              <Dropdown.Option
                key={p.id}
                value={{ program: p.program, id: p.id, label: p.label }}
              >
                <div>{p.label}</div>
              </Dropdown.Option>
            ))}
          </Dropdown.Options>
        </Dropdown>
        <DateFilter
          onChange={handleDateFilterSelection}
          panelClassNames="-translate-x-[175px]"
          defaultText="Last 7 days"
          showShortcuts
          onClear={() => {
            setSelectedDateFilter({
              startDate: format(startOfDay(new Date()), DATE_FORMAT),
              endDate: format(endOfDay(new Date()), DATE_FORMAT),
            });
          }}
        />
      </div>
      <div
        className="flex h-full flex-col mx-auto px-0.5"
        style={{
          width: "clamp(1000px, 90%, 1128px)",
        }}
      >
        {modelScreen ? (
          <ExploreOverviewCard setModelScreen={setModelScreen} />
        ) : (
          ""
        )}
        <StatCards program={program} dateRange={selectedDateFilter} />

        <OverviewChart
          isGroupedByHour={isGroupedByHour}
          dateRange={selectedDateFilter}
          programID={program.id}
        />
        <div className="flex gap-5 mt-5 mb-12 pb-12">
          {isProgramListPending ? (
            <Shimmer h="415px" />
          ) : (
            <>
              <ProtectedComponent
                type={PERMISSIONS_TYPE.overview as PermissionTypes}
                action="view monitor"
              >
                <TriggeredMonitorsCard />
              </ProtectedComponent>
              <ProtectedComponent
                type={PERMISSIONS_TYPE.overview as PermissionTypes}
                action="view history"
              >
                <DeploymentHistoryCard
                  dateRange={selectedDateFilter}
                  program={program.id}
                />
              </ProtectedComponent>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

//-------------------- To be used while developing Journey card --------------------
// function JourneyDistributionCard({
//   program,
// }: {
//   program?: Pick<ProgramTemplate, "program" | "id" | "label">;
// }) {
//   const [searchQuery, setSearchQuery] = useState("");
//   const journeyList = useGetJourneyList({
//     programId: program?.id,
//   });

//   return (
//     <OverviewHeaderCard
//       className="basis-1/2 max-h-[346px] min-h-[346px] overflow-auto"
//       title="Favourite Journeys"
//       content={
//         <>
//           <div className="relative flex flex-col px-6 py-4  w-full items-center justify-start">
//             {/* <Search
//               className="!mr-0 w-full [&>div>input]:bg-neutral-0 [&>div>input]:h-8"
//               onSearchChange={(e) => setSearchQuery(e.target.value)}
//             /> */}
//             <div className="w-full space-y-1 mt-2">
//               {journeyList.data?.data.data.journeys
//                 ?.filter((j) => j.isFavourite)
//                 .filter((p) =>
//                   p.journeyName
//                     .toLowerCase()
//                     .includes(searchQuery.toLowerCase())
//                 )
//                 .map((row) => {
//                   return (
//                     <div className="flex justify-between items-center hover:bg-neutral-0 cursor-pointer rounded-md px-2.5">
//                       <span className="h-8 flex font-b2-medium text-neutral-black gap-1 items-center">
//                         <span className="text-neutral-500 font-b2-medium">
//                           {row.label.toUpperCase()}
//                         </span>
//                         <span>{row.journeyName}</span>
//                       </span>
//                       <span className="text-right">
//                         <Badge className="inline-block" variant="secondary">
//                           Traffic: {shortNumber(row.totalCount)}
//                         </Badge>
//                       </span>
//                     </div>
//                   );
//                 })}
//             </div>
//           </div>
//         </>
//       }
//     />
//   );
// }

export default Overview;
